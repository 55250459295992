import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Switch } from '@mantine/core';

import { User } from 'store/user/types';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { updateUserDetails } from 'store/user/requests';
import { receiveSingleUser } from 'store/user/actions';
import { getString } from 'strings/translation';
import setToast from 'actions/toastActions';

import styles from './Container.module.css';

interface ScanningProps {
  user: User;
}

const Scanning = ({ user }: ScanningProps) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setToastMessage = (message, type?, timeout?) => dispatch(setToast(message, type, timeout));

  const handleSubmit = async () => {
    try {
      const response = await updateUserDetails(
        {
          can_scan: !user.can_scan,
        },
        user.id,
      );
      dispatch(receiveSingleUser(response));
      setToastMessage(getString('userScanningUpdated', language));
    } catch (err) {
      setToastMessage(getString('requestFailed', language), 'error', 5000);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Switch
      checked={user.can_scan}
      className={styles.Scanning}
      disabled={isSubmitting}
      label={user.can_scan ? getString('yes', language) : getString('no', language)}
      onChange={handleSubmit}
    />
  );
};

export default Scanning;
