import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FieldType, MapboxSample, SampleType, SamplingPlanType } from 'store/fields/types';
import center from '@turf/center';
import mapboxgl, { GeoJSONSourceRaw, LngLatBoundsLike } from 'mapbox-gl';
import turfBbox from '@turf/bbox';
import { getString } from 'strings/translation';
import { RISK_FILL_COLORS, DARK_BLUE, WHITE } from 'util/mapImageryColors';
import { AnalyticType } from 'store/analytics/types';
import { BCSR, BINARY, MAP_ANALYTIC, OM_ID, SOIL_ATTRIBUTES } from 'constants/results';
import { featureCollection } from '@turf/helpers';
import {
  getMinMaxSampleQuantity,
  rangeToString,
  checkEnlargePointZone,
  sortByRiskColor,
  adjustSamplesForInterpolation,
  planHasMissingSample,
  getDisplaySamplesForAnalytic,
} from 'util/results';
import { AdminAgentAccess } from 'common';
import { FiEdit } from 'react-icons/fi';
import useMapboxGl from 'common/MapHooks';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Feature, Geometry } from 'geojson';
import { getRiskColorFill } from 'util/chartUtils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { roundThreeDecimal } from 'util/numUtils';
import {
  AWAITING_RESULTS,
  HIGH_RISK,
  MODERATE_RISK,
  NO_DATA,
  NOT_ANALYZED,
  NOT_DETECTED,
} from 'constants/fieldRisks';
import circle from '@turf/circle';
import { Box, Group, Text, Button, Space, Stack, SegmentedControl } from '@mantine/core';
import { DISPLAY_POINT_RADIUS } from 'constants/samplePlanning';
import { getFieldResultsConfig } from 'util/overviewResultsDisplay';
import { RootState } from 'store';
import acToHectares from 'util/units';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { RecommendationType } from 'store/recommendations/types';
import { getProMapLayer } from 'util/samplePlan';
import { NUTRIENT_PANEL, PRESSURE_PANEL } from 'constants/products';

import styles from './Mapbook.module.css';
import Recommendations from './Recommendations';

interface Props {
  activeAnalytic: AnalyticType;
  field: FieldType;
  samples: MapboxSample[];
  samplingPlan: SamplingPlanType;
  recommendations: RecommendationType[];
  isModifyRecommendation?: boolean;
  removeNavigation?: boolean;
}

const Mapbook = ({
  activeAnalytic,
  field,
  samples,
  samplingPlan,
  recommendations,
  isModifyRecommendation,
  removeNavigation,
}: Props) => {
  const { operationId } = useParams<{ operationId?: string }>();
  const { acreage_unit } = field.features[0].properties;
  const navigate = useNavigate();
  const { search } = useLocation();
  const browserLang = useBroswerLanguage();
  const language = new URLSearchParams(search).get('language') || browserLang;
  const [centerLongitude, centerLatitude] = center(field).geometry?.coordinates as number[];

  const proLayer = getProMapLayer(samplingPlan, activeAnalytic);

  const [mapVersion, setMapVersion] = useState(proLayer ? 'pro' : 'points');
  const mapPointsId = `${MAP_ANALYTIC}-${samplingPlan.field_id}-${samplingPlan.id}-${activeAnalytic.id}-points`;
  const mapProId = `${MAP_ANALYTIC}-${samplingPlan.field_id}-${samplingPlan.id}-${activeAnalytic.id}-pro`;
  const pointsQuantityId = `${mapPointsId}-quantity`;

  const [viewport, setViewport] = useState({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: 5.5,
  });
  const [editRecommendation, toggleEditRecommendation] = useState(false);
  const [mapHasLoaded, setMapHasLoaded] = useState(false);
  const [samplingPlanId, setSamplingPlanId] = useState<number | null>(null);
  const [analyticId, setAnalyticId] = useState(activeAnalytic.id);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);

  const { operation } = useSelector((state: RootState) => ({
    operation: state.operations.operationsById[Number(operationId)],
  }));

  useMapboxGl(
    mapContainerRef,
    mapRef,
    null,
    viewport,
    setViewport,
    () => {},
    !removeNavigation,
    null,
    null,
  );

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      map.on('load', () => {
        setMapHasLoaded(true);
      });
    }
  }, [mapRef, setMapHasLoaded]);

  const {
    planAnalytic,
    risk,
    recommendation,
    fieldAtRiskAcres,
    fieldTotalAcres,
    fieldOmQuantities,
    hasInsufficientData,
  } = getFieldResultsConfig(activeAnalytic, samplingPlan, samples, recommendations, field);

  useEffect(() => {
    const map = mapRef.current;
    if (mapHasLoaded && map) {
      const bbox = turfBbox(field) as LngLatBoundsLike;

      const source = { type: 'geojson', data: field } as GeoJSONSourceRaw;
      if (map.getLayer('field-outline')) {
        map.removeLayer('field-outline');
      }
      if (map.getSource('field-outline')) {
        map.removeSource('field-outline');
      }

      map.addLayer({
        id: `field-outline`,
        type: 'line',
        source,
        paint: { 'line-color': WHITE, 'line-width': 2 },
      });

      map.fitBounds(bbox, {
        duration: 0,
        padding: 30,
      });
      const zoom = map.getZoom();
      setViewport((prevViewport) => ({
        ...prevViewport,
        centerLatitude,
        centerLongitude,
        zoom,
      }));
    }
  }, [centerLatitude, centerLongitude, field, mapHasLoaded, mapRef]);

  const resetMapLayers = () => {
    const map = mapRef.current;
    if (mapHasLoaded && map) {
      [mapPointsId, mapProId, pointsQuantityId].forEach((layer) => {
        if (map.getLayer(layer)) {
          map.removeLayer(layer);
          map.removeSource(layer);
        }
      });
    }
  };

  useEffect(() => {
    const map = mapRef.current;
    if (mapHasLoaded && map) {
      resetMapLayers();
      if (mapVersion === 'points') {
        const displaySamples = getDisplaySamplesForAnalytic(samples, activeAnalytic);
        const validSamples = samplingPlan.is_pro
          ? displaySamples.filter((sample) => {
              const panelToSearch = [BCSR, SOIL_ATTRIBUTES].includes(activeAnalytic.category)
                ? NUTRIENT_PANEL
                : PRESSURE_PANEL;
              return sample.properties.products.includes(panelToSearch);
            })
          : displaySamples;

        const mappedFeatures = sortByRiskColor(
          validSamples.map((val) => ({
            ...val,
            geometry: checkEnlargePointZone(val, samplingPlan, 5, field)
              ? // @ts-ignore
                circle(center(val.geometry), DISPLAY_POINT_RADIUS, {
                  units: 'meters',
                  steps: 100,
                }).geometry
              : val.geometry,
          })),
        ) as Feature<Geometry, SampleType & { quantity: number }>[];

        // adjust quantity values to show asterisks if a specific processing is not available
        const analysisAdjustedSamples = adjustSamplesForInterpolation(
          mappedFeatures,
          activeAnalytic,
          samplingPlan,
        );
        const adjustedSource = {
          type: 'geojson',
          data: featureCollection(analysisAdjustedSamples),
        };
        if (!map.getLayer(mapPointsId)) {
          if (validSamples.length) {
            map.addLayer({
              id: mapPointsId,
              type: 'fill',
              source: adjustedSource as GeoJSONSourceRaw,
              paint: {
                'fill-color': ['get', 'fill-color'],
                'fill-outline-color': WHITE,
              },
            });
            map.addLayer({
              id: pointsQuantityId,
              type: 'symbol',
              // @ts-ignore
              source: adjustedSource,
              layout: {
                'text-field': ['get', 'quantity'],
                'text-allow-overlap': true,
                'text-justify': 'center',
                'text-size': 9,
              },
            });
          }
        }
      } else if (proLayer && planAnalytic?.data_summary) {
        if (!map.getLayer(mapProId)) {
          map.addSource(mapProId, {
            type: 'geojson',
            data: proLayer.geojson_uri,
          });

          map.addLayer({
            id: mapProId,
            type: 'fill',
            source: mapProId,
            paint: {
              'fill-color': [
                'interpolate',
                ['linear'],
                ['get', proLayer.layer_name.split('_')[0]],
                planAnalytic.data_summary.low[0],
                RISK_FILL_COLORS.HIGH_RISK,
                (planAnalytic.data_summary.moderate[0] + planAnalytic.data_summary.moderate[1]) / 2,
                RISK_FILL_COLORS.MODERATE_RISK,
                planAnalytic.data_summary.high[1],
                RISK_FILL_COLORS.LOW_RISK,
              ],
              'fill-opacity': 1,
            },
          });
        }
      }

      map.getStyle();
      setAnalyticId(activeAnalytic.id);
      setSamplingPlanId(samplingPlan.id);
    }
  }, [
    mapHasLoaded,
    samples,
    analyticId,
    activeAnalytic,
    samplingPlan,
    field,
    viewport.zoom,
    samplingPlanId,
    mapVersion,
  ]);

  const toggleMap = (element: string) => {
    resetMapLayers();
    setMapVersion(element);
  };

  return (
    <Box className={styles.FieldMap} id={`analytic-${activeAnalytic.id}`}>
      {planAnalytic && editRecommendation && activeAnalytic && samplingPlan && recommendation && (
        <AdminAgentAccess>
          <Recommendations
            analyticId={activeAnalytic.id}
            riskLevel={planAnalytic.risk_level}
            samplingPlan={samplingPlan}
            recommendation={recommendation}
            operation={operation}
            onClose={() => toggleEditRecommendation(false)}
          />
          <div className={styles.Overlay} />
        </AdminAgentAccess>
      )}
      <Box className={styles.FieldMapInfo}>
        <Group
          justify="space-between"
          className={styles.AnalyticHeader}
          style={{
            backgroundColor: getRiskColorFill(planAnalytic?.risk_level),
          }}
        >
          <Group gap="sm">
            <Text fw={500}>
              {activeAnalytic.name} {planAnalytic?.unit && `(${planAnalytic.unit})`}
            </Text>
            <Text className={styles.HideForPrint}>
              <b>{getString('risk', language)}:</b> {getString(risk, language)}
            </Text>
          </Group>
          {![NO_DATA, NOT_ANALYZED, NOT_DETECTED].includes(risk) &&
            planAnalytic?.quantity !== null && (
              <Button
                variant="white"
                className={styles.HideForPrint}
                onClick={() =>
                  navigate(
                    `/results/analytic/${operationId}/${samplingPlan.field_id}/${samplingPlan.id}/${activeAnalytic.id}`,
                  )
                }
              >
                {getString('viewDetails', language)}
              </Button>
            )}
        </Group>
        <Space h="xs" />
        <Group className={styles.DisplayPrintOnly}>
          <Text>
            <b>{getString('risk', language)}: </b>
            {getString(risk, language)}
          </Text>
        </Group>
        <Group>
          <Text>
            <b>{getString('avg', language)}: </b>
            {planAnalytic?.quantity && roundThreeDecimal(planAnalytic.quantity)}{' '}
          </Text>
          <Text>
            <b>{getString('range', language)}: </b>
            {analyticId === OM_ID
              ? `${fieldOmQuantities.min} - ${fieldOmQuantities.max}`
              : getMinMaxSampleQuantity(samples, activeAnalytic)}
          </Text>
          <Text>
            <b>{getString('acAtRisk', language)}: </b>
            {Math.round(acToHectares(fieldAtRiskAcres, acreage_unit))} {getString('of', language)}{' '}
            {Math.round(acToHectares(fieldTotalAcres, acreage_unit))}
            {` (${Math.round((100 * fieldAtRiskAcres) / fieldTotalAcres)}%)`}
          </Text>
        </Group>
        <Space h="xs" />
        {planAnalytic?.risk_summary?.risk_level_order !== BINARY && (
          <>
            <Group gap="xs">
              <Text fw={500}>{getString('ranges', language)}: </Text>
              {planAnalytic?.data_summary?.low && (
                <Text>
                  <b>{getString('low', language)}: </b>
                  {rangeToString(planAnalytic?.data_summary?.low, 2)}
                </Text>
              )}
              {planAnalytic?.data_summary?.moderate && (
                <Text>
                  <b>{getString('mod', language)}: </b>
                  {rangeToString(planAnalytic?.data_summary?.moderate, 2)}
                </Text>
              )}
              {planAnalytic?.data_summary?.high && (
                <Text className={styles.InfoSpace}>
                  <b>{getString('high', language)}: </b>
                  {rangeToString(planAnalytic?.data_summary?.high, 2)}
                </Text>
              )}
            </Group>
            <Space h="xs" />
          </>
        )}
        <Stack gap="xs">
          <Group gap="xs">
            <Text fw={500}>{getString('recommendation', language)}:</Text>
            {isModifyRecommendation && (
              <FiEdit
                onClick={() =>
                  isModifyRecommendation &&
                  toggleEditRecommendation(
                    ![NOT_ANALYZED, NO_DATA, AWAITING_RESULTS].includes(risk),
                  )
                }
              />
            )}
          </Group>
          <Box
            className={styles.Recommendation}
            style={{
              color:
                risk === HIGH_RISK || risk === MODERATE_RISK
                  ? RISK_FILL_COLORS.HIGH_RISK
                  : DARK_BLUE,
            }}
            onClick={() =>
              isModifyRecommendation &&
              toggleEditRecommendation(![NOT_ANALYZED, NO_DATA, AWAITING_RESULTS].includes(risk))
            }
          >
            <Text>{recommendation?.recommendation || ''}</Text>
          </Box>
        </Stack>
        <Box>
          <Text size="xs" fs="italic">
            {planHasMissingSample(samples, activeAnalytic) &&
              `* ${getString('averageAppliedToZone', language)}`}
          </Text>
        </Box>
        <Box>
          <Text size="xs" fs="italic">
            {hasInsufficientData && `* ${getString('insufficientDataWarning', language)}`}
          </Text>
        </Box>
      </Box>
      <Stack justify="flex-start" className={styles.MapWrapper}>
        <Box className={styles.Map} ref={mapContainerRef} />
        {proLayer ? (
          <SegmentedControl
            data={[
              { label: getString('proResults', language), value: 'pro' },
              { label: getString('pointResults', language), value: 'points' },
            ]}
            onChange={toggleMap}
            value={mapVersion}
          />
        ) : null}
      </Stack>
    </Box>
  );
};

export default Mapbook;
