import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Center, Group, Input, Loader, Modal, Space, Stack, Text } from '@mantine/core';
import { AdminAccess, Header, AnalysisMethodPreference } from 'common';
import { getOperationAssociatedUsers } from 'store/user/thunks';
import { removeField } from 'store/fields/thunks';
import { getOperation, setActiveOperation, updateOperation } from 'store/operation/thunks';
import { UserRole } from 'store/user/types';
import { RootState } from 'store';
import { OperationFieldType } from 'store/operation/types';
import { getOperationAllAgencies } from 'store/agencies/thunks';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { getAllFeatureFlags } from 'store/featureFlags/thunks';
import useOperationFieldGeometries from 'util/hooks/useOperationFieldGeometries';
import showToast from 'actions/toastActions';
import { sortFarmThenFieldName } from 'util/sortByName';

import { FieldInputs } from '../Common';

import styles from './Container.module.css';
import FeatureFlags from './FeatureFlags';
import BillingDetails from './BillingDetails';
import Section from './Section';
import Commission from './Commission';
import ManageUsers from './ManageUsers/Container';
import DefaultSet from './DefaultSet';
import DiscountProgramModal from './DiscountProgramModal';
import DiscountProgram from './DiscountProgram';
import UserAgreementLinks from '../Common/UserAgreementLinks';
import ManageOperationAgencies from './ManageOperationAgencies';
import ContractPreference from './ContractPreference';

const EditOperationContainer = () => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const operationId = Number(useParams<{ id: string }>().id);
  const [fields, setFields] = useState<{ [id: number]: OperationFieldType }>({});
  const [fieldToDelete, setFieldToDelete] = useState<OperationFieldType>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [operationName, setOperationName] = useState('');
  const [discountModalOpen, toggleDiscountModalOpen] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const { agencies, users, operation, hasFetched, isFetching, geometries, currentUser, countries } =
    useSelector((state: RootState) => ({
      countries: state.user.countries,
      currentUser: state.user.currentUser,
      agencies: state.agencies.allAgencies,
      users: state.user.allUsers,
      operation: state.operations.operationsById[operationId],
      hasFetched: state.operations.hasFetched,
      isFetching: state.user.isFetching || state.operations.isFetching,
      geometries: state.fieldGeometry.geometries,
    }));

  useEffect(() => {
    if (operationId) {
      dispatch(getOperationAssociatedUsers(operationId));
      dispatch(getOperationAllAgencies(operationId));
      dispatch(getAllFeatureFlags());
    }
  }, [operationId]);

  const nonAgencyUsers = useMemo(
    () =>
      users.filter((user) =>
        user.roles.some(
          (role) => ![String(UserRole.AgencyAdmin), String(UserRole.Agent)].includes(role.name),
        ),
      ),
    [users],
  );

  const fetchOperation = useCallback(
    () => dispatch(getOperation(operationId)),
    [dispatch, operationId],
  );

  useEffect(() => {
    if (operation) {
      dispatch(setActiveOperation(operation.id));
      setOperationName(operation.name);
      setFields(operation.fields.reduce((all, single) => ({ ...all, [single.id]: single }), {}));
    } else {
      fetchOperation();
    }
  }, [operation, hasFetched, operationId, dispatch, fetchOperation]);

  useOperationFieldGeometries(operation, true, false);

  const isFormValid = () =>
    operationName.length &&
    (Object.keys(fields).length === 0 || Object.values(fields).every((field) => field.name.length));

  const submit = async () => {
    try {
      setIsSubmitting(true);
      await dispatch(
        updateOperation({
          id: operation.id,
          name: operationName,
        }),
      );
      dispatch(showToast(getString('operationSavedMsg', language)));
      setIsSubmitting(false);
    } catch (err) {
      dispatch(showToast(getString('errorSavingOperationMsg', language), 'error', 7000));
    }
  };

  const onCancelDeletion = () => {
    setShowConfirmDialog(false);
    setFieldToDelete(undefined);
  };

  const onAttemptDeletion = (field: OperationFieldType) => {
    setShowConfirmDialog(true);
    setFieldToDelete(field);
  };

  const onConfirmDeletion = () => {
    setShowConfirmDialog(false);
    dispatch(removeField(fieldToDelete!.id));
    const newFields = { ...fields };
    delete newFields[fieldToDelete!.id];
    setFields(newFields);
    dispatch(showToast(getString('fieldDeletedMsg', language)));
  };

  if (!currentUser || !operation || !countries) {
    return (
      <Center h="70vh">
        <Loader />
      </Center>
    );
  }

  return (
    <Stack className={styles.Wrapper}>
      <Header title={`${operationName} - ${getString('editOperation', language)}`}>
        <Button
          variant="outline"
          data-test-id="save"
          loading={isFetching || isSubmitting || !isFormValid()}
          onClick={submit}
        >
          {getString('save', language)}
        </Button>
      </Header>
      <AdminAccess>
        <Input.Wrapper label={getString('accountName', language)} w="20rem">
          <Input onChange={(e) => setOperationName(e.target.value)} value={operationName} />
        </Input.Wrapper>
        <Space h="lg" />
        <FeatureFlags operationId={operation.id} />
        <Space h="lg" />
      </AdminAccess>
      <div className={styles.Section}>
        <ManageUsers operation={operation} users={users} />
      </div>
      <div className={styles.Section}>
        <BillingDetails
          billingUserId={operation.billing_user_id}
          users={nonAgencyUsers}
          agencies={agencies}
        />
      </div>
      <AdminAccess>
        <div className={styles.Section}>
          <ManageOperationAgencies agencies={agencies} operationId={operationId} />
        </div>
        <Section title={getString('commission', language)}>
          <Commission operation={operation} agencies={agencies} />
        </Section>
        <Section title={getString('billingAgency', language)}>
          <ContractPreference operation={operation} agencies={agencies} />
        </Section>
        <Section title={getString('recommendationDefaults', language)}>
          <DefaultSet operation={operation} />
        </Section>
        <Section title={getString('analysisOptions', language)}>
          <AnalysisMethodPreference
            resultPreferences={operation?.result_preferences}
            isOperation
            operationOrAgencyId={operation.id}
          />
        </Section>
      </AdminAccess>
      <AdminAccess>
        <DiscountProgram operation={operation} toggleDiscountModalOpen={toggleDiscountModalOpen} />
        {discountModalOpen && (
          <DiscountProgramModal
            hideModal={() => toggleDiscountModalOpen(false)}
            operation_id={operationId}
            fetchOperation={fetchOperation}
            open={discountModalOpen}
          />
        )}
      </AdminAccess>
      <Section title={getString('fields', language)}>
        <FieldInputs
          fields={sortFarmThenFieldName(Object.values(fields))}
          geometries={geometries}
          deleteField={onAttemptDeletion}
          language={language}
        />
      </Section>
      <Section title={getString('support', language)}>
        {getString('deleteYourDataMsg', language)}
        <br />
        <UserAgreementLinks role={currentUser.roles[0].name} />
      </Section>
      {showConfirmDialog && (
        <Modal
          onClose={onCancelDeletion}
          opened={showConfirmDialog}
          centered
          title={
            fieldToDelete
              ? `${getString('confirmFieldRemoval', language)} - ${fieldToDelete.name}`
              : null
          }
          size="lg"
        >
          <Text>{getString('removeFieldMsg', language)}</Text>
          <Space h="md" />
          <Text fw={600}>{getString('confirmRemoveFieldMsg', language)}</Text>
          <Space h="md" />
          <Group justify="flex-end">
            <Button color="darkRed" onClick={onConfirmDeletion}>
              {getString('remove', language)}
            </Button>
            <Button variant="outline" onClick={onCancelDeletion}>
              {getString('cancel', language)}
            </Button>
          </Group>
        </Modal>
      )}
    </Stack>
  );
};

export default EditOperationContainer;
