import React, { useEffect, useMemo, useRef, useState } from 'react';
import mapboxgl, { GeoJSONSourceRaw, LngLatBoundsLike } from 'mapbox-gl';
import center from '@turf/center';
import turfBbox from '@turf/bbox';
import { featureCollection } from '@turf/helpers';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Box, Button } from '@mantine/core';
import useMapboxGl from 'common/MapHooks';
import { FieldType } from 'store/fields/types';
import { formatZonesWithOpacity } from 'util/prescription';
import { DARK_BLUE, PRESCRIPTION_COLORS, WHITE } from 'util/mapImageryColors';
import { PrescriptionZoneType } from 'store/prescriptions/types';

import styles from './Map.module.css';
import { useParams } from 'react-router-dom';
import MapScriptInfo from './MapScriptInfo';

interface MapProps {
  field: FieldType;
  mapRef: { current: mapboxgl.Map | null };
  mapContainerRef: { current: HTMLDivElement | null };
  zones: PrescriptionZoneType[] | null;
  selectedZone: number | null;
}

interface ViewPortProps {
  centerLongitude?: number;
  centerLatitude?: number;
  latitude: number;
  longitude: number;
  zoom: number;
  width?: number;
  height?: number;
}

type paramsType = {
  prescriptionId: string;
};

const Map = ({ field, mapRef, mapContainerRef, zones, selectedZone }: MapProps) => {
  const language = useBroswerLanguage();
  const params = useParams<paramsType>();
  const prescriptionId = Number(params.prescriptionId);

  const [centerLongitude, centerLatitude] = center(field).geometry?.coordinates as number[];
  const [initialViewport, setInitialViewport] = useState({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: 5.5,
  });
  const [viewport, setViewport] = useState<ViewPortProps>({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: 5.5,
    width: 0,
    height: 0,
  });
  const [mapHasLoaded, setMapHasLoaded] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useMapboxGl(mapContainerRef, mapRef, null, viewport, setViewport, () => {}, true);

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      map.on('load', () => {
        setMapHasLoaded(true);
      });
    }
  }, [mapRef, setMapHasLoaded]);

  useEffect(() => {
    const map = mapRef.current;
    if (mapHasLoaded && map) {
      const bbox = turfBbox(field) as LngLatBoundsLike;

      const source = { type: 'geojson', data: field } as GeoJSONSourceRaw;
      if (map.getLayer('field-outline')) {
        map.removeLayer('field-outline');
      }
      if (map.getSource('field-outline')) {
        map.removeSource('field-outline');
      }

      map.addLayer({
        id: `field-outline`,
        type: 'line',
        source,
        paint: { 'line-color': WHITE, 'line-width': 2 },
      });

      map.fitBounds(bbox, { duration: 0, padding: 30 });
      const zoom = map.getZoom();
      setViewport((prevViewport) => ({
        ...prevViewport,
        centerLatitude,
        centerLongitude,
        zoom,
      }));
      setInitialViewport((prevViewport) => ({
        ...prevViewport,
        latitude: centerLatitude,
        longitude: centerLongitude,
        zoom,
      }));
    }
  }, [centerLatitude, centerLongitude, field, mapHasLoaded, mapRef]);

  useEffect(() => {
    const map = mapRef.current;
    if (mapHasLoaded && map) {
      const zonesId = 'prescription-zones';
      const amountId = 'prescription-zones-amounts';
      const fieldId = 'field-background';
      if (map.getLayer(zonesId)) {
        map.removeLayer(zonesId);
        map.removeSource(zonesId);
      }
      if (map.getLayer(amountId)) {
        map.removeLayer(amountId);
        map.removeSource(amountId);
      }
      if (map.getLayer(fieldId)) {
        map.removeLayer(fieldId);
        map.removeSource(fieldId);
      }
      if (zones) {
        const formattedZones = formatZonesWithOpacity(zones);
        const newFeatureCollection = featureCollection(formattedZones);
        const source = {
          type: 'geojson',
          data: newFeatureCollection,
        } as GeoJSONSourceRaw;

        const pointSource = {
          type: 'geojson',
          data: {
            ...newFeatureCollection,
            features: newFeatureCollection.features.map((f) => ({
              ...f,
              geometry: center(f.geometry).geometry,
            })),
          },
        } as GeoJSONSourceRaw;
        map.addLayer({
          id: zonesId,
          type: 'fill',
          source,
          paint: {
            'fill-color': {
              property: 'fillPercent',
              stops: PRESCRIPTION_COLORS,
            },
            'fill-outline-color': WHITE,
          },
        });
        map.addLayer({
          id: amountId,
          type: 'symbol',
          source: pointSource,
          layout: {
            'text-field': ['get', 'amount'],
            'text-justify': 'center',
            'text-size': 12,
          },
          paint: {
            'text-color': WHITE,
          },
        });
      }
    }
  }, [field, mapHasLoaded, mapRef, zones]);

  useEffect(() => {
    const map = mapRef.current;
    if (mapHasLoaded && map) {
      const zonesId = 'prescription-zones-outline';
      if (map.getLayer(zonesId)) {
        map.removeLayer(zonesId);
        map.removeSource(zonesId);
      }
      if (zones && selectedZone) {
        const selectedZoneFeature = zones.find((z) => z.properties.id === selectedZone);
        const newFeatureCollection = featureCollection(
          selectedZoneFeature ? [selectedZoneFeature] : [],
        );
        const source = {
          type: 'geojson',
          data: newFeatureCollection,
        } as GeoJSONSourceRaw;

        map.addLayer({
          id: zonesId,
          type: 'line',
          source,
          paint: { 'line-color': DARK_BLUE, 'line-width': 2 },
        });
      }
    }
  }, [field, mapHasLoaded, mapRef, zones, selectedZone]);

  const recenterMap = () => {
    setViewport(initialViewport);
    if (mapRef.current) {
      mapRef.current.setZoom(initialViewport.zoom);
      mapRef.current.setCenter([initialViewport.longitude, initialViewport.latitude]);
    }
  };

  const prescription = useMemo(
    () => field.features[0].properties.prescriptions.find((rx) => rx.id === prescriptionId),
    [field, prescriptionId],
  );

  return (
    <Box className={styles.Wrapper}>
      <Box className={styles.MapWrapper} ref={wrapperRef}>
        <Box ref={mapContainerRef} className={styles.MapWrapper} />
        <MapScriptInfo prescription={prescription} field={field} />
        <Button variant="white" className={styles.Recenter} onClick={recenterMap}>
          {getString('recenter', language)}
        </Button>
      </Box>
    </Box>
  );
};

export default Map;
