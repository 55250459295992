import { AgronomicProductType, CoverageRatingType, SeedType } from 'store/cropPlans/types';
import { RecommendationType } from 'store/recommendations/types';

export const RECEIVE_RECOMMENDATION_SETS = 'RECEIVE_RECOMMENDATION_SETS';
export const RECEIVE_SINGLE_RECOMMENDATION_SET = 'RECEIVE_SINGLE_RECOMMENDATION_SET';
export const REQUEST_RECOMMENDATION_SETS = 'REQUEST_RECOMMENDATION_SETS';
export const RECOMMENDATION_SET_REQUEST_ERROR = 'RECOMMENDATION_SET_REQUEST_ERROR';

export type RecommendedSeedType = {
  ratings: CoverageRatingType[];
  seed_metadata: SeedType;
};

export type RecommendedProductType = {
  ratings: CoverageRatingType[];
  product_metadata: AgronomicProductType;
};

export type RecommendationSetType = {
  id: number;
  name: string;
  created_by_id: number;
  show_in_ui: boolean;
  agency: {
    id: number;
    name: string;
  } | null;
  recommendations: RecommendationType[];
  recommended_seeds: { [id: number]: RecommendedSeedType };
  recommended_products: {
    [id: number]: RecommendedProductType;
  };
};

type RequestRecommendationSetType = {
  type: typeof REQUEST_RECOMMENDATION_SETS;
};

type ReceiveRecommendationSetsType = {
  type: typeof RECEIVE_RECOMMENDATION_SETS;
  payload: {
    recommendation_sets: RecommendationSetType[];
  };
};

type ReceiveSingleRecommendationSetType = {
  type: typeof RECEIVE_SINGLE_RECOMMENDATION_SET;
  payload: {
    recommendation_set: RecommendationSetType;
  };
};

type RecommendationSetRequestError = {
  type: typeof RECOMMENDATION_SET_REQUEST_ERROR;
};

export type RecommendationSetActionsType =
  | ReceiveRecommendationSetsType
  | ReceiveSingleRecommendationSetType
  | RequestRecommendationSetType
  | RecommendationSetRequestError;
