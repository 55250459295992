import React, { MouseEvent, useEffect, useState } from 'react';
import {
  Button,
  Container,
  Label,
  Input,
  UserSearch,
  CloseIcon,
  ClickOutsideWrapper,
} from 'common';
import { UserSelectorType } from 'store/user/types';
import { postSamplePrice, putSamplePrice } from 'store/samplePrices/requests';
import { isNumber } from 'util/numUtils';
import { useDispatch } from 'react-redux';
import getSamplePrices from 'store/samplePrices/thunks';
import { SamplePrice } from 'store/samplePrices/types';
import styles from './Container.module.css';

type SamplePricingPropsType = {
  onCancel: () => void;
  hideModal: (event?: MouseEvent) => void;
  setToastMessage: (message: string, type?: string, time?: number) => void;
  userSamplePrice?: SamplePrice | null;
};

type MaxValueType = { index: number; value: string; price: string };

const SamplePricingModal = ({
  onCancel,
  hideModal,
  setToastMessage,
  userSamplePrice,
}: SamplePricingPropsType) => {
  const dispatch = useDispatch();
  const [usersSelected, setUsersSelected] = useState<UserSelectorType[]>([]);
  const [maxValues, setMaxValues] = useState<{
    [index: number]: MaxValueType;
  }>({ 0: { index: 0, value: '0', price: '' } });

  useEffect(() => {
    if (userSamplePrice) {
      setUsersSelected([
        {
          id: String(userSamplePrice.user.id),
          user_id: userSamplePrice.user.id,
          displayName: `${userSamplePrice.user.first_name} ${userSamplePrice.user.last_name}`,
          email: userSamplePrice.user.email,
          first_name: userSamplePrice.user.first_name,
          last_name: userSamplePrice.user.last_name,
        },
      ]);
      setMaxValues(
        userSamplePrice.pricing_ranges
          .sort((a, b) => (a.max_density > b.max_density ? 1 : -1))
          .reduce((all, single, idx) => {
            return {
              ...all,
              [idx]: {
                index: idx,
                value: single.max_density,
                price: single.price,
              },
            };
          }, {}),
      );
    }
  }, [userSamplePrice]);

  const handleUserSelection = (selcted: UserSelectorType) => {
    const { id, displayName, user_id, email, first_name, last_name } = selcted;
    setUsersSelected([
      ...usersSelected,
      {
        id,
        user_id,
        displayName,
        email,
        first_name,
        last_name,
      },
    ]);
  };

  const removeUser = (id: number | null) => {
    setUsersSelected(usersSelected.filter((selected) => selected.user_id !== id));
  };

  const addPriceRange = () => {
    const len = Object.keys(maxValues).length;
    setMaxValues((values) => ({
      ...values,
      [len]: {
        index: len,
        value: '',
        price: '',
      },
    }));
  };

  const updateMax = (oldValue: MaxValueType, dataType: string, newValue: string) => {
    if (isNumber(Number(newValue))) {
      setMaxValues((values) => ({
        ...values,
        [oldValue.index]: {
          ...oldValue,
          index: oldValue.index,
          [dataType]: newValue,
        },
      }));
    }
  };

  const onConfirm = async () => {
    try {
      const sorted = Object.values(maxValues)
        .sort((a, b) => (a.index > b.index ? 1 : -1))
        .map((val) => ({
          ...val,
          price: Number(val.price),
          value: Number(val.value),
        }));

      const isValid = sorted.every(
        (single, index) => (index === 0 || single.value > sorted[index - 1].value) && single.price,
      );
      if (isValid && sorted.length > 0) {
        const body = {
          users: usersSelected.map((single) => single.user_id),
          max_values: sorted,
        };
        if (userSamplePrice?.pricing_ranges) {
          await putSamplePrice(userSamplePrice.user.id, body);
        } else {
          await postSamplePrice(body);
        }

        hideModal();
        dispatch(getSamplePrices());
        setToastMessage(
          "Congratulations! You've Successfully added sample prices for the selected users!",
          'success',
          10000,
        );
      } else {
        setToastMessage(
          'Please fill out all fields and use ascending order for ranges.',
          'error',
          10000,
        );
      }
    } catch (err) {
      setToastMessage(
        'Unable to save sample pricing. Make sure the sure does not have a sample price schema already.',
        'error',
        10000,
      );
    }
  };

  return (
    <>
      <ClickOutsideWrapper className={styles.Modal} onClickOutside={onCancel}>
        <div className={styles.DialogContent}>
          <h4>Assign Sample pricing</h4>
          <div className={styles.Columns}>
            <div className={styles.Column}>
              <UserSearch
                placeholder="Search by user"
                onSelect={handleUserSelection}
                className={styles.SearchBar}
              />
              <div>
                {Object.values(maxValues).map((max) => (
                  <div key={max.index} className={styles.RangeWithPrice}>
                    <span>
                      {maxValues[max.index - 1] ? maxValues[max.index - 1].value : '0'}
                      {'  '}-{'  '}
                    </span>
                    <Label label="Range Max">
                      <Input
                        className={styles.RangeInput}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          updateMax(max, 'value', e.target.value)
                        }
                        value={max.value}
                      />
                    </Label>
                    $
                    <Label label="Price">
                      <Input
                        className={styles.PriceInput}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          updateMax(max, 'price', e.target.value)
                        }
                        value={max.price}
                      />
                    </Label>
                  </div>
                ))}
              </div>
              <Button onClick={() => addPriceRange()}>Add Price Range</Button>
            </div>
            <div className={styles.Column}>
              {usersSelected.map((selected) => (
                <div className={styles.Tag} key={selected.id}>
                  <span>{selected.email}</span>
                  <span onClick={() => removeUser(selected.user_id)}>
                    <CloseIcon />
                  </span>
                </div>
              ))}
            </div>
          </div>
          <Container className={styles.DialogButtons}>
            <Button danger onClick={onCancel}>
              Cancel
            </Button>
            <Button primary onClick={onConfirm}>
              Add Sample Price
            </Button>
          </Container>
        </div>
      </ClickOutsideWrapper>
      <div className={styles.Overlay} />
    </>
  );
};

export default SamplePricingModal;
