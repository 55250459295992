import { RecommendedProductType } from 'store/recommendationSets/types';
import { getString } from 'strings/translation';
import { ALL, NONE } from './variables';

export const BELOW_GROUND = 'below_ground';
export const FOLIAR = 'foliar';
export const IN_FURROW = 'in_furrow';
export const SEED_TREATMENT = 'seed_treatment';
export const SEED_DISEASE_RISKS = 'seed_disease_risks';

export const PRODUCT_TYPE_SEED = 'Seed';
export const PRODUCT_TYPE_CROP_PROTECTION = 'Crop Protection';

export const REC_SET_PRODUCT_TYPES = [PRODUCT_TYPE_SEED, PRODUCT_TYPE_CROP_PROTECTION];

export const SEEDS = 'seeds';
export const AGRONOMIC_PRODUCTS = 'agronomic_products';

export const NO_COVERAGE = 0;
export const LOW_COVERAGE = 1;
export const MODERATE_COVERAGE = 2;
export const HIGH_COVERAGE = 3;

export const SEED = 'seed';
export const TRAITS = 'traits';
export const TREATMENT = 'treatment';
export const OPEN_RISKS = 'openRisks';

export const PATTERN_PRODUCT_CROP_PROTECTION = 'crop_protection';
export const CROP_PLAN_HEADERS = [SEED, TRAITS, TREATMENT, IN_FURROW, FOLIAR, OPEN_RISKS];

export const INPUTS_MAP = {
  [SEED]: SEED,
  [TRAITS]: TRAITS,
  [TREATMENT]: SEED_TREATMENT,
  [IN_FURROW]: IN_FURROW,
  [FOLIAR]: FOLIAR,
};

export const CHART_INPUTS_SELCTION_MAP = {
  [FOLIAR]: [FOLIAR],
  [IN_FURROW]: [IN_FURROW],
  [SEED_TREATMENT]: [TREATMENT],
};

export const CATEGORY_INPUT_MAP = {
  [TREATMENT]: SEED_TREATMENT,
  [IN_FURROW]: PATTERN_PRODUCT_CROP_PROTECTION,
  [FOLIAR]: PATTERN_PRODUCT_CROP_PROTECTION,
};

export const OUTSTANDING_RISK_COLOR = '#D72E3D66';

export const PRODUCT_NONE_ID = 20643;

export const getNoneProduct = (language: string): RecommendedProductType => ({
  product_metadata: {
    name: getString('none', language),
    id: 20643,
    pattern_product_type: NONE,
    registrant: NONE,
    coverage_ratings: [],
    crop: ALL,
  },
  ratings: [],
});
