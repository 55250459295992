import React from 'react';
import { useDispatch } from 'react-redux';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Button, Container, Header } from 'common';
import { PlateType } from 'store/plates/types';
import { requestDownloadPlateRunsheet } from 'store/plates/requests';
import showToast from 'actions/toastActions';

import { GridContainer } from '../../Wells/Grid/Container';

type PlateWellsPropsType = {
  plate: PlateType;
  runSheet?: boolean;
};

export const PlateWells = ({ plate, runSheet }: PlateWellsPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const downloadRunSheet = async () => {
    try {
      await requestDownloadPlateRunsheet(plate);
    } catch (error) {
      dispatch(showToast(getString('runSheetDownloadFailed', language), 'error'));
    }
  };

  return (
    <Container vertical>
      <Header title={getString('sampleSummary', language)} underline="none" size="H2">
        {runSheet && (
          <Button dataTestId="download-runsheet" onClick={downloadRunSheet}>
            {getString('downloadRunSheet', language)}
          </Button>
        )}
      </Header>
      <GridContainer plate={plate} />
    </Container>
  );
};
