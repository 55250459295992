import React, { ChangeEvent, useState } from 'react';
import {
  Button,
  CloseButton,
  Combobox,
  ComboboxItem,
  Group,
  Select,
  Space,
  Stack,
  TextInput,
  Text,
  useCombobox,
} from '@mantine/core';
import { useDebouncedCallback } from 'use-debounce';
import { FiSearch } from 'react-icons/fi';

import { PRODUCT_TYPE_SEED, REC_SET_PRODUCT_TYPES } from 'constants/cropPlan';
import { CORN, SOYBEANS } from 'constants/variables';
import { AgronomicProductType, SeedType } from 'store/cropPlans/types';
import { requestGetProductSearch, requestGetSeedSearch } from 'store/cropPlans/requests';
import { DEBOUNCE } from 'util/request';
import { RecommendationSetType } from 'store/recommendationSets/types';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { FONT_WEIGHT_BOLD, FONT_WEIGHT_STANDARD } from 'constants/mantine';
import { sortInputsByCoverage } from 'util/cropPlans';

import styles from './Container.module.css';
import InputModal from './InputModal';

interface ProductSearchProps {
  closeModal: VoidFunction;
  isSaving: boolean;
  modalOpened: boolean;
  onError: (msg: string) => void;
  openModal: VoidFunction;
  toggleIsSaving: (saving: boolean) => void;
  recSet: Partial<RecommendationSetType>;
}

const ProductSearch = ({
  closeModal,
  isSaving,
  modalOpened,
  onError,
  openModal,
  toggleIsSaving,
  recSet,
}: ProductSearchProps) => {
  const language = useBroswerLanguage();
  const [productType, setProductType] = useState<string | null>(PRODUCT_TYPE_SEED);
  const [crop, setCrop] = useState<string>(CORN);
  const [productName, setProductName] = useState('');
  const [trait, setTrait] = useState('');

  const [products, setProducts] = useState<AgronomicProductType[]>([]);
  const [seeds, setSeeds] = useState<SeedType[]>([]);

  const [selectedSeed, setSelectedSeed] = useState<SeedType | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<AgronomicProductType | null>(null);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const debounceFetchProducts = useDebouncedCallback(
    async (name: string) => {
      try {
        toggleIsSaving(true);
        const response: AgronomicProductType[] = await requestGetProductSearch(name, crop);
        setProducts(response);
      } catch (error) {
        onError('Failed to fetch agronomic products.');
      } finally {
        toggleIsSaving(false);
      }
    },
    DEBOUNCE,
    { trailing: true },
  );
  const debounceFetchSeeds = useDebouncedCallback(
    async (name: string) => {
      try {
        toggleIsSaving(true);
        const response: SeedType[] = await requestGetSeedSearch(name, crop);
        setSeeds(response);
      } catch (error) {
        onError('Failed to fetch seeds.');
      } finally {
        toggleIsSaving(false);
      }
    },
    DEBOUNCE,
    { trailing: true },
  );

  const handleDropdownSelection = (str: string) => {
    if (productType === PRODUCT_TYPE_SEED) {
      const seed = seeds.find((s) => s.hybrid === str);
      if (seed) {
        setProductName(str);
        setTrait(seed.traits.join(','));
        setSelectedSeed(seed);
        setSelectedProduct(null);
      }
    } else {
      const product = products.find((p) => p.name === str);
      if (product) {
        setProductName(str);
        setSelectedSeed(null);
        setSelectedProduct(product);
      }
    }
  };

  const handleProductNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setProductName(name);
    if (name && !isSaving) {
      if (productType === PRODUCT_TYPE_SEED) {
        debounceFetchSeeds(name);
      } else {
        debounceFetchProducts(name);
      }
    }
  };

  const options = (() => {
    const data =
      productType === PRODUCT_TYPE_SEED
        ? seeds.map((seed) => ({
            id: seed.id,
            displayName: seed.hybrid,
            fw: seed.coverage_ratings.length ? FONT_WEIGHT_BOLD : FONT_WEIGHT_STANDARD,
            coverage_ratings: seed.coverage_ratings,
          }))
        : products.map((product) => ({
            id: product.id,
            displayName: product.name,
            fw: product.coverage_ratings.length ? FONT_WEIGHT_BOLD : FONT_WEIGHT_STANDARD,
            coverage_ratings: product.coverage_ratings,
          }));
    const sorted = sortInputsByCoverage(data);
    return sorted.map((element) => (
      <Combobox.Option fw={element.fw} value={element.displayName} key={element.id}>
        {element.displayName}
      </Combobox.Option>
    ));
  })();

  const reset = () => {
    setSelectedProduct(null);
    setSelectedSeed(null);
    setProductName('');
    setTrait('');
  };

  const handleCloseModal = () => {
    reset();
    closeModal();
  };

  const handleProductTypeSelection = (_type: ComboboxItem) => {
    reset();
    setProductType(_type.value);
  };

  return (
    <Stack className={styles.ProductSearch}>
      <Text size="xl">Add Products</Text>
      <Group align="flex-end">
        <Select
          data={REC_SET_PRODUCT_TYPES}
          label="Product Type"
          onChange={(_value, option) => handleProductTypeSelection(option)}
          value={productType}
        />
        {productType === PRODUCT_TYPE_SEED ? (
          <Select
            data={[CORN, SOYBEANS]}
            label="Crop"
            onChange={(c) => (c ? setCrop(c) : () => {})}
            value={crop}
          />
        ) : null}
        <Combobox onOptionSubmit={handleDropdownSelection} store={combobox}>
          <Combobox.Target>
            <TextInput
              w="13rem"
              disabled={Boolean(productType === PRODUCT_TYPE_SEED ? selectedSeed : selectedProduct)}
              label="Product Name"
              leftSection={<FiSearch />}
              rightSection={
                productName ? (
                  <CloseButton
                    onClick={reset}
                    style={{ display: selectedProduct || selectedSeed ? undefined : 'none' }}
                  />
                ) : null
              }
              onChange={handleProductNameChange}
              onClick={() => combobox.openDropdown()}
              onFocus={() => combobox.openDropdown()}
              onBlur={() => combobox.closeDropdown()}
              value={productName}
            />
          </Combobox.Target>

          <Combobox.Dropdown hidden={!options.length} onClick={() => combobox.closeDropdown()}>
            {options}
          </Combobox.Dropdown>
        </Combobox>
        {productType === PRODUCT_TYPE_SEED ? (
          <TextInput disabled label="Trait" onChange={() => {}} value={trait} />
        ) : null}
        <Button
          color="#112F63"
          disabled={!(selectedProduct || selectedSeed) || modalOpened}
          onClick={openModal}
          radius="lg"
          variant="filled"
        >
          + {getString('addProduct', language)}
        </Button>
      </Group>
      <Space h="sm" />
      {modalOpened ? (
        <InputModal
          closeModal={handleCloseModal}
          isTemporary
          modalOpened={modalOpened}
          recSet={recSet}
          selectedProduct={selectedProduct}
          selectedSeed={selectedSeed}
        />
      ) : null}
    </Stack>
  );
};

export default ProductSearch;
