import { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import MapboxDraw from '@patternag/mapbox-gl-draw';
import MAPBOX_CONSTANTS, { DRAW_STYLING } from 'constants/mapbox';
import { coordinatesGeocoder } from 'util/geospatial';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';

mapboxgl.accessToken = MAPBOX_CONSTANTS.token;

function useMapboxGl(
  mapContainerRef,
  mapRef,
  drawRef,
  viewport,
  setViewport,
  setMode,
  viewNavigation,
  viewSearch,
  mapStyle,
) {
  const language = useBroswerLanguage();

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current || '',
      style: mapStyle || MAPBOX_CONSTANTS.styleWithLabels,
      center: [viewport.longitude, viewport.latitude],
      zoom: viewport.zoom,
      attributionControl: false,
      preserveDrawingBuffer: true,
    });
    const map = mapRef.current;
    if (map) {
      map.doubleClickZoom.disable();
      if (viewNavigation) {
        mapRef.current.addControl(
          new mapboxgl.NavigationControl({ showCompass: false }),
          'bottom-right',
        );
      }
      if (drawRef) {
        const { modes } = MapboxDraw;
        // eslint-disable-next-line no-param-reassign
        drawRef.current = new MapboxDraw({
          userProperties: true,
          modes,
          displayControlsDefault: false,
          styles: DRAW_STYLING,
        });
        mapRef.current.addControl(drawRef.current);
        mapRef.current.on('draw.modechange', (e) => {
          setMode(e.mode);
        });
      }

      map.getCanvas().style.cursor = 'pointer';
      map.on('zoomend', () => {
        const zoom = mapRef.current.getZoom();
        const mapCenter = mapRef.current.getCenter();
        setViewport({
          latitude: mapCenter.lat,
          longitude: mapCenter.lng,
          zoom,
        });
      });
      map.on('moveend', () => {
        const zoom = mapRef.current.getZoom();
        const mapCenter = mapRef.current.getCenter();
        setViewport({
          latitude: mapCenter.lat,
          longitude: mapCenter.lng,
          zoom,
        });
      });
      viewSearch &&
        map.addControl(
          new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl,
            placeholder: getString('mapSearchPlaceholder', language),
            reverseGeocode: true,
            localGeocoder: coordinatesGeocoder,
          }),
        );
    }
    return () => map?.remove();
    // eslint-disable-next-line
  }, [mapContainerRef, drawRef, mapRef]);
}

export default useMapboxGl;
