import React from 'react';
import { FileUploadButton, Header } from 'common';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import showToast from 'actions/toastActions';
import { requestGetAgencyLogoSecureUrl, requestPostAgencyLogo } from 'store/agencies/requests';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { getAgency } from 'store/agencies/thunks';
import styles from './AgencyImage.module.css';
import { uploadToGCP } from 'util/request';
import { getUser } from 'store/user/thunks';

interface AgencyImageProps {
  agencyId: number;
}

const AgencyImage = ({ agencyId }: AgencyImageProps) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const { agency, user } = useSelector((state: RootState) => ({
    agency: state.agencies.byId[agencyId],
    user: state.user.currentUser,
  }));

  const handleUploadImage = async (file: File) => {
    try {
      const uploadInfo = await requestGetAgencyLogoSecureUrl(agencyId, file.name);
      if (!uploadInfo.file_name || !uploadInfo.upload_url) {
        return dispatch(showToast(getString('uploadFailedMsg', language), 'error'));
      }
      await uploadToGCP(uploadInfo, file);
      await requestPostAgencyLogo(agencyId, uploadInfo.file_name);
      dispatch(getAgency(agencyId));
      if (user) {
        dispatch(getUser(user.id));
      }
      dispatch(showToast(getString('uploadSuccessMsg', language), 'success'));
    } catch (error) {
      dispatch(showToast(getString('uploadFailedMsg', language), 'error'));
    }
  };

  return (
    <>
      <Header title={getString('uploadLogo', language)} />
      <div className={styles.Wrapper}>
        {agency?.agency_logo_url && (
          <div className={styles.LogoImage}>
            <img alt="Pattern Agency Logo" src={agency.agency_logo_url} />
          </div>
        )}
        <FileUploadButton
          danger
          minDimension={1}
          maxFileSize={2000000}
          accept=".jpg, .jpeg, .png"
          buttonLabel={getString('uploadLogo', language)}
          onUpload={handleUploadImage}
          dialogLabel={getString('uploadLogo', language)}
        />
      </div>
    </>
  );
};

export default AgencyImage;
