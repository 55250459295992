import React, { useState } from 'react';
import { Button, Collapse, Flex, Table, Text, Title } from '@mantine/core';
import { useDispatch } from 'react-redux';
import { CombinedAnalyticType, PestGroupPayloadType, SeedType } from 'store/cropPlans/types';
import { useDisclosure } from '@mantine/hooks';
import { FiChevronDown, FiChevronUp, FiDelete } from 'react-icons/fi';
import { RecommendationSetType } from 'store/recommendationSets/types';
import { AnalyticType } from 'store/analytics/types';
import { requestRecSetRemoveSeed } from 'store/cropPlans/requests';
import { receiveSingleRecommendationSet } from 'store/recommendationSets/actions';
import { capitalize } from 'util/stringUtils';

import styles from './Container.module.css';
import InputModal from './InputModal';
import InputCoverageTable from './InputCoverageTable';

interface SeedCatalogPropsType {
  analytics: AnalyticType[];
  pestGroups: PestGroupPayloadType;
  recSet: Partial<RecommendationSetType>;
  seeds: SeedType[];
  setToastMessage: (message: string, type?: string, time?: number) => void;
}

const SeedCatalog = ({
  analytics,
  pestGroups,
  recSet,
  seeds,
  setToastMessage,
}: SeedCatalogPropsType) => {
  const dispatch = useDispatch();
  const [seedsOpened, { toggle: toggleSeeds }] = useDisclosure(true);

  const [modalSeed, setModalSeed] = useState<SeedType | null>();

  const SeedsTable = () => {
    const handleRemoveProduct = async (seed: SeedType) => {
      try {
        if (recSet.id) {
          const response = await requestRecSetRemoveSeed([seed.id], recSet.id);
          dispatch(receiveSingleRecommendationSet(response));
          setToastMessage(`${seed.hybrid} successfully removed.`);
        }
      } catch (error) {
        setToastMessage('Failed to remove seed. Try again later.', 'error');
      }
    };
    const sortedSeeds = seeds.sort((a, b) => {
      if (a.crop < b.crop) {
        return -1;
      }
      return a.crop > b.crop ? 1 : 0;
    });
    const rows = sortedSeeds.length ? (
      sortedSeeds.map((seed) => (
        <Table.Tr key={seed.id}>
          <Table.Td>
            <FiDelete
              color="red"
              cursor="pointer"
              onClick={() => handleRemoveProduct(seed)}
              size={28}
            />
          </Table.Td>
          <Table.Td w={'10%'}>{capitalize(seed.crop)}</Table.Td>
          <Table.Td w={'10%'}>{seed.hybrid}</Table.Td>
          <Table.Td w={'15%'}>{seed.traits.join(',')}</Table.Td>
          <Table.Td>
            <InputCoverageTable
              analytics={analytics}
              pestGroups={pestGroups}
              recSet={recSet}
              seed={seed}
            />
          </Table.Td>
          <Table.Td>
            <Button onClick={() => setModalSeed(seed)}>View More / Edit</Button>
          </Table.Td>
        </Table.Tr>
      ))
    ) : (
      <Table.Tr>
        <Table.Td>
          <Text className={styles.EmptyProducts}>Add Products Above</Text>
        </Table.Td>
      </Table.Tr>
    );

    return (
      <Table>
        <Table.Thead>
          {sortedSeeds.length ? (
            <Table.Tr>
              <Table.Th />
              <Table.Th>Crop</Table.Th>
              <Table.Th>Hybrid</Table.Th>
              <Table.Th>Trait</Table.Th>
              <Table.Th>Protection</Table.Th>
              <Table.Th />
            </Table.Tr>
          ) : null}
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    );
  };

  const getModalSeedRatings = (seed: SeedType) => {
    return analytics.reduce((all: { [key: number]: CombinedAnalyticType }, analytic) => {
      const recSetSeed = recSet.recommended_seeds?.[seed.id];
      if (!recSetSeed) {
        return all;
      }

      const analyticInCoverage = recSetSeed.ratings.find(
        (rating) => rating.analytic_id === analytic.id,
      );
      if (analyticInCoverage) {
        return { ...all, [analytic.id]: { ...analytic, ...analyticInCoverage } };
      }
      return { ...all, [analytic.id]: { ...analytic, coverage_rating: 0 } };
    }, {});
  };

  return (
    <>
      <Flex align="center" className={styles.CategoryTitle} justify="space-between">
        <Title order={2}>Seed Catalog</Title>
        {seedsOpened ? (
          <FiChevronUp size="2rem" onClick={toggleSeeds} />
        ) : (
          <FiChevronDown size="2rem" onClick={toggleSeeds} />
        )}
      </Flex>
      <Collapse in={seedsOpened}>
        <SeedsTable />
      </Collapse>
      {modalSeed && (
        <InputModal
          closeModal={() => setModalSeed(null)}
          defaultSeedRatings={getModalSeedRatings(modalSeed)}
          isTemporary={false}
          modalOpened={Boolean(modalSeed)}
          recSet={recSet}
          selectedProduct={null}
          selectedSeed={modalSeed}
        />
      )}
    </>
  );
};

export default SeedCatalog;
