import { AppThunk } from 'store';
import showToast from 'actions/toastActions';
import {
  analyticsRequestError,
  receiveAllAnalytics,
  receiveSingleAnalytic,
  requestAnalytics,
} from './actions';
import { requestAllAnalytics, requestGetSingleAnalytic } from './requests';

export const getAllAnalytics = (): AppThunk => async (dispatch) => {
  dispatch(requestAnalytics);
  try {
    const response = await requestAllAnalytics();
    dispatch(receiveAllAnalytics({ analytics: response.analytics }));
  } catch (e) {
    dispatch(analyticsRequestError);
  }
};

export const getAnalyticById =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(requestAnalytics);
    try {
      const response = await requestGetSingleAnalytic(id);
      dispatch(receiveSingleAnalytic(response));
    } catch (e) {
      dispatch(showToast(e.message, 'error'));
      dispatch(analyticsRequestError);
    }
  };
