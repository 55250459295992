import {
  CLEAR_ZONE_ANALYSIS_V2,
  UPDATE_ZONE_ANALYSIS_V2,
  ZoneAnalysisActionsType,
  ZoneAnalysisKeyType,
} from './types';

export const updateZoneAnalysis = (
  metaKeyValues: ZoneAnalysisKeyType,
): ZoneAnalysisActionsType => ({
  type: UPDATE_ZONE_ANALYSIS_V2,
  payload: { metaKeyValues },
});

export const clearZoneAnalysisState = (): ZoneAnalysisActionsType => ({
  type: CLEAR_ZONE_ANALYSIS_V2,
});
