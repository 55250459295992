import React, { useEffect, useState } from 'react';
import { Feature, GeoJSON, MultiPolygon } from 'geojson';
import { useDispatch, useSelector } from 'react-redux';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import showToast from 'actions/toastActions';
import { Button, Header, Spinner, Table, TableCol, TableRow } from 'common';
import useBroswerLanguage from 'util/hooks/useLanguage';
import {
  requestDeleteCommercialTerritory,
  requestPostCommercialTerritory,
} from 'store/commercialTerritories/requests';
import {
  CommercialTerritoryProperties,
  CommercialTerritoryType,
} from 'store/commercialTerritories/types';
import { getAllTerritories } from 'store/commercialTerritories/thunks';
import styles from './Container.module.css';
import TerritoryModal from './TerritoryModal';
import CancelModal from '../../Orders/FieldList/FullProductReport/common/CancelModal';

const initialTerritory = {
  type: 'Feature',
  geometry: null,
  properties: {
    name: '',
    users: [],
  },
} as GeoJSON.Feature<GeoJSON.MultiPolygon | null, CommercialTerritoryProperties>;

const CommercialTerritoryContainer = () => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();

  const [userTerritory, setUserTerritory] = useState<CommercialTerritoryType>(initialTerritory);
  const [showTerritoryModal, toggleTerritoryModal] = useState(false);
  const [deleteModalId, setDeleteModalId] = useState<number | undefined>();
  const { user, allTerritories, isFetchingTerritories } = useSelector((state: RootState) => ({
    user: state.user.currentUser,
    allTerritories: state.commercialTerritories.allTerritories,
    isFetchingTerritories: state.commercialTerritories.isFetching,
  }));

  useEffect(() => {
    dispatch(getAllTerritories());
  }, []);

  const openModal = (territory: CommercialTerritoryType) => {
    setUserTerritory(territory);
    toggleTerritoryModal(true);
  };

  const submit = async (territory: Feature<MultiPolygon>) => {
    try {
      territory.properties = userTerritory?.properties || {};
      await requestPostCommercialTerritory(territory);
      dispatch(getAllTerritories());
      setUserTerritory(initialTerritory);
      toggleTerritoryModal(false);
      dispatch(showToast(getString('territoryCreated', language)));
    } catch (error) {
      dispatch(showToast(getString('  territorySavingErrMsg', language), 'error', 7000));
    }
  };

  const hideModal = () => {
    setUserTerritory(initialTerritory);
    toggleTerritoryModal(false);
  };

  const deleteTerritory = async () => {
    try {
      if (deleteModalId) {
        await requestDeleteCommercialTerritory(deleteModalId);
        dispatch(getAllTerritories());
        setDeleteModalId(undefined);
        dispatch(showToast(getString('territoryDeletedMsg', language)));
      }
    } catch (error) {
      dispatch(showToast(getString('  territorySavingErrMsg', language), 'error', 7000));
    }
  };

  if (!user || isFetchingTerritories) {
    return <Spinner fill />;
  }

  return (
    <div>
      <Header title={getString('commercialTerritory', language)}>
        <Button primary onClick={() => toggleTerritoryModal(true)}>
          {getString('newTerritory', language)}
        </Button>
      </Header>
      {userTerritory && showTerritoryModal && (
        <TerritoryModal
          submit={submit}
          user={user}
          userTerritory={userTerritory}
          hideModal={hideModal}
          setUserTerritory={setUserTerritory}
        />
      )}
      {deleteModalId && (
        <CancelModal
          cancelTitle={getString('deleteTerritory', language)}
          cancel={deleteTerritory}
          cancelText={getString('deleteTerritoryMsg', language)}
          isFetching={false}
          hideModal={() => setDeleteModalId(undefined)}
          opened={!!deleteModalId}
        />
      )}
      <Table className={styles.Table}>
        <TableRow header className={styles.TableHeader}>
          <TableCol>{getString('name', language)}</TableCol>
          <TableCol>{getString('users', language)}</TableCol>
          <TableCol>{getString('owner', language)}</TableCol>
          <TableCol />
          <TableCol />
        </TableRow>
        {!isFetchingTerritories &&
          allTerritories.map((row) => (
            <TableRow key={row.properties.id}>
              <TableCol>{row.properties.name}</TableCol>
              <TableCol>
                {row.properties.users.map((rowUser) => rowUser.last_name).join(', ')}
              </TableCol>
              <TableCol>
                {row.properties.users[0].first_name} {row.properties.users[0].last_name}
              </TableCol>
              <TableCol>
                <Button onClick={() => openModal(row)}>Edit</Button>
              </TableCol>
              <TableCol>
                <Button danger onClick={() => setDeleteModalId(row.properties.id)}>
                  X
                </Button>
              </TableCol>
            </TableRow>
          ))}
      </Table>
    </div>
  );
};

export default CommercialTerritoryContainer;
