import classNames from 'classnames';
import React from 'react';
import { getPriceDisplay, pricePerAcre } from 'util/samplePlan';
import styles from './Confirmation.module.css';

type PlanTotalProps = {
  acreage: number;
  priceSummary: { [key: string]: { total: number; num_zones: number } };
};

const PlanTotal = ({ acreage, priceSummary }: PlanTotalProps) => {
  const totalPrice = Object.keys(priceSummary).reduce(
    (total, sid) => (priceSummary[sid].total ? priceSummary[sid].total + total : total),
    0,
  );
  const totalPerAcre = pricePerAcre(totalPrice, acreage);
  return (
    <div className={classNames(styles.FormRow, styles.Total)}>
      <div />
      <div />
      <div />
      <div>{getPriceDisplay('', totalPrice)}</div>
      <div>{getPriceDisplay('', totalPerAcre)}</div>
    </div>
  );
};

export default PlanTotal;
