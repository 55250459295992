import React, { useState } from 'react';
import { Button, Collapse, Flex, Space, Table, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useDispatch } from 'react-redux';
import { FiChevronDown, FiChevronUp, FiDelete } from 'react-icons/fi';

import {
  AgronomicProductType,
  CombinedAnalyticType,
  PestGroupPayloadType,
} from 'store/cropPlans/types';
import { AnalyticType } from 'store/analytics/types';
import { RecommendationSetType } from 'store/recommendationSets/types';
import { requestRecSetRemoveProduct } from 'store/cropPlans/requests';
import { receiveSingleRecommendationSet } from 'store/recommendationSets/actions';
import { FOLIAR, IN_FURROW, SEED_TREATMENT } from 'constants/cropPlan';

import InputModal from './InputModal';
import InputCoverageTable from './InputCoverageTable';

import styles from './Container.module.css';

interface CropProtectionCatalogPropsType {
  products: AgronomicProductType[];
  analytics: AnalyticType[];
  pestGroups: PestGroupPayloadType;
  recSet: Partial<RecommendationSetType>;
  setToastMessage: (message: string, type?: string, time?: number) => void;
}

const CropProtectionCatalog = ({
  products,
  analytics,
  recSet,
  pestGroups,
  setToastMessage,
}: CropProtectionCatalogPropsType) => {
  const dispatch = useDispatch();
  const [productsOpened, { toggle: toggleProducts }] = useDisclosure(true);

  const [modalProduct, setModalProduct] = useState<AgronomicProductType | null>();

  const handleRemoveProduct = async (product: AgronomicProductType) => {
    try {
      if (recSet.id) {
        const response = await requestRecSetRemoveProduct([product.id], recSet.id);
        dispatch(receiveSingleRecommendationSet(response));
        setToastMessage(`${product.name} successfully removed.`);
      }
    } catch (error) {
      setToastMessage('Failed to remove product. Try again later.', 'error');
    }
  };

  const makeRows = (agronomicProducts: AgronomicProductType[], category: string) =>
    agronomicProducts.length ? (
      agronomicProducts.map((product) => (
        <Table.Tr key={product.id}>
          <Table.Td>
            <FiDelete
              color="red"
              cursor="pointer"
              onClick={() => handleRemoveProduct(product)}
              size={28}
            />
          </Table.Td>
          <Table.Td w={'10%'}>{product.name}</Table.Td>
          <Table.Td w={'10%'}>{product.registrant}</Table.Td>
          <Table.Td w={'10%'}>{product.crop}</Table.Td>
          <Table.Td>
            <InputCoverageTable
              analytics={analytics}
              category={category}
              pestGroups={pestGroups}
              product={product}
              recSet={recSet}
            />
          </Table.Td>
          <Table.Td>
            <Button onClick={() => setModalProduct(product)}>View More / Edit</Button>
          </Table.Td>
        </Table.Tr>
      ))
    ) : (
      <Table.Tr>
        <Table.Td>
          <Text className={styles.EmptyProducts}>Add Products Above</Text>
        </Table.Td>
      </Table.Tr>
    );

  const foliarProducts = products.filter((product) => product.category === FOLIAR);

  const inFurrowProducts = products.filter((product) => product.category === IN_FURROW);

  const seedTreatments = products.filter((product) => product.category === SEED_TREATMENT);

  const getModalProductRatings = (product: AgronomicProductType) => {
    return analytics.reduce((all: { [key: number]: CombinedAnalyticType }, analytic) => {
      const recSetProduct = recSet.recommended_products?.[product.id];
      if (!recSetProduct) {
        return all;
      }

      const analyticInCoverage = recSetProduct.ratings.find(
        (rating) => rating.analytic_id === analytic.id,
      );
      if (analyticInCoverage) {
        return { ...all, [analytic.id]: { ...analytic, ...analyticInCoverage } };
      }
      return { ...all, [analytic.id]: { ...analytic, coverage_rating: 0 } };
    }, {});
  };

  return (
    <>
      <Flex align="center" className={styles.CategoryTitle} justify="space-between">
        <Title order={2}>Crop Protection</Title>
        {productsOpened ? (
          <FiChevronUp size="2rem" onClick={toggleProducts} />
        ) : (
          <FiChevronDown size="2rem" onClick={toggleProducts} />
        )}
      </Flex>
      <Collapse in={productsOpened}>
        <Title order={3}>Seed Treatments</Title>
        <Table stickyHeaderOffset={60}>
          <Table.Thead>
            {seedTreatments.length ? (
              <Table.Tr>
                <Table.Th />
                <Table.Th>Name</Table.Th>
                <Table.Th>Manufacturer</Table.Th>
                <Table.Th>Crop</Table.Th>
                <Table.Th>Protection</Table.Th>
                <Table.Th />
              </Table.Tr>
            ) : null}
          </Table.Thead>
          <Table.Tbody>{makeRows(seedTreatments, SEED_TREATMENT)}</Table.Tbody>
        </Table>
        <Space h="md" />
        <Title order={3}>In-Furrow Products</Title>
        <Table stickyHeaderOffset={60}>
          <Table.Thead>
            {inFurrowProducts.length ? (
              <Table.Tr>
                <Table.Th />
                <Table.Th>Name</Table.Th>
                <Table.Th>Manufacturer</Table.Th>
                <Table.Th>Crop</Table.Th>
                <Table.Th>Protection</Table.Th>
                <Table.Th />
              </Table.Tr>
            ) : null}
          </Table.Thead>
          <Table.Tbody>{makeRows(inFurrowProducts, IN_FURROW)}</Table.Tbody>
        </Table>
        <Space h="md" />
        <Title order={3}>Foliar Products</Title>
        <Table stickyHeaderOffset={60}>
          <Table.Thead>
            {foliarProducts.length ? (
              <Table.Tr>
                <Table.Th />
                <Table.Th>Name</Table.Th>
                <Table.Th>Manufacturer</Table.Th>
                <Table.Th>Crop</Table.Th>
                <Table.Th>Protection</Table.Th>
                <Table.Th />
              </Table.Tr>
            ) : null}
          </Table.Thead>
          <Table.Tbody>{makeRows(foliarProducts, FOLIAR)}</Table.Tbody>
        </Table>
      </Collapse>
      {modalProduct && (
        <InputModal
          closeModal={() => setModalProduct(null)}
          defaultProductRatings={getModalProductRatings(modalProduct)}
          isTemporary={false}
          modalOpened={Boolean(modalProduct)}
          recSet={recSet}
          selectedProduct={modalProduct}
          selectedSeed={null}
        />
      )}
    </>
  );
};

export default CropProtectionCatalog;
