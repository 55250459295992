import React, { useState } from 'react';
import { Button, Checkbox, Divider, Flex, Modal, Stack, Text, Title } from '@mantine/core';
import { FiDelete } from 'react-icons/fi';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { EOCollectionFeatureType, EOCollectionFieldFeatureType } from 'store/eoCollections/types';
import { FONT_WEIGHT_BOLD } from 'constants/mantine';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { isPlanCancelled, sortPlansByDate } from 'util/samplePlan';
import { formatDateMonthDayYear } from 'util/date';
import { disableCollection } from 'store/eoCollections/thunks';
import setToast from 'actions/toastActions';

import CollectionMap from './CollectionMap';

import styles from './Container.module.css';
import { RED } from 'util/mapImageryColors';

interface FieldCollectionsProps {
  field: EOCollectionFieldFeatureType;
  selectedFieldIds: number[];
  toggleField: (id: number) => void;
}

const FieldCollections = ({ field, selectedFieldIds, toggleField }: FieldCollectionsProps) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const {
    acreage,
    acreage_unit,
    farm_name,
    id: fieldId,
    name,
    pending_eo_collections,
    sampling_plans,
  } = field.features[0].properties;
  const [selectedCollection, setSelectedCollection] = useState<number | null>(
    pending_eo_collections[0]?.properties.id || null,
  );
  const [collectionToDelete, setCollectionToDelete] = useState<number | null>(null);

  const setToastMessage = (message: string, type?: string, time?: number) =>
    dispatch(setToast(message, type, time));

  const handleDisable = async () => {
    try {
      if (collectionToDelete) {
        setToastMessage(getString('deletingCollection', language));
        await dispatch(disableCollection(collectionToDelete));
        setToastMessage(getString('collectionDeleted', language));
        setCollectionToDelete(null);
      }
    } catch (error) {
      setToastMessage(getString('requestFailed', language), 'error');
    }
  };

  const lastPlan = sortPlansByDate(sampling_plans || []).filter((p) => !isPlanCancelled(p))[0];
  const totalOverlap = pending_eo_collections.reduce(
    (total, collection) => total + Math.round(collection.properties.overlap_percentage),
    0,
  );

  if (!(lastPlan && pending_eo_collections.length)) {
    return null;
  }

  const renderCollection = (collection: EOCollectionFeatureType) => {
    const {
      created_at,
      id,
      name: collectionName,
      overlap_percentage,
      scanning_time_minutes,
    } = collection.properties;
    return (
      <Flex
        align="center"
        className={classNames(styles.CollectionRow, {
          [styles.CollectionRowSelected]: id === selectedCollection,
        })}
        justify="space-between"
        key={`collection-${id}`}
        onClick={() => setSelectedCollection(id)}
      >
        <Flex align="center" w="25%">
          <FiDelete
            className={styles.DisableIcon}
            color="red"
            onClick={(event) => {
              event.stopPropagation();
              setCollectionToDelete(id);
            }}
          />
          <Text fw={FONT_WEIGHT_BOLD} size="sm">
            {collectionName}
          </Text>
        </Flex>
        <Text fw={FONT_WEIGHT_BOLD} size="sm" w="25%">
          {formatDateMonthDayYear(created_at, language)}
        </Text>
        <Text fw={FONT_WEIGHT_BOLD} size="sm" w="35%">
          {`${Math.round(scanning_time_minutes)} ${getString('minutesAbbreviated', language)}`}
        </Text>
        <Text fw={FONT_WEIGHT_BOLD} size="sm" w="15%">
          {`${Math.round(overlap_percentage)}%`}
        </Text>
      </Flex>
    );
  };

  const closeModal = () => setCollectionToDelete(null);

  return (
    <>
      <Flex className={styles.Row} justify="space-between">
        <Flex align="center" w="25%">
          <Checkbox
            className={styles.Checkbox}
            checked={selectedFieldIds.includes(fieldId)}
            color="rgba(17, 48, 99, 1)"
            onChange={() => toggleField(fieldId)}
          />
          <Text fw={FONT_WEIGHT_BOLD} size="sm">
            {`${farm_name ? `${farm_name}:` : ''} ${name}`}
          </Text>
        </Flex>
        <Text fw={FONT_WEIGHT_BOLD} size="sm" w="25%">
          {lastPlan.name}
        </Text>
        <Text fw={FONT_WEIGHT_BOLD} size="sm" w="35%">
          {`${Math.round(acreage)} ${acreage_unit}`}
        </Text>
        <Text fw={FONT_WEIGHT_BOLD} size="sm" w="15%">
          {`${getString('totalScanCoverage', language)}: ${totalOverlap}%`}
        </Text>
      </Flex>
      <Flex className={styles.Collection} justify="space-between">
        <Stack w="60%">
          <Flex align="center">
            <Text fw={FONT_WEIGHT_BOLD} size="sm" w="25%">
              {getString('collectionName', language)}
            </Text>
            <Text fw={FONT_WEIGHT_BOLD} size="sm" w="25%">
              {getString('collectionDate', language)}
            </Text>
            <Text fw={FONT_WEIGHT_BOLD} size="sm" w="35%">
              {getString('scanningTime', language)}
            </Text>
            <Text fw={FONT_WEIGHT_BOLD} size="sm" w="15%">
              {getString('fieldCoverage', language)}
            </Text>
          </Flex>
          <Divider size="sm" />
          {pending_eo_collections.map(renderCollection)}
        </Stack>
        <CollectionMap field={field} selectedCollection={selectedCollection} />
      </Flex>
      {collectionToDelete && (
        <Modal
          opened={Boolean(collectionToDelete)}
          onClose={closeModal}
          centered
          withCloseButton={false}
          size="lg"
          ta="center"
        >
          <Stack p="2rem">
            <Title
              order={1}
              fw={FONT_WEIGHT_BOLD}
            >{`${getString('deleteCollection', language)}?`}</Title>
            <Text>{getString('deleteCollectionConfirm', language)}</Text>
            <Flex justify="flex-end">
              <Button bg={RED} onClick={closeModal} variant="filled">
                {getString('cancel', language)}
              </Button>
              <Button ml="1rem" onClick={handleDisable}>
                {getString('confirm', language)}
              </Button>
            </Flex>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default FieldCollections;
