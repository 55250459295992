import { Feature, GeometryCollection } from 'geojson';
import { AnalyticsFieldType } from 'store/analytics/types';

export const RECEIVE_SAMPLES = 'RECEIVE_SAMPLES';
export const REQUEST_SAMPLES = 'REQUEST_SAMPLES';
export const SAMPLE_REQUEST_ERROR = 'SAMPLE_REQUEST_ERROR';

export type SampleType = {
  zone_type: string | null;
  created_at: string | null;
  updated_at: string | null;
  sampled_at: string | null;
  processed_at: string | null;
  received_at: string | null;
  arrived_at: string | null;
  sterilized_at: string | null;
  shipped_at?: string | null;
  sampled_by?: number | null;
  sampled_by_name?: string | null;
  sampled_by_id?: number | null;
  rnd: boolean;
  id: number;
  sample_uuid: string;
  barcode: null | string;
  elevation_level: null | string;
  primary: boolean;
  field_id: number;
  sampling_plan_id: number;
  status: 'created' | 'sampled' | 'received' | 'processed' | "won't sample" | 'cancelled';
  replica_type: 'technical' | 'rerun' | 'process' | null;
  products: string[];
  analytics: AnalyticsFieldType;
  sample_grouping_id: number | null;
  sample_weight: number | null;
  soil_moisture_percentage: number | null;
  'fill-color'?: string;
  nutrient_panel_not_analyzed: boolean;
  nitrate_panel_not_analyzed: boolean;
  performance_panel_not_analyzed: boolean;
  pressure_panel_not_analyzed: boolean;
  nutrient_panel_analyze_om?: boolean;
  quantity?: number | string | null;
  pressure_panel: number | null;
  performance_panel: number | null;
  nutrient_panel: number | null;
  nitrate_panel: number | null;
  qpcr_processed: boolean;
  shotgun_processed: boolean;
  chemistry_processed: boolean;
  m3_processed: boolean;
  nitrate_processed: boolean;
  ph_processed: boolean;
  aa_processed: boolean;
  om_processed: boolean;
  // TODO: deprecate after refactor
  product?: string;
  total_reads?: number;
  biological_subsample: boolean;
  pressure_panel_analyze_rkn: boolean;
};

export interface RiskSampleType extends SampleType {
  'fill-color'?: string;
  quantity?: number;
  riskLevelForAnalytic?: string;
}

export type SampleFeatureType = Feature<GeometryCollection, SampleType>;

export type SamplesResponse = SampleFeatureType[];

export interface SampleState {
  hasFailedList: number[];
  hasFetchedList: number[];
  isFetchingList: number[];
  samples: {
    [fieldId: number]: SamplesResponse;
  };
}

interface RequestSamplesAction {
  type: typeof REQUEST_SAMPLES;
  payload: {
    id: number;
  };
}

interface ReceiveSamplesAction {
  type: typeof RECEIVE_SAMPLES;
  payload: {
    id: number;
    samples: SamplesResponse;
  };
}

interface SamplesRequestError {
  type: typeof SAMPLE_REQUEST_ERROR;
  payload: {
    id: number;
    message: string;
  };
}

export type SamplesActionType = RequestSamplesAction | ReceiveSamplesAction | SamplesRequestError;
