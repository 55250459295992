import React, { useState } from 'react';

import { OperationManagerAccess, FieldThumbnail } from 'common';
import { OperationFieldType } from 'store/operation/types';
import { FieldType } from 'store/fields/types';
import { useDispatch } from 'react-redux';
import { updateField } from 'store/operation/thunks';
import { getString } from 'strings/translation';
import { getFieldGeometry } from 'store/fields/thunks';
import setToast from 'actions/toastActions';
import { Group, Button, Input, Text, Loader, Space, Box } from '@mantine/core';

type FieldInputsPropsType = {
  deleteField: (field: OperationFieldType) => void;
  geometry: FieldType;
  field: OperationFieldType;
  language: string;
};

const FieldInput = ({ deleteField, field, geometry, language }: FieldInputsPropsType) => {
  const [fieldName, setFieldName] = useState<string>(field.name);
  const [farmName, setFarmName] = useState<string | null>(field.farm_name);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const saveName = async () => {
    try {
      setLoading(true);
      const newFeatures = (geometry?.features || []).map((fieldFeature) => ({
        ...fieldFeature,
        properties: {
          ...fieldFeature.properties,
          name: fieldName,
          farm_name: farmName,
        },
      }));
      await dispatch(updateField({ type: 'FeatureCollection', features: newFeatures }, field.id));
      await dispatch(getFieldGeometry(field.id, true));
      setLoading(false);
      dispatch(setToast(getString('fieldSavedMsg', language)));
    } catch (err) {
      dispatch(setToast(getString('errorSavingFieldMsg', language), 'error', 7000));
    }
  };

  const cancelNameChange = () => {
    setFieldName(geometry.features[0].properties.name);
    setFarmName(geometry.features[0].properties.farm_name);
  };

  const fieldOrFieldNameChanged =
    geometry &&
    (fieldName !== geometry?.features[0].properties.name ||
      geometry?.features[0].properties.farm_name !== farmName);

  return (
    <>
      <Group key={field.id}>
        <Box w="3rem" h="2rem">
          {geometry || loading ? <FieldThumbnail features={geometry} /> : <Loader size="sm" />}
        </Box>
        <Text>{getString('field', language)}:</Text>
        <Input
          onChange={(event) => setFieldName(event.target.value)}
          disabled={!geometry}
          value={fieldName || ''}
        />
        <Text>{getString('farm', language)}:</Text>
        <Input
          onChange={(event) => setFarmName(event.target.value)}
          disabled={!geometry}
          value={farmName || ''}
        />
        {fieldOrFieldNameChanged && (
          <Group>
            <Button disabled={loading} onClick={saveName}>
              {getString('save', language)}
            </Button>
            <Button variant="outline" disabled={loading} onClick={cancelNameChange}>
              {getString('cancel', language)}
            </Button>
          </Group>
        )}
        {!fieldOrFieldNameChanged && (
          <OperationManagerAccess>
            <Button color="darkRed" onClick={() => deleteField(field)}>
              {getString('remove', language)}
            </Button>
          </OperationManagerAccess>
        )}
      </Group>
      <Space h="sm" />
    </>
  );
};

export default FieldInput;
