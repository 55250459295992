import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import center from '@turf/center';
import turfBbox from '@turf/bbox';
import { featureCollection } from '@turf/helpers';
import mapboxgl, { GeoJSONSourceRaw, LngLatBoundsLike } from 'mapbox-gl';

import useMapboxGl from 'common/MapHooks';
import { PRESCRIPTION_COLORS, WHITE } from 'util/mapImageryColors';
import { formatZonesWithOpacity } from 'util/prescription';
import { FieldType } from 'store/fields/types';
import { PrescriptionType } from 'store/prescriptions/types';
import getZonesForPrescription from 'store/prescriptions/thunks';
import { RootState } from 'store';

import styles from './Prescription.module.css';

interface PrescriptionThumbnailPropsType {
  field: FieldType;
  prescription: PrescriptionType;
}

const PrescriptionThumbnail = ({ field, prescription }: PrescriptionThumbnailPropsType) => {
  const { zones } = useSelector((state: RootState) => ({
    zones: state.prescriptionZones.zones[prescription.id],
  }));
  const dispatch = useDispatch();

  const [centerLongitude, centerLatitude] = useMemo(
    () => center(field).geometry?.coordinates as number[],
    [field],
  );
  const [viewport, setViewport] = useState({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: 5.5,
  });

  const [mapHasLoaded, setMapHasLoaded] = useState(false);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);

  useMapboxGl(mapContainerRef, mapRef, null, viewport, setViewport, () => {}, false, false);

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      map.on('load', () => {
        setMapHasLoaded(true);
      });
    }
  }, [mapRef, setMapHasLoaded]);

  useEffect(() => {
    dispatch(getZonesForPrescription(prescription.id, field.features[0].properties.acreage_unit));
  }, [dispatch, prescription, field]);

  useEffect(() => {
    const map = mapRef.current;
    if (mapHasLoaded && map) {
      const bbox = turfBbox(field) as LngLatBoundsLike;

      const source = { type: 'geojson', data: field } as GeoJSONSourceRaw;
      if (!map.getLayer('field-outline')) {
        map.addLayer({
          id: `field-outline`,
          type: 'line',
          source,
          paint: { 'line-color': WHITE, 'line-width': 2 },
        });

        map.fitBounds(bbox, {
          duration: 0,
          padding: 30,
        });
        const zoom = map.getZoom();
        setViewport((prevViewport) => ({
          ...prevViewport,
          centerLatitude,
          centerLongitude,
          zoom,
        }));
      }
    }
  }, [centerLatitude, centerLongitude, field, mapHasLoaded, mapRef]);

  useEffect(() => {
    const map = mapRef.current;
    if (mapHasLoaded && map && zones) {
      const formattedZones = formatZonesWithOpacity(zones);
      const newFeatureCollection = featureCollection(formattedZones);
      const source = {
        type: 'geojson',
        data: newFeatureCollection,
      } as GeoJSONSourceRaw;
      const mapId = `prescriptions-${prescription.field_id}-${prescription.id}`;
      if (!map.getLayer(mapId) && formattedZones.length) {
        map.addLayer({
          id: mapId,
          type: 'fill',
          source,
          paint: {
            'fill-color': {
              property: 'fillPercent',
              stops: PRESCRIPTION_COLORS,
            },
            'fill-outline-color': WHITE,
          },
        });
        map.addLayer({
          id: `${mapId}-quantity`,
          type: 'symbol',
          source,
          layout: {
            'text-field': ['get', 'amount'],
            'text-justify': 'center',
            'text-size': 12,
          },
          paint: {
            'text-color': WHITE,
          },
        });
      }
    }
  }, [mapRef, mapHasLoaded, prescription, zones]);

  return (
    <div className={styles.Map}>
      <div ref={mapContainerRef} className={styles.MapWrapper} />
    </div>
  );
};

export default PrescriptionThumbnail;
