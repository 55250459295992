import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  Group,
  Input,
  MultiSelect,
  SegmentedControl,
  Select,
  Stack,
  Switch,
  Textarea,
  Text,
} from '@mantine/core';
import { AnalyticType } from 'store/analytics/types';
import {
  BINARY,
  categoryDisplayNames,
  CONTINOUOS,
  DISPLAY_ANALYTICS_TOGGLES,
} from 'constants/results';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { COUNTRY_OPTIONS } from 'constants/countries';
import styles from '../Container.module.css';

type AnalyticAttributesPropsType = {
  analytic?: AnalyticType;
  setAttribute: (attributeName: string, attributeValue: any) => void;
};

const AnalyticAttributes = ({ analytic, setAttribute }: AnalyticAttributesPropsType) => {
  const language = useBroswerLanguage();
  const { analyticCategories } = useSelector((state: RootState) => {
    return {
      analyticCategories: state.analytics.analyticCategories,
    };
  });

  const analyticCategoryOptions = analyticCategories.map((cat) => ({
    id: cat,
    label: categoryDisplayNames[cat],
    value: cat,
  }));

  return (
    <Stack gap="xl">
      <Group justify="space-between" align="flex-start">
        <Input.Wrapper label={getString('analyticName', language)}>
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setAttribute('name', e.target.value)
            }
            value={analytic?.name || ''}
          />
        </Input.Wrapper>
        <Input.Wrapper label={getString('category', language)}>
          <Select
            data={analyticCategoryOptions}
            placeholder="Select a category"
            value={analyticCategoryOptions.find((c) => c.id === analytic?.category)?.value}
            onChange={(_, option) => setAttribute('category', option.value)}
          />
        </Input.Wrapper>
        <Input.Wrapper label={getString('showInUI', language)}>
          <Switch
            checked={analytic?.show_in_ui}
            className={styles.Toggle}
            onChange={() => setAttribute('show_in_ui', !analytic?.show_in_ui)}
          />
        </Input.Wrapper>
      </Group>
      <Group justify="space-between" align="flex-start">
        {DISPLAY_ANALYTICS_TOGGLES.map((ndVal) => (
          <Input.Wrapper label={getString(ndVal, language)} key={ndVal}>
            <Switch
              checked={analytic?.display_config?.[ndVal] || false}
              className={styles.Toggle}
              onChange={() =>
                setAttribute('display_config', {
                  ...analytic?.display_config,
                  [ndVal]: !analytic?.display_config?.[ndVal],
                })
              }
            />
          </Input.Wrapper>
        ))}
        <Stack gap={0}>
          <Text size="sm" fw={500} mt={0}>
            {getString('fieldResultsOverview', language)}
          </Text>
          <SegmentedControl
            data={[CONTINOUOS, BINARY]}
            value={analytic?.display_config?.is_field_result_binary ? BINARY : CONTINOUOS}
            onChange={() =>
              setAttribute('display_config', {
                ...analytic?.display_config,
                is_field_result_binary: !analytic?.display_config?.is_field_result_binary,
              })
            }
          />
        </Stack>
        <MultiSelect
          label={getString('countries', language)}
          w="15rem"
          data={COUNTRY_OPTIONS}
          value={analytic?.display_config?.countries || []}
          onChange={(countries) =>
            setAttribute('display_config', { ...analytic?.display_config, countries })
          }
        />
      </Group>
      <Group justify="space-between" align="flex-start">
        <Input.Wrapper label={getString('yieldImpact', language)}>
          <Textarea
            onChange={(e) => setAttribute('potential_yield_impact_notes', e.target.value)}
            value={analytic?.potential_yield_impact_notes || ''}
            w="35rem"
            autosize
            maxRows={5}
          />
        </Input.Wrapper>
        <Input.Wrapper label={getString('environmentalConsiderations', language)}>
          <Textarea
            onChange={(e) => setAttribute('environment_and_weather_notes', e.target.value)}
            value={analytic?.environment_and_weather_notes || ''}
            w="35rem"
            autosize
            maxRows={5}
          />
        </Input.Wrapper>
      </Group>
    </Stack>
  );
};

export default AnalyticAttributes;
