import React from 'react';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import classNames from 'classnames';
import { Container, Header, TooltipWrapper } from 'common';
import { AMPLICON_QPCR, STORAGE } from 'constants/plates';
import { PlateType } from 'store/plates/types';
import { getPlateDisplayName, getPlateStatusDisplayName } from 'util/plates';
import { getAnalysisDisplayName } from 'util/analysis';

import ActionsDropdown from './Actions/ActionsDropdown';

import styles from './PageHeader.module.css';

type PageHeaderPropsType = {
  plate: PlateType;
};

const PageHeader = ({ plate }: PageHeaderPropsType) => {
  const language = useBroswerLanguage();

  const plateType = plate.plate_type;

  const barcodeSummary = () => {
    const { plate_type: type, barcode, related_plates } = plate;
    if (type !== AMPLICON_QPCR) {
      return barcode;
    }
    const plateIds = (related_plates?.parents_plates || []).map((p) => p.homogenization_plate_id);
    const barcodeSuffix = plateIds
      .filter(Boolean)
      .map((id) => `P${id}`)
      .join('-');

    return barcodeSuffix ? `${barcode} (${barcodeSuffix})` : barcode;
  };

  return (
    <Header
      title={
        <div className={styles.PlateHeader}>
          {plate.homogenization_plate_id && (
            <div
              className={classNames(
                styles.PlateName,
                plate.is_regulated ? styles.Regulated : undefined,
              )}
            >{`P${plate.homogenization_plate_id}`}</div>
          )}
          <div className={styles.PlateInfo}>
            <div className={styles.PlateType}>
              {`${getPlateDisplayName(plateType, language)}${
                plate.analysis_type
                  ? ` - ${getAnalysisDisplayName(plate.analysis_type, language)}`
                  : ''
              }`}
            </div>
            <span data-test-id="plate-status">
              {`${barcodeSummary()} - ${getPlateStatusDisplayName(plate.status, language)}`}
              {plate.is_regulated && (
                <span>
                  {` - `}
                  <span className={styles.RegulatedText}>{`(${getString(
                    'regulated',
                    language,
                  )})`}</span>
                </span>
              )}
            </span>
          </div>
          {plate.status === STORAGE && (
            <TooltipWrapper
              content={
                <Container className={styles.Tooltip} vertical>
                  <div>{`${getString('temp', language)}: ${plate.temp}`}</div>
                  <div>{`${getString('freezer', language)}: ${plate.freezer}`}</div>
                  <div>{`${getString('rack', language)}: ${plate.rack}`}</div>
                  <div>{`${getString('shelf', language)}: ${plate.shelf}`}</div>
                </Container>
              }
            >
              <div className={styles.QuestionMark}>?</div>
            </TooltipWrapper>
          )}
        </div>
      }
    >
      <span className={styles.ActionsWrapper}>
        <ActionsDropdown plate={plate} />
      </span>
    </Header>
  );
};

export default PageHeader;
