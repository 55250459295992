import React from 'react';
import { Button, ActionIcon, SegmentedControl, Group, Text, Space, Select } from '@mantine/core';
import { OperationAgencySearchBar, Spinner, UserSearch } from 'common';
import { getString, useTranslation } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import {
  countryTabs,
  getAssignmentOptions,
  getReadinessOptions,
  getSamplePlanStatuses,
} from 'util/samplePlan';
import { FiXCircle, FiCheck, FiList, FiMap } from 'react-icons/fi';
import { OperationAgencyListType } from 'store/samplePlans/types';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { UserSelectorType } from 'store/user/types';
import styles from './TrackingFilters.module.css';

type TrackingFiltersPropsType = {
  operationOrAgency: OperationAgencyListType | undefined;
  showAllAccounts: boolean;
  setShowAllAccounts: (val: boolean) => void;
  samplerUser: UserSelectorType | undefined;
  setSamplerUser: (val: UserSelectorType | undefined) => void;
  setOperationOrAgency: (val: OperationAgencyListType | undefined) => void;
  setAlert: (val: string) => void;
  setAssignment: (val: string) => void;
  setReadiness: (val: string) => void;
  setStatus: (str: string) => void;
  status: string;
  assignment: string;
  readiness: string;
  alert: string;
  isFetchingTracking: boolean;
  setSelectedCountry: (str: string) => void;
  selectedCountry: string;
  setIsDisplayMap: (val: (old) => boolean) => void;
  isDisplayMap: boolean;
  samplingPlanLength: number;
};

const TrackingFilters = ({
  operationOrAgency,
  showAllAccounts,
  setShowAllAccounts,
  setAlert,
  setAssignment,
  setReadiness,
  setStatus,
  status,
  assignment,
  readiness,
  alert,
  isFetchingTracking,
  setOperationOrAgency,
  setSamplerUser,
  samplerUser,
  setSelectedCountry,
  selectedCountry,
  setIsDisplayMap,
  isDisplayMap,
  samplingPlanLength,
}: TrackingFiltersPropsType) => {
  const alertNames = useSelector((state: RootState) => state.user.alertNames);
  const language = useBroswerLanguage();
  const countryTabsList = countryTabs(language);
  const samplePlanStatuses = getSamplePlanStatuses(language);
  const assignmentOptions = getAssignmentOptions(language);
  const readinessOptions = getReadinessOptions(language);

  return (
    <div className={styles.FiltersWrapper}>
      <div>
        <Group justify="space-between">
          <Group>
            <OperationAgencySearchBar
              onSelect={setOperationOrAgency}
              className={styles.SearchInput}
              placeholder={useTranslation('enterAccountName')}
              onDeselect={() => setOperationOrAgency(undefined)}
              showAgency
              operationOrAgency={operationOrAgency}
            />
            {`--- ${getString('or', language)} ---`}
            <Button
              onClick={() => setShowAllAccounts(!showAllAccounts)}
              variant={showAllAccounts ? 'outline' : undefined}
              rightSection={showAllAccounts ? <FiCheck /> : <FiXCircle />}
              className={styles.ShowAllButton}
            >
              {getString('myAccounts', language)}
            </Button>
          </Group>
          <Group justify="center">
            <Text>
              <b>{getString('showing', language)}:</b> {samplingPlanLength}
            </Text>
            <SegmentedControl
              value={selectedCountry}
              onChange={setSelectedCountry}
              data={countryTabsList}
            />
            <ActionIcon variant="filled" size="lg" onClick={() => setIsDisplayMap((old) => !old)}>
              {isDisplayMap ? <FiList /> : <FiMap />}
            </ActionIcon>
          </Group>
        </Group>
        <Space h="md" />
        <Group>
          <Select
            data={samplePlanStatuses}
            value={status}
            onChange={(val) => val && setStatus(val)}
          />
          <Select
            data={assignmentOptions}
            onChange={(val) => val && setAssignment(val)}
            value={assignment}
          />
          <Select
            value={readiness}
            onChange={(val) => val && setReadiness(val)}
            data={readinessOptions}
          />
          <Select data={alertNames} value={alert} onChange={(val) => val && setAlert(val)} />
          <UserSearch
            placeholder={getString('searchSampler', language)}
            onSelect={setSamplerUser}
            onDeselect={() => setSamplerUser(undefined)}
            className={styles.SearchBar}
            userSelected={samplerUser}
          />
          {isFetchingTracking && (
            <div className={styles.SmallSpinnerWrapper}>
              <Spinner className={styles.SmallSpinner} />
            </div>
          )}
        </Group>
      </div>
    </div>
  );
};

export default TrackingFilters;
