import { Feature, Geometry, GeoJSON } from 'geojson';
import {
  AL_SATURATION,
  ALUMINUM_ID,
  analyticsOrder,
  analyticSubcategories,
  analyticToMethodMap,
  BCSR,
  BIOFERTILITY,
  BPH_ID,
  BPH_SIKORA_ID,
  CROP_PROTECTION,
  FOLIAR,
  macronutrientIds,
  micronutrientIds,
  NITROGEN_ID,
  NORTHERN_CORN_ROOTWORM_ID,
  NUTRIENT_PANEL_NOT_ANALYZED,
  NUTRIENTS,
  OM_ID,
  OM_MEASURED_ID,
  PATHOGENS,
  pathogensWithNameDiff,
  PERFORMANCE_PANEL_NOT_ANALYZED,
  phosphorusIds,
  PRESSURE_PANEL_NOT_ANALYZED,
  ROOTWORM_ID,
  RX,
  SCN_ID,
  SOIL_ATTRIBUTES,
  SOIL_HEALTH,
  SOYBEAN_CYST_NEMATODE_RKN,
  SUDDEN_DEATH_SYNDROME_QPCR_ID,
  SUDDEN_DEATH_SYNDROME_SHOTGUN_ID,
  SUGAR_BEETS,
  WESTERN_CORN_ROOTWORM_ID,
  ANALYTIC_DISPLAY_CATEGORIES,
} from 'constants/results';
import {
  AWAITING_RESULTS,
  HIGH_RISK,
  LOW_RISK,
  MODERATE_RISK,
  ND,
  NO_DATA,
  NOT_ANALYZED,
  NOT_DETECTED,
} from 'constants/fieldRisks';
import { ROOTWORM_PRESSURE_PANEL, SCN_PRESSURE_PANEL } from 'constants/products';
import {
  AnalyticBucket,
  AnalyticType,
  RiskRangesType,
  SingleAnalyticType,
} from 'store/analytics/types';
import { FieldType, MapboxSample, SampleType, SamplingPlanType } from 'store/fields/types';
import { OperationType } from 'store/operation/types';
import turfArea from '@turf/area';
import { CANCELLED, WONT_SAMPLE } from 'constants/samples';
import { M3 } from 'constants/analysis';
import { NOT_APPLICABLE } from 'constants/defaultValues';
import { getString } from 'strings/translation';
import { PORTUGUESE } from 'constants/languages';
import {
  AWAITING_RESULTS_FILL,
  NO_DATA_FILL,
  NOT_ANALYZED_FILL,
  RISK_FILL_COLORS,
} from './mapImageryColors';
import { BR, US } from 'constants/countries';
import { RecommendationType } from 'store/recommendations/types';
import { SamplePlanType } from 'store/samplePlans/types';
import { sortAnalyticsById } from './sortByName';
import { squareMetersToAcres } from './geospatial';
import { isNumber, roundThreeDecimal, roundTwoDecimal } from './numUtils';
import {
  ACRE_SIZE_AT_POINT_RADIUS,
  CUSTOM_POINTS,
  GRID_POINTS,
  GRID_ZONES,
} from '../constants/samplePlanning';
import { SampleGeoJSON } from './generalTypes';

export const getAnalyticBuckets = (analyticCategories: string[], analytics: AnalyticType[]) =>
  analyticCategories
    .filter((category) => category !== SOIL_HEALTH)
    .map((category) => {
      const analyticsInCategory = analytics
        .filter(
          (analytic) =>
            analytic.category === category ||
            (analytic.category === SOIL_HEALTH && category === BIOFERTILITY),
        )
        .sort((a, b) => {
          const aIdx = analyticsOrder.indexOf(a.id);
          const bIdx = analyticsOrder.indexOf(b.id);
          return (aIdx > -1 ? aIdx : Infinity) - (bIdx > -1 ? bIdx : Infinity);
        });
      return {
        category,
        analytics: analyticsInCategory,
      };
    });

export const analyticPreferenceList = (operation: OperationType, analytics: AnalyticType[]) => {
  if (!operation) {
    return [];
  }

  return analytics.filter((analytic) => showAnalyticForMethodPreference(operation, analytic));
};

export const showAnalyticForMethodPreference = (
  operation: OperationType,
  analytic: AnalyticType,
) => {
  const checkPreference = (preference: string | undefined) => {
    const method = analyticToMethodMap[analytic.id];
    if (!preference) {
      return method === M3;
    }
    return preference === method;
  };
  if (analytic.id === ALUMINUM_ID) {
    // Aluminum is analyzed with resin analysis and does not map to
    // other procedures. Always return true here and filter downstream.
    return true;
  }

  if (analytic.category === SOIL_ATTRIBUTES) {
    const preferences = operation.result_preferences;
    if (phosphorusIds.includes(analytic.id)) {
      return checkPreference(preferences?.p_method_shown);
    }
    if (macronutrientIds.includes(analytic.id)) {
      return checkPreference(preferences?.ca_k_mg_method_shown);
    }
    if (micronutrientIds.includes(analytic.id)) {
      return checkPreference(preferences?.micro_method_shown);
    }
  }

  return true;
};

export const getPlanAnalytic = (
  samplingPlan: SamplingPlanType | SampleType | undefined,
  category: string,
  analyticId: number,
) => {
  return analyticId === OM_ID
    ? getOmAnalytic(samplingPlan)
    : samplingPlan?.analytics[category]?.[analyticId];
};

export const getRiskDisplayName = (analyticId: number, risk: string, language: string) =>
  [BPH_ID, BPH_SIKORA_ID].includes(analyticId)
    ? NOT_APPLICABLE
    : getString(`${risk}Risk`, language);

export const getSampleAnalyticQuantity = (
  sample: SampleType,
  activeAnalytic: AnalyticType,
): number | null => {
  if (activeAnalytic.id === ROOTWORM_ID) {
    const ncrw = sample.analytics[activeAnalytic.category]?.[NORTHERN_CORN_ROOTWORM_ID];
    const wcrw = sample.analytics[activeAnalytic.category]?.[WESTERN_CORN_ROOTWORM_ID];
    if (!ncrw?.hasOwnProperty('quantity') && !wcrw?.hasOwnProperty('quantity')) {
      return null;
    }
    if (ncrw.quantity === null && wcrw.quantity === null) {
      return null;
    }

    const combinedEggs = (ncrw?.quantity || 0) + (wcrw?.quantity || 0);
    return roundThreeDecimal(combinedEggs);
  }

  const analyticValue =
    activeAnalytic.id === OM_ID
      ? getOmAnalytic(sample)
      : sample.analytics[activeAnalytic.category]?.[activeAnalytic.id];
  // Checking if key exists. OK even if value is None
  return roundThreeDecimal(analyticValue?.quantity);
};

export const getPlanRiskColor = (riskLevel: string | undefined) => {
  switch (riskLevel) {
    case HIGH_RISK:
      return RISK_FILL_COLORS.HIGH_RISK;
    case MODERATE_RISK:
      return RISK_FILL_COLORS.MODERATE_RISK;
    case LOW_RISK:
      return RISK_FILL_COLORS.LOW_RISK;
    default:
      return NO_DATA_FILL;
  }
};

export const getAnalyticFillColor = (
  sample: SampleType,
  activeAnalytic: AnalyticType,
  samplingPlan?: SamplingPlanType,
) => {
  const displayConfig = activeAnalytic?.display_config;
  if (sample.status === WONT_SAMPLE) {
    return NOT_ANALYZED_FILL;
  }
  if (activeAnalytic.id === ROOTWORM_ID) {
    const ncrw = sample.analytics[activeAnalytic.category]?.[NORTHERN_CORN_ROOTWORM_ID];
    const wcrw = sample.analytics[activeAnalytic.category]?.[WESTERN_CORN_ROOTWORM_ID];
    const planRiskLevel = getPlanRiskColor(
      samplingPlan?.analytics[activeAnalytic.category]?.[activeAnalytic.id]?.risk_level,
    );
    if (!ncrw?.hasOwnProperty('quantity') && !wcrw?.hasOwnProperty('quantity')) {
      if (planRiskLevel) {
        return planRiskLevel;
      }
      return AWAITING_RESULTS_FILL;
    }
    if (ncrw.quantity === null && wcrw.quantity === null) {
      return planRiskLevel;
    }

    const combinedEggs = (ncrw.quantity || 0) + (wcrw.quantity || 0);
    if (combinedEggs > 6) {
      return RISK_FILL_COLORS.HIGH_RISK;
    }
    if (combinedEggs > 0) {
      return RISK_FILL_COLORS.MODERATE_RISK;
    }
    return RISK_FILL_COLORS.LOW_RISK;
  }
  const analyticValue =
    activeAnalytic.id === OM_ID
      ? getOmAnalytic(sample)
      : sample.analytics[activeAnalytic.category]?.[activeAnalytic.id];
  const interpolatedValue =
    samplingPlan && getInterpolatedValue(sample, activeAnalytic, samplingPlan);
  if (displayConfig?.is_sample_nd) {
    if (!analyticValue?.quantity && interpolatedValue === ND) {
      return RISK_FILL_COLORS.LOW_RISK;
    }
    return RISK_FILL_COLORS.HIGH_RISK;
  }
  // Checking if key exists. OK even if value is None
  if (
    !analyticValue ||
    !analyticValue.hasOwnProperty('risk_level') ||
    (analyticValue.quantity === null && ![BPH_ID, BPH_SIKORA_ID].includes(activeAnalytic.id))
  ) {
    return getPlanRiskColor(
      samplingPlan?.analytics[activeAnalytic.category]?.[activeAnalytic.id]?.risk_level,
    );
  }
  return getPlanRiskColor(analyticValue.risk_level);
};

export const getSampleAnalyticRiskLevel = (sample: SampleType, activeAnalytic: AnalyticType) => {
  if (activeAnalytic.id === ROOTWORM_ID) {
    const ncrw = sample.analytics[activeAnalytic.category]?.[NORTHERN_CORN_ROOTWORM_ID];
    const wcrw = sample.analytics[activeAnalytic.category]?.[WESTERN_CORN_ROOTWORM_ID];

    const combinedEggs = (ncrw?.quantity || 0) + (wcrw?.quantity || 0);
    if (combinedEggs > 6) {
      return HIGH_RISK;
    }
    if (combinedEggs > 0) {
      return MODERATE_RISK;
    }
    return LOW_RISK;
  }

  const analyticValue = sample.analytics[activeAnalytic.category]?.[activeAnalytic.id];
  return (activeAnalytic.id === OM_ID ? getOmAnalytic(sample) : analyticValue)?.risk_level || null;
};

export const getPlanRiskLevel = (planAnalytic?: SingleAnalyticType | null) => {
  if (planAnalytic?.risk_level) {
    return planAnalytic.risk_level;
  }
  if (planAnalytic?.hasOwnProperty('risk_level')) {
    return NO_DATA;
  }
  if (planAnalytic) {
    return AWAITING_RESULTS;
  }

  return NOT_ANALYZED;
};

export const getFieldRisk = (
  planAnalytic: SingleAnalyticType | undefined,
  samplingPlan: SamplingPlanType,
  activeAnalytic: AnalyticType,
) => {
  const displayConfig = activeAnalytic?.display_config;
  if (displayConfig?.is_field_result_binary && planAnalytic?.quantity) {
    return HIGH_RISK;
  }
  if (displayConfig?.is_field_nd && planAnalytic?.risk_level === LOW_RISK) {
    return NOT_DETECTED;
  }
  if (planAnalytic?.risk_level) {
    return planAnalytic.risk_level;
  }
  if (planAnalytic?.hasOwnProperty('risk_level')) {
    return NO_DATA;
  }
  if (
    !samplingPlan.product ||
    (samplingPlan.product === ROOTWORM_PRESSURE_PANEL && activeAnalytic.id !== ROOTWORM_ID) ||
    (samplingPlan.product === SCN_PRESSURE_PANEL && activeAnalytic.id !== SCN_ID)
  ) {
    return NOT_ANALYZED;
  }
  return AWAITING_RESULTS;
};

export const getResultRecommendation = (
  recommendations: RecommendationType[] | null | undefined,
  analytic_id: number,
  risk?: string | null,
  all: boolean = false,
) => {
  const translatedRisk = risk || LOW_RISK;
  const matchingRec = (recommendations || []).filter(
    (r: RecommendationType) => r.analytic_id === analytic_id && r.risk_level === translatedRisk,
  );

  return all ? matchingRec : matchingRec[0];
};

export const getMinMaxSampleQuantity = (samples: MapboxSample[], analytic: AnalyticType) => {
  const minMax = samples.reduce(
    (all: { lowest: number; highest: number }, single) => {
      if (single.geometry) {
        const sampleValue = getSampleAnalyticQuantity(single.properties, analytic);
        if (sampleValue !== null && isNumber(sampleValue)) {
          return {
            lowest: sampleValue < all.lowest ? sampleValue : all.lowest,
            highest: sampleValue > all.highest ? sampleValue : all.highest,
          };
        }
      }
      return all;
    },
    { lowest: 100000, highest: 0 },
  );

  return `${minMax.lowest.toFixed(2)} - ${minMax.highest.toFixed(2)}`;
};

export const getPercentAtRiskAcres = (samples: MapboxSample[], analytic: AnalyticType) => {
  return samples
    .filter((sample) => sample.geometry !== null)
    .reduce((total, sample) => {
      const riskLevel = getSampleAnalyticRiskLevel(sample.properties, analytic);
      if (riskLevel === HIGH_RISK || riskLevel === MODERATE_RISK) {
        const area = squareMetersToAcres(turfArea(sample));
        return total + area;
      }
      return total;
    }, 0);
};

export const getTotalAcres = (samples: MapboxSample[]) => {
  return samples
    .filter((sample) => sample.geometry !== null)
    .reduce((total, sample) => total + squareMetersToAcres(turfArea(sample)), 0);
};

export const getTotalSamplesAtRisk = (samples: MapboxSample[], analytic: AnalyticType) => {
  return samples.filter((sample) => {
    if (sample.geometry === null) {
      return false;
    }
    const riskLevel = getSampleAnalyticRiskLevel(sample.properties, analytic);
    return riskLevel === HIGH_RISK || riskLevel === MODERATE_RISK;
  }).length;
};

export const getMidwestBenchmarkText = (midwestBenchmark: SingleAnalyticType | null) => {
  const highRiskText = midwestBenchmark?.risk_summary.high
    ? `, ${midwestBenchmark?.risk_summary.high} are high risk.`
    : '.';

  return midwestBenchmark?.risk_summary
    ? `Midwest benchmarks indicate levels of ${midwestBenchmark.risk_summary.moderate}
      are moderate risk${highRiskText}`
    : '';
};
export const getDomainMaxFromRiskRanges = (riskRanges: RiskRangesType) => {
  return Math.max(
    ...([
      ...(riskRanges.low?.flat() || []),
      ...(riskRanges.moderate?.flat() || []),
      ...(riskRanges.high?.flat() || []),
    ] as number[]),
  );
};

// Check if a quantity falls into a risk range
export const isQuantityInRange = (range: number[] | undefined, quantity: number) => {
  return range && quantity >= range[0] && quantity <= range[1];
};

// Get the risk range that a specific quantity falls into OR if it's the highest
// range, then quantity above it will fall into that range
export const getRiskSetFromQuantity = (
  range: number[][] | undefined,
  quantity: number | undefined,
  isHighestLevel: boolean | undefined,
) => {
  if (!range?.length || !quantity) {
    return null;
  }
  if (
    isQuantityInRange(range[1], quantity) ||
    (range[1] && isHighestLevel && quantity > Math.max(...range[1]))
  ) {
    return range[1];
  }
  if (
    isQuantityInRange(range[0], quantity) ||
    (range[0] && isHighestLevel && quantity > Math.max(...range[0]))
  ) {
    return range[0];
  }
  return null;
};

export const getCategoryFromParams = (analysis: string | undefined) => {
  if (analysis === CROP_PROTECTION) {
    return PATHOGENS;
  }
  if (analysis === FOLIAR) {
    return FOLIAR;
  }
  if (analysis === SUGAR_BEETS) {
    return SUGAR_BEETS;
  }
  if (analysis === NUTRIENTS || analysis === RX) {
    return SOIL_ATTRIBUTES;
  }
  if (analysis === BCSR) {
    return BCSR;
  }
  return BIOFERTILITY;
};

export const getAnalyticFromPlan = (
  plan: SamplingPlanType | undefined | null,
  analytic: AnalyticType,
) =>
  analytic.id === OM_ID ? getOmAnalytic(plan) : plan?.analytics[analytic.category][analytic.id];

export const rangeToString = (range: number[] | undefined | null, decimalLength?: number) => {
  if (!range?.length) {
    return '';
  }
  return isNumber(decimalLength)
    ? `${range[0].toFixed(decimalLength)} - ${range[1].toFixed(decimalLength)}`
    : `${range[0]} - ${range[1]}`;
};

export const riskRangeToString = (range: number[][] | undefined | null, decimalLength?: number) =>
  range
    ? range
        .filter((single) => single.length)
        .map((single) => rangeToString(single, decimalLength))
        .join(', ')
    : '';

export const hasShotgunSDS = (allAnaytics: AnalyticType[], analytic: AnalyticType) => {
  return (
    SUDDEN_DEATH_SYNDROME_QPCR_ID === Number(analytic.id) &&
    allAnaytics.find((single) => single.id === SUDDEN_DEATH_SYNDROME_SHOTGUN_ID)
  );
};

export const getSubCategoryAnalytics = (
  category: string,
  analyticCategories: string[],
  analytics: AnalyticType[],
  fieldGeometries: FieldType[],
) => {
  const countryCode = fieldGeometries?.[0]?.features[0].properties.country_code || US;
  const analyticBuckets = getAnalyticBuckets(analyticCategories, analytics);
  const subCategories = analyticSubcategories[category];
  if (pathogensWithNameDiff.includes(category)) {
    category = PATHOGENS;
  }
  const analyticsPerTab = analyticBuckets
    .filter((analytic) => analytic.category === category)
    .flatMap((bucket) => bucket.analytics)
    .filter((analytic) => {
      if (countryCode === BR) {
        return analytic.id !== NITROGEN_ID;
      }
      const removeAnalyticList = [ALUMINUM_ID, AL_SATURATION];
      return !removeAnalyticList.includes(analytic.id);
    });

  return {
    analyticsPerTab,
    subCategoryAnalytics: Object.keys(subCategories).map((cat) => {
      return analyticsPerTab
        .filter((analytic) =>
          subCategories[cat] ? subCategories[cat].includes(analytic.id) : true,
        )
        .map((analytic) => ({
          ...analytic,
          subCategory: cat,
        }));
    }),
  };
};

export const fulfillAnalyticsFromConfig = (
  analyticSortConfig: { [crop: string]: number[] },
  analyticsPerTab: AnalyticType[],
) =>
  Object.keys(analyticSortConfig).reduce(
    (all, cat) => ({
      ...all,
      [cat]: sortAnalyticsById(
        analyticsPerTab
          .filter((analytic) => (analyticSortConfig?.[cat] || []).includes(analytic.id))
          .map((analytic) => ({
            ...analytic,
            subCategory: cat,
          })),
        analyticSortConfig[cat],
      ),
    }),
    {},
  );

export const getAnalyticsPerTab = (
  analyticCategories: string[],
  analytics: AnalyticType[],
  category: string,
  countryCode: string,
) => {
  const analyticBuckets = getAnalyticBuckets(analyticCategories, analytics); // Pathogens, soil attributes, etc
  return analyticBuckets
    .filter((analytic) => analytic.category === category)
    .flatMap((bucket) => bucket.analytics)
    .filter((analytic) => {
      if (countryCode === BR) {
        return analytic.id !== NITROGEN_ID;
      }
      const removeAnalyticList = [ALUMINUM_ID, AL_SATURATION];
      return !removeAnalyticList.includes(analytic.id);
    });
};

const getAnalyticsInTab = (
  analyticIdsForCrops: { [crop: string]: number[] },
  analytics: AnalyticType[],
  countryCode: string,
) => {
  const analyticIdsPerTab = Array.from(
    new Set(Object.values(analyticIdsForCrops).flatMap((ids) => ids)),
  );
  return analytics.filter(
    (analytic) =>
      analyticIdsPerTab.includes(analytic.id) &&
      analytic.display_config?.countries?.includes(countryCode),
  );
};

export const getSubCategoryAnalyticsV2 = (
  analytics: AnalyticType[],
  fieldGeometries: FieldType[],
  analyticIdsForCrops: { [crop: string]: number[] },
) => {
  const countryCode = fieldGeometries?.[0]?.features[0].properties.country_code || US;
  const analyticsPerTab = getAnalyticsInTab(analyticIdsForCrops, analytics, countryCode);
  return {
    analyticsPerTab,
    subCategoryAnalytics: fulfillAnalyticsFromConfig(analyticIdsForCrops, analyticsPerTab),
  };
};

export const sortByCategoryId = (
  analytics: AnalyticType[],
  category: string | undefined | null,
) => {
  const ids =
    category && analyticSubcategories[category]
      ? Object.values(analyticSubcategories[category]).flatMap((cat) => cat)
      : [];
  return ids.reduce((all: AnalyticType[], id) => {
    const foundAnalytic = analytics.find((analytic) => analytic.id === id);
    if (foundAnalytic) {
      return [...all, foundAnalytic];
    }
    return all;
  }, []);
};

export const getAnalyticId = (activeAnalytic: AnalyticType, samplingPlan: SamplePlanType) => {
  if (activeAnalytic.id === OM_ID) {
    if (samplingPlan.analytics[activeAnalytic.category]?.[OM_ID]) {
      return OM_ID;
    }
    return OM_MEASURED_ID;
  }
  return activeAnalytic.id;
};

export const hasForResultsInPlan = (
  analyticsInField: string[],
  analytic: AnalyticType,
  samplingPlan: SamplingPlanType | undefined,
) => {
  const planAnalyticCat = samplingPlan?.analytics?.[analytic.category];
  if (
    !planAnalyticCat ||
    !analyticsInField.includes(String(getAnalyticId(analytic, samplingPlan)))
  ) {
    return false;
  }

  return Object.values(planAnalyticCat[getAnalyticId(analytic, samplingPlan)]).some((val) => !!val);
};

export const getAnalyticsPerCategory = (
  analyticBuckets: AnalyticBucket[],
  category: string | undefined | null,
  analyticsInField: string[],
  samplingPlan: SamplingPlanType | undefined,
) => {
  const cat = category && pathogensWithNameDiff.includes(category) ? PATHOGENS : category;
  const analyticsPerTab = analyticBuckets
    .filter((analytic) => analytic.category === cat)
    .flatMap((bucket) => bucket.analytics);
  return sortByCategoryId(
    analyticsPerTab.reduce((all: AnalyticType[], analytic: AnalyticType) => {
      const hasResultForAnalytic = hasForResultsInPlan(analyticsInField, analytic, samplingPlan);
      const hasRKNResult = isNumber(
        samplingPlan?.analytics?.[PATHOGENS]?.[SOYBEAN_CYST_NEMATODE_RKN]?.quantity,
      );
      if (
        category &&
        pathogensWithNameDiff.includes(category) &&
        Object.values(analyticSubcategories[category])
          .flatMap((id) => id)
          .includes(analytic.id)
      ) {
        return [...all, analytic];
      }
      if (hasShotgunSDS(all, analytic) || !hasResultForAnalytic) {
        return all;
      }
      if (
        analytic.id !== SOYBEAN_CYST_NEMATODE_RKN ||
        (analytic.id === SOYBEAN_CYST_NEMATODE_RKN && hasRKNResult)
      ) {
        return [...all, analytic];
      }
      return all;
    }, []),
    category,
  );
};
// Check if Shotgun exists for SDS, if not, show QPCR or nothing
export const displaySdsQpcrOrShotgun = (
  analytic: AnalyticType,
  samplingPlan: SamplingPlanType | undefined,
) => {
  if (
    samplingPlan?.analytics.pathogens[SUDDEN_DEATH_SYNDROME_SHOTGUN_ID] &&
    analytic.id === SUDDEN_DEATH_SYNDROME_SHOTGUN_ID
  ) {
    return true;
  }
  if (
    samplingPlan?.analytics.pathogens[SUDDEN_DEATH_SYNDROME_QPCR_ID] &&
    !samplingPlan?.analytics.pathogens[SUDDEN_DEATH_SYNDROME_SHOTGUN_ID] &&
    analytic.id === SUDDEN_DEATH_SYNDROME_QPCR_ID
  ) {
    return true;
  }
  return (
    ![SUDDEN_DEATH_SYNDROME_SHOTGUN_ID, SUDDEN_DEATH_SYNDROME_QPCR_ID].includes(analytic.id) ||
    false
  );
};

export const checkEnlargePointZone = (
  sample: MapboxSample | SampleGeoJSON | null,
  samplingPlan: SamplingPlanType,
  acreSize: number,
  field?: FieldType,
) => {
  if (sample === null || !field) {
    return false;
  }
  if (
    samplingPlan.is_pro &&
    samplingPlan.zone_type === GRID_ZONES &&
    sample.properties.zone_type === 'point'
  ) {
    return true;
  }
  const sampleSize = squareMetersToAcres(turfArea(sample));
  const fieldSize = squareMetersToAcres(turfArea(field));
  return (
    sampleSize < ACRE_SIZE_AT_POINT_RADIUS &&
    fieldSize > acreSize &&
    samplingPlan.zone_type &&
    [CUSTOM_POINTS, GRID_POINTS].includes(samplingPlan.zone_type)
  );
};

export const sortByRiskColor = (samples: GeoJSON.Feature<GeoJSON.Geometry, SampleType>[]) => {
  return Object.values(
    samples.reduce(
      (all: any, sample) => {
        if (sample === null) {
          return all;
        }
        if (sample.properties['fill-color'] === RISK_FILL_COLORS.HIGH_RISK) {
          return {
            ...all,
            high: [...all.high, sample],
          };
        }
        if (sample.properties['fill-color'] === RISK_FILL_COLORS.MODERATE_RISK) {
          return {
            ...all,
            moderate: [...all.moderate, sample],
          };
        }
        return {
          ...all,
          others: [...all.others, sample],
        };
      },
      { others: [], moderate: [], high: [] },
    ),
  ).flatMap((all) => all) as GeoJSON.Feature<GeoJSON.Geometry, SampleType>[];
};

const getSeasonDisplayName = (year: number, language: string) => {
  const seasonString = getString('season', language);
  if (language === PORTUGUESE) {
    return `${seasonString} ${year}`;
  }

  return `${year} ${seasonString}`;
};

// TODO: deprecate this function when overview is complete
export const getCropYearOptions = (cropYears: number[], language: string) => {
  if (cropYears?.length) {
    return cropYears.map((year, index) => ({
      id: index,
      displayName: getSeasonDisplayName(year, language),
      value: year,
    }));
  }

  const currentYear = new Date().getFullYear();
  return [
    {
      id: 0,
      displayName: getSeasonDisplayName(currentYear, language),
      value: currentYear,
    },
  ];
};

export const getCropYearOptionsV2 = (cropYears: number[], language: string) => {
  if (cropYears?.length) {
    return cropYears.map((year) => ({
      label: getSeasonDisplayName(year, language),
      value: String(year),
    }));
  }

  const currentYear = new Date().getFullYear();
  return [
    {
      label: getSeasonDisplayName(currentYear, language),
      value: String(currentYear),
    },
  ];
};

export const getAnalyzedKeyForAnalytic = (analytic: AnalyticType) => {
  switch (analytic.category) {
    case PATHOGENS:
      return PRESSURE_PANEL_NOT_ANALYZED;
    case BIOFERTILITY:
      return PERFORMANCE_PANEL_NOT_ANALYZED;
    case SOIL_HEALTH:
      return PERFORMANCE_PANEL_NOT_ANALYZED;
    default:
      return NUTRIENT_PANEL_NOT_ANALYZED;
  }
};

const cancelledNullOrOMValues = (activeAnalytic: AnalyticType, samplingPlan: SamplingPlanType) => {
  const displayConfig = activeAnalytic?.display_config;
  const newQuantity =
    activeAnalytic.id === OM_ID
      ? getOmAnalytic(samplingPlan)?.quantity
      : samplingPlan.analytics[activeAnalytic.category][activeAnalytic.id].quantity;
  if (displayConfig?.is_sample_nd && !newQuantity) {
    return ND;
  }
  if (![BPH_ID, BPH_SIKORA_ID].includes(activeAnalytic.id)) {
    return `${roundTwoDecimal(newQuantity)}*`;
  }
  return 'N/A';
};

const getInterpolatedValue = (
  properties: SampleType,
  activeAnalytic: AnalyticType,
  samplingPlan: SamplingPlanType,
) => {
  const displayConfig = activeAnalytic?.display_config;
  const keyForAnalysis = getAnalyzedKeyForAnalytic(activeAnalytic);
  const quantity = getSampleAnalyticQuantity(properties, activeAnalytic);
  if (properties.status === CANCELLED || properties[keyForAnalysis] === true || quantity === null) {
    return cancelledNullOrOMValues(activeAnalytic, samplingPlan);
  }
  if (displayConfig?.is_sample_nd && quantity === 0) {
    return ND;
  }
  return roundTwoDecimal(quantity);
};

export const adjustSamplesForInterpolation = (
  features: Feature<Geometry, SampleType & { quantity: number }>[],
  activeAnalytic: AnalyticType,
  samplingPlan: SamplingPlanType,
) =>
  features.map((f) => ({
    ...f,
    properties: {
      ...f.properties,
      quantity: getInterpolatedValue(f.properties, activeAnalytic, samplingPlan),
      'fill-color': getAnalyticFillColor(f.properties, activeAnalytic, samplingPlan),
    },
  })) as Feature<Geometry, SampleType & { quantity: number }>[];

export const getOmQuantities = (samples: MapboxSample[]) => {
  const values = samples.map((sample) => getOmAnalytic(sample.properties)?.quantity || 0);
  if (!samples.length) {
    return {
      average: 0,
      max: 0,
      min: 0,
    };
  }
  const average = values.reduce((a, b) => a + b) / values.length;
  return {
    average: roundTwoDecimal(average),
    max: roundTwoDecimal(Math.max(...values)),
    min: roundTwoDecimal(Math.min(...values)),
  };
};

export const getAccountSelectionOptions = (language: string) => [
  {
    value: '/operations/manage/all',
    displayName: getString('allAccounts', language),
    label: getString('allAccounts', language),
  },
  {
    value: '/operations/manage',
    displayName: getString('myAccounts', language),
    label: getString('myAccounts', language),
  },
];

export const getAnalysisViewOptions = (
  language: string,
  categories: string[] | undefined = ANALYTIC_DISPLAY_CATEGORIES,
  hideRx?: boolean,
) =>
  categories
    .map((cat, idx) => ({
      id: idx,
      displayName: getString(cat, language),
      label: getString(cat, language),
      value: cat,
    }))
    .filter((cat) => !hideRx || cat.value !== RX);

export const getOmAnalytic = (
  sampleOrSamplingPlan: SampleType | SamplingPlanType | undefined | null,
) => {
  const measuredOm = sampleOrSamplingPlan?.analytics[SOIL_ATTRIBUTES]?.[OM_MEASURED_ID];
  if (sampleOrSamplingPlan?.nutrient_panel_analyze_om) {
    return measuredOm;
  }
  const modeledOm = sampleOrSamplingPlan?.analytics[SOIL_ATTRIBUTES]?.[OM_ID];
  return modeledOm;
};

export const getDisplaySamplesForAnalytic = (
  samples: MapboxSample[],
  activeAnalytic: AnalyticType,
) => {
  const subsamples = samples.filter((sample) => sample.properties.biological_subsample);
  const standardSamples = samples.filter((sample) => !sample.properties.biological_subsample);
  const analyticSamples =
    ![SOIL_ATTRIBUTES, BCSR].includes(activeAnalytic.category) && subsamples.length
      ? subsamples
      : standardSamples;
  return analyticSamples.filter((sample) => sample.geometry !== null);
};

export const planHasMissingSample = (samples: MapboxSample[], analytic: AnalyticType) => {
  const displayConfig = analytic?.display_config;
  const key = getAnalyzedKeyForAnalytic(analytic);

  const displaySamples = getDisplaySamplesForAnalytic(samples, analytic);
  return (
    displaySamples.some((sample) => sample.properties[key] === true) ||
    displaySamples.some((sample) => {
      // for rootworm we need to check multiple analytics
      const analyticSearchIds =
        analytic.id === ROOTWORM_ID
          ? [NORTHERN_CORN_ROOTWORM_ID, WESTERN_CORN_ROOTWORM_ID]
          : [analytic.id];
      const analytics = analyticSearchIds.map(
        (id) => sample.properties.analytics[analytic.category]?.[id],
      );
      return (
        analytics.every((a) => !a || !Object.keys(a).length) ||
        analytics.some((a) => !displayConfig?.is_field_nd && a.quantity === null)
      );
    })
  );
};
