interface Object {
  [key: string]: any;
}
type ArrayOfObjects = Object[];

export function groupBy(obj: ArrayOfObjects, groupByKey: string) {
  return obj.reduce((group, val) => {
    const valKey = val[groupByKey];
    group[valKey] = (group[valKey] || []).concat(val);
    return group;
  }, {});
}

export function byKey(arr: ArrayOfObjects, key: string) {
  return arr.reduce((group, val) => {
    return {
      ...group,
      [val[key]]: val,
    };
  }, {});
}

/**
 * Replace an item in a collection if the newItem has the same id.
 * Otherwise, adds newItem to the end of the collection.
 * @param collection
 * @param newItem
 * @returns Array
 */
export function replaceOrAdd(
  collection: ArrayOfObjects = [],
  newItem: { id: number; [key: string]: any },
) {
  const newItemIdx = collection.findIndex((item) => item.id === newItem.id);
  if (newItemIdx !== -1) {
    return [
      ...collection.slice(0, newItemIdx),
      newItem,
      ...collection.slice(newItemIdx + 1, collection.length),
    ];
  }
  return [...collection, newItem];
}
