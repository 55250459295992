import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Group, Stack, Loader, Center, Select } from '@mantine/core';

import { RootState } from 'store';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getOperation, getResultsLinkByFormat, setActiveOperation } from 'store/operation/thunks';
import { requestRecordUserActivity } from 'store/user/requests';
import { getOperationAllAgencies } from 'store/agencies/thunks';
import { Header, TabbedController } from 'common';
import { FILTER_OPTIONS, ANALYTIC_DISPLAY_CATEGORIES } from 'constants/results';
import classNames from 'classnames';
import { NOT_APPLICABLE } from 'constants/defaultValues';
import { getAllPlansWithResultsForFields } from 'util/samplePlan';
import { getAnalysisViewOptions, getCropYearOptionsV2 } from 'util/results';
import AnalysisTableContainer from './AnalysisTable/Container';
import OverviewControlBar from '../common/OverviewControlBar';
import styles from './Container.module.css';

type ParamsType = {
  operationId: string;
  analysis:
    | 'crop-protection'
    | 'foliar'
    | 'sugar-beets'
    | 'bioactive'
    | 'nutrients'
    | 'bcsr'
    | 'rx';
  crop: string;
};

const ResultsContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { operationId, analysis } = useParams<ParamsType>();
  const numericId = Number(operationId);
  const { search } = useLocation();
  const year = new URLSearchParams(search).get('year');
  const browserLang = useBroswerLanguage();
  const language = new URLSearchParams(search).get('language') || browserLang;
  const [selectedYear, setSelectedYear] = useState<string | null | undefined>(
    String(new Date().getFullYear()),
  );
  const [selectedFilterdIndex, setSelectedFilterIndex] = useState(1);
  const [isPrinting, toggleIsPrinting] = useState(false);
  const { operation, analyticCategories, isFetchingAgencies, fieldGeometries } = useSelector(
    (state: RootState) => ({
      operation: state.operations.operationsById[numericId],
      analyticCategories: state.analytics.analyticCategories,
      isFetchingAgencies: state.agencies.isFetching,
      fieldGeometries: state.fieldGeometry.geometries,
    }),
  );

  const analysisViewOptions = getAnalysisViewOptions(language, ANALYTIC_DISPLAY_CATEGORIES, false);

  const latestCompletePlans = useMemo(
    () =>
      getAllPlansWithResultsForFields(
        Object.values(fieldGeometries)
          .filter(
            (field) =>
              field.features[0].properties.operation_id === numericId &&
              field.features[0].properties.sampling_plans.length,
          )
          .map((field) => field.features[0].properties),
        analyticCategories,
      ),

    [numericId, fieldGeometries, analyticCategories],
  );

  const yearOptions = useMemo(
    () => getCropYearOptionsV2(operation?.crop_years, language),
    [operation],
  );

  useEffect(() => {
    if (year && yearOptions.length) {
      setSelectedYear(year);
    }
  }, [year, yearOptions, selectedYear]);

  useEffect(() => {
    if (!year && operation?.crop_years?.length) {
      navigate(
        `/results/operation/${operationId}/${analysis}?year=${operation.crop_years[0]}&language=${language}`,
      );
    }
  }, [operation, year]);

  useEffect(() => {
    requestRecordUserActivity(numericId);
  }, [numericId]);

  const filteredPlans = useMemo(
    () =>
      latestCompletePlans.filter((plan) => [plan.crop_year, null].includes(Number(selectedYear))),
    [latestCompletePlans, yearOptions, selectedYear],
  );

  useEffect(() => {
    dispatch(getResultsLinkByFormat(numericId));
    const resultsInterval = setInterval(() => {
      dispatch(getResultsLinkByFormat(numericId));
    }, 30000);

    return () => {
      clearInterval(resultsInterval);
    };
  }, [dispatch, numericId]);

  const fetchOperation = useCallback(
    () => dispatch(getOperation(numericId)),
    [dispatch, numericId],
  );

  const changeOperation = useCallback((id) => dispatch(setActiveOperation(id)), [dispatch]);

  useEffect(() => {
    if (!operation) {
      fetchOperation();
    } else {
      changeOperation(operation.id);
    }
  }, [fetchOperation, operation, changeOperation]);

  const filterOptions = FILTER_OPTIONS.map((option) => ({
    ...option,
    displayName: getString(option.value, language),
  }));

  // for populating agency logo data when printing
  useEffect(() => {
    if (numericId) {
      dispatch(getOperationAllAgencies(numericId));
    }
  }, [numericId]);

  const setCropYear = (cyear: string) => {
    setSelectedYear(cyear);
    navigate(`/results/operation/${operationId}/${analysis}?year=${cyear}`);
  };

  return operation?.name ? (
    <Stack>
      <Header title={`${getString('results', language)} - ${operation?.name || NOT_APPLICABLE}`}>
        {yearOptions.length > 0 && (
          <Select
            data-test-id="results-year-dropdown"
            value={selectedYear}
            onChange={(val) => val && setCropYear(val)}
            data={yearOptions}
          />
        )}
        <Group className={styles.HideWhilePrinting}>
          {selectedYear && (
            <OverviewControlBar
              isFetchingAgencies={isFetchingAgencies}
              operationId={numericId}
              setIsPrinting={toggleIsPrinting}
              result_links={operation?.result_links}
              selectedYear={Number(selectedYear)}
              language={language}
            />
          )}
        </Group>
      </Header>
      <Group
        className={classNames(styles.HideWhilePrinting, styles.TabController)}
        grow
        preventGrowOverflow={false}
        justify="space-between"
        wrap="nowrap"
      >
        <Group grow>
          <TabbedController
            options={analysisViewOptions}
            onChange={(idx: number) => {
              const newAnalysis = analysisViewOptions[idx].value;
              navigate(
                `/results/operation/${operationId}/${newAnalysis}?year=${selectedYear}&language=${language}`,
              );
            }}
            activeIndex={analysisViewOptions.findIndex((s) => s.value === analysis)}
          />
        </Group>
        <TabbedController
          className={styles.FilterTabs}
          activeIndex={selectedFilterdIndex}
          options={filterOptions}
          onChange={setSelectedFilterIndex}
        />
      </Group>
      <AnalysisTableContainer
        operation={operation}
        samplingPlans={filteredPlans}
        analysis={analysis}
        completeOnly={selectedFilterdIndex === 0}
        cropYear={Number(selectedYear)}
        isPrinting={isPrinting}
      />
    </Stack>
  ) : (
    <Center maw="100%" h={400}>
      <Loader />
    </Center>
  );
};

export default ResultsContainer;
