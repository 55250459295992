import {
  HIGH_RISK,
  lowModHighRiskLevels,
  LOW_RISK,
  MODERATE_RISK,
  riskToDisplay,
} from 'constants/fieldRisks';
import {
  alternateCategoryNameDict,
  altNutrientIds,
  analyticSubcategories,
  cropAnalyticCategoriesDict,
  cropAnalyticSubCategoriesDict,
} from 'constants/results';
import { AnalyticType } from 'store/analytics/types';
import { HeatMapAnalyticType, HeatMapType } from 'store/heatMap/types';
import { getString } from 'strings/translation';
import { convertDecimalToPercent } from './numUtils';

/**
 * Generates separate objects for each analytic; for ease of use when filtering.
 * @param heatMap
 * @returns HeatMapAnalyticType[]
 */
export const convertToHeatMapAnalyticsType = (heatMap: HeatMapType[]) => {
  const withAnalyticData: HeatMapAnalyticType[] = [];
  heatMap.forEach((heat) => {
    Object.values(heat.analytics).forEach((category) => {
      Object.entries(category).forEach(([analyticId, analytic]) => {
        if (analytic.hasOwnProperty('quantity')) {
          withAnalyticData.push({
            ...analytic,
            ...heat,
            analytic_id: Number(analyticId),
          });
        }
      });
    });
  });

  return withAnalyticData;
};

export const extractOperationsFromHeatMap = (heatMap: HeatMapAnalyticType[]) =>
  Array.from(
    new Set(
      heatMap.map((h) =>
        JSON.stringify({
          id: h.operation_id,
          value: h.operation_id,
          label: h.operation_name,
        }),
      ),
    ),
  )
    .map((o) => JSON.parse(o))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

export const getHeatMapForOperation = (heatMap: HeatMapAnalyticType[], operationId: number) =>
  heatMap.filter((h) => h.operation_id === operationId);

export const getHeatMapForAnalytic = (
  heatMap: HeatMapAnalyticType[],
  analyticId: number,
  ...riskLevels: string[]
) =>
  heatMap.filter((h) => {
    if (h.analytic_id === analyticId && h.quantity !== null && h.quantity !== undefined) {
      return riskLevels?.length ? riskLevels.includes(h.risk_level) : true;
    }
    return false;
  });

export const getRiskLevelOptions = (
  language: string,
  riskLevels: string[] = lowModHighRiskLevels,
) =>
  riskLevels.map((risk, index) => ({
    id: index + 1,
    label: getString(riskToDisplay[risk], language),
    value: risk,
  }));

export const getCategoriesForCrop = (language: string, crop: string) =>
  cropAnalyticCategoriesDict[crop].map((category) => ({
    value: category,
    label: getString(category, language),
  }));

/**
 * Filters analytics to be displayed for a specified category and crop.
 * @param crop
 * @param category
 * @param analytics
 * @returns AnalyticType[]
 */
export const getAnalyticsForCategory = (
  crop: string,
  category: string,
  analytics: AnalyticType[],
) => {
  const possibleCategories: string[] = alternateCategoryNameDict[category]
    ? alternateCategoryNameDict[category]
    : [category];
  const cropSubCategories = cropAnalyticSubCategoriesDict[crop];

  const analyticIds = Array.from(
    new Set(
      possibleCategories.reduce((list, cat) => {
        const subCategories = analyticSubcategories[cat];
        const idList: number[] = [];
        Object.entries(subCategories).forEach(([subCat, ids]) => {
          if (cropSubCategories.includes(subCat)) {
            idList.push(...ids);
          }
        });
        return list.concat(idList);
      }, [] as number[]),
    ),
  );

  // TODO: temp fix until analysis method supported for heat map
  return analytics
    .filter((a) => !altNutrientIds.includes(a.id))
    .filter((analytic) => analyticIds.includes(analytic.id));
};

export const getStatsForHeatMap = (heatMap: HeatMapAnalyticType[], analyticId: number) => {
  const stats = {
    count: 0,
    totalAcres: 0,
    highRiskAcres: 0,
    moderateRiskAcres: 0,
    lowRiskAcres: 0,
    highRiskPercent: 0,
    moderateRiskPercent: 0,
    lowRiskPercent: 0,
    modHighRiskPercent: 0,
  };
  heatMap.forEach((heat) => {
    if (heat.analytic_id === analyticId) {
      stats.count += 1;
      stats.totalAcres += heat.calculated_area_acres;
      if (heat.risk_level === HIGH_RISK) {
        stats.highRiskAcres += heat.calculated_area_acres;
      }
      if (heat.risk_level === MODERATE_RISK) {
        stats.moderateRiskAcres += heat.calculated_area_acres;
      }
      if (heat.risk_level === LOW_RISK) {
        stats.lowRiskAcres += heat.calculated_area_acres;
      }
    }
  });

  stats.totalAcres = Math.round(stats.totalAcres);
  stats.highRiskAcres = Math.round(stats.highRiskAcres);
  stats.moderateRiskAcres = Math.round(stats.moderateRiskAcres);
  stats.lowRiskAcres = Math.round(stats.lowRiskAcres);

  if (stats.totalAcres > 0) {
    stats.highRiskPercent = convertDecimalToPercent(stats.highRiskAcres / stats.totalAcres, 0);
    stats.moderateRiskPercent = convertDecimalToPercent(
      stats.moderateRiskAcres / stats.totalAcres,
      0,
    );
    stats.lowRiskPercent = convertDecimalToPercent(stats.lowRiskAcres / stats.totalAcres, 0);
    stats.modHighRiskPercent = convertDecimalToPercent(
      (stats.highRiskAcres + stats.moderateRiskAcres) / stats.totalAcres,
      0,
    );
  }

  return stats;
};

export const sortHeatMap = (heatMap: HeatMapAnalyticType[]) =>
  heatMap.sort((a, b) => {
    const compareOperation = a.operation_name.localeCompare(b.operation_name);
    if (compareOperation !== 0) {
      return compareOperation;
    }

    const compareFarm = (a.farm_name || '').localeCompare(b.farm_name || '');
    if (compareFarm !== 0) {
      return compareFarm;
    }

    const compareField = (a.field_name || '').localeCompare(b.field_name || '');
    if (compareField !== 0) {
      return compareField;
    }

    return a.id > b.id ? 1 : -1;
  });
