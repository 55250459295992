import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Dialog, Typeahead, Spinner } from 'common';
import setToast from 'actions/toastActions';
import { useTranslation, getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { capitalizeEveryWord } from 'util/stringUtils';
import sortByName from 'util/sortByName';
import { RootState } from 'store';
import { getPatternSamplers } from 'store/user/thunks';
import { requestPutBulkAssignPlans } from 'store/samplePlans/requests';
import { SamplePlanTrackingType, SamplePlanType } from 'store/samplePlans/types';
import styles from './BulkAssign.module.css';

type BulkAssignSamplerPropsType = {
  samplePlans: SamplePlanTrackingType[];
  onClose: () => void;
  onSubmit: () => void;
};

export const BulkAssignSampler = ({
  samplePlans,
  onClose,
  onSubmit,
}: BulkAssignSamplerPropsType) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const [userSelected, setUserSelected] = useState<{
    name: string;
    displayName: string;
    id: number | null;
  }>({
    displayName: '',
    id: null,
    name: '',
  });
  const [isSubmitting, toggleIsSubmitting] = useState(false);
  const typeaheadRef = useRef<HTMLInputElement>(null);

  const { samplers } = useSelector((state: RootState) => ({
    samplers: state.user.patternSamplers,
  }));

  useEffect(() => {
    if (!samplers) {
      dispatch(getPatternSamplers());
    }
  }, [samplers]);

  const options = useMemo(
    () =>
      samplers
        ? sortByName(
            samplers.map((user) => {
              const displayName = capitalizeEveryWord(`${user.first_name} ${user.last_name}`);
              return {
                id: user.id,
                displayName,
                name: displayName,
              };
            }),
          )
        : [],
    [samplers],
  );

  const submit = useCallback(async () => {
    toggleIsSubmitting(true);
    if (userSelected.id) {
      const body = {
        user_id: userSelected.id,
        plan_ids: samplePlans.map((plan: SamplePlanType | SamplePlanTrackingType) => plan.id),
      };
      try {
        await requestPutBulkAssignPlans(body);
        toggleIsSubmitting(false);
        onSubmit();
        onClose();
      } catch (error) {
        dispatch(setToast(getString('assignSamplerError', language), 'error', 7000));
      }
    }
  }, [dispatch, userSelected, onClose]);

  useEffect(() => {
    if (typeaheadRef.current) {
      typeaheadRef.current.focus();
    }
  }, [typeaheadRef]);

  const handleUserSelection = (index: number) => {
    setUserSelected(options[index]);
  };

  const removeFilter = () => {
    setUserSelected({ id: null, displayName: '', name: '' });
  };

  const getAssignSamplerBody = () => {
    if (isSubmitting) {
      return <Spinner fill />;
    }

    return (
      <>
        <div className={styles.Body}>
          <div className={styles.Summary}>{`${getString('samplePlans', language)}: ${
            samplePlans.length
          }`}</div>
          <div className={styles.Summary}>{`${getString('acres', language)}: ${samplePlans
            .reduce((total_acres, plan) => total_acres + plan.calculated_area_acres, 0)
            .toFixed(2)}`}</div>
          <div className={styles.Summary}>{`${getString('samples', language)}: ${samplePlans.reduce(
            (total_samples, plan) => total_samples + plan.sample_total_active_count,
            0,
          )}`}</div>
          <p>{getString('assignSamplerPar', language)}:</p>
          <Typeahead
            ref={typeaheadRef}
            onSelect={handleUserSelection}
            options={options}
            className={styles.Input}
            placeholder={getString('searchSampler', language)}
            onDeselect={removeFilter}
            waitForKeyPress
            value={userSelected.displayName}
            icon="SearchIcon"
          />
        </div>
        <div className={styles.SubmitWrapper}>
          <Button onClick={submit} primary disabled={!userSelected.id}>
            {getString('inviteSampler', language)}
          </Button>
        </div>
      </>
    );
  };

  return (
    <Dialog onClose={onClose}>
      <div className={styles.Wrapper}>
        <Container className={styles.InputColumn} vertical>
          <h1>{useTranslation('assignSampler')}</h1>
          {getAssignSamplerBody()}
        </Container>
      </div>
    </Dialog>
  );
};
