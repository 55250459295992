import React, { useState } from 'react';
import { Button, Header, Spinner } from 'common';
import { useDispatch } from 'react-redux';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { BillingAddress } from 'store/user/types';
import { US } from 'constants/countries';
import setToast from 'actions/toastActions';
import { registerBillingDetails, updateBillingDetails } from 'store/user/requests';
import { receiveSingleUser } from 'store/user/actions';
import { validateZipCode, zipCodeExp } from 'util/validators';

import styles from './ContactInfo.module.css';
import BillingInfoInputs from './BillingInfoInputs';

interface Props {
  initialBillingAddress?: BillingAddress;
  userId: number;
}

const BillingInfo = ({ initialBillingAddress, userId }: Props) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues = {
    street: initialBillingAddress?.street || '',
    city: initialBillingAddress?.city || '',
    state: initialBillingAddress?.state || '',
    zip: initialBillingAddress?.zip || '',
    country_code: initialBillingAddress?.country_code || US,
  };

  const [address, setAddress] = useState<{
    country_code: string;
    street: string;
    city: string;
    state: string;
    zip: string;
  }>(initialValues);

  const setToastMessage = (message, type?, timeout?) => dispatch(setToast(message, type, timeout));

  const handleSubmitBilling = async () => {
    try {
      const { street, city, state, zip, country_code } = address;
      if (street && city && state && zip && country_code) {
        if (!validateZipCode(zip)) {
          return setToastMessage(getString('invalidZipcode', language), 'error', 5000);
        }
        setIsSubmitting(true);
        const newAddress = {
          street: street.trim(),
          city: city.trim(),
          state,
          zip: zip.trim(),
          country_code,
          id: initialBillingAddress?.id,
        };
        const response = initialBillingAddress?.id
          ? await updateBillingDetails(newAddress, initialBillingAddress.id)
          : await registerBillingDetails(newAddress, userId);
        dispatch(receiveSingleUser(response));
        setToastMessage(getString('billingInfoUpdated', language));
      } else {
        setToastMessage(getString('missingInfoMsg', language), 'error', 5000);
      }
    } catch (err) {
      setToastMessage(getString('updateAddressFailMsg', language), 'error', 5000);
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateBillingAddress = (addresskey: string, addressValue: string) => {
    setAddress({ ...address, [addresskey]: addressValue });
  };

  const updateZipcode = (value: string) => {
    if (zipCodeExp.test(value) && value.length < 10) {
      setAddress({ ...address, zip: value });
    }
  };

  return (
    <div className={styles.Wrapper}>
      <Header underline="thin" size="H2" title="Billing Info">
        <Button onClick={() => setAddress(initialValues)} type="submit">
          {getString('cancel', language)}
        </Button>
        <Button primary onClick={handleSubmitBilling}>
          {isSubmitting ? (
            <Spinner className={styles.Spinner} />
          ) : (
            getString('saveBillingInfo', language)
          )}
        </Button>
      </Header>
      <BillingInfoInputs
        updateBillingAddress={updateBillingAddress}
        updateZipcode={updateZipcode}
        address={address}
      />
    </div>
  );
};

export default BillingInfo;
