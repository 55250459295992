import { AppThunk } from 'store';
import { postField } from 'store/operation/requests';
import { getOperation } from 'store/operation/thunks';
import showToast from 'actions/toastActions';

import { FieldEventType } from './types';
import { getFieldEvents, updateFieldEvent } from './requests';
import {
  requestFieldEvents,
  receiveFieldEvents,
  fieldEventRequestError,
  receiveFieldEvent,
} from './actions';

export const getEventsForField =
  (fieldId: number): AppThunk =>
  (dispatch) => {
    dispatch(requestFieldEvents);
    return getFieldEvents(fieldId)
      .then((response) => dispatch(receiveFieldEvents(fieldId, response.field_events) as any))
      .catch(() => dispatch(fieldEventRequestError));
  };

export const editFieldEventFieldId =
  (event: FieldEventType, newId: number, newFieldName?: string, operationId?: number): AppThunk =>
  async (dispatch) => {
    dispatch(requestFieldEvents);
    try {
      let fieldId = newId;

      if (newFieldName && operationId) {
        // Create a new field
        const newField = await postField({
          features: [
            {
              properties: {
                active: true,
                name: newFieldName,
                operation_id: operationId,
              },
            },
          ],
        });
        fieldId = newField.features[0].properties.id;

        // Refresh the List
        dispatch(getOperation(operationId));
      }

      const requestBody = {
        ...event,
        field_id: fieldId,
      };
      const response = await updateFieldEvent(event.id, requestBody);
      return dispatch(receiveFieldEvent(response, event.field_id) as any);
    } catch (e) {
      dispatch(showToast(e.message, 'error'));
      return dispatch(fieldEventRequestError);
    }
  };
