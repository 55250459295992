import React from 'react';
import { Box, Accordion, Text, Group, Input, Switch, Stack, Select } from '@mantine/core';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import styles from './Form.module.css';
import AnalysisAndSamplingPanel from './AnalysisAndSamplingPanel';
import {
  CONFIRM_ANALYSIS,
  GRID_POINTS,
  GRID_ZONES,
  SAMPLE_PLAN_DETAILS,
  SAMPLING_ZONES,
} from 'constants/samplePlanning';
import SamplingZonesPanel from './SamplingZonesPanel';
import { useDispatch, useSelector } from 'react-redux';
import { ANALYSIS_TYPES, ZoneAnalysisKeyType } from 'store/zoneAnalysisV2/types';
import { updateZoneAnalysis } from 'store/zoneAnalysisV2/actions';
import { RootState } from 'store';
import { FeatureCollection, Point, Polygon } from '@turf/helpers';
import { GeoJsonProperties } from 'geojson';
import { AdminAccess } from 'common';
import { agenciesAllowedForSamplePlan } from 'util/agency';
import useOperation from 'util/hooks/useOperation';

type FormPropsType = {
  createZones: (setUserZones?: boolean) => void;
  resetZones: (
    creationType:
      | typeof ANALYSIS_TYPES.CREATION_OPTION
      | typeof ANALYSIS_TYPES.PRO_POINT_CREATION_OPTION,
    creationOption: string,
    resetUserZones?: boolean,
  ) => void;
  resetPreviewScanGrid: (dens: number) => void;
  uploadRef: any;
  drawRef: any;
  createFinalizedGrid: (
    gridType: typeof GRID_POINTS | typeof GRID_ZONES,
  ) => Promise<
    | FeatureCollection<Point, GeoJsonProperties>
    | FeatureCollection<Polygon, GeoJsonProperties>
    | null
  >;
};

const Form = ({
  createZones,
  resetZones,
  resetPreviewScanGrid,
  createFinalizedGrid,
  uploadRef,
  drawRef,
}: FormPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const { operation } = useOperation();

  const { analysis, agencies } = useSelector((state: RootState) => ({
    analysis: state.zoneAnalysisV2.plan,
    agencies: state.agencies.allAgencies,
  }));

  const updatePlanState = (metaKeyValue: ZoneAnalysisKeyType) =>
    dispatch(updateZoneAnalysis(metaKeyValue));

  const agencyOptions =
    agenciesAllowedForSamplePlan(operation, agencies)?.map((agency) => ({
      id: agency.id,
      label: agency.name,
      value: String(agency.id),
    })) || [];

  return (
    <Box className={styles.FormWrapper}>
      <Accordion
        value={analysis.accordionLocation}
        onChange={(val) => val && updatePlanState({ accordionLocation: val })}
      >
        <Accordion.Item key="accordion-1" value={SAMPLE_PLAN_DETAILS}>
          <Accordion.Control>
            <Text fw={600}>{getString('samplePlanDetails', language)}</Text>
          </Accordion.Control>
          <Accordion.Panel>
            <Stack gap="md">
              <Group justify="space-between">
                <Text>{getString('name', language)}:</Text>
                <Input
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    updatePlanState({ name: event.target.value })
                  }
                  value={analysis.name}
                />
              </Group>
              <AdminAccess>
                <Group justify="space-between">
                  <Text>RND:</Text>
                  <Switch
                    checked={analysis.rnd}
                    onChange={() => updatePlanState({ rnd: !analysis.rnd })}
                    size="lg"
                  />
                </Group>
              </AdminAccess>
              {agencyOptions.length > 0 && (
                <Group justify="space-between">
                  <Text>{getString('agency', language)}:</Text>
                  <Select
                    data-test-id="agency-id-selector"
                    value={String(analysis.agencyId)}
                    onChange={(val) => updatePlanState({ agencyId: val })}
                    data={agencyOptions}
                    w="14rem"
                  />
                </Group>
              )}
            </Stack>
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item key="accordion-2" value={CONFIRM_ANALYSIS}>
          <Accordion.Control>
            <Text fw={600}>{getString('analysisAndSampling', language)}</Text>
          </Accordion.Control>
          <Accordion.Panel>
            <AnalysisAndSamplingPanel />
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item key="accordion-3" value={SAMPLING_ZONES}>
          <Accordion.Control>
            <Text fw={600}>{getString('samplingZones', language)}</Text>
          </Accordion.Control>
          <Accordion.Panel>
            <SamplingZonesPanel
              createZones={createZones}
              resetZones={resetZones}
              resetPreviewScanGrid={resetPreviewScanGrid}
              createFinalizedGrid={createFinalizedGrid}
              uploadRef={uploadRef}
              drawRef={drawRef}
            />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Box>
  );
};

export default Form;
