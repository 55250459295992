import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Group, Stack, Text, Title } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RestrictedGeo } from 'common';
import { getLabSamples } from 'store/labSamples/thunks';
import { LabSampleType } from 'store/labSamples/types';
import { RootState } from 'store';

import { SampleAnalysis } from './Analysis';
import { ProductOrdered } from './ProductOrdered';
import { MarkArrived } from './MarkArrived';
import { MarkSterilized } from './MarkSterilized';
import { EditMass } from './EditMass';
import { EditMoisture } from './EditMoisture';
import { EditPh } from './EditPh';
import { SampleQRCode } from './SampleQRCode';
import { SplitDensitySummary } from './SplitDensitySummary';

type ReceivingContainerPropsType = {
  sample: LabSampleType;
  searchBarFocus: () => void;
};

export const ReceivingContainer = ({ sample, searchBarFocus }: ReceivingContainerPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const { fieldSamples } = useSelector((state: RootState) => ({
    fieldSamples: state.labSamples.byFieldId[sample.field_id],
  }));

  useEffect(() => {
    if (sample.field_id && !fieldSamples) {
      dispatch(getLabSamples(sample.field_id));
    }
  }, [sample, fieldSamples]);

  const planSamples = useMemo(
    () =>
      fieldSamples
        ?.filter((s) => sample.sampling_plan_id && s.sampling_plan_id === sample.sampling_plan_id)
        .sort((a, b) => a.id - b.id) || [],
    [fieldSamples, sample],
  );

  const sampleStatusColumn = (
    <Stack gap="0" w="4.2rem">
      <Text size="md" fw={700}>{`${getString('status', language)}: `}</Text>
      <Text size="sm" fw={400}>
        {getString(sample.status, language)}
      </Text>
    </Stack>
  );

  return (
    <Stack>
      <Group justify="space-between" align="flex-start" wrap="nowrap">
        <Title order={2} fw={700}>
          {getString('receivingDataEntry', language)}
        </Title>
        <SampleQRCode sample={sample} />
      </Group>
      <Group justify="space-between" align="flex-start" wrap="nowrap" flex={2}>
        <Stack style={{ width: '100%' }}>
          <RestrictedGeo sample={sample} />
          <ProductOrdered sample={sample} />
          {sample.sample_grouping_id !== null &&
            planSamples.find((s) => s.biological_subsample) && (
              <SplitDensitySummary primarySample={sample} samples={planSamples} />
            )}
          <SampleAnalysis sample={sample} />
          <Group gap="xl" wrap="nowrap" align="flex-start">
            {sampleStatusColumn}
            <Group gap="xl" align="flex-start">
              <MarkArrived sample={sample} searchBarFocus={searchBarFocus} />
              {sample.county?.restricted && (
                <MarkSterilized sample={sample} searchBarFocus={searchBarFocus} />
              )}
            </Group>
          </Group>
          <EditMass sample={sample} searchBarFocus={searchBarFocus} />
          <EditMoisture sample={sample} searchBarFocus={searchBarFocus} />
          <EditPh sample={sample} />
        </Stack>
      </Group>
    </Stack>
  );
};
