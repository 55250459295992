export const isNumber = (value: number | string | null | undefined) =>
  typeof value === 'number' && !Number.isNaN(value);

const roundToNPlaces = (n: number) => (value: number | undefined | null) => {
  if (typeof value !== 'number' || !Number.isFinite(value)) {
    return null;
  }
  const multiplier = 10 ** n;
  return Math.round(value * multiplier) / multiplier;
};

export const roundTwoDecimal = roundToNPlaces(2);
export const roundThreeDecimal = roundToNPlaces(3);

export const convertDecimalToPercent = (d: number, places: number = 2) =>
  Math.round(d * 1000 * 10 ** places) / (10 * 10 ** places);

// Selector array for 1...len
export const selectorNumberArray = (len: number) =>
  Array.from(Array(len).keys()).map((i) => ({
    label: String(i),
    value: String(i),
  }));

// roundTwoDecimal can be undefined, return a number
export const roundTwoOrZero = (
  valType: number | undefined | null,
  wrapperFunc: Function,
  acreageUnit: string,
) => {
  return (valType ? roundTwoDecimal(wrapperFunc(valType, acreageUnit)) : 0) as number;
};

export const toCommaDollars = (value: string) => `$${value.toLocaleString()}`;

export const formatBigNumberNotation = (n: number) => {
  const abs = Math.abs(n);

  if (abs >= 1000000) {
    return `${(n / 1000000).toFixed(1)}M`;
  }
  if (abs >= 1000) {
    return `${(n / 1000).toFixed(1)}K`;
  }
  return Math.round(n);
};

export const sum = (a: number[]) =>
  a.reduce((value, total) => {
    return total + value;
  }, 0);
