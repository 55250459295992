import React, { useState } from 'react';
import setToast from 'actions/toastActions';
import { Grid, Paper, Text, Button, Box } from '@mantine/core';
import { BarChart } from '@mantine/charts';
import { XAxis, Bar, Cell } from 'recharts';
import { useParams } from 'react-router-dom';
import {
  getChartAnalytics,
  getChartPixelHeight,
  getRatingColor,
  getRatingText,
  getRecommendedSeedsProducts,
  getRiskLevel,
} from 'util/cropPlans';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import {
  RecommendationSetType,
  RecommendedProductType,
  RecommendedSeedType,
} from 'store/recommendationSets/types';
import { FieldType } from 'store/fields/types';
import { getLastSamplingPlanWithResults } from 'util/samplePlan';
import { getAnalyticFromPlan } from 'util/results';
import { getRiskColorFill } from 'util/chartUtils';
import { AnalyticType } from 'store/analytics/types';
import { AgronomicProductType, CropPlanType, SeedType } from 'store/cropPlans/types';
import { requestPutAssignInput, requestDeleteAssignInput } from 'store/cropPlans/requests';
import { getFieldGeometry } from 'store/fields/thunks';
import {
  HIGH_COVERAGE,
  INPUTS_MAP,
  SEED,
  SEED_TREATMENT,
  TRAITS,
  TREATMENT,
} from 'constants/cropPlan';
import useFirebase from 'util/hooks/useFirebase';
import { CROP_PLANNING_ADD_INPUT, CROP_PLANNING_REMOVE_INPUT } from 'constants/firebase';
import { fieldHasRknResults } from 'util/overviewResultsDisplay';
import { FONT_WEIGHT_BOLD, FONT_WEIGHT_STANDARD } from 'constants/mantine';

import styles from './CropPlanChart.module.css';
import AddInput from './AddInput';

interface Props {
  fieldGeometry: FieldType;
  cropType: string;
  defaultRecSet: RecommendationSetType | null;
  selectedChartInfo: { label: string; value: string | number } | null;
  cropPlan: CropPlanType;
}

type ParamsType = {
  operationId: string;
  analysis: string;
};

const CropPlanChart = ({
  fieldGeometry,
  cropType,
  defaultRecSet,
  selectedChartInfo,
  cropPlan,
}: Props) => {
  const { analysis } = useParams<ParamsType>();
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const [isLoading, toggleIsLoading] = useState(false);
  const firebase = useFirebase();

  const isSeed = !!selectedChartInfo && [SEED, TRAITS].includes(selectedChartInfo?.label);

  const seedProductRecs = selectedChartInfo
    ? getRecommendedSeedsProducts(
        defaultRecSet,
        cropType,
        selectedChartInfo?.label,
        isSeed,
        language,
      )
    : [];

  const chartColumnHeight = getChartPixelHeight(true, seedProductRecs.length);
  const chartPaperHeight = getChartPixelHeight(false, seedProductRecs.length);

  const { analytics, analyticCategories, analyticSortOrder } = useSelector((state: RootState) => ({
    analytics: state.analytics.analytics,
    analyticCategories: state.analytics.analyticCategories,
    pestGroups: state.cropPlanning.pestGroups,
    analyticSortOrder: state.analytics.analyticSortOrder,
  }));

  const analyticsDisplayed = getChartAnalytics(
    analysis,
    cropType,
    analytics,
    fieldHasRknResults(fieldGeometry),
    analyticSortOrder,
  ) as AnalyticType[];

  const collectedValues = (() =>
    analyticsDisplayed.map((analytic) => {
      const samplingPlan = getLastSamplingPlanWithResults(
        fieldGeometry.features[0].properties,
        analyticCategories,
      );
      const analyticCoverage = cropPlan.outstanding_risks.analytics[analytic.id || -1];

      const analyticValue = getAnalyticFromPlan(samplingPlan, analytic as AnalyticType);

      const analyzed = analyticCoverage?.analyzed_risk || 0;
      const optimized = analyticCoverage?.adjusted_risk || 0;

      return {
        id: analytic.id,
        analyticName: analytic.name,
        riskLevel: analyticCoverage?.adjusted_risk || '',
        optimized,
        coverage: analyzed - optimized,
        actual: analyticValue?.quantity,
        units: analyticValue?.unit,
      };
    }))();

  const endBarData = {
    analyticName: '',
    quantity: 0,
    optimized: 0,
    coverage: 0,
    id: undefined,
    analyticId: undefined,
    riskLevel: '',
    optimizedFill: 'transparent',
    coverageFill: 'transparent',
    actual: undefined,
    units: undefined,
  };

  const barData = [
    endBarData,
    ...collectedValues.map((analytic) => ({
      optimized: analytic.optimized || 0,
      coverage: analytic.coverage,
      actual: analytic.actual,
      analyticId: analytic.id,
      analyticName: analytic.analyticName,
      optimizedFill: getRiskColorFill(getRiskLevel(analytic.optimized)),
      coverageFill: 'red',
      units: analytic.units || '',
      id: analytic.id,
    })),
    endBarData,
  ];

  const getRowProtection = (payload: { index: number; value: number }) => {
    if (!payload.index) {
      return (
        <Text fw={600} size="xs">
          {getString('protection', language)}
        </Text>
      );
    }
    if (payload.index === barData.length - 1) {
      return <Box />;
    }
    const barAnalytic = barData[payload.index];
    const coverage =
      barAnalytic.optimized === 0 && barAnalytic.coverage ? HIGH_COVERAGE : barAnalytic.coverage;
    return (
      <Paper
        key={payload.index}
        bg={getRatingColor(coverage)}
        h="2rem"
        className={styles.LevelPaper}
      >
        <Text size="xs">{getRatingText(coverage, language)}</Text>
      </Paper>
    );
  };

  const setToastMessage = (message: string, type?: string, seconds = 3000) =>
    dispatch(setToast(message, type, seconds));

  const selectSeedRec = async (
    rec: RecommendedSeedType | RecommendedProductType,
    isRemoveSeed: boolean,
  ) => {
    try {
      toggleIsLoading(true);
      const selectedType = isSeed ? 'seed_ids' : 'agronomic_products';
      const selectedTypeRecMeta = isSeed
        ? (rec as RecommendedSeedType).seed_metadata
        : (rec as RecommendedProductType).product_metadata;
      if (isRemoveSeed) {
        await requestDeleteAssignInput(cropPlan.id, {
          [selectedType]: [selectedTypeRecMeta.id],
        });
        firebase.logEvent(CROP_PLANNING_REMOVE_INPUT, { input_id: selectedTypeRecMeta.id });
      } else {
        if (isSeed && cropPlan.seeds?.length) {
          // remove old seed
          await requestDeleteAssignInput(cropPlan.id, {
            [selectedType]: [cropPlan.seeds[0].id],
          });
          firebase.logEvent(CROP_PLANNING_REMOVE_INPUT, { input_id: cropPlan.seeds[0].id });
        }
        if (selectedChartInfo) {
          await requestPutAssignInput(cropPlan.id, {
            [selectedType]: [
              [selectedTypeRecMeta.id, isSeed ? null : INPUTS_MAP[selectedChartInfo?.label], null],
            ],
          });
          firebase.logEvent(CROP_PLANNING_ADD_INPUT, { input_id: selectedTypeRecMeta.id });
        }
      }
      setToastMessage(getString('updateFieldCropSuccessMsg', language));
      dispatch(getFieldGeometry(fieldGeometry.features[0].properties.id));
    } catch (error) {
      setToastMessage(getString('updateFieldCropErrorMsg', language), 'error');
    } finally {
      toggleIsLoading(false);
    }
  };

  const getInputOptions = (
    idx: number,
    payload: { index: number; value: number },
    rec: RecommendedSeedType | RecommendedProductType,
  ) => {
    const { ratings } = rec || [];
    const metaData = isSeed
      ? (rec as RecommendedSeedType).seed_metadata
      : (rec as RecommendedProductType).product_metadata;

    const seedOnPlan =
      isSeed &&
      cropPlan.seeds?.find((seed) => seed.id === (rec as RecommendedSeedType).seed_metadata.id);
    const productCategoryKey =
      selectedChartInfo?.label === TREATMENT ? SEED_TREATMENT : selectedChartInfo?.label;
    const productOnPlan =
      !isSeed &&
      cropPlan.agronomic_products?.find(
        (product) =>
          product.id === (rec as RecommendedProductType).product_metadata.id &&
          product.category === productCategoryKey,
      );
    const inputOnPlan = seedOnPlan || productOnPlan;
    if (!payload.index) {
      return (
        <Text
          fw={inputOnPlan ? FONT_WEIGHT_BOLD : FONT_WEIGHT_STANDARD}
          size="xs"
          style={{
            border: inputOnPlan ? '1px solid' : '',
            borderRadius: '0.5rem',
            padding: '0 0.5rem',
          }}
        >
          {isSeed ? (metaData as SeedType)?.hybrid : (metaData as AgronomicProductType)?.name}
        </Text>
      );
    }
    if (payload.index === barData.length - 1) {
      return (
        <Button
          size="xs"
          variant={inputOnPlan ? 'filled' : 'outline'}
          onClick={() => selectSeedRec(rec, !!inputOnPlan)}
          loading={isLoading}
        >
          {getString(inputOnPlan ? 'remove' : 'select', language)}
        </Button>
      );
    }
    const analytic = barData[payload.index];
    const analyticRating = ratings.find((rating) => rating.analytic_id === analytic.analyticId);
    return (
      <Paper
        bg={getRatingColor(analyticRating?.coverage_rating)}
        h="2rem"
        className={styles.LevelPaper}
      >
        <Text size="xs">{getRatingText(analyticRating?.coverage_rating, language)}</Text>
      </Paper>
    );
  };

  const ChartsHeader = (props: any) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <foreignObject
          x={-30}
          y={0}
          dy={0}
          width={80}
          height={chartColumnHeight}
          className={styles.HeaderForeign}
        >
          <Grid columns={1} justify="center" className={styles.StackNameAndRanges}>
            <Grid.Col span={1} h="3rem" className={styles.VerticalCenter}>
              <Text size="0.6rem">{payload.value}</Text>
            </Grid.Col>
            <Grid.Col span={1} h="3rem" className={!payload.index ? styles.LeftAlign : undefined}>
              {getRowProtection(payload)}
            </Grid.Col>
            {!!selectedChartInfo && (
              <>
                {!payload.index && (
                  <Grid.Col span={1} h="3rem" className={styles.LeftAlign}>
                    <Text fw={600} size="sm">
                      {getString('resistanceOptions', language)}
                    </Text>
                  </Grid.Col>
                )}
                {!!payload.index && <Grid.Col span={barData.length} h="3rem" />}
                {!!selectedChartInfo &&
                  seedProductRecs.map((seedRec, idx) => (
                    <Grid.Col
                      span={1}
                      h="3rem"
                      className={!payload.index ? styles.LeftAlign : undefined}
                      key={idx}
                    >
                      {getInputOptions(idx, payload, seedRec)}
                    </Grid.Col>
                  ))}
              </>
            )}
          </Grid>
        </foreignObject>
      </g>
    );
  };

  return (
    <Grid
      gutter="xs"
      columns={10}
      className={styles.ChartWrapper}
      style={{ minHeight: chartPaperHeight }}
    >
      <Paper className={styles.ChartPaper} style={{ minHeight: chartPaperHeight }}>
        <BarChart
          h={200}
          data={barData}
          dataKey="analyticName"
          style={{ marginLeft: -30, padding: -10 }}
          withXAxis={false}
          withTooltip={false}
          yAxisProps={{
            domain: [0, 4],
            tickCount: 3,
            tick: { dy: 15, dx: 30, fontSize: 10 },
            ticks: [1, 2, 3],
            tickFormatter: (value) => {
              if (value === 3) {
                return 'High';
              }
              if (value === 2) {
                return 'Mod';
              }
              return 'Low';
            },
          }}
          gridProps={{ horizontalValues: [0, 1, 2, 3] }}
          series={[]}
        >
          <XAxis
            dataKey="analyticName"
            minTickGap={2}
            xAxisId={1}
            interval={0}
            height={60}
            tick={<ChartsHeader />}
            orientation="bottom"
            axisLine={false}
          />
          <Bar dataKey="optimized" stackId="a">
            {barData.map((entry, index) => (
              <Cell key={`cell-${index}-${entry.analyticId}`} fill={entry.optimizedFill} />
            ))}
          </Bar>
          <Bar dataKey="coverage" stackId="a">
            {barData.map((entry, index) => (
              <>
                <Cell
                  key={`cell-${index}-a-${entry.analyticId}`}
                  fill={entry.coverageFill}
                  opacity={0.2}
                />
              </>
            ))}
          </Bar>
        </BarChart>
      </Paper>
      {selectedChartInfo && defaultRecSet && (
        <AddInput
          cropPlan={cropPlan}
          recSet={defaultRecSet}
          selectedChartInfo={selectedChartInfo}
        />
      )}
    </Grid>
  );
};

export default CropPlanChart;
