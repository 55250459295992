import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Button, Dialog, Container } from 'common';
import showToast from 'actions/toastActions';
import { shipBatch } from 'store/batches/thunks';
import { BatchType } from 'store/batches/types';

import styles from '../ActionsDropdown.module.css';

type MarkBatchPropTypes = {
  batch: BatchType;
  onClose: () => void;
};

export const MarkBatch: FunctionComponent<MarkBatchPropTypes> = ({ batch, onClose }) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const handleMarkBatchSent = () => {
    if (batch.id) {
      try {
        dispatch(shipBatch(batch.id));
        onClose();
      } catch (e) {
        dispatch(showToast(e));
      }
    }
  };

  return (
    <Dialog onClose={onClose}>
      <Container className={styles.Wrapper} vertical>
        <div className={styles.Title}>{`${getString('markBatchSent', language)}: ${batch.id}`}</div>
        <div>
          <b>{getString('markBatchWarningMsg', language)}</b>
        </div>
        <div>{getString('markBatchConfirmMsg', language)}</div>
        <div className={styles.SubmitButton}>
          <Button primary onClick={handleMarkBatchSent}>
            {getString('confirm', language)}
          </Button>
          <Button onClick={onClose}>{getString('cancel', language)}</Button>
        </div>
      </Container>
    </Dialog>
  );
};
