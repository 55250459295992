import React, { FunctionComponent, ReactNode } from 'react';

import { ToastContainer, PrintHeaderFooter } from 'common';

interface LayoutProps {
  children: ReactNode;
}

const Layout: FunctionComponent<LayoutProps> = ({ children }) => {
  return (
    <PrintHeaderFooter>
      <ToastContainer />
      {children}
    </PrintHeaderFooter>
  );
};

export default Layout;
