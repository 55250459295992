import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Group, SegmentedControl, Stack, Text } from '@mantine/core';
import { AnalyticType } from 'store/analytics/types';
import { AgronomicProductType, PestGroupPayloadType, SeedType } from 'store/cropPlans/types';
import { RecommendationSetType } from 'store/recommendationSets/types';
import { CORN } from 'constants/variables';
import sortByName from 'util/sortByName';
import { getLowModHigh } from 'util/cropPlans';
import useBroswerLanguage from 'util/hooks/useLanguage';
import setToast from 'actions/toastActions';
import { receiveSingleRecommendationSet } from 'store/recommendationSets/actions';
import { requestRecSetAssignProduct, requestRecSetAssignSeed } from 'store/cropPlans/requests';

interface InputCoverageTableProps {
  analytics: AnalyticType[];
  category?: string;
  pestGroups: PestGroupPayloadType;
  product?: AgronomicProductType;
  recSet: Partial<RecommendationSetType>;
  seed?: SeedType;
}

const InputCoverageTable = ({
  analytics,
  category,
  pestGroups,
  product,
  recSet,
  seed,
}: InputCoverageTableProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  if (!(product || seed)) {
    return <Text>N/A</Text>;
  }

  const setToastMessage = (message: string, type?: string, time?: number) =>
    dispatch(setToast(message, type, time));

  const getAnalyticIds = () => {
    if (product) {
      return Array.from(
        new Set(
          pestGroups.corn.below_ground.concat(
            pestGroups.corn.foliar,
            pestGroups.soybeans.below_ground,
            pestGroups.soybeans.foliar,
          ),
        ),
      );
    }
    if (seed) {
      const pestIds = seed.crop === CORN ? pestGroups.corn : pestGroups.soybeans;
      return pestIds.below_ground.concat(pestIds.foliar);
    }
    return [];
  };

  const getRecSetInputRatings = () => {
    if (product) {
      return recSet.recommended_products?.[product.id].ratings;
    }
    if (seed) {
      return recSet.recommended_seeds?.[seed.id].ratings;
    }
  };

  const editInputRating = async (analyticId: number, rating: number) => {
    try {
      setIsSaving(true);
      if (seed && recSet.id) {
        const response = await requestRecSetAssignSeed([[seed.id, analyticId, rating]], recSet.id);
        setToastMessage(`Seed saved successfully!`);
        dispatch(receiveSingleRecommendationSet(response));
      } else if (product && recSet.id && category) {
        const response = await requestRecSetAssignProduct(
          [[product.id, analyticId, rating]],
          recSet.id,
          category,
          product.crop,
        );
        setToastMessage(`Seed saved successfully!`);
        dispatch(receiveSingleRecommendationSet(response));
      }
    } catch (error) {
      setToastMessage(
        `Couldn't save ${product ? 'product' : 'seed'}. Please refresh and try again.`,
        'error',
      );
    } finally {
      setIsSaving(false);
    }
  };

  const inputRatings = (() => {
    const recSetInputRatings = getRecSetInputRatings() || [];
    const analyticIds = getAnalyticIds();
    const analyticsForInput = analytics.filter((analytic) => analyticIds.includes(analytic.id));
    const sortedAnalytics = sortByName(analyticsForInput);

    return sortedAnalytics
      .map((analytic) => {
        const coverage = recSetInputRatings.find((rating) => rating.analytic_id === analytic.id);
        if (coverage?.coverage_rating) {
          const lowModHigh = getLowModHigh(language, true);
          return (
            <Stack
              align="center"
              justify="flex-start"
              key={analytic.id}
              w="5rem"
              h="13rem"
              style={{ textAlign: 'center' }}
            >
              <SegmentedControl
                data={lowModHigh}
                disabled={isSaving}
                orientation="vertical"
                onChange={(val) => editInputRating(analytic.id, Number(val))}
                value={String(coverage.coverage_rating)}
              />
              <Text size="sm" fw={600}>
                {analytic.name}
              </Text>
            </Stack>
          );
        }
        return null;
      })
      .filter(Boolean)
      .slice(0, 5);
  })();

  return <Group>{inputRatings}</Group>;
};

export default InputCoverageTable;
