import { AppThunk } from 'store';
import { getSamples } from './requests';
import { requestSamples, receiveSamples, sampleRequestError } from './actions';

const getSamplesForField =
  (fieldId: number): AppThunk =>
  async (dispatch) => {
    dispatch(requestSamples(fieldId));
    try {
      const response = await getSamples(fieldId);
      dispatch(receiveSamples({ id: fieldId, samples: response.features }));
    } catch (e) {
      dispatch(sampleRequestError({ id: fieldId, message: e.message }));
    }
  };

export default getSamplesForField;
