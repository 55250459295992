import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Group, Text, Stack, Center, Avatar } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getSamplePlansTrackingPage } from 'store/samplePlans/thunks';
import { getString } from 'strings/translation';
import { Spinner } from 'common';
import { setLocalStorage } from 'util/localStorage';
import { PLAN_TRACKING_FILTERS } from 'constants/samplePlanning';
import { ALL_ALERTS, ONLY_ALERTS } from 'constants/alerts';
import styles from './Container.module.css';

type AlertSummary = {
  value: number;
  alertIndex: number;
  name: string;
};

const AlertSummaryContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useBroswerLanguage();

  useEffect(() => {
    dispatch(
      getSamplePlansTrackingPage(
        1,
        false,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        ONLY_ALERTS,
      ),
    );
  }, []);

  const { isFetchingTracking, samplePlans, alertNames } = useSelector((state: RootState) => ({
    samplePlans: state.samplePlans.summary.items,
    isFetchingTracking: state.samplePlans.isFetchingTracking,
    alertNames: state.user.alertNames,
  }));

  const alertSummary: Record<string, AlertSummary> = useMemo(() => {
    const alertStrings = alertNames.reduce((acc, cur, index) => {
      if (cur.id !== ALL_ALERTS && cur.id !== ONLY_ALERTS) {
        return {
          ...acc,
          [cur.id]: {
            value: 0,
            alertIndex: index,
            name: cur.value,
          },
        };
      }
      return {};
    }, {});
    Object.values(samplePlans).forEach((plans) => {
      plans.forEach((plan) => {
        plan.alerts.forEach((a) => {
          if (alertStrings[a.name]) {
            alertStrings[a.name].value += 1;
          }
        });
      });
    });
    return alertStrings;
  }, [samplePlans, isFetchingTracking, alertNames]);

  const handleAlertClicked = (alertName) => {
    setLocalStorage(
      PLAN_TRACKING_FILTERS,
      JSON.stringify({
        alert: alertName,
        showAllAccounts: false,
      }),
    );
    navigate('/plan-tracking');
  };

  const AlertBoxes = () => {
    return (
      <>
        {Object.entries(alertSummary).map(([key, value]) => {
          return (
            <Stack
              key={key}
              justify="space-between"
              className={styles.AlertBox}
              onClick={() => handleAlertClicked(value.name)}
            >
              <Center>
                <Text size="md" fw={500} className={styles.AlertString}>
                  {getString(key, language)}
                </Text>
              </Center>
              <Center>
                <Avatar
                  color="blue"
                  className={value.value ? styles.Avatar : undefined}
                  radius="xl"
                >
                  {String(value.value)}
                </Avatar>
              </Center>
            </Stack>
          );
        })}
      </>
    );
  };

  return (
    <Stack justify="center" align="center">
      <div className={styles.Divider} />
      <Text size="xl" fw={500}>
        {getString('myAlerts', language)}
      </Text>
      {!Object.keys(alertSummary).length ? (
        <Center>
          <Spinner className={styles.SmallSpinner} />
        </Center>
      ) : (
        <Group justify="center">
          <AlertBoxes />
        </Group>
      )}
    </Stack>
  );
};

export default AlertSummaryContainer;
