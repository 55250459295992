import React, { FunctionComponent, ReactNode } from 'react';

import { Layout, NavBar, ToastContainer } from 'common';

import styles from './NavBarLayout.module.css';

interface NavBarLayoutProps {
  children: ReactNode;
}

const NavBarLayout: FunctionComponent<NavBarLayoutProps> = ({ children }) => {
  return (
    <Layout>
      {/* @ts-ignore */}
      <NavBar mode="core" />
      <ToastContainer />
      <div data-test-id="navbar" className={styles.PageContent}>
        {children}
      </div>
    </Layout>
  );
};

export default NavBarLayout;
