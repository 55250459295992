import { SET_TOAST } from 'constants/actionTypes';

export const initialState = { message: null, type: null };

export const ToastReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_TOAST:
      return { message: action.payload.message, type: action.payload.type };
    default:
      return state;
  }
};
