import React, { useCallback, useEffect } from 'react';
import { Table, Group } from '@mantine/core';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';
import { ColumnSortType, SamplePlanTrackingType } from 'store/samplePlans/types';
import styles from './Container.module.css';
import TrackingRow from './TrackingRow';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

type SamplePlanTablePropsType = {
  toggleInitSearch: (val: boolean) => void;
  sortingCol: ColumnSortType;
  setSortingCol: (val: ColumnSortType) => void;
  setPage: (val: (prev: number) => number) => void;
};

const TrackingTable = ({
  toggleInitSearch,
  sortingCol,
  setSortingCol,
  setPage,
}: SamplePlanTablePropsType) => {
  const language = useBroswerLanguage();

  const { isFetchingTracking, samplePlans, isLastPage } = useSelector((state: RootState) => ({
    samplePlans: state.samplePlans.summary.items,
    isFetchingTracking: state.samplePlans.isFetchingTracking,
    isLastPage: state.samplePlans.summary.isLastPage,
  }));

  const handleScroll = useCallback(() => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;

    if (bottom && !isLastPage && !isFetchingTracking) {
      setPage((prev: number) => prev + 1);
    }
  }, [isLastPage, isFetchingTracking]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const sortColumn = (column: string) => {
    const clickColumnSort = () => {
      setSortingCol(
        sortingCol.col === column && sortingCol.asc
          ? { col: undefined, asc: false }
          : {
              col: column,
              asc: sortingCol.col === column ? !sortingCol.asc : false,
            },
      );
    };

    return (
      <Group justify="flex-start" onClick={clickColumnSort}>
        <div className={styles.WrapName}>{getString(column, language)}</div>
        {!sortingCol.col && <FiArrowDown color="#a7a7a7" />}
        {sortingCol.col === column && sortingCol.asc && <FiArrowUp color="#113063" />}
        {sortingCol.col === column && !sortingCol.asc && <FiArrowDown color="#113063" />}
      </Group>
    );
  };

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th className={styles.SortableColStr}>{sortColumn('operation')}</Table.Th>
          <Table.Th className={styles.SortableColStr}>{sortColumn('field')}</Table.Th>
          <Table.Th>{getString('planName', language)}</Table.Th>
          <Table.Th>{getString('agency', language)}</Table.Th>
          <Table.Th>{getString('updated', language)}</Table.Th>
          <Table.Th>
            <div className={styles.VerticalAlign}>
              <span className={styles.SpanInDiv}>{getString('planStatus', language)}</span>
              <span className={styles.SpanInDiv}>{getString('sampler', language)}</span>
              <span className={styles.SpanInDiv}>{getString('readiness', language)}</span>
            </div>
          </Table.Th>
          <Table.Th>{getString('alerts', language)}</Table.Th>
          <Table.Th className={styles.SortableColStr}>{sortColumn('days_marked_ready')}</Table.Th>
          <Table.Th>
            <div className={styles.VerticalAlign}>
              <span className={styles.SpanInDiv}>{getString('sampled', language)}</span>
              <span className={styles.SpanInDiv}>{getString('arrived', language)}</span>{' '}
              <span className={styles.SpanInDiv}>{getString('completed', language)}</span>
            </div>
          </Table.Th>
          <Table.Th />
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {Object.values(samplePlans).map((plans: SamplePlanTrackingType[]) =>
          plans.map((plan: SamplePlanTrackingType) => (
            <TrackingRow key={plan.id} samplePlan={plan} toggleInitSearch={toggleInitSearch} />
          )),
        )}
      </Table.Tbody>
    </Table>
  );
};

export default TrackingTable;
