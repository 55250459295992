import React, { useEffect, useRef, useState } from 'react';
import center from '@turf/center';
import { featureCollection } from '@turf/helpers';
import mapboxgl, { GeoJSONSourceRaw, LngLatBoundsLike } from 'mapbox-gl';
import turfBbox from '@turf/bbox';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { AnalyticType, SingleAnalyticType } from 'store/analytics/types';
import { FieldType, MapboxSample, SamplingPlanType } from 'store/fields/types';
import {
  adjustSamplesForInterpolation,
  getAnalyticFillColor,
  getFieldRisk,
  getSampleAnalyticQuantity,
} from 'util/results';
import { Text, Box, Group } from '@mantine/core';
import { getRiskColorFill } from 'util/chartUtils';
import { WHITE } from 'util/mapImageryColors';
import useMapboxGl from 'common/MapHooks';
import { NUTRIENT_PANEL } from 'constants/products';

import 'mapbox-gl/dist/mapbox-gl.css';

import styles from './MapThumbnail.module.css';

interface MapThumbnailProps {
  activeAnalytic: AnalyticType;
  field: FieldType;
  planAnalytic: SingleAnalyticType;
  samples: MapboxSample[];
  samplingPlan: SamplingPlanType;
}

const MapThumbnail = ({
  activeAnalytic,
  field,
  planAnalytic,
  samples,
  samplingPlan,
}: MapThumbnailProps) => {
  const language = useBroswerLanguage();
  const [centerLongitude, centerLatitude] = center(field).geometry?.coordinates as number[];

  const [viewport, setViewport] = useState({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: 5.5,
  });
  const [mapHasLoaded, setMapHasLoaded] = useState(false);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);

  useMapboxGl(mapContainerRef, mapRef, null, viewport, setViewport, () => {}, false);

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      map.on('load', () => {
        setMapHasLoaded(true);
      });
    }
  }, [mapRef, setMapHasLoaded]);

  const risk = getFieldRisk(planAnalytic, samplingPlan, activeAnalytic);

  useEffect(() => {
    const map = mapRef.current;
    if (mapHasLoaded && map) {
      const bbox = turfBbox(field) as LngLatBoundsLike;

      const source = { type: 'geojson', data: field } as GeoJSONSourceRaw;
      if (map.getLayer('field-outline')) {
        map.removeLayer('field-outline');
      }
      if (map.getSource('field-outline')) {
        map.removeSource('field-outline');
      }

      map.addLayer({
        id: `field-outline`,
        type: 'line',
        source,
        paint: { 'line-color': WHITE, 'line-width': 2 },
      });

      map.fitBounds(bbox, {
        duration: 0,
        padding: 30,
      });
      const zoom = map.getZoom();
      setViewport((prevViewport) => ({
        ...prevViewport,
        centerLatitude,
        centerLongitude,
        zoom,
      }));
    }
  }, [centerLatitude, centerLongitude, field, mapHasLoaded, mapRef]);

  useEffect(() => {
    const map = mapRef.current;
    if (mapHasLoaded && map) {
      const mapId = 'samples';
      if (map.getLayer(mapId)) {
        map.removeLayer(mapId);
        map.removeSource(mapId);
        map.removeLayer(`${mapId}-quantity`);
        map.removeSource(`${mapId}-quantity`);
      }
      const validSamples = samples.filter(
        (sample) => sample.geometry !== null && sample.properties.products.includes(NUTRIENT_PANEL),
      );
      const mappedFeatures = validSamples.map((val) => ({
        ...val,
        properties: {
          ...val.properties,
          'fill-color': getAnalyticFillColor(val.properties, activeAnalytic, samplingPlan),
          quantity: getSampleAnalyticQuantity(val.properties, activeAnalytic),
        },
      }));
      const newFeatureCollection = featureCollection(mappedFeatures);
      const source = {
        type: 'geojson',
        data: newFeatureCollection,
      } as GeoJSONSourceRaw;
      // adjust quantity values to show asterisks if a specific processing is not available
      const analysisAdjustedSamples = adjustSamplesForInterpolation(
        // @ts-ignore
        mappedFeatures,
        activeAnalytic,
        samplingPlan,
      );
      const adjustedSource = {
        type: 'geojson',
        data: featureCollection(analysisAdjustedSamples),
      };
      if (!map.getLayer(mapId)) {
        if (validSamples.length) {
          map.addLayer({
            id: mapId,
            type: 'fill',
            source,
            paint: {
              'fill-color': ['get', 'fill-color'],
              'fill-outline-color': WHITE,
            },
          });
          map.addLayer({
            id: `${mapId}-quantity`,
            type: 'symbol',
            // @ts-ignore
            source: adjustedSource,
            layout: {
              'text-field': ['get', 'quantity'],
              'text-justify': 'center',
              'text-size': 12,
            },
          });
        }
      }
      map.getStyle();
    }
  }, [mapHasLoaded, activeAnalytic, samplingPlan]);

  return (
    <Box className={styles.FieldMap}>
      <Group
        gap="sm"
        style={{
          paddingLeft: '0.5rem',
          backgroundColor: getRiskColorFill(planAnalytic.risk_level),
        }}
      >
        <Text fw={700}>{activeAnalytic.name}</Text>
        <Text>
          <b>{getString('risk', language)}:</b> {getString(risk, language)}
        </Text>
      </Group>
      <div ref={mapContainerRef} className={styles.MapWrapper} />
    </Box>
  );
};

export default MapThumbnail;
