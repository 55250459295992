import { AppThunk } from 'store';
import {
  analyticsRequestError,
  receiveAnalyticCategories,
  receiveAnalytics,
  receiveAnalyticSortOrder,
  requestAnalytics,
} from 'store/analytics/actions';
import setToast from 'actions/toastActions';
import { LOADING_FETCHES } from 'constants/auth';
import { getString } from 'strings/translation';
import {
  receiveUserSet,
  receiveOperationUsers,
  receivePatternSamplers,
  receiveSingleUser,
  requestUsers,
  setUserFetching,
  receiveCountries,
  receiveLabs,
  userRequestError,
  receiveAlertNames,
} from './actions';
import {
  requestUserSet,
  requestOperationAllUsers,
  requestPatternSamplers,
  requestUserDetails,
  updateUserDetails,
  requestInitialMetaDetails,
} from './requests';
import { User } from './types';
import { setActiveAgency } from '../agencies/thunks';
import { receiveContractPriceDefaults, receivePriceMinMax } from '../agencies/actions';
import { receiveRoles, requestRoles, roleRequestError } from '../roles/actions';
import { receiveInputs } from '../inputs/actions';

export const getUserSet =
  (page: number, name?: string, attr?: string, desc?: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(requestUsers());
    try {
      const response = await requestUserSet(page, name, attr, desc);
      return dispatch(receiveUserSet(response));
    } catch (e) {
      return dispatch(userRequestError());
    }
  };

export const getOperationAssociatedUsers =
  (operationId: number): AppThunk =>
  async (dispatch) => {
    dispatch(requestUsers());
    try {
      const response = await requestOperationAllUsers(operationId);
      return dispatch(receiveOperationUsers(response.users));
    } catch (e) {
      return dispatch(userRequestError());
    }
  };

export const getPatternSamplers = (): AppThunk => async (dispatch) => {
  dispatch(requestUsers());
  try {
    const response = await requestPatternSamplers();
    return dispatch(receivePatternSamplers(response.users));
  } catch (e) {
    return dispatch(userRequestError());
  }
};

export const getUser =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(requestUsers(id));
    try {
      const response = await requestUserDetails(id);
      dispatch(setActiveAgency(response));
      return dispatch(receiveSingleUser(response));
    } catch (e) {
      return dispatch(userRequestError(id));
    }
  };

export const submitUserDetails =
  (
    user: Partial<User>,
    id: number,
    language: string,
    refreshUser: boolean = true,
    successKey?: string,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(requestUsers());
    try {
      const response = await updateUserDetails(user, id);
      // we should only refresh the base user store if a user is editing their own account
      if (refreshUser) {
        dispatch(receiveSingleUser(response));
      }
      if (successKey) {
        dispatch(setToast(getString(successKey, language)));
      }
    } catch (e) {
      dispatch(setToast(getString('updateUserInfoFailMsg', language), 'error', 5000));
    } finally {
      dispatch(setUserFetching(false));
    }
  };

// Get all Metadata for the app in one place, then dispatch it to correct state
export const getInitialMeta =
  (errorMessage: string, setFetchMetaStatus: Function): AppThunk =>
  async (dispatch) => {
    // Only dispatch tracked state changes (isFetching, hasFailed, etc)
    dispatch(requestAnalytics);
    dispatch(requestRoles);
    try {
      // analytics, roles, inputs, analytic_categories, lab locations, alerts
      const response = await requestInitialMetaDetails();
      dispatch(receiveAnalyticCategories(response));
      dispatch(receiveAnalyticSortOrder(response.analytic_sort_order));
      dispatch(receiveAnalytics(response));
      dispatch(receiveRoles(response.roles));
      dispatch(receiveInputs(response.inputs));
      dispatch(receiveCountries(response.countries));
      dispatch(receiveLabs(response.lab_locations));
      dispatch(receiveAlertNames(response.alert_names));
      dispatch(receiveContractPriceDefaults(response.contract_price_defaults));
      dispatch(receivePriceMinMax(response.contract_price_min_max));
      setFetchMetaStatus(LOADING_FETCHES.FINISHED);
    } catch (e) {
      dispatch(setToast(errorMessage, 'error', 10000));
      dispatch(analyticsRequestError);
      dispatch(roleRequestError);
    }
  };
