import React, { useState, useEffect, useCallback, useMemo, ChangeEvent } from 'react';
import {
  Stack,
  Group,
  Text,
  Select,
  Button,
  Box,
  Switch,
  Space,
  ActionIcon,
  Tooltip,
} from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { featureCollection, FeatureCollection, Point, Polygon, MultiPolygon } from '@turf/helpers';
import {
  CUSTOM_POINTS,
  DEFAULT_GRID_SIZE,
  densityOptions,
  GRID_POINTS,
  GRID_ZONES,
  splitDensities,
  SPLIT_DENSITY,
  ZONE_BY_ZONE,
} from 'constants/samplePlanning';
import useFieldGeometry from 'util/hooks/useFieldGeometry';
import {
  isCustomPointsOption,
  isGridsOption,
  isUploadOption,
  lockingButtonText,
  unlockingButtonText,
  isProScanPoint,
  getZoneCreationOptions,
  getProPointCreationOptions,
  getBioZonesCount,
  getBioZonesDensity,
  getProNutrientTillRxPointsCount,
  getSubmitPointsMsg,
  getProNutrientDensity,
  isPointsOption,
  getNewAnalysisFromUpdate,
} from 'util/samplePlan';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { ZoneAnalysisKeyType, ANALYSIS_TYPES } from 'store/zoneAnalysisV2/types';
import { updateZoneAnalysis } from 'store/zoneAnalysisV2/actions';
import { NUTRIENT_PANEL, PATTERN_360, PERFORMANCE_PANEL, PRESSURE_PANEL } from 'constants/products';
import { Selector } from 'common';
import styles from './Form.module.css';
import showToast from 'actions/toastActions';
import { postDensityGroupings, postZonesForUpload } from 'store/samplePlans/requests';
import { getSelectedBioProductFromAnalysis, isNutrientPanel } from 'util/product';
import { GeoJsonProperties } from 'geojson';
import { POINT, POLYGON, ZONE_TYPES } from 'constants/mapbox';
import {
  clipZonesToLayer,
  filterZonesWithinBoundaries,
  processZoneFeatureCollection,
} from 'util/geospatial';
import acToHectares, { getAcreageUnitFromLang } from 'util/units';
import { FiEdit2 } from 'react-icons/fi';

type SamplingZonesFormPropsType = {
  resetZones: (
    creationType:
      | typeof ANALYSIS_TYPES.CREATION_OPTION
      | typeof ANALYSIS_TYPES.PRO_POINT_CREATION_OPTION,
    creationOption: string,
    resetUserZones?: boolean,
  ) => void;
  createZones: (setUserZones?: boolean) => void;
  resetPreviewScanGrid: (dens: number) => void;
  uploadRef: any;
  drawRef: any;
  createFinalizedGrid: (
    gridType: typeof GRID_POINTS | typeof GRID_ZONES,
  ) => Promise<
    | FeatureCollection<Point, GeoJsonProperties>
    | FeatureCollection<Polygon, GeoJsonProperties>
    | null
  >;
};

const SamplingZonesPanel = ({
  resetZones,
  createZones,
  resetPreviewScanGrid,
  createFinalizedGrid,
  uploadRef,
  drawRef,
}: SamplingZonesFormPropsType) => {
  const language = useBroswerLanguage();
  const fieldGeometry = useFieldGeometry();
  const dispatch = useDispatch();
  const [isSwitching, toggleIsSwitching] = useState(false);
  const [splitIndex, setSplitIndex] = useState(0);
  const [isSwitchingPro, toggleIsSwitchingPro] = useState(false);

  const analysis = useSelector((state: RootState) => state.zoneAnalysisV2.plan);

  // const showDensity = [SSURGO_ZONES, DEM_ZONES, GRID_ZONES, GRID_POINTS].includes(
  //   analysis.creationOption,
  // );
  const isUpload = isUploadOption(analysis.creationOption);
  const isCustomPoints = isCustomPointsOption(analysis.creationOption);
  const totalZones = analysis.zones?.features.length || 0;
  const isRestricted = Boolean(fieldGeometry?.features[0].properties.county?.restricted);
  const selectedBioProduct = getSelectedBioProductFromAnalysis(analysis, language, isRestricted);
  const canSelectSplitDensity = analysis.zones && selectedBioProduct?.value === PATTERN_360;
  const isProOrTillRx = analysis.isProScan || analysis.isTillRx;

  const isProGridPoints = analysis.proPointCreationOption === GRID_POINTS;
  const isProCustomPoints = analysis.proPointCreationOption === CUSTOM_POINTS;
  const isBioZoneCreationType = analysis.zoneCreationType === ANALYSIS_TYPES.CREATION_OPTION;
  const { acreage_unit } = fieldGeometry.features[0].properties;

  const splitDensityOptions = splitDensities
    .reduce((list, percent) => {
      const split = Math.floor(percent * totalZones);
      return split && !list.includes(split) ? list.concat([split]) : list;
    }, [] as number[])
    .map((split, index) => {
      return {
        id: index,
        displayName: split,
        value: split,
      };
    });

  const zoneCreationOptions = getZoneCreationOptions(language, isProOrTillRx);
  const proPointCreationOptions = getProPointCreationOptions(language);

  const updatePlanState = useCallback(
    (metaKeyValue: ZoneAnalysisKeyType) => dispatch(updateZoneAnalysis(metaKeyValue)),
    [],
  );
  const displayToast = (message: string, type?: string, time?: number) =>
    dispatch(showToast(message, type, time));

  useEffect(() => {
    if (isSwitching) {
      updatePlanState({
        zonesLocked: false,
        disableMapTools: isGridsOption(analysis.creationOption),
      });
      resetZones(ANALYSIS_TYPES.CREATION_OPTION, analysis.creationOption, true);
      updatePlanState({ analysisMode: ZONE_BY_ZONE });
      toggleIsSwitching(false);
    }
  }, [isSwitching, analysis.creationOption]);

  useEffect(() => {
    if (isSwitchingPro) {
      updatePlanState({
        editScanPoints: true,
        disableMapTools: !!isGridsOption(analysis.proPointCreationOption),
        zoneCreationType: ANALYSIS_TYPES.PRO_POINT_CREATION_OPTION,
      });
      resetZones(ANALYSIS_TYPES.PRO_POINT_CREATION_OPTION, analysis.proPointCreationOption, true);
      updatePlanState({
        analysisMode: ZONE_BY_ZONE,
        zoneGeomType: POINT,
      });
      toggleIsSwitchingPro(false);
    }
  }, [isSwitchingPro, analysis.proPointCreationOption]);

  const toggleEditMode = () => {
    const newCreation =
      isProOrTillRx && isPointsOption(analysis.creationOption)
        ? GRID_ZONES
        : analysis.creationOption;
    updatePlanState({
      enableButtonSpinner: false,
      editScanPoints: false,
      creationOption: newCreation,
    });
    analysis.zonesLocked
      ? resetZones(ANALYSIS_TYPES.CREATION_OPTION, newCreation, true)
      : createZones(!isUpload);

    updatePlanState({
      zonesLocked: !analysis.zonesLocked,
      disableMapTools: isGridsOption(analysis.creationOption)
        ? analysis.zonesLocked
        : analysis.disableMapTools,
    });
  };

  const switchCreationType = (val: string | null) => {
    if (val) {
      updatePlanState({ creationOption: val });
      toggleIsSwitching(true);
    }
  };

  const switchProCreationType = (val: string | null) => {
    if (val) {
      updatePlanState({ proPointCreationOption: val });
      toggleIsSwitchingPro(true);
    }
  };

  const switchDensityType = (val: string | null) => {
    if (val) {
      updatePlanState({ density: Number(val) });
      toggleIsSwitching(true);
    }
  };

  const getButtonFunction = () => {
    updatePlanState({ zoneCreationType: ANALYSIS_TYPES.CREATION_OPTION });
    if (isCustomPoints) {
      return resetZones(ANALYSIS_TYPES.CREATION_OPTION, CUSTOM_POINTS, true);
    }
    return toggleEditMode();
  };

  const numGroups = useMemo(() => {
    const groupNumbers = analysis.zones?.features
      .map((feature) => feature.properties?.sample_group)
      .filter(Boolean);
    const uniqueNumbers = new Set(groupNumbers);
    return uniqueNumbers.size;
  }, [analysis.zones]);

  const showMismatch = numGroups && numGroups !== splitDensityOptions[splitIndex].value;

  const getGroupings = useCallback(
    async (groupsNum: number, collection: FeatureCollection<Polygon | Point>) => {
      try {
        const groupedZones = await postDensityGroupings(groupsNum, collection);
        updatePlanState({
          zones: groupedZones as FeatureCollection<Polygon | Point>,
        });
      } catch (error) {
        dispatch(showToast(getString('unableGenerateZoneGroupings', language), 'error'));
      }
    },
    [],
  );

  const updateSplitDensityGroupings = async (idx: number) => {
    setSplitIndex(idx);
    if (analysis.zones) {
      await getGroupings(splitDensityOptions[idx].value, analysis.zones);
    }
  };

  const setSplitDensityGetGroupings = async () => {
    try {
      if (analysis.analysisMode === SPLIT_DENSITY) {
        return updatePlanState({ analysisMode: ZONE_BY_ZONE, disableMapTools: false });
      }

      updatePlanState({
        analysisMode: SPLIT_DENSITY,
        isOrderButtonDisabled: true,
        disableMapTools: true,
      });
      if (analysis.zones) {
        const groupedZones = await postDensityGroupings(
          splitDensityOptions[analysis.splitIndex].value,
          analysis.zones,
        );
        updatePlanState({
          zones: groupedZones as FeatureCollection<Polygon | Point>,
          isOrderButtonDisabled: false,
        });
      }
    } catch (e) {
      dispatch(showToast(getString('unableGenerateZoneGroupings', language), 'error', 5000));
    } finally {
      updatePlanState({
        isOrderButtonDisabled: false,
      });
    }
  };

  const setupEditLockScanPoints = async () => {
    updatePlanState({
      zoneCreationType: ANALYSIS_TYPES.PRO_POINT_CREATION_OPTION,
      disableMapTools: false,
    });
    if (isProCustomPoints) {
      return resetZones(ANALYSIS_TYPES.PRO_POINT_CREATION_OPTION, CUSTOM_POINTS, true);
    }
    if (analysis.editScanPoints) {
      const pointsMapped: FeatureCollection<Polygon | Point> | null = isProGridPoints
        ? await createFinalizedGrid(GRID_POINTS)
        : drawRef.current.getAll();
      updatePlanState({
        zones: featureCollection([
          ...(analysis.zones?.features || []),
          ...(pointsMapped?.features.map((feat) => ({
            ...feat,
            properties: {
              ...feat.properties,
              products: [NUTRIENT_PANEL],
              zone_type: ZONE_TYPES.POINT,
            },
          })) || []),
        ]),
        zoneGeomType: POLYGON,
        zonesLocked: true,
        disableMapTools: true,
        editScanPoints: false,
        previewZones: null,
      });
    } else {
      let newZones = analysis.zones?.features || [];
      if (isProOrTillRx) {
        newZones = newZones?.filter((sample) => !isProScanPoint(sample, isProOrTillRx));
      }
      updatePlanState({
        zonesLocked: true,
        disableMapTools: true,
        editScanPoints: true,
        zones: featureCollection(newZones),
        zoneGeomType: POLYGON,
      });
      resetPreviewScanGrid(analysis.scanDensity);
    }
  };

  const updateScanDensity = (val: string | null) => {
    if (val) {
      updatePlanState({ scanDensity: Number(val) });
      if (analysis.editScanPoints) {
        resetPreviewScanGrid(Number(val));
      }
    }
  };

  const uploadFile = useCallback(
    async (file: File) => {
      try {
        const responseJson: FeatureCollection = await postZonesForUpload(file);
        if (responseJson.features?.length) {
          const filteredZones = filterZonesWithinBoundaries(
            responseJson as FeatureCollection<MultiPolygon | Polygon>,
            fieldGeometry,
          );
          const clippedZones = clipZonesToLayer(
            filteredZones,
            fieldGeometry,
          ) as FeatureCollection<Polygon>;
          if (clippedZones.features?.length) {
            const newZones = processZoneFeatureCollection(clippedZones, POLYGON);
            updatePlanState({
              zones: newZones as FeatureCollection<Polygon>,
            });
            displayToast(getString('planZonesSuccessMsg', language));
          } else {
            displayToast(getString('planZonesErrorDiffMsg', language), 'error');
          }
        } else {
          displayToast(getString('planZonesErrorNoZonesMsg', language), 'error');
        }
      } catch (err) {
        updatePlanState({ enableButtonSpinner: false });
        displayToast(getString('planZonesErrorNoZipMsg', language), 'error');
      }
    },
    [fieldGeometry],
  );

  const handleFileChange = (event: ChangeEvent) => {
    const input = event.target as HTMLInputElement;
    const file = input.files && input.files[0];
    if (file) {
      uploadFile(file);
    }
    if (uploadRef.current) {
      uploadRef.current.value = '';
    }
    updatePlanState({ enableButtonSpinner: false });
  };

  const getProPointButtonText = () => {
    if (analysis.proPointCreationOption === CUSTOM_POINTS) {
      return getString('resetPoints', language);
    }
    return getString(analysis.editScanPoints ? 'lockPoints' : 'generatePoints', language);
  };

  const setupEditZones = () => {
    const newAnalysis = {
      ...analysis,
      zonesLocked: true,
      disableMapTools: false,
      editScanPoints: false,
      zoneCreationType: ANALYSIS_TYPES.CREATION_OPTION,
      zones: featureCollection(
        analysis.zones?.features.filter(
          (feat) => feat.properties?.zone_type !== ZONE_TYPES.POINT,
        ) || [],
      ),
    };
    updatePlanState(getNewAnalysisFromUpdate(newAnalysis, fieldGeometry));
  };

  // If Till Rx and not Pro, force 10ac density
  const proTillRxDensityOptions = densityOptions[acreage_unit].filter(
    (opt) => !analysis.isTillRx || analysis.isProScan || Number(opt.value) === DEFAULT_GRID_SIZE,
  );

  const isShowBioZones =
    (isProOrTillRx &&
      analysis.products.some((product) => [PRESSURE_PANEL, PERFORMANCE_PANEL].includes(product))) ||
    !isProOrTillRx;

  const isProNutrientDisabled =
    !analysis.scanDensity ||
    (!analysis.zonesLocked && !isNutrientPanel(analysis.products)) ||
    (!analysis.zones?.features.length && isShowBioZones);
  return (
    <Stack>
      {isShowBioZones && (
        <>
          {isProOrTillRx && (
            <Text fs="italic">{getString('setupZonesInstructionsMsg', language)}</Text>
          )}
          <Group
            justify="space-between"
            align="flex-end"
            className={isBioZoneCreationType ? styles.HighlightedColor : undefined}
          >
            <Select
              label={getString('type', language)}
              value={analysis.creationOption}
              onChange={switchCreationType}
              data={zoneCreationOptions}
              disabled={!isBioZoneCreationType}
              w="11rem"
            />
            {!isCustomPoints && (
              <Select
                label={getString('density', language)}
                value={String(analysis.density)}
                onChange={switchDensityType}
                disabled={!isBioZoneCreationType}
                data={densityOptions[acreage_unit]}
                w="5rem"
              />
            )}
            <Button
              variant="outline"
              data-test-id="lock-zones"
              onClick={getButtonFunction}
              loading={analysis.enableButtonSpinner}
            >
              {analysis.zonesLocked || isCustomPoints
                ? unlockingButtonText(
                    analysis.creationOption,
                    analysis.enableButtonSpinner,
                    language,
                  )
                : lockingButtonText(
                    analysis.creationOption,
                    analysis.enableButtonSpinner,
                    language,
                  )}
            </Button>
            <input
              accept=".gz,.zip"
              hidden
              onChange={handleFileChange}
              ref={uploadRef}
              type="file"
            />
          </Group>
          <Group justify="space-between">
            <Group>
              <Text size="xs" fs="italic">
                {getString('zones', language)}: <b>{getBioZonesCount(analysis)}</b>
              </Text>
              <Text size="xs" fs="italic">
                {getString('density', language)}:{' '}
                <b>
                  {acToHectares(getBioZonesDensity(analysis, fieldGeometry), acreage_unit)}{' '}
                  {getAcreageUnitFromLang(language)} / {getString('zone', language).toLowerCase()}
                </b>
              </Text>
            </Group>
            {isProOrTillRx &&
              analysis.zones?.features.some(
                (feat) => feat.properties?.zone_type === ZONE_TYPES.POINT,
              ) && (
                <Tooltip label={getString('editZones', language)} color="blue">
                  <ActionIcon variant="filled" onClick={setupEditZones}>
                    <FiEdit2 />
                  </ActionIcon>
                </Tooltip>
              )}
          </Group>
        </>
      )}
      {isProOrTillRx && (
        <>
          <Space h="xs" />
          <Group
            justify="space-between"
            align="flex-end"
            className={!isBioZoneCreationType ? styles.HighlightedColor : undefined}
          >
            <Select
              label={
                analysis.isTillRx && !analysis.products.length
                  ? getString('tillMapperPoints', language)
                  : getString('nutrientPoints', language)
              }
              disabled={isProNutrientDisabled}
              value={analysis.proPointCreationOption}
              onChange={switchProCreationType}
              data={proPointCreationOptions}
              w="11rem"
            />
            {isProGridPoints && (
              <Select
                label={getString('density', language)}
                value={String(analysis.scanDensity)}
                disabled={isProNutrientDisabled}
                onChange={updateScanDensity}
                data={proTillRxDensityOptions}
                w="5rem"
              />
            )}
            <Button
              variant="outline"
              disabled={isProNutrientDisabled}
              onClick={setupEditLockScanPoints}
            >
              {getProPointButtonText()}
            </Button>
          </Group>
          <Group>
            <Text
              size="xs"
              fs="italic"
              color={getSubmitPointsMsg(analysis, fieldGeometry) ? 'darkRed' : 'blue'}
            >
              {getString('points', language)}: <b>{getProNutrientTillRxPointsCount(analysis)}</b>
            </Text>
            <Text
              size="xs"
              fs="italic"
              color={getSubmitPointsMsg(analysis, fieldGeometry) ? 'darkRed' : 'blue'}
            >
              {getString('density', language)}:{' '}
              <b>
                {acToHectares(getProNutrientDensity(analysis, fieldGeometry), acreage_unit)}{' '}
                {getAcreageUnitFromLang(language)} / {getString('zone', language).toLowerCase()}
              </b>
            </Text>
          </Group>
        </>
      )}
      {!isRestricted &&
        getSelectedBioProductFromAnalysis(analysis, language, isRestricted)?.value ===
          PATTERN_360 &&
        !isProOrTillRx && (
          <Text
            data-test-id="split-density"
            c={canSelectSplitDensity ? '#0060FF' : '#B7BBBF'}
            style={{ cursor: canSelectSplitDensity ? 'pointer' : 'auto' }}
            onClick={() => canSelectSplitDensity && setSplitDensityGetGroupings()}
            size="sm"
          >
            {analysis.analysisMode === ZONE_BY_ZONE
              ? getString('splitDensityForBio', language)
              : getString('performAnalysisSingleDensity', language)}
          </Text>
        )}
      {analysis.analysisMode === SPLIT_DENSITY && (
        <Stack gap="xs">
          <Group justify="center">
            <Text size="xl" fw={500}>
              {getString('splitDensity', language)}
            </Text>
          </Group>
          <Group justify="flex-start">
            <Text size="xs">{getString('splitDensityDesc', language)}</Text>
          </Group>
          <Group justify="space-between">
            <Text>{`${getString(NUTRIENT_PANEL, language)} ${getString('zones', language)}: `}</Text>
            <Text>{totalZones}</Text>
          </Group>
          <Group justify="space-between">
            <Text>{getString('complete_bio', language)}: </Text>
            <Group>
              <Text fs="italic" hidden={!showMismatch}>{`*${numGroups} actual`}</Text>
              <Selector
                dataTestId="density-selector"
                className={styles.Density}
                activeIndex={splitIndex}
                onChange={updateSplitDensityGroupings}
                options={splitDensityOptions}
                menuClassName={styles.ReverseDropdown}
              />
            </Group>
          </Group>
          <Group justify="space-between">
            <Box className={styles.ProductInfoWrapper}>
              <Text size="lg" fw={500}>
                {getString('nitrateAnalysis', language)}
              </Text>
              <Text size="sm" fs="italic">
                {getString('nitrateAnalysisMsg', language)}
              </Text>
            </Box>
            <Switch
              checked={analysis.isSplitDensityNitrate}
              onChange={() =>
                updatePlanState({
                  isSplitDensityNitrate: !analysis.isSplitDensityNitrate,
                })
              }
              size="lg"
            />
          </Group>
        </Stack>
      )}
    </Stack>
  );
};

export default SamplingZonesPanel;
