import { AMMONIUM_ACETATE, BRAY, DTPA, M3, OLSEN } from './analysis';
import { CORN, COTTON, SOYBEANS, SUGAR_BEETS as SugarBeetsCrop } from './variables';

export const EGGS = 'eggs';
export const DNA_COPIES = 'dna copies';
export const WORMS = 'worms';

export const CROP_PROTECTION = 'crop-protection';
export const NUTRIENTS = 'nutrients';
export const RX = 'rx';
export const TOAST_TWELVE = 12000;
export const PDF_FORMAT = 'pdf';
export const CSV_FORMAT = 'csv';
export const SHAPEFILE_FORMAT = 'shapefile';
export const SUMMARY = 'summary';
export const ANALYTIC_DETAILS = 'analytic-details';
export const FIELD_COMPARISON = 'field-comparison';
export const ZONE_DETAILS = 'zone-details';
export const MAP_ANALYTIC = 'map-analytic';

export const NUTRIENT_PANEL_NOT_ANALYZED = 'nutrient_panel_not_analyzed';
export const PERFORMANCE_PANEL_NOT_ANALYZED = 'performance_panel_not_analyzed';
export const PRESSURE_PANEL_NOT_ANALYZED = 'pressure_panel_not_analyzed';

export const FILTER_OPTIONS = [
  {
    id: 1,
    displayName: 'Completed',
    value: 'completed',
  },
  {
    id: 2,
    displayName: 'All',
    value: 'all',
  },
];
export const QUICKBOOKS_TERMS = [
  { displayName: 'Net 15', id: 0 },
  { displayName: 'Net 30', id: 1 },
  { displayName: 'Net 60', id: 2 },
];

export const REFUND_THRESHOLD = 0.25;

export const PATHOGENS = 'pathogens';
export const FOLIAR = 'foliar';
export const BIOFERTILITY = 'bioactive';
export const SOIL_ATTRIBUTES = 'soil attributes';
export const SOIL_HEALTH = 'soil health';

export const BCSR = 'bcsr';

export const CORN_PATHOGENS = 'cornPathogens';
export const CORN_SOYBEAN_PATHOGENS = 'cornAndSoybeanPathogens';
export const SOYBEAN_PATHOGENS = 'soybeanPathogens';
export const COTTON_PATHOGENS = 'cottonPathogens';
export const CORN_FOLIAR = 'cornFoliar';
export const SOYBEAN_FOLIAR = 'soybeanFoliar';
export const COTTON_FOLIAR = 'cottonFoliar';
export const SUGAR_BEETS = 'sugar-beets';
export const SUGAR_BEET_PESTS_DISEASE = 'sugarBeetPestsDiseases';
export const BENEFICIAL_BIOMARKERS = 'beneficialBioMarkers';
export const HARMFUL_BIOMARKERS = 'harmfulBiomarkers';
export const MACRONUTRIENTS = 'macronutrients';
export const MICRONUTRIENTS = 'micronutrients';
export const CATION_RATIOS = 'cationRatios';

export const ANALYTIC_DISPLAY_CATEGORIES = [
  CROP_PROTECTION,
  FOLIAR,
  BIOFERTILITY,
  NUTRIENTS,
  BCSR,
  RX,
];

export const RESULT_DISPLAY_CATEGORIES = [CROP_PROTECTION, FOLIAR, BIOFERTILITY, NUTRIENTS, BCSR];

export const categoryDisplayNames = {
  [PATHOGENS as string]: 'Pathogens',
  [FOLIAR as string]: 'Foliar',
  [SUGAR_BEETS as string]: 'Sugar Beets',
  [BIOFERTILITY as string]: 'Biofertility',
  [SOIL_ATTRIBUTES as string]: 'Soil Attributes',
  [SOIL_HEALTH as string]: 'Soil Health',
  [BCSR as string]: 'BCSR',
};

export const WESTERN_CORN_ROOTWORM_ID = 1;
export const NORTHERN_CORN_ROOTWORM_ID = 2;
export const ROOTWORM_ID = 3;
export const ANTHRACNOSE_CORN_ID = 31;
export const ANTHRACNOSE_SOYBEAN_ID = 32;
export const GIBBERELLA_STALK_ROOT_ID = 38;
export const SOYBEAN_STEM_CANKER_ID = 41;
export const SOYBEAN_BROWN_STEM_ROT_ID = 40;
export const SUGAR_BEET_FUSARIUM_ID = 42;
export const WHITE_MOLD_ID = 44;
export const SCN_ID = 4;
export const SUDDEN_DEATH_SYNDROME_SHOTGUN_ID = 5;
export const SUDDEN_DEATH_SYNDROME_QPCR_ID = 51;
export const FUSARIUM_ID = 6;
export const PHYTOPHTHORA_ID = 7;
export const PYTHIUM_ID = 8;
export const RHIZOCTONIA_ID = 9;
export const SOYBEAN_NFIXERS_ID = 10;
export const NITROGEN_FIXATION_POTENTIAL_ID = 11;
export const DENITRIFICATION_POTENTIAL_ID = 12;
export const PSOLUBILIZATION_POTENTIAL_ID = 13;
export const PLANT_GROWTH_PROMOTERS_ID = 14;
export const PH_ID = 15;
export const CEC_ID = 16;
export const OM_ID = 17;
export const CALCIUM_ID = 18;
export const DIVERSITY_ID = 19;
export const GRAM_POSITIVE_RATIO_ID = 20;
export const GRAM_NEGATIVE_ID = 21;
export const GRAM_POSITIVE_ID = 22;
export const ANAEROBIC_POTENTIAL_ID = 23;
export const SOYBEAN_NFIXERS2_ID = 24;
export const IRON_ID = 25;
export const BPH_ID = 26;
export const MAGNESIUM_ID = 27;
export const BORON_ID = 28;
export const MANGANESE_ID = 29;
export const APHANOMYCES_ID = 33;
export const CERCOSPORA_BLIGHT_SOYBEAN_ID = 35;
export const CERCOSPORA_LEAF_SPOT_SUGAR_BEET_ID = 36;
export const VERTICILLIUM_WILT_ID = 43;
export const NITROGEN_ID = 45;
export const PHOSPHORUS_ID = 46;
export const POTASSIUM_ID = 47;
export const AMF_ID = 30;
export const FROGEYE_LEAF_SPOT_ID = 37;
export const GRAY_LEAF_SPOT_ID = 39;
export const COPPER_ID = 48;
export const ZINC_ID = 49;
export const SULFUR_ID = 50;
export const PATHOGENIC_ASPERGILLUS_ID = 53;
export const GOSS_WILT_ID = 56;
export const TARGET_SPOT_OF_COTTON_ID = 57;
export const NORTHERN_CORN_LEAF_BLIGHT_ID = 58;
export const SUGAR_BEET_CYST_NEMATODE_ID = 60;
export const CHARCOAL_ROT_ID = 62;
export const TAR_SPOT_ID = 66;
export const TEXAS_ROOT_ROT_OF_COTTON_ID = 67;
export const DIPLODIA_EAR_ROT_ID = 71;
export const BACTERIAL_BLIGHT_OF_COTTON_ID = 74;
export const BACTERIAL_LEAF_STREAK_CORN_ID = 75;
export const RHIZOCTONIA_SUGAR_BEET_ID = 76;
export const RED_CROWN_ROT_ID = 86;
export const SEPTORIA_BROWN_SPOT_ID = 95;
export const DISSIMILATORY_NITRATE_REDUCTION_TO_AMMONIA = 97;
export const ASSIMILATORY_NITRATE_REDUCTION_TO_AMMONIA = 98;
export const NITRIFICATION = 99;
export const RHIZOCTONIA_COTTON_BALL_ID = 103;
export const FUSARIUM_COTTON_BALL_ROT_ID = 104;
export const CHARCOAL_ROT_COTTON_ID = 106;
export const BPH_SIKORA_ID = 1006;
export const P_BRAY_ID = 1007;
export const K_AMMONIUM_ACETATE_ID = 1008;
export const CA_AMMONIUM_ACETATE_ID = 1009;
export const MG_AMMONIUM_ACETATE_ID = 1010;
export const BASE_SATURATION = 1011;
export const CA_SATURATION = 1012;
export const MG_SATURATION = 1013;
export const K_SATURATION = 1014;
export const CA_MG_RATIO = 1015;
export const CA_K_RATIO = 1016;
export const MG_K_RATIO = 1017;
export const SSURGO_OM_ID = 1018;
export const P_OLSEN_ID = 1019;
export const B_DTPA_ID = 1020;
export const FE_DTPA_ID = 1021;
export const MN_DTPA_ID = 1022;
export const CU_DTPA_ID = 1023;
export const ZN_DTPA_ID = 1024;
export const ALUMINUM_ID = 1025;
export const AL_SATURATION = 1026;

export const SOYBEAN_CYST_NEMATODE_RKN = 1027;

export const OM_MEASURED_ID = 2026;

export const phosphorusIds = [PHOSPHORUS_ID, P_BRAY_ID, P_OLSEN_ID];
export const potassiumIds = [POTASSIUM_ID, K_AMMONIUM_ACETATE_ID];
export const calciumIds = [CALCIUM_ID, CA_AMMONIUM_ACETATE_ID];
export const magnesiumIds = [MAGNESIUM_ID, MG_AMMONIUM_ACETATE_ID];
export const boronIds = [BORON_ID, B_DTPA_ID];
export const ironIds = [IRON_ID, FE_DTPA_ID];
export const manganeseIds = [MANGANESE_ID, MN_DTPA_ID];
export const copperIds = [COPPER_ID, CU_DTPA_ID];
export const zincIds = [ZINC_ID, ZN_DTPA_ID];
export const altNutrientIds = [
  P_BRAY_ID,
  P_OLSEN_ID,
  K_AMMONIUM_ACETATE_ID,
  CA_AMMONIUM_ACETATE_ID,
  MG_AMMONIUM_ACETATE_ID,
  B_DTPA_ID,
  FE_DTPA_ID,
  MN_DTPA_ID,
  CU_DTPA_ID,
  ZN_DTPA_ID,
];
export const macronutrientIds = [...calciumIds, ...potassiumIds, ...magnesiumIds];
export const micronutrientIds = [
  ...boronIds,
  ...ironIds,
  ...manganeseIds,
  ...copperIds,
  ...zincIds,
  ALUMINUM_ID,
];

export const analyticToMethodMap = {
  // P
  [PHOSPHORUS_ID]: M3,
  [P_BRAY_ID]: BRAY,
  [P_OLSEN_ID]: OLSEN,
  // K
  [POTASSIUM_ID]: M3,
  [K_AMMONIUM_ACETATE_ID]: AMMONIUM_ACETATE,
  // Ca
  [CALCIUM_ID]: M3,
  [CA_AMMONIUM_ACETATE_ID]: AMMONIUM_ACETATE,
  // Mg
  [MAGNESIUM_ID]: M3,
  [MG_AMMONIUM_ACETATE_ID]: AMMONIUM_ACETATE,
  // B
  [BORON_ID]: M3,
  [B_DTPA_ID]: DTPA,
  // Fe
  [IRON_ID]: M3,
  [FE_DTPA_ID]: DTPA,
  // Mn
  [MANGANESE_ID]: M3,
  [MN_DTPA_ID]: DTPA,
  // Cu
  [COPPER_ID]: M3,
  [CU_DTPA_ID]: DTPA,
  // Zn
  [ZINC_ID]: M3,
  [ZN_DTPA_ID]: DTPA,
};

export const analyticSubcategories = {
  [PATHOGENS as string]: {
    [CORN_PATHOGENS as string]: [
      WESTERN_CORN_ROOTWORM_ID,
      NORTHERN_CORN_ROOTWORM_ID,
      ROOTWORM_ID,
      ANTHRACNOSE_CORN_ID,
      GIBBERELLA_STALK_ROOT_ID,
    ],
    [CORN_SOYBEAN_PATHOGENS as string]: [
      FUSARIUM_ID,
      PYTHIUM_ID,
      RHIZOCTONIA_ID,
      SOYBEAN_CYST_NEMATODE_RKN,
      CHARCOAL_ROT_COTTON_ID,
      PATHOGENIC_ASPERGILLUS_ID,
      VERTICILLIUM_WILT_ID,
    ],
    [SOYBEAN_PATHOGENS as string]: [
      SCN_ID,
      SUDDEN_DEATH_SYNDROME_SHOTGUN_ID,
      SUDDEN_DEATH_SYNDROME_QPCR_ID,
      SOYBEAN_STEM_CANKER_ID,
      SOYBEAN_BROWN_STEM_ROT_ID,
      WHITE_MOLD_ID,
      PHYTOPHTHORA_ID,
      CHARCOAL_ROT_ID,
      RED_CROWN_ROT_ID,
    ],
    [COTTON_PATHOGENS]: [CHARCOAL_ROT_COTTON_ID, PATHOGENIC_ASPERGILLUS_ID, VERTICILLIUM_WILT_ID],
  },
  [FOLIAR as string]: {
    [CORN_FOLIAR as string]: [
      TAR_SPOT_ID,
      NORTHERN_CORN_LEAF_BLIGHT_ID,
      GRAY_LEAF_SPOT_ID,
      GOSS_WILT_ID,
      BACTERIAL_LEAF_STREAK_CORN_ID,
      DIPLODIA_EAR_ROT_ID,
    ],
    [SOYBEAN_FOLIAR as string]: [
      CERCOSPORA_BLIGHT_SOYBEAN_ID,
      FROGEYE_LEAF_SPOT_ID,
      ANTHRACNOSE_SOYBEAN_ID,
      SEPTORIA_BROWN_SPOT_ID,
    ],
    [COTTON_FOLIAR]: [
      BACTERIAL_BLIGHT_OF_COTTON_ID,
      FUSARIUM_COTTON_BALL_ROT_ID,
      TARGET_SPOT_OF_COTTON_ID,
    ],
  },
  [SUGAR_BEETS as string]: {
    [SUGAR_BEET_PESTS_DISEASE as string]: [
      CERCOSPORA_LEAF_SPOT_SUGAR_BEET_ID,
      RHIZOCTONIA_SUGAR_BEET_ID,
      APHANOMYCES_ID,
      SUGAR_BEET_CYST_NEMATODE_ID,
      SUGAR_BEET_FUSARIUM_ID,
    ],
  },
  [SOIL_ATTRIBUTES as string]: {
    [MACRONUTRIENTS as string]: [
      NITROGEN_ID,
      ...phosphorusIds,
      ...potassiumIds,
      ...calciumIds,
      ...magnesiumIds,
      PH_ID,
      BPH_SIKORA_ID,
      OM_ID,
      CEC_ID,
    ],
    [MICRONUTRIENTS as string]: [
      ...boronIds,
      ...copperIds,
      ...ironIds,
      ...manganeseIds,
      SULFUR_ID,
      ...zincIds,
      ALUMINUM_ID,
    ],
  },
  [BIOFERTILITY as string]: {
    [BENEFICIAL_BIOMARKERS as string]: [
      SOYBEAN_NFIXERS_ID,
      SOYBEAN_NFIXERS2_ID,
      PSOLUBILIZATION_POTENTIAL_ID,
      PLANT_GROWTH_PROMOTERS_ID,
      DIVERSITY_ID,
      AMF_ID,
    ],
    [HARMFUL_BIOMARKERS as string]: [DENITRIFICATION_POTENTIAL_ID, ANAEROBIC_POTENTIAL_ID],
  },
  [BCSR as string]: {
    [CATION_RATIOS as string]: [
      BASE_SATURATION,
      CA_SATURATION,
      MG_SATURATION,
      K_SATURATION,
      AL_SATURATION,
      CA_MG_RATIO,
      CA_K_RATIO,
      MG_K_RATIO,
    ],
  },
};

export const biofertilityList = [
  SOYBEAN_NFIXERS_ID,
  SOYBEAN_NFIXERS2_ID,
  NITROGEN_FIXATION_POTENTIAL_ID,
  DISSIMILATORY_NITRATE_REDUCTION_TO_AMMONIA,
  ASSIMILATORY_NITRATE_REDUCTION_TO_AMMONIA,
  NITRIFICATION,
  DENITRIFICATION_POTENTIAL_ID,
  PSOLUBILIZATION_POTENTIAL_ID,
  AMF_ID,
  PLANT_GROWTH_PROMOTERS_ID,
  DIVERSITY_ID,
  ANAEROBIC_POTENTIAL_ID,
];

export const bcsrList = [
  BASE_SATURATION,
  CA_SATURATION,
  MG_SATURATION,
  K_SATURATION,
  AL_SATURATION,
  CA_MG_RATIO,
  CA_K_RATIO,
  MG_K_RATIO,
];

export const nutrientList = [
  NITROGEN_ID,
  ...phosphorusIds,
  ...potassiumIds,
  ...calciumIds,
  ...magnesiumIds,
  PH_ID,
  BPH_SIKORA_ID,
  OM_ID,
  CEC_ID,
  ...boronIds,
  ...copperIds,
  ...ironIds,
  ...manganeseIds,
  SULFUR_ID,
  ...zincIds,
  ALUMINUM_ID,
];

export const RX_ANALYTIC_IDS = [
  NITROGEN_ID,
  ...phosphorusIds,
  ...potassiumIds,
  PH_ID,
  BPH_SIKORA_ID,
  ...calciumIds,
  ...magnesiumIds,
];

export const pathogensWithNameDiff = [FOLIAR, SUGAR_BEETS];

export const analyticsOrder = [
  WESTERN_CORN_ROOTWORM_ID,
  NORTHERN_CORN_ROOTWORM_ID,
  ROOTWORM_ID,
  ANTHRACNOSE_CORN_ID,
  GIBBERELLA_STALK_ROOT_ID,
  SCN_ID,
  SUDDEN_DEATH_SYNDROME_SHOTGUN_ID,
  SUDDEN_DEATH_SYNDROME_QPCR_ID,
  FUSARIUM_ID,
  SOYBEAN_STEM_CANKER_ID,
  SOYBEAN_BROWN_STEM_ROT_ID,
  WHITE_MOLD_ID,
  PHYTOPHTHORA_ID,
  FUSARIUM_ID,
  PYTHIUM_ID,
  RHIZOCTONIA_ID,
  NITROGEN_ID,
  ...phosphorusIds,
  ...potassiumIds,
  ...calciumIds,
  ...magnesiumIds,
  PH_ID,
  BPH_SIKORA_ID,
  OM_ID,
  CEC_ID,
  ...boronIds,
  ...ironIds,
  ...manganeseIds,
  ...copperIds,
  ...zincIds,
  ALUMINUM_ID,
  SULFUR_ID,
  SOYBEAN_NFIXERS_ID,
  SOYBEAN_NFIXERS2_ID,
  DENITRIFICATION_POTENTIAL_ID,
  PSOLUBILIZATION_POTENTIAL_ID,
  PLANT_GROWTH_PROMOTERS_ID,
  ANAEROBIC_POTENTIAL_ID,
  DIVERSITY_ID,
  BASE_SATURATION,
  CA_SATURATION,
  MG_SATURATION,
  K_SATURATION,
  AL_SATURATION,
  CA_MG_RATIO,
  CA_K_RATIO,
  MG_K_RATIO,
];

export const cropAnalyticCategoriesDict = {
  [CORN as string]: [CROP_PROTECTION, FOLIAR, BIOFERTILITY, NUTRIENTS, BCSR],
  [SOYBEANS as string]: [CROP_PROTECTION, FOLIAR, BIOFERTILITY, NUTRIENTS, BCSR],
  [COTTON as string]: [CROP_PROTECTION, FOLIAR, BIOFERTILITY, NUTRIENTS, BCSR],
  [SugarBeetsCrop as string]: [SUGAR_BEETS, BIOFERTILITY, NUTRIENTS, BCSR],
};

export const cornSubcategories = [
  CORN_PATHOGENS,
  CORN_SOYBEAN_PATHOGENS,
  CORN_FOLIAR,
  MACRONUTRIENTS,
  MICRONUTRIENTS,
  BENEFICIAL_BIOMARKERS,
  HARMFUL_BIOMARKERS,
  CATION_RATIOS,
];

export const soyBeanSubcategories = [
  CORN_SOYBEAN_PATHOGENS,
  SOYBEAN_PATHOGENS,
  SOYBEAN_FOLIAR,
  MACRONUTRIENTS,
  MICRONUTRIENTS,
  BENEFICIAL_BIOMARKERS,
  HARMFUL_BIOMARKERS,
  CATION_RATIOS,
];

export const cottonSubcategories = [
  COTTON_PATHOGENS,
  COTTON_FOLIAR,
  MACRONUTRIENTS,
  MICRONUTRIENTS,
  BENEFICIAL_BIOMARKERS,
  HARMFUL_BIOMARKERS,
  CATION_RATIOS,
];

export const sugarBeetSubcategories = [
  CORN_PATHOGENS,
  CORN_SOYBEAN_PATHOGENS,
  SOYBEAN_PATHOGENS,
  CORN_FOLIAR,
  SOYBEAN_FOLIAR,
  SUGAR_BEET_PESTS_DISEASE,
  MACRONUTRIENTS,
  MICRONUTRIENTS,
  BENEFICIAL_BIOMARKERS,
  HARMFUL_BIOMARKERS,
  CATION_RATIOS,
];

export const cropAnalyticSubCategoriesDict = {
  [CORN as string]: cornSubcategories,
  [SOYBEANS as string]: soyBeanSubcategories,
  [COTTON as string]: cottonSubcategories,
  [SugarBeetsCrop as string]: sugarBeetSubcategories,
};

export const alternateCategoryNameDict = {
  [CROP_PROTECTION]: [PATHOGENS],
  [NUTRIENTS]: [SOIL_ATTRIBUTES],
};

export const PLANNING_TABS = [CROP_PROTECTION, NUTRIENTS, BIOFERTILITY];

export const PATTERN_RECOMMENDATION_SET = 1;

export const IS_OVERVIEW_ND = 'is_overview_nd';
export const IS_FIELD_ND = 'is_field_nd';
export const IS_SAMPLE_ND = 'is_sample_nd';
export const IS_FIELD_RESULT_BINARY = 'is_field_result_binary';

export const CONTINOUOS = 'continuous';
export const BINARY = 'binary';

export const DISPLAY_ANALYTICS_TOGGLES = [IS_OVERVIEW_ND, IS_FIELD_ND, IS_SAMPLE_ND];

export const PRO_ANALYTIC_IDS = [PH_ID, CEC_ID].concat(phosphorusIds).concat(potassiumIds);
