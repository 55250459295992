import React from 'react';
import { AlertType, SamplePlanTrackingType } from 'store/samplePlans/types';
import { getString } from 'strings/translation';
import styles from './TrackingMap.module.css';
import { formatDateMonthDayYear } from 'util/date';
import { PARTIAL_STATUSES } from 'constants/samplePlanning';

interface TrackingMapPopupProps {
  plan: SamplePlanTrackingType;
  openAssignSampler: (plan: SamplePlanTrackingType) => void;
  language: string;
}

const createTrackingMapPopup = ({ plan, openAssignSampler, language }: TrackingMapPopupProps) => {
  return (
    <div>
      <div>
        <b>{getString('account', language)}: </b>
        {plan.operation_name}
      </div>
      <div>
        <b>{getString('field', language)}: </b>
        {plan.field_name}
      </div>
      <div>
        <b>{getString('acres', language)}: </b>
        {plan.calculated_area_acres}
      </div>
      <div>
        <b>{getString('samples', language)}: </b>
        {plan.sample_total_active_count}
      </div>
      <div>
        <b>{getString('status', language)}: </b>
        {plan.status}
      </div>
      <div>
        <b>{getString('updated', language)}: </b>
        {plan.updated_at ? formatDateMonthDayYear(plan.updated_at, language) : ''}
      </div>
      <div>
        <b>{getString('alerts', language)}: </b>
        {(plan.alerts || []).map((alert: AlertType) => getString(alert.name, language)).join('; ')}
      </div>
      {PARTIAL_STATUSES.includes(plan.status) && (
        <div className={styles.HoverOver} onClick={() => openAssignSampler(plan)}>
          <u>
            <b>
              {getString('assignSampler', language)} {'>'}
            </b>
          </u>
        </div>
      )}
      <div
        className={styles.HoverOver}
        onClick={() => window.open(`/orders/${plan.operation_id}`, '_blank')}
      >
        <u>
          <b>
            {getString('goToPlan', language)} {'>'}
          </b>
        </u>
      </div>
    </div>
  );
};

export default createTrackingMapPopup;
