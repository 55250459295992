import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { getLocalStorage, removeLocalStorageItem, setLocalStorage } from 'util/localStorage';
import { SEARCHED_USER_OR_AGENCY } from 'constants/auth';
import {
  Header,
  Spinner,
  AgencyAdminAccess,
  Pagination,
  AdminAgentAccess,
  UserAgencySearch,
} from 'common';
import setToast from 'actions/toastActions';
import { RootState } from 'store';
import { SegmentedControl, Button } from '@mantine/core';
import { getOperations, getOperationsTotalStats, setActiveOperation } from 'store/operation/thunks';
import { userIsAdmin, userIsAgencyAdmin, userIsSuperAdmin } from 'store/user/selectors';
import { DEMO_OPERATION_ID } from 'constants/variables';
import { getString } from 'strings/translation';
import useBrowserLanguage from 'util/hooks/useLanguage';
import { UserAgencySelectorType } from 'store/user/types';
import { getAcreageUnitFromLang } from 'util/units';
import { getAccountSelectionOptions } from 'util/results';
import NoOperationsBlurb from './NoOperationsBlurb';
import OperationsTable from './OperationsTable';

import styles from './Container.module.css';
import { CROP_PROTECTION } from 'constants/results';
import { OperationType } from 'store/operation/types';
import DeliveryCompleteModal from 'apps/Orders/FieldList/FullProductReport/DeliveryCompleteModal';

const MultipleOperationsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSearching, toggleIsSearching] = useState(false);
  const [userOrAgencySelected, setUserOrAgencySelected] = useState<UserAgencySelectorType | null>(
    null,
  );
  const [hasSetSearch, toggleHasSetSearch] = useState(false);
  const [page, setPage] = useState(1);
  const [sortAttribute, setSortAttribute] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const language = useBrowserLanguage();
  const accountSelection = getAccountSelectionOptions(language);
  const [showDeliveryModal, toggleDeliveryModal] = useState<OperationType | null>(null);

  const {
    activeOperationId,
    hasFetched,
    isFetching,
    operations,
    totalOperationPages,
    operationTotals,
    isAdmin,
    isAgencyAdmin,
  } = useSelector((state: RootState) => ({
    activeOperationId: state.operations.activeOperationId,
    hasFetched: state.operations.hasFetched,
    isFetching: state.operations.isFetching,
    operations: state.operations.summary.items,
    totalOperationPages: state.operations.summary.total,
    operationTotals: state.operations.summary.totalStats,
    isAdmin: userIsAdmin(state) || userIsSuperAdmin(state),
    isAgencyAdmin: userIsAgencyAdmin(state),
  }));

  const showAllOperations = useMemo(() => {
    return location.pathname.includes('/all') && (isAdmin || isAgencyAdmin);
  }, [location, isAdmin, isAgencyAdmin]);

  const changeOperation: any = useCallback(
    (id: number) => dispatch(setActiveOperation(id)),
    [dispatch],
  );

  useEffect(() => {
    if (!hasSetSearch) {
      const savedSearch: UserAgencySelectorType | null = JSON.parse(
        getLocalStorage(SEARCHED_USER_OR_AGENCY) || '{}',
      );
      if (savedSearch?.user_id || savedSearch?.agency_id) {
        setUserOrAgencySelected(savedSearch);
      }
      toggleHasSetSearch(true);
    }
  }, [hasSetSearch]);

  useEffect(() => {
    if (hasSetSearch) {
      const search_users = userOrAgencySelected?.user_id ? [userOrAgencySelected?.user_id] : [];
      const search_agency =
        userOrAgencySelected?.agency_id && userOrAgencySelected?.agency_primary_id
          ? {
              agency_id: userOrAgencySelected.agency_id,
              agency_primary_id: userOrAgencySelected.agency_primary_id,
            }
          : null;
      dispatch(
        getOperations(
          getAcreageUnitFromLang(language),
          showAllOperations,
          page,
          '',
          search_users,
          search_agency,
          sortAttribute,
          sortOrder === 'desc',
        ),
      );
      dispatch(
        getOperationsTotalStats(
          getAcreageUnitFromLang(language),
          showAllOperations,
          '',
          search_users,
          search_agency,
        ),
      );
    }
  }, [page, showAllOperations, userOrAgencySelected, sortAttribute, sortOrder, hasSetSearch]);

  useEffect(() => {
    if (!activeOperationId && operations.length) {
      changeOperation(operations[0].id);
    }
  }, [activeOperationId, changeOperation, hasFetched, operations, dispatch]);

  const manageOrders = (id: number) => {
    changeOperation(id).then(() => navigate(`/orders/${id}`));
  };

  const viewResults = (id: number) => {
    changeOperation(id).then(() => navigate(`/results/operation/${id}/${CROP_PROTECTION}`));
  };

  const editOperation = (id: number) => {
    changeOperation(id).then(() => navigate(`/operations/${id}/edit`));
  };

  const goToCreateAccount = () => {
    navigate(`/operations/register`);
  };

  const handleUserSelection = (selcted: UserAgencySelectorType | null) => {
    if (!selcted) {
      removeFilter();
    } else {
      const { id, displayName, user_id, agency_id, agency_primary_id } = selcted;
      setPage(1);
      setUserOrAgencySelected({
        id,
        user_id,
        displayName,
        agency_id,
        agency_primary_id,
      });
      setLocalStorage(SEARCHED_USER_OR_AGENCY, JSON.stringify(selcted));
    }
  };

  const removeFilter = () => {
    setUserOrAgencySelected(null);
    removeLocalStorageItem(SEARCHED_USER_OR_AGENCY);
  };

  const handleMarkAsDelivered = () => {
    toggleHasSetSearch(false);
    toggleDeliveryModal(null);
    dispatch(setToast(getString('planMarkedDeliveredSuccess', language)));
  };

  return (
    <div data-test-id="operations-table" className={styles.Wrapper}>
      <Header
        title={
          isAdmin || isAgencyAdmin ? (
            <SegmentedControl
              color="blue"
              value={accountSelection.find((url) => url.value === location.pathname)?.value}
              onChange={(value) => navigate(value)}
              data={accountSelection}
            />
          ) : (
            getString('myAccounts', language)
          )
        }
      >
        <AgencyAdminAccess>
          {(isFetching || isSearching) && <Spinner className={styles.MiniSpinner} />}
          <UserAgencySearch
            placeholder={getString('searchByUserAgency', language)}
            onSelect={handleUserSelection}
            onDeselect={removeFilter}
            className={styles.SearchBar}
            toggleIsSearching={toggleIsSearching}
            selected={userOrAgencySelected}
          />
        </AgencyAdminAccess>
        <Button variant="outline" onClick={() => navigate('/risk-estimate')}>
          {getString('createEstimate', language)}
        </Button>
        <AdminAgentAccess>
          <Button variant="outline" onClick={goToCreateAccount}>
            {getString('createAccount', language)}
          </Button>
          <Button variant="outline" onClick={() => manageOrders(DEMO_OPERATION_ID)}>
            {getString('demoAccount', language)}
          </Button>
        </AdminAgentAccess>
      </Header>
      {showDeliveryModal && (
        <AdminAgentAccess>
          <DeliveryCompleteModal
            operation={showDeliveryModal}
            hideModal={() => toggleDeliveryModal(null)}
            onSuccess={() => handleMarkAsDelivered()}
            open={!!showDeliveryModal}
          />
        </AdminAgentAccess>
      )}
      <OperationsTable
        manageOrders={manageOrders}
        viewResults={viewResults}
        editOperation={editOperation}
        operations={operations}
        operationTotals={operationTotals}
        sortAttribute={sortAttribute}
        sortOrder={sortOrder}
        setSortAttribute={setSortAttribute}
        setSortOrder={setSortOrder}
        toggleDeliveryModal={toggleDeliveryModal}
      />
      {!operations.length ? <NoOperationsBlurb /> : null}
      <div className={styles.Pagination}>
        <Pagination page={page} setPage={setPage} totalPages={totalOperationPages} />
      </div>
    </div>
  );
};

export default MultipleOperationsContainer;
