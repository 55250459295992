import { ResultPreferencesType } from 'store/operation/types';
import { User } from 'store/user/types';
import {
  AGENCIES_REQUEST_ERROR,
  Agency,
  AgencyActionType,
  RECEIVE_AGENCY_ASSOCIATED_USERS,
  RECEIVE_ALL_AGENCIES,
  RECEIVE_MY_AGENCIES,
  RECEIVE_SINGLE_AGENCY,
  REQUEST_AGENCIES,
  REQUEST_AGENCY_USERS,
  SET_ACTIVE_AGENCY,
  RECEIVE_AGENCY_CONTRACTS,
  REQUEST_AGENCY_CONTRACTS,
  RECEIVE_AGENCY_RESULT_PREFERENCES,
  RECEIVE_DEFAULT_PRICES,
  DefaultContractPriceType,
  ContractMinMaxType,
  RECEIVE_MIN_MAX_PRICES,
  REQUEST_MY_AGENCIES,
  ContractSamplingMetricsType,
  RECEIVE_AGENCY_CONTRACT_METRICS,
  ContractResponseType,
  RECEIVE_AGENCY_CONTRACT_TIERS,
} from './types';

export const agencyRequestError = (id?: number): AgencyActionType => {
  return {
    type: AGENCIES_REQUEST_ERROR,
    payload: {
      id,
    },
  };
};

export const requestAgencies = (id?: number): AgencyActionType => {
  return {
    type: REQUEST_AGENCIES,
    payload: {
      id,
    },
  };
};

export const requestMyAgencies = (id?: number): AgencyActionType => {
  return {
    type: REQUEST_MY_AGENCIES,
    payload: {
      id,
    },
  };
};

export const requestAgencyAssociatedUsers = (): AgencyActionType => {
  return {
    type: REQUEST_AGENCY_USERS,
  };
};

export const receiveContractPriceDefaults = (
  defaultPrices: DefaultContractPriceType,
): AgencyActionType => {
  return {
    type: RECEIVE_DEFAULT_PRICES,
    payload: defaultPrices,
  };
};

export const receivePriceMinMax = (minMaxes: ContractMinMaxType): AgencyActionType => {
  return {
    type: RECEIVE_MIN_MAX_PRICES,
    payload: minMaxes,
  };
};

export const receiveAllAgencies = (agencies: Agency[]): AgencyActionType => ({
  type: RECEIVE_ALL_AGENCIES,
  payload: {
    agencies,
  },
});

export const receiveMyAgencies = (agencies: Agency[]): AgencyActionType => ({
  type: RECEIVE_MY_AGENCIES,
  payload: {
    agencies,
  },
});

export const receiveSingleAgency = (agency: Agency): AgencyActionType => ({
  type: RECEIVE_SINGLE_AGENCY,
  payload: agency,
});

export const receiveAgencyUsers = (id: number, users: User[]): AgencyActionType => {
  return {
    type: RECEIVE_AGENCY_ASSOCIATED_USERS,
    payload: {
      id,
      users,
    },
  };
};

export const setActiveAgency = (id: number): AgencyActionType => {
  return {
    type: SET_ACTIVE_AGENCY,
    payload: id,
  };
};

export const requestAgencyContracts = (): AgencyActionType => {
  return {
    type: REQUEST_AGENCY_CONTRACTS,
  };
};

export const receiveAgencyContracts = (
  agencyId: number,
  contracts: { agency: ContractResponseType[]; parent_agency: ContractResponseType[] },
): AgencyActionType => ({
  type: RECEIVE_AGENCY_CONTRACTS,
  payload: {
    agencyId,
    contracts,
  },
});

export const receiveAgencyResultPreferences = (
  preferences: ResultPreferencesType,
  id: number,
): AgencyActionType => {
  return {
    type: RECEIVE_AGENCY_RESULT_PREFERENCES,
    payload: { id, preferences },
  };
};

export const receiveAgencyContractMetrics = (
  agencyId: number,
  contractId: number,
  metrics: ContractSamplingMetricsType,
): AgencyActionType => {
  return {
    type: RECEIVE_AGENCY_CONTRACT_METRICS,
    payload: {
      agencyId,
      contractId,
      metrics,
    },
  };
};

export const receiveContractTiers = (
  agencyId: number,
  contractTiers: DefaultContractPriceType,
): AgencyActionType => ({
  type: RECEIVE_AGENCY_CONTRACT_TIERS,
  payload: {
    agencyId,
    contractTiers,
  },
});
