import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Spinner, Header } from 'common';
import { getLocalStorage } from 'util/localStorage';
import { USER_TRANSACTION_ID } from 'constants/auth';
import { postQuickbooksToken } from 'store/payments/requests';
import setToast from 'actions/toastActions';
import { useDispatch } from 'react-redux';

const QuickbooksCallbackContainer = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const saveToken = async () => {
      setIsLoading(true);
      const params = new URLSearchParams(window.location.search);
      const code = params.get('code');
      const realm_id = params.get('realmId');
      if (code && realm_id) {
        try {
          await postQuickbooksToken(realm_id, code);
        } catch (err) {
          dispatch(setToast(`Error: ${err.message}`, 'error', 10000));
        }
        navigate(`/payments/open/details/${getLocalStorage(USER_TRANSACTION_ID)}`);
      } else {
        dispatch(setToast('Error: No authorization code found in headers.', 'error', 10000));
        navigate('/payments/open');
      }
    };
    saveToken();
  }, [navigate, dispatch]);

  return (
    <div>
      <Header title="Quickbooks Authorization" />
      {isLoading && <Spinner />}
    </div>
  );
};

export default QuickbooksCallbackContainer;
