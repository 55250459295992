import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';

import { ToastCorner } from 'common';
import { TOAST_LOCATION } from 'constants/auth';

interface ToastContainerProps {
  toastMessage?: string;
  toastType?: string;
  placement?:
    | TOAST_LOCATION.TOP_LEFT
    | TOAST_LOCATION.TOP_RIGHT
    | TOAST_LOCATION.BOTTOM_LEFT
    | TOAST_LOCATION.BOTTOM_RIGHT;
}

const ToastContainer: FunctionComponent<ToastContainerProps> = ({
  toastMessage,
  toastType,
  placement,
}) => {
  return (
    <ToastCorner
      message={toastMessage?.toString()}
      type={toastType}
      placement={placement || TOAST_LOCATION.TOP_RIGHT}
    />
  );
};

// todo: toast store typing
const mapStateToProps = (state: any) => ({
  toastMessage: state.toast.message,
  toastType: state.toast.type,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ToastContainer);
