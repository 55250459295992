import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { AnalyticType } from 'store/analytics/types';
import { Group, Button, Stack, Loader, Space, Text, Select } from '@mantine/core';
import { FieldType } from 'store/fields/types';
import { getLastSamplingPlan } from 'util/samplePlan';
import { InputType } from 'store/inputs/types';
import { OperationType } from 'store/operation/types';
import { PrescriptionZoneType } from 'store/prescriptions/types';
import { SampleFeatureType } from 'store/samples/types';
import { getExportedPrescriptions } from 'store/prescriptions/thunks';
import { useDispatch } from 'react-redux';
import PrescriptionList from './PrescriptionList/Container';
import SinglePrescription from './SinglePrescription/Container';
import { getLatestCropPlan } from 'util/cropPlans';
import { CORN } from 'constants/variables';
import styles from './Container.module.css';

type PanelPropsType = {
  analytics: AnalyticType[];
  field: FieldType;
  inputs: InputType[];
  isOverview: boolean;
  operation: OperationType;
  samples: SampleFeatureType[];
  selectedField: number | undefined;
  setNewField: (value: any) => void;
  zones: PrescriptionZoneType[] | null;
  updatePrescriptionZones: (zoneId: number, mapValue: string) => void;
  setSelectedZone: (zoneId: number | null) => void;
  saveZoneAmount: (zoneId: number, amount: number) => void;
};

const PanelContainer = ({
  analytics,
  field,
  inputs,
  isOverview,
  operation,
  samples,
  selectedField,
  setNewField,
  zones,
  updatePrescriptionZones,
  setSelectedZone,
  saveZoneAmount,
}: PanelPropsType) => {
  const { fieldId, operationId } = useParams<{
    operationId: string;
    fieldId: string;
  }>();
  const location = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const language = useBroswerLanguage();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getExportedPrescriptions(Number(fieldId)));
  }, []);

  const fieldOptions = useMemo(
    () =>
      operation.fields
        .filter((fiel) => fiel.nutrient_results_available)
        .map((fiel) => ({
          id: fiel.id,
          label: fiel.name,
          value: String(fiel.id),
        })),
    [operation],
  );

  const lastPlan = getLastSamplingPlan(field.features[0].properties);
  const cropPlan = getLatestCropPlan(field);

  const navigateToFieldSummary = () =>
    navigate(
      `/results/field/${operation.id}/${field.features[0].properties.id}/${lastPlan?.id}/rx?crop=${cropPlan.crop || CORN}`,
    );

  const navigateToPrescriptionList = () => navigate(`/results/rx/${operationId}/${fieldId}/all`);

  const isList = location.pathname.includes('all');

  return (
    <Stack className={styles.PanelWrapper}>
      <Stack className={styles.StickyHeader}>
        <Space h="xs" />
        <Group justify="flex-start">
          <Button
            data-test-id="back-to-field-summary"
            className={styles.GoBack}
            onClick={navigateToFieldSummary}
            variant="outline"
          >
            {getString('backToFieldSummary', language)}
          </Button>
          {isSubmitting && <Loader size="sm" className={styles.SmallSpinner} />}
        </Group>
        <Group justify="space-between">
          <Text
            size="lg"
            data-test-id="back-to-all-rx"
            className={styles.BackButton}
            onClick={isList ? navigateToFieldSummary : navigateToPrescriptionList}
          >
            &lt; {getString('backToAllRx', language)}
          </Text>
          <Select
            data-test-id="field-selector"
            value={String(selectedField)}
            onChange={(val) => setNewField(val)}
            data={fieldOptions}
          />
        </Group>
        <Space h="xs" />
      </Stack>
      {isOverview ? (
        <PrescriptionList analytics={analytics} field={field} inputs={inputs} samples={samples} />
      ) : (
        <SinglePrescription
          field={field}
          inputs={inputs}
          samples={samples}
          zones={zones}
          updatePrescriptionZones={updatePrescriptionZones}
          setSelectedZone={setSelectedZone}
          saveZoneAmount={saveZoneAmount}
          setIsSubmitting={setIsSubmitting}
        />
      )}
    </Stack>
  );
};

export default PanelContainer;
