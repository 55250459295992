import React from 'react';
import { useDispatch } from 'react-redux';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { FileUploadButton } from 'common';
import { getAllNaptControls } from 'store/naptControls/thunks';
import { requestPostNaptControl } from 'store/naptControls/requests';
import { NaptFileColumns } from 'constants/fileUpload';

export const CreateNaptControlButton = () => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const handleUpload = async (file: File) => {
    try {
      await requestPostNaptControl(file);
      await dispatch(getAllNaptControls());
    } catch (e) {
      dispatch(showToast(e.message, 'error'));
    }
  };

  return (
    <FileUploadButton
      buttonLabel={getString('createNaptControl', language)}
      onUpload={handleUpload}
      accept=".csv,.xls"
      primary
    >
      <h3>{`${getString('csvColumnsRequiredMsg', language)}:`}</h3>
      <b>{NaptFileColumns.join(', ')}</b>
    </FileUploadButton>
  );
};
