import acToHectares, { lbsAcToKgHa } from 'util/units';
import { roundTwoOrZero } from 'util/numUtils';
import {
  PRESCRIPTION_ZONE_REQUEST_ERROR,
  PrescriptionExportJobType,
  PrescriptionZoneActionType,
  PrescriptionZoneState,
  PrescriptionZoneType,
  RECEIVE_EXPORTED_PRESCRIPTIONS,
  RECEIVE_PRESCRIPTION_ZONES,
  REQUEST_EXPORTED_PRESCRIPTIONS,
  REQUEST_PRESCRIPTION_ZONES,
} from './types';
import { sortByCreatedAt } from 'util/date';

export const initialState: PrescriptionZoneState = {
  hasFailed: false,
  hasFetched: false,
  isFetching: false,
  isFetchingExported: false,
  zones: {},
  scriptExports: {},
};

const convertBasedOnUnits = (prescriptions: PrescriptionZoneType[], acreageUnit: string) =>
  prescriptions.map((script) => ({
    ...script,
    properties: {
      ...script.properties,
      amount: roundTwoOrZero(script.properties.amount, lbsAcToKgHa, acreageUnit),
      calculated_area_acres: roundTwoOrZero(
        script.properties.calculated_area_acres,
        acToHectares,
        acreageUnit,
      ),
    },
  }));

export const getLatestExport = (jobs: PrescriptionExportJobType[]) =>
  sortByCreatedAt(jobs, true).reduce((latest, job) => {
    if (latest[job.prescription_id]?.[job.external_provider_name]) {
      return latest;
    }
    return {
      ...latest,
      [job.prescription_id]: {
        [job.external_provider_name]: job,
      },
    };
  }, {});

export const PrescriptionZoneReducer = (
  state = initialState,
  action: PrescriptionZoneActionType,
) => {
  switch (action.type) {
    case RECEIVE_PRESCRIPTION_ZONES:
      return {
        ...state,
        hasFetched: true,
        isFetching: false,
        zones: {
          ...state.zones,
          [action.payload.id]: convertBasedOnUnits(
            action.payload.zones,
            action.payload.acreageUnit,
          ),
        },
      };
    case REQUEST_PRESCRIPTION_ZONES:
      return {
        ...state,
        hasFetched: false,
        hasFailed: false,
        isFetching: true,
      };
    case PRESCRIPTION_ZONE_REQUEST_ERROR:
      return {
        ...state,
        hasFailed: true,
        isFetching: false,
      };
    case REQUEST_EXPORTED_PRESCRIPTIONS:
      return {
        ...state,
        isFetchingExported: true,
      };
    case RECEIVE_EXPORTED_PRESCRIPTIONS:
      return {
        ...state,
        isFetchingExported: false,
        scriptExports: {
          ...state.scriptExports,
          [action.payload.fieldId]: getLatestExport(action.payload.exportedPrescriptions),
        },
      };
    default:
      return state;
  }
};
