import { FieldType, SampleType, SamplingPlanType } from 'store/fields/types';
import { LabSampleType } from 'store/labSamples/types';
import { FIELD_DISPLAY_POINT_RADIUS } from 'constants/samplePlanning';
import { genomicPlateTypes, HOMOGENIZATION } from 'constants/plates';
import {
  ISOLATES,
  RESIDUE,
  RHIZOSPHERE,
  ROOT_BALL,
  ROOT_TISSUE,
  sampleTypeDisplayNames,
  SOIL,
  PROCESS,
  RERUN,
  TECHNICAL,
  replicaTypeDisplayNames,
} from 'constants/samples';
import { AnalyticType } from 'store/analytics/types';
import { getString } from 'strings/translation';
import circle from '@turf/circle';
import center from '@turf/center';
import { GeoJSON } from 'geojson';
import { SampleGeoJSON } from './generalTypes';
import { sortByCreatedAt } from './date';
import {
  checkEnlargePointZone,
  getAnalyticFillColor,
  getSampleAnalyticQuantity,
  getSampleAnalyticRiskLevel,
  sortByRiskColor,
} from './results';

export const parseSample = (sample: LabSampleType) => {
  return {
    ...sample,
    field_name: sample.field?.name,
    farm_name: sample.field?.farm_name,
    operation_id: sample.operation?.id,
    operation_name: sample.operation?.name,
  };
};

export const sampleIsPrimary = (sample: SampleType) => sample.primary;
export const sampleIsActive = (sample: SampleType) =>
  sample.status !== 'cancelled' && sample.status !== "won't sample";

export const findPrimarySample = (samples: LabSampleType[]) => {
  const primaryOnly = samples.filter(sampleIsPrimary);
  return primaryOnly?.[0];
};

export const sortPrimaryFirst = (samples: LabSampleType[]) =>
  sortByCreatedAt(samples).sort((a) => (sampleIsPrimary(a) ? -1 : 1));

export const filterSamplesWithPlan = (
  samples: SampleGeoJSON[],
  samplingPlan?: SamplingPlanType | null,
) => {
  if (!(samples && samplingPlan)) {
    return [];
  }
  const planSamples = samples.filter(
    (sample) => sample.properties.sampling_plan_id === samplingPlan.id,
  );
  return planSamples.map((sample) => ({
    ...sample,
    geometry: sample.geometry?.geometries?.find(
      (geom) => geom.type === 'Polygon' || geom.type === 'MultiPolygon' || null,
    ),
  }));
};

export const filterSamplesAndAddRisk = (
  samples: SampleGeoJSON[],
  samplingPlan: SamplingPlanType | undefined,
  analytic: AnalyticType,
  field: FieldType,
) =>
  analytic && samplingPlan && samples
    ? sortByRiskColor(
        filterSamplesWithPlan(samples, samplingPlan)
          .filter((sample) => sample.geometry !== null)
          .map((val) => ({
            ...val,
            geometry: checkEnlargePointZone(val as SampleGeoJSON, samplingPlan, 5, field)
              ? // @ts-ignore
                circle(center(val.geometry), FIELD_DISPLAY_POINT_RADIUS, {
                  units: 'meters',
                  steps: 100,
                }).geometry
              : val.geometry,
            properties: {
              ...val.properties,
              'fill-color': getAnalyticFillColor(val.properties, analytic, samplingPlan),
              quantity: getSampleAnalyticQuantity(val.properties, analytic),
              riskLevelForAnalytic: getSampleAnalyticRiskLevel(val.properties, analytic),
            },
          })) as GeoJSON.Feature<GeoJSON.Geometry, SampleType>[],
      )
    : [];

export const labSampleIsPlated = (sample: LabSampleType, plateType: string) => {
  const checkType = genomicPlateTypes.includes(plateType) ? HOMOGENIZATION : plateType;
  return Boolean(sample.plates_summary?.[checkType]?.length);
};

export const getSampleTypeOptions = (language: string) => [
  {
    id: 0,
    value: SOIL,
    displayName: getString(sampleTypeDisplayNames[SOIL], language),
  },
  {
    id: 1,
    value: RESIDUE,
    displayName: getString(sampleTypeDisplayNames[RESIDUE], language),
  },
  {
    id: 2,
    value: ROOT_BALL,
    displayName: getString(sampleTypeDisplayNames[ROOT_BALL], language),
  },
  {
    id: 3,
    value: RHIZOSPHERE,
    displayName: getString(sampleTypeDisplayNames[RHIZOSPHERE], language),
  },
  {
    id: 4,
    value: ROOT_TISSUE,
    displayName: getString(sampleTypeDisplayNames[ROOT_TISSUE], language),
  },
  {
    id: 5,
    value: ISOLATES,
    displayName: getString(sampleTypeDisplayNames[ISOLATES], language),
  },
];

export const getReplicaTypeOptions = (language: string) => [
  {
    id: 1,
    value: RERUN,
    displayName: getString(replicaTypeDisplayNames[RERUN], language),
  },
  {
    id: 2,
    value: TECHNICAL,
    displayName: getString(replicaTypeDisplayNames[TECHNICAL], language),
  },
  {
    id: 3,
    value: PROCESS,
    displayName: getString(replicaTypeDisplayNames[PROCESS], language),
  },
];
