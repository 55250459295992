import React, { MouseEvent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Select, Input, Group, Button, Space, Text } from '@mantine/core';
import { requestOperationDiscount } from 'store/operation/requests';
import showToast from 'actions/toastActions';
import { isNumber } from 'util/numUtils';
import { discountPrograms } from 'constants/propTypes/operation';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';

interface DeliveryCompleteModalProps {
  hideModal: (event: MouseEvent) => void;
  operation_id: number;
  fetchOperation: () => void;
  open: boolean;
}

const DiscountProgramModal = ({
  hideModal,
  operation_id,
  fetchOperation,
  open,
}: DeliveryCompleteModalProps) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const [isLoading, toggleLoading] = useState(false);
  const [maxAcres, setMaxAcres] = useState<string>('');
  const [selectedProgram, setSelectedProgram] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState('');
  const handleHide = useCallback(() => {
    if (!isLoading) {
      hideModal({} as MouseEvent);
    }
  }, [hideModal, isLoading]);

  const handleMark = async () => {
    try {
      toggleLoading(true);
      if (isNumber(Number(maxAcres)) && selectedProgram !== undefined) {
        await requestOperationDiscount(operation_id, Number(maxAcres), selectedProgram);
        hideModal({} as MouseEvent);
      } else {
        setErrorMessage('Max Acres is not a number.');
      }
    } catch (e) {
      dispatch(
        showToast('Error Marking Operation as part of discount program. Try again.', 'error'),
      );
      hideModal({} as MouseEvent);
    } finally {
      fetchOperation();
      toggleLoading(false);
    }
  };

  return (
    <Modal
      centered
      size="lg"
      opened={open}
      onClose={handleHide}
      title={getString('selectDiscountProgram', language)}
    >
      <Group>
        <Select
          label={getString('programToEnrollMsg', language)}
          onChange={(val) => val && setSelectedProgram(val)}
          value={selectedProgram}
          data={discountPrograms}
        />
        <Input.Wrapper label={getString('maxAcresForEnrollment', language)}>
          <Input onChange={(e) => setMaxAcres(e.currentTarget.value)} value={maxAcres} />
        </Input.Wrapper>
      </Group>
      <Space h="md" />
      <Group justify="flex-end">
        <Text color="darkRed">{errorMessage}</Text>
        <Button
          disabled={isLoading || !selectedProgram || !isNumber(Number(maxAcres))}
          onClick={handleMark}
        >
          {getString('submit', language)}
        </Button>
      </Group>
    </Modal>
  );
};

export default DiscountProgramModal;
