import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'store';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { Button, Header } from 'common';
import { LabShipmentFormType } from 'store/labShipments/types';
import { createLabShipment, getLabShipment, updateLabShipment } from 'store/labShipments/thunks';
import showToast from 'actions/toastActions';
import { labDisplayNames } from 'constants/shipping';

import styles from './Container.module.css';
import { RegulatedMaterial } from './Sections/RegulatedMaterial/RegulatedMaterial';
import { UPSLabels } from './Sections/UPSLabels/UPSLabels';
import { ShipmentSamples } from './Sections/Samples/Samples';
import { AddShippingPlate } from './Sections/ShippingPlates/AddPlate';

const LabShippingDetailsContainer = () => {
  const language = useBroswerLanguage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { groupId } = useParams<{ groupId: string }>();
  const [shipmentForm, setShipmentForm] = useState<LabShipmentFormType>({});

  const { shipment } = useSelector((state: RootState) => ({
    shipment: groupId ? state.labShipments.byId[groupId] : undefined,
  }));

  useEffect(() => {
    if (groupId && shipment === undefined) {
      dispatch(getLabShipment(groupId));
    } else if (shipment) {
      const plateBarcodes = shipment.plates.map((p) => p.barcode);
      setShipmentForm({
        ...shipment,
        plate_barcodes: plateBarcodes,
      });
    }
  }, [groupId, shipment]);

  const handleUpdateForm = (
    attributeName: string,
    newValue: string | string[] | number | boolean,
  ) => {
    setShipmentForm(
      (form) =>
        ({
          ...form,
          [attributeName]: newValue,
        }) as LabShipmentFormType,
    );
  };

  const handleSubmitForm = async () => {
    try {
      if (shipment) {
        await dispatch(updateLabShipment(shipment.group_id, shipmentForm));
        dispatch(showToast(getString('shipmentUpdatedMsg', language)));
      }
    } catch (e) {
      dispatch(showToast(e.message));
    }
  };

  const handleAddTrackingLabel = async (label: string) => {
    try {
      if (shipment?.group_id) {
        const submit = {
          ...shipmentForm,
          new_tracking_numbers: [label],
        };
        await dispatch(updateLabShipment(shipment.group_id, submit));
        dispatch(showToast(getString('shipmentUpdatedMsg', language)));
      } else {
        const submit = {
          ...shipmentForm,
          tracking_numbers: [label],
        };
        const response: any = await dispatch(createLabShipment(submit));
        dispatch(showToast(getString('shipmentCreatedMsg', language)));
        const { group_id } = response;
        if (group_id) {
          navigate(`/lab/shipping/details/${group_id}`);
        }
      }
    } catch (e) {
      dispatch(showToast(e.message));
    }
  };

  return (
    <div className={styles.Wrapper}>
      <Button onClick={() => navigate(`/lab/shipping`)} className={styles.GoBack}>
        {getString('backToList', language)}
      </Button>
      <Header
        title={`${getString('interLabShipping', language)}${
          shipment
            ? ` - ${labDisplayNames[shipment.ship_from_lab]} -> ${
                labDisplayNames[shipment.ship_to_lab]
              }`
            : ''
        }`}
      >
        <Button onClick={() => handleSubmitForm()} disabled={!shipment?.group_id} primary>
          {getString('saveChanges', language)}
        </Button>
      </Header>
      <div className={styles.Row}>
        <div className={styles.Column}>
          <UPSLabels shipment={shipmentForm} addLabel={handleAddTrackingLabel} />
        </div>
        <div className={styles.Column}>
          <RegulatedMaterial shipment={shipmentForm} updateShipment={handleUpdateForm} />
          <ShipmentSamples shipment={shipmentForm} updateShipment={handleUpdateForm} />
          <AddShippingPlate shipment={shipmentForm} updateShipment={handleUpdateForm} />
        </div>
      </div>
    </div>
  );
};

export default LabShippingDetailsContainer;
