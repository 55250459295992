import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Group, Space, Stack, Text } from '@mantine/core';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { Header } from 'common';
import showToast from 'actions/toastActions';
import { GENOMIC } from 'constants/analysis';
import { batchTypeList, numPlatesPerBatchType } from 'constants/batches';
import { addNewBatch } from 'store/batches/thunks';
import { BatchFormType } from 'store/batches/types';
import { formatBatchForSubmission } from 'util/batches';

import { BatchTypeSelector } from './BatchForms/FormInputs/BatchTypeSelector';
import { BatchFormContainer } from './BatchForms/Container';

const BatchCreate = () => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { batchType } = useParams<{ batchType: string }>();
  const [batch, setBatch] = useState<BatchFormType>({
    type: '',
    plates: [],
  });
  const { isFetching, errorMessage } = useSelector((state: RootState) => {
    return state.batches;
  });

  useEffect(() => {
    if (!batchType || !batchTypeList.includes(batchType)) {
      navigate(`/lab/batches/create/${GENOMIC}`);
    } else {
      const numPlatesNeeded = numPlatesPerBatchType[batchType];
      setBatch((currentBatch) => ({
        ...currentBatch,
        type: batchType,
        plates: currentBatch.plates?.slice(0, numPlatesNeeded),
      }));
    }
  }, [batchType]);

  const onSubmit = async () => {
    if (batch.type) {
      try {
        const result: any = await dispatch(addNewBatch(formatBatchForSubmission(batch)));
        const { id } = result.payload;
        if (id) {
          navigate(`/lab/batches/details/${id}`);
        }
      } catch (e) {
        dispatch(showToast(e.message));
      }
    }
  };

  const handleOnValueChange = (
    attributeKey: string,
    newValue: string | string[] | { [key: string]: any },
  ) => {
    setBatch((batchForm) => ({ ...batchForm, [attributeKey]: newValue }) as BatchFormType);
  };

  return (
    <Stack>
      <Header title={getString('newBatch', language)} />
      <Stack>
        <Group align="flex-end">
          <BatchTypeSelector
            batchType={batchType}
            onValueChange={handleOnValueChange}
            w="12.5rem"
          />
          <Group>
            <Button data-test-id="create-batch" disabled={isFetching} onClick={onSubmit}>
              {getString('createBatch', language)}
            </Button>
          </Group>
          {errorMessage && <Text c="red.5">{errorMessage}</Text>}
        </Group>
      </Stack>
      {batchType && <BatchFormContainer batch={batch} onValueChange={handleOnValueChange} />}
      <Space h="10rem" />
    </Stack>
  );
};

export default BatchCreate;
