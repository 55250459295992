import React from 'react';

import { Stack, Group, Text, SegmentedControl } from '@mantine/core';
import {
  AgronomicProductType,
  CombinedAnalyticType,
  PestGroupPayloadType,
  SeedType,
} from 'store/cropPlans/types';
import { RecommendationSetType } from 'store/recommendationSets/types';
import { CORN } from 'constants/variables';
import { NO_COVERAGE } from 'constants/cropPlan';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getLowModHigh } from 'util/cropPlans';

interface SeedProtectionPropsType {
  analytics: { [id: number]: CombinedAnalyticType };
  pestGroups: PestGroupPayloadType;
  recSet: Partial<RecommendationSetType>;
  seed: SeedType;
  setInputRating: (
    input: SeedType | AgronomicProductType,
    analyticId: number,
    coverage: number,
  ) => void;
  isTemporary?: boolean;
}

const SeedProtection = ({
  analytics,
  pestGroups,
  recSet,
  seed,
  isTemporary,
  setInputRating,
}: SeedProtectionPropsType) => {
  const pestIds = seed.crop === CORN ? pestGroups.corn : pestGroups.soybeans;
  const analyticIds = pestIds.below_ground.concat(pestIds.foliar);
  const language = useBroswerLanguage();

  return (
    <Group>
      {analyticIds.map((analyticId) => {
        const analytic = Object.values(analytics).find((_analytic) => _analytic.id === analyticId);
        if (!analytic) {
          return null;
        }
        const coverageRatings = recSet.recommended_seeds?.[seed.id]?.ratings || [];
        const coverage = isTemporary
          ? analytic.coverage_rating
          : coverageRatings.find((rating) => rating.analytic_id === analyticId)?.coverage_rating;

        const lowModHigh = getLowModHigh(language);

        return (
          <Stack
            align="center"
            justify="flex-start"
            key={analyticId}
            w="5rem"
            h="13rem"
            style={{ textAlign: 'center' }}
          >
            <SegmentedControl
              orientation="vertical"
              data={lowModHigh}
              onChange={(val) => setInputRating(seed, analytic.id, Number(val))}
              value={String(coverage || NO_COVERAGE)}
            />
            <Text size="sm" fw={600}>
              {analytic.name}
            </Text>
          </Stack>
        );
      })}
    </Group>
  );
};

export default SeedProtection;
