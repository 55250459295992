import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Center, Group, Loader, Stack, Text } from '@mantine/core';
import { RootState } from 'store';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Header, YearSelect } from 'common';
import { DEMO_OPERATION_ID } from 'constants/variables';
import { getAgency } from 'store/agencies/thunks';
import { getHeatMapForUser } from 'store/heatMap/thunks';
import { getLocalStorage, setLocalStorage } from 'util/localStorage';
import { ACTIVE_HEATMAP_AGENCY_ID } from 'constants/auth';
import { US_STATE_NAMES_DICT } from 'constants/states';
import { getSamplingTimings } from 'store/samplingTimings/thunks';
import { userIsAdmin, userIsSuperAdmin } from 'store/user/selectors';
import { isNumber } from 'util/numUtils';
import { MyAgenciesSelector } from './Common/MyAgenciesSelector';
import HeatMapFilter from './HeatMapFilter';

const HeatMapContainer = () => {
  const language = useBroswerLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { agencyId: stringId, cropYear: stringCropYear } = useParams<{
    agencyId: string;
    cropYear: string;
  }>();
  const agencyId = isNumber(Number(stringId)) ? Number(stringId) : undefined;
  const stateName = stringId
    ? Object.values(US_STATE_NAMES_DICT).find(
        (k) => k.toLowerCase() === String(stringId).toLowerCase(),
      )
    : undefined;
  const myAccounts = location.pathname.startsWith('/risk-map/my-accounts');
  const demoAccount = location.pathname.startsWith('/risk-map/demo');
  const cropYear = stringCropYear ? Number(stringCropYear) : undefined;

  const [selectedCropYear, setSelectedCropYear] = useState(cropYear);

  const activeAgencyId = getLocalStorage(ACTIVE_HEATMAP_AGENCY_ID);
  const { heatMap, fetchingHeatMap, analytics, agency, fetchingAgency, currentCropYear, isAdmin } =
    useSelector((state: RootState) => ({
      heatMap: state.heatMap.heatMap || [],
      fetchingHeatMap: state.heatMap.isFetching,
      analytics: state.analytics.analytics,
      agency: agencyId ? state.agencies.byId[agencyId] : undefined,
      fetchingAgency: state.agencies.isFetching,
      currentCropYear: state.timings.currentCropYear,
      isAdmin: userIsAdmin(state) || userIsSuperAdmin(state),
    }));

  useEffect(() => {
    if (agencyId) {
      dispatch(getAgency(agencyId));
      setLocalStorage(ACTIVE_HEATMAP_AGENCY_ID, agencyId);
    }
  }, [agencyId]);

  useEffect(() => {
    if (agencyId || stateName || myAccounts || demoAccount) {
      const sendAgencyId = demoAccount ? undefined : agencyId;
      const sendStateName = demoAccount ? undefined : stateName;
      const showDemo = demoAccount ? DEMO_OPERATION_ID : undefined;
      const onlyMine = isAdmin && myAccounts;
      dispatch(getHeatMapForUser(sendAgencyId, sendStateName, cropYear, showDemo, !onlyMine));
    }
  }, [agencyId, stateName, cropYear, myAccounts, demoAccount, isAdmin]);

  useEffect(() => {
    dispatch(getSamplingTimings());
  }, []);

  useEffect(() => {
    if (!cropYear && currentCropYear) {
      handleSelectCropYear(currentCropYear);
    }
  }, [cropYear, currentCropYear, agency, stateName, myAccounts, demoAccount, isAdmin]);

  useEffect(() => {
    if (agencyId === undefined && !stateName && !myAccounts && !demoAccount && activeAgencyId) {
      handleSelectNewView(activeAgencyId);
    }
  }, [agencyId, stateName, activeAgencyId, myAccounts, demoAccount]);

  const getViewName = (excludeAgency: boolean = false, stringNames?: boolean) => {
    if (!excludeAgency && agency) {
      return String(agency.id);
    }
    if (!excludeAgency && stateName) {
      return stateName;
    }
    if (demoAccount) {
      return stringNames ? 'demoAccount' : 'demo';
    }
    if (myAccounts) {
      return stringNames ? 'myAccounts' : 'my-accounts';
    }
    return undefined;
  };

  const handleSelectNewView = (view: number | string | null) => {
    const year = selectedCropYear || cropYear;
    navigate(`/risk-map/${view}${year ? `/${year}` : ''}`);
  };

  const handleSelectCropYear = (year: number) => {
    setSelectedCropYear(year);
    const blockNav = !agency && !stateName && !myAccounts && !demoAccount && isAdmin;
    if (!blockNav) {
      navigate(`/risk-map/${getViewName()}/${year}`);
    }
  };

  const viewName = getViewName(true, true);
  return (
    <Stack gap="0">
      <Header
        title={
          <Group gap="xs">
            <Text>{`${getString('analyticRiskMap', language)} -`}</Text>
            <MyAgenciesSelector
              setView={handleSelectNewView}
              agency={agency}
              stateName={stateName}
              agencyNotSelected={viewName}
            />
            {(fetchingHeatMap || fetchingAgency) && <Loader size={20} />}
          </Group>
        }
      >
        <YearSelect
          year={cropYear}
          setYear={handleSelectCropYear}
          currentYear={currentCropYear}
          yearsBack={3}
        />
      </Header>
      {!agency && !stateName && !myAccounts && !demoAccount ? (
        <Center>
          <Stack align="center">
            <Text>{getString('selectAgencyToBeginMsg', language)}</Text>
          </Stack>
        </Center>
      ) : (
        <HeatMapFilter
          heatMap={fetchingHeatMap ? [] : heatMap}
          analytics={analytics}
          agency={agency}
        />
      )}
    </Stack>
  );
};

export default HeatMapContainer;
