import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

import styles from './ToastCorner.module.css';

type ToastProps = {
  message?: string;
  'data-test-id'?: string;
  type?: string;
  placement: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
};

export const ToastCorner: FunctionComponent<ToastProps> = ({
  placement,
  message,
  'data-test-id': testId,
  type,
}) =>
  message ? (
    <div
      data-test-id={testId}
      className={classNames({
        [styles.Toast]: true,
        [styles.TopLeft]: placement === 'top-left',
        [styles.TopRight]: placement === 'top-right',
        [styles.BottomLeft]: placement === 'bottom-left',
        [styles.BottomRight]: placement === 'bottom-right',
        [styles.ToastError]: type === 'error',
      })}
    >
      {message}
    </div>
  ) : null;
