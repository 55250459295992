import React, { FunctionComponent, ReactNode } from 'react';

import { Layout, NavBar, ToastContainer } from 'common';

import styles from './NavBarLayout.module.css';

interface LabAppNavBarLayoutProps {
  children: ReactNode;
}

const LabAppNavBarLayout: FunctionComponent<LabAppNavBarLayoutProps> = ({ children }) => {
  return (
    <Layout>
      {/* @ts-ignore */}
      <NavBar mode="lab" />
      <ToastContainer />
      <div className={styles.PageContent}>{children}</div>
    </Layout>
  );
};

export default LabAppNavBarLayout;
