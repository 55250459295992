import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Label, Input, RequiredAnalysis, RestrictedGeo, Toggle } from 'common';
import { getSampleByBarcode } from 'store/samples/requests';
import { PlateType, WellType } from 'store/plates/types';
import { LabSampleType } from 'store/labSamples/types';
import { labSampleIsPlated, parseSample } from 'util/sample';
import { THREE_EM_DASH } from 'constants/defaultValues';

import { SharedFormInputs } from './FormInputs/SharedInputs';
import styles from './Container.module.css';

type ExistingSampleFormPropsType = {
  plate: PlateType;
  well: WellType;
  onValueChange: (
    attributeKey: keyof WellType,
    newValue: string | string[] | { [key: string]: any } | number | boolean | null,
  ) => void;
  setIsFormValid: (isValid: boolean) => void;
  setErrorMessage: (message: string) => void;
  errorMessage?: string | null;
  barcodeRef: any;
  submitForm: () => void;
};

export const ExistingSampleForm = ({
  plate,
  well,
  onValueChange,
  setIsFormValid,
  setErrorMessage,
  errorMessage,
  barcodeRef,
  submitForm,
}: ExistingSampleFormPropsType) => {
  const language = useBroswerLanguage();
  const [autoSubmit, toggleAutoSubmit] = useState(false);

  const checkBarcode = async (barcode: string) => {
    try {
      const matches: LabSampleType[] = await getSampleByBarcode(barcode);
      const parsedMatches = matches.map(parseSample);

      if (parsedMatches.length >= 1) {
        const primarySample = parsedMatches.find((sample) => sample.primary) || parsedMatches[0];
        onValueChange('sample', primarySample);
        onValueChange('sample_barcode', primarySample.barcode);
        setIsFormValid(true);

        if (labSampleIsPlated(primarySample, plate.plate_type)) {
          setErrorMessage(getString('sampleAlreadyInWellMsg', language));
        } else {
          setErrorMessage('');

          if (autoSubmit) {
            submitForm();
          }
        }
        return;
      }
      setErrorMessage(getString('sampleNotFoundMsg', language));
    } catch (e) {
      setErrorMessage(e.message);
    }
    setIsFormValid(false);
    onValueChange('sample', {});
  };

  const debouncedCheckBarcode = useDebouncedCallback(
    (barcode: string) => checkBarcode(barcode),
    250,
    { trailing: true },
  );

  const handleBarcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: barcode } = event.target;
    onValueChange('sample_barcode', barcode);
    if (barcode.trim().length > 0) {
      // prevent empty strings
      debouncedCheckBarcode(barcode);
    } else {
      setErrorMessage('');
      onValueChange('sample', {});
      setIsFormValid(true);
    }
  };

  return (
    <>
      <Label label={getString('sampleBarcode', language)}>
        <Input
          dataTestId="enter-sample-barcode"
          placeholder={getString('enterBarcodeMsg', language)}
          value={well.sample_barcode || ''}
          onChange={handleBarcodeChange}
          ref={barcodeRef}
        />
        {errorMessage && <span className={styles.Error}>{errorMessage}</span>}
      </Label>
      <Label className={styles.InputLabel} label={`${getString('autoSubmit', language)}:`}>
        <Toggle
          checked={autoSubmit}
          onChange={(e: React.ChangeEvent<HTMLFormElement>) => toggleAutoSubmit(e.target.checked)}
        />
      </Label>
      <Label className={styles.ValueLabel} label={`${getString('analysis', language)}: `}>
        {well.sample.barcode && <RequiredAnalysis sample={well.sample as LabSampleType} />}
      </Label>
      <Label className={styles.ValueLabel} label={`${getString('operation', language)}: `}>
        <div>{well.sample.operation_name || THREE_EM_DASH}</div>
      </Label>
      <Label className={styles.ValueLabel} label={`${getString('field', language)}: `}>
        <div>{well.sample.field_name || THREE_EM_DASH}</div>
      </Label>
      <RestrictedGeo sample={well.sample as LabSampleType} />
      <SharedFormInputs
        well={well}
        onValueChange={onValueChange}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        rndToggle
        sampleType
        replicaType
        rndAttributes
      />
    </>
  );
};
