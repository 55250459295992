import React from 'react';
import { getString } from 'strings/translation';
import { FieldType, MapboxSample, SamplingPlanType } from 'store/fields/types';
import { AnalyticType } from 'store/analytics/types';
import { RecommendationType } from 'store/recommendations/types';
import { getAnalyticFromPlan } from 'util/results';

import Mapbook from '../common/Mapbook';
import styles from './Container.module.css';
import SummaryChart from './SummaryChart';

interface MapbookListType {
  samplingPlan: SamplingPlanType;
  fieldGeometry: FieldType;
  filteredSamples: MapboxSample[];
  analyticsPerTab: AnalyticType[];
  recommendations: RecommendationType[];
  language: string;
}

const MapbookList = ({
  samplingPlan,
  fieldGeometry,
  filteredSamples,
  analyticsPerTab,
  recommendations,
  language,
}: MapbookListType) => {
  const onClickChartBar = (analyticId: number) => {
    const violation = document.getElementById(`analytic-${analyticId}`);
    window.scrollTo({
      top: violation?.offsetTop ? violation?.offsetTop - 100 : 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className={styles.MapbookWrapper}>
      <SummaryChart
        samplingPlan={samplingPlan}
        analyticsPerTab={analyticsPerTab}
        onClickChartBar={onClickChartBar}
      />

      <div className={styles.MapbookBar}>{getString('analytics', language)}</div>
      {analyticsPerTab.map((analytic) => {
        const planAnalytic = getAnalyticFromPlan(samplingPlan, analytic);
        return (
          planAnalytic && (
            <Mapbook
              key={analytic.id}
              activeAnalytic={analytic}
              field={fieldGeometry}
              samples={filteredSamples}
              samplingPlan={samplingPlan}
              recommendations={recommendations}
              isModifyRecommendation
            />
          )
        );
      })}
    </div>
  );
};

export default MapbookList;
