import React, { useState } from 'react';
import { Divider, Flex, Text } from '@mantine/core';
import classNames from 'classnames';

import { FONT_WEIGHT_BOLD } from 'constants/mantine';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';

import { OperationCollectionsType } from './Container';
import FieldCollections from './FieldCollections';

import styles from './Container.module.css';

interface OperationCollectionsProps {
  collections: OperationCollectionsType;
  defaultOpen: boolean;
  selectedFieldIds: number[];
  toggleField: (id: number) => void;
}

const OperationCollections = ({
  collections,
  defaultOpen,
  selectedFieldIds,
  toggleField,
}: OperationCollectionsProps) => {
  const [isExpanded, setIsExpanded] = useState(defaultOpen);
  const language = useBroswerLanguage();

  if (!collections.fields.length) {
    return null;
  }

  const totalAcreage = collections.fields.reduce(
    (total, field) => total + Math.round(field.features[0].properties.acreage),
    0,
  );
  const acreageUnit = collections.fields[0].features[0].properties.acreage_unit;
  const totalCollections = collections.fields.reduce(
    (total, field) => total + field.features[0].properties.pending_eo_collections.length,
    0,
  );
  return (
    <>
      <Flex
        className={classNames(styles.Header, styles.Row)}
        justify="space-between"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Text fw={FONT_WEIGHT_BOLD} w="25%">
          {collections.name}
        </Text>
        <Text fw={FONT_WEIGHT_BOLD} w="25%">
          {getString('planNames', language)}
        </Text>
        <Text fw={FONT_WEIGHT_BOLD} w="35%">
          {`${totalAcreage} ${acreageUnit}`}
        </Text>
        <Text fw={FONT_WEIGHT_BOLD} w="15%">
          {isExpanded
            ? getString('viewScanCoverage', language)
            : `${totalCollections} ${getString('collectionsToReview', language)}`}
        </Text>
      </Flex>
      <Divider size="sm" />
      {isExpanded
        ? collections.fields.map((field) => (
            <FieldCollections
              field={field}
              key={`field-${field.features[0].properties.id}`}
              selectedFieldIds={selectedFieldIds}
              toggleField={toggleField}
            />
          ))
        : null}
    </>
  );
};

export default OperationCollections;
