import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Spinner } from 'common';
import { Button, Menu, Select, Group, Input, Text, Space, NumberInput } from '@mantine/core';
import { FiChevronDown } from 'react-icons/fi';
import { RootState } from 'store';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getAllShippingAddresses, getShippingBatches } from 'store/shipping/thunks';
import setToast from 'actions/toastActions';
import { downloadRegulatedSoilPDF, requestCreateShippingLabelBatch } from 'store/shipping/requests';
import { selectorNumberArray } from 'util/numUtils';
import {
  JobType,
  LabAddressType,
  ParcelDimensionType,
  ShippingAddress,
  ShippingLabelBatch,
} from 'store/shipping/types';
import {
  DEFAULT_PARCEL_DIMENSIONS,
  FAILED,
  JOB_COMPLETENESS,
  MAX_LABELS_CREATE,
  REGULATED_PACKING_SLIP_PDF,
  REGULATED_SOIL_PDF,
} from 'constants/shipping';
import { formatToDateHourFormat, sortByCreatedAt } from 'util/date';
import styles from './Container.module.css';
import ShippingAddressModal from './ShippingAddressModal';
import CreateShipmentModal from './CreateShipmentModal';
import { PENDING } from 'constants/analysis';

interface CompleteFailedJobs extends Partial<ShippingLabelBatch>, Partial<JobType> {}

const ShippingLabelContainer = () => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const [isLoading, setLoading] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<
    (ShippingAddress & { value: string; label: string }) | null
  >(null);
  const [selectedLab, setSelectedLab] = useState<
    (LabAddressType & { value: string; label: string }) | null
  >(null);
  const [parcelDimensions, setParcelDimensions] =
    useState<ParcelDimensionType>(DEFAULT_PARCEL_DIMENSIONS);
  const [numLabels, setNumLabels] = useState<number | string>(1);
  const [shippingModalOpen, toggleShippingModalOpen] = useState(false);
  const [submitModalOpen, toggleSubmitModalOpen] = useState(false);
  const [isCallOccurring, toggleCallOccurring] = useState(false);

  const {
    isFetching,
    shippingAddresses,
    labAddresses,
    shippingBatches,
    pendingBatches,
    failedBatches,
  } = useSelector((state: RootState) => ({
    shippingAddresses: state.shipping.shippingAddresses,
    labAddresses: state.shipping.labAddresses,
    shippingBatches: state.shipping.ready,
    pendingBatches: state.shipping.pending,
    failedBatches: state.shipping.failed,
    isFetching: state.shipping.isFetching,
  }));

  useEffect(() => {
    dispatch(getAllShippingAddresses());
  }, [dispatch]);

  const displayAddresses = shippingAddresses.map((addr) => ({
    ...addr,
    value: String(addr.id),
    label: `${addr.street}, ${addr.city} ${addr.state}, ${addr.zip}`,
  }));

  const displayLabAddresses = labAddresses.map((addr) => ({
    ...addr,
    value: String(addr.id),
    label: addr.name,
  }));

  const setToastMessage = useCallback(
    (message: string, type?: string, time?: number) => {
      dispatch(setToast(message, type, time));
    },
    [dispatch],
  );

  const resetForm = () => {
    toggleSubmitModalOpen(false);
    toggleSubmitModalOpen(false);
    toggleCallOccurring(false);
  };

  useEffect(() => {
    async function checkLabel() {
      toggleCallOccurring(true);
      dispatch(getShippingBatches());
      toggleCallOccurring(false);
    }
    checkLabel();
    const batchInterval = setInterval(() => {
      (async function checkBatchLabels() {
        if (!isCallOccurring) {
          try {
            await checkLabel();
          } catch (e) {
            setToastMessage(getString('serverErrorMsg', language), 'error', 10000);
          }
        }
      })();
    }, 5000);
    return () => {
      clearInterval(batchInterval);
    };
  }, []);

  const createShippingLabels = async () => {
    try {
      setLoading(true);
      if (selectedAddress && selectedLab && numLabels) {
        await requestCreateShippingLabelBatch({
          address_id: selectedAddress.id,
          parcel: parcelDimensions,
          lab_id: selectedLab.id,
          num_of_labels: Number(numLabels),
        });
        toggleSubmitModalOpen(false);
        setToastMessage(getString('successCreatedShippingLabelBatch', language), 'success', 7000);
        resetForm();
      }
    } catch (err) {
      setToastMessage(getString('errorCreatingShippingLabel', language), 'error');
    } finally {
      setLoading(false);
    }
  };

  const checkDisabledOrSubmit = () => {
    if (!selectedAddress || !selectedLab || !numLabels) {
      setToastMessage(getString('fillOutRequiredFields', language), 'error', 5000);
    } else if (numLabels > MAX_LABELS_CREATE) {
      setToastMessage(getString('tooManyLabelsMsg', language), 'error', 5000);
    } else {
      toggleSubmitModalOpen(true);
    }
  };

  const downloadRegulatedSoil = async (fileName: string) => {
    try {
      const pdf_url = await downloadRegulatedSoilPDF(fileName);
      window.open(pdf_url, '_blank');
    } catch (err) {
      setToastMessage(getString('failedToDownloadRegSoilMsg', language));
    }
  };

  const getDownloadDisplayName = (singleBatch: CompleteFailedJobs) => {
    const createdAt = singleBatch.created_at && formatToDateHourFormat(singleBatch.created_at);
    if (!singleBatch.tracking_number) {
      return `${getString(singleBatch.complete === JOB_COMPLETENESS[FAILED] ? FAILED : PENDING, language)} - ${createdAt}`;
    }
    return `${createdAt} - ${singleBatch.lab_name}`;
  };

  const completedFailedBatches = sortByCreatedAt([...shippingBatches, ...failedBatches]);

  const dropdownButtons = [
    ...pendingBatches.map((job) => ({
      label: `${getDownloadDisplayName(job)}`,
      dataTestId: 'label-button-disabled',
      disabled: true,
      failed: job.complete === JOB_COMPLETENESS[FAILED],
      onClick: () => {},
    })),
    ...completedFailedBatches.map((batch: CompleteFailedJobs, index) => ({
      label: getDownloadDisplayName(batch),
      dataTestId: `label-button-${index}`,
      disabled: !batch.shippo_label_url,
      failed: batch.complete === JOB_COMPLETENESS[FAILED],
      onClick: () => {
        if (batch.shippo_label_url) {
          window.open(batch.shippo_label_url, '_blank');
        }
      },
    })),
  ];

  const parcelSelectors = [
    {
      key: 'parcelLength',
      listNumbers: selectorNumberArray(36),
      label: `${getString('length', language)} (in)`,
    },
    {
      key: 'parcelWidth',
      listNumbers: selectorNumberArray(25),
      label: `${getString('width', language)} (in)`,
    },
    {
      key: 'parcelHeight',
      listNumbers: selectorNumberArray(25),
      label: `${getString('height', language)} (in)`,
    },
    {
      key: 'parcelWeight',
      listNumbers: selectorNumberArray(60),
      label: `${getString('weight', language)} (lbs)`,
    },
  ];

  if (isFetching) {
    return <Spinner className={styles.Spinner} />;
  }

  return (
    <div className={styles.Wrapper}>
      <Header title={getString('shippingLabel', language)} className={styles.MarginBottom}>
        <Menu shadow="md">
          <Menu.Target data-test-id="action-button">
            <Button
              variant="outline"
              data-test-id="label-downloads-dropdown"
              rightSection={<FiChevronDown />}
            >
              {getString('labelDownloads', language)}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {dropdownButtons.map((dropButton) => (
              <Menu.Item
                key={dropButton.dataTestId}
                data-test-id={dropButton.dataTestId}
                onClick={dropButton.onClick}
                disabled={dropButton.disabled}
              >
                <Text color={dropButton?.failed ? 'darkRed' : undefined}>{dropButton.label}</Text>
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      </Header>
      {shippingModalOpen && (
        <ShippingAddressModal
          open={shippingModalOpen}
          hideModal={() => toggleShippingModalOpen(false)}
        />
      )}
      {submitModalOpen && selectedAddress && selectedLab && numLabels && (
        <CreateShipmentModal
          open={submitModalOpen}
          isLoading={isLoading}
          createShippingLabels={createShippingLabels}
          hideModal={() => toggleSubmitModalOpen(false)}
          addressFrom={selectedAddress}
          labName={selectedLab.name}
          numLabels={Number(numLabels)}
        />
      )}
      <Group justify="space-between">
        <Select
          className={styles.Street}
          label={getString('shippingFrom', language)}
          data-test-id="ship-from-address"
          value={selectedAddress?.value}
          disabled={isLoading}
          onChange={(val) =>
            setSelectedAddress(displayAddresses.find((addr) => addr.value === val) || null)
          }
          data={displayAddresses}
          clearable
        />
        <Button data-test-id="add-address" onClick={() => toggleShippingModalOpen(true)}>
          {getString('addAddress', language)}
        </Button>
      </Group>
      <Group>
        <Select
          className={styles.LabSelector}
          label={getString('shippingTo', language)}
          data-test-id="ship-to-address"
          value={selectedLab?.value}
          disabled={isLoading}
          onChange={(val) =>
            setSelectedLab(displayLabAddresses.find((addr) => addr.value === val) || null)
          }
          data={displayLabAddresses}
          clearable
        />
      </Group>
      <Input.Wrapper
        label={`${getString('labelCount', language)} (1-80)`}
        className={styles.ParcelSelector}
      >
        <NumberInput data-test-id="label-count" value={numLabels} onChange={setNumLabels} />
      </Input.Wrapper>
      <Space h="lg" />
      <Text size="xl" fw="500">
        {getString('parcelDimensions', language)}
      </Text>
      <Group>
        {parcelSelectors.map((sel) => (
          <Select
            key={sel.key}
            className={styles.ParcelSelector}
            label={sel.label}
            value={parcelDimensions[sel.key]}
            defaultValue={parcelDimensions[sel.key]}
            disabled={isLoading}
            onChange={(val) =>
              setParcelDimensions({
                ...parcelDimensions,
                [sel.key]: val,
              })
            }
            data={sel.listNumbers}
          />
        ))}
      </Group>
      <Space h="lg" />
      <Text size="xl" color="darkRed">
        *{getString('shippingRegulatedSoil', language)}?:{' '}
        <span
          className={styles.DownloadLink}
          onClick={() => downloadRegulatedSoil(REGULATED_SOIL_PDF)}
        >
          {REGULATED_SOIL_PDF}.pdf
        </span>
      </Text>
      <Text size="xl" color="darkRed">
        *{getString('patternAgDSCANumber', language)}:{' '}
        <span
          className={styles.DownloadLink}
          onClick={() => downloadRegulatedSoil(REGULATED_PACKING_SLIP_PDF)}
        >
          {REGULATED_PACKING_SLIP_PDF}.pdf
        </span>
      </Text>
      <Space h="lg" />
      <Button
        data-test-id="create-shipping-label"
        className={styles.CreateLabelButton}
        onClick={checkDisabledOrSubmit}
        disabled={isLoading}
      >
        {getString('createShippingLabels', language)}
      </Button>
    </div>
  );
};

export default ShippingLabelContainer;
