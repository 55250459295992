import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { Button, Loader, Group, Menu, ThemeIcon } from '@mantine/core';
import { ResultsLinksType, ResultsLinkType } from 'store/operation/types';
import PrintResultsModal from '../OverviewSummaryV2/PrintResultsModal';
import { FiChevronDown, FiXCircle } from 'react-icons/fi';
import {
  requestDeleteResultsDownloadLink,
  startGenerateResultsByFormat,
} from 'store/operation/requests';
import { CSV_FORMAT, SHAPEFILE_FORMAT, TOAST_TWELVE } from 'constants/results';
import { dayJsConvertUtcToLocale } from 'util/date';
import CancelModal from 'apps/Orders/FieldList/FullProductReport/common/CancelModal';
import { getResultsLinkByFormat } from 'store/operation/thunks';

interface ControlBarProps {
  isFetchingAgencies?: boolean;
  operationId: number;
  setIsPrinting?: (isPrinting: boolean) => void;
  result_links?: ResultsLinksType;
  selectedYear: number;
  language: string;
}

const ControlBar = ({
  isFetchingAgencies,
  operationId,
  setIsPrinting,
  result_links,
  selectedYear,
  language,
}: ControlBarProps) => {
  const dispatch = useDispatch();
  const [isMapbookModal, setIsMapbookModal] = useState(false);
  const [deactivatePDF, setDeactivatePDF] = useState<ResultsLinkType | null>(null);
  const [isLoading, toggleIsLoading] = useState(false);

  const showMessage = (message: string, type = '', timeout = 5000) =>
    dispatch(showToast(message, type, timeout));

  const handlePrint = () => {
    setIsPrinting && setIsPrinting(true);
    // wait for state change to propagate
    setTimeout(() => {
      window.print();
    }, 800);

    window.onafterprint = () => {
      // workaround https://github.com/facebook/react/issues/17918
      setTimeout(() => {
        setIsPrinting && setIsPrinting(false);
      }, 0);

      window.onafterprint = null;
    };
  };

  const deletePDF = async () => {
    if (deactivatePDF) {
      try {
        toggleIsLoading(true);
        await requestDeleteResultsDownloadLink(operationId, deactivatePDF.id);
        dispatch(getResultsLinkByFormat(operationId));
        showMessage(getString('deletePDFSuccessMsg', language));
        setDeactivatePDF(null);
      } catch (error) {
        showMessage(getString('deletePDFErrorMsg', language), 'error');
      } finally {
        toggleIsLoading(false);
      }
    }
  };

  const downloadLineItem = (resultLink: ResultsLinkType) => (
    <Menu.Item key={resultLink.id} disabled={!resultLink.signed_url}>
      <a target="_blank" rel="noopener noreferrer" href={resultLink.signed_url}>
        {resultLink.name || getString('pending', language)} (
        {dayJsConvertUtcToLocale(resultLink.created_at, 'MM/DD/YYYY h:mm A')})
      </a>
    </Menu.Item>
  );

  return (
    <Group>
      {isMapbookModal && (
        <PrintResultsModal
          open={isMapbookModal}
          onClose={() => setIsMapbookModal(false)}
          operationId={operationId}
          showMessage={showMessage}
          selectedYear={selectedYear}
          availableFieldIds={result_links?.mapbook_available_fields || []}
        />
      )}
      {!!deactivatePDF && (
        <CancelModal
          cancelTitle={getString('deletePDFMsg', language)}
          cancelText={`${getString('name', language)}: ${deactivatePDF.name}`}
          cancel={deletePDF}
          isFetching={false}
          hideModal={() => setDeactivatePDF(null)}
          opened={!!deactivatePDF}
          isDisabled={isLoading}
        />
      )}
      {isFetchingAgencies ? (
        <Loader size="xs" />
      ) : (
        <Menu shadow="md">
          <Menu.Target data-test-id="action-button">
            <Button
              variant="outline"
              data-test-id="overview-pdf-print-button"
              rightSection={<FiChevronDown />}
            >
              {getString('print', language)}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>{getString('printOptions', language)}</Menu.Label>
            <Menu.Item onClick={handlePrint} data-test-id="print-button">
              {getString('printSummary', language)}
            </Menu.Item>
            <Menu.Item
              onClick={() => setIsMapbookModal(true)}
              data-test-id="generate-mapbook-button"
            >
              {getString('generateMapbookResults', language)}
            </Menu.Item>
            <Menu.Label>{getString('mapbookDownloads', language)}</Menu.Label>
            {result_links?.pdf?.map((single) => (
              <Menu.Item key={single.id} disabled={!single.signed_url}>
                <a target="_blank" rel="noopener noreferrer" href={single.signed_url}>
                  {single.name || getString('pending', language)} (
                  {dayJsConvertUtcToLocale(single.created_at, 'MM/DD/YYYY h:mm A')})
                </a>
                {single.signed_url && (
                  <ThemeIcon
                    variant="transparent"
                    color="darkRed"
                    onClick={() => setDeactivatePDF(single)}
                  >
                    <FiXCircle color="darkRed" />
                  </ThemeIcon>
                )}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      )}
      <Menu shadow="md">
        <Menu.Target data-test-id="action-button">
          <Button
            variant="outline"
            data-test-id="overview-download-button"
            rightSection={<FiChevronDown />}
          >
            {getString('download', language)}
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>CSV</Menu.Label>
          <Menu.Item
            onClick={async () => {
              showMessage(getString('csvDownloadMsg', language), 'success', TOAST_TWELVE);
              await startGenerateResultsByFormat(operationId, selectedYear, CSV_FORMAT, {});
            }}
            data-test-id="generate-csv-button"
          >
            {getString('generateCsvResults', language)}
          </Menu.Item>
          {result_links?.csv?.map((single) => downloadLineItem(single))}
          <Menu.Label>{getString('shapefile', language)}</Menu.Label>
          <Menu.Item
            onClick={async () => {
              showMessage(getString('shapefileDownloadMsg', language), 'success', TOAST_TWELVE);
              await startGenerateResultsByFormat(operationId, selectedYear, SHAPEFILE_FORMAT, {});
            }}
            data-test-id="generate-shp-button"
          >
            {getString('generateShapefileResults', language)}
          </Menu.Item>
          {result_links?.shapefile?.map((single) => downloadLineItem(single))}
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
};

export default ControlBar;
