import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Spinner, UserAgencySearch } from 'common';
import {
  Title,
  Button,
  Input,
  SimpleGrid,
  Group,
  Select,
  Container,
  Space,
  Modal,
} from '@mantine/core';
import { UserAgencySelectorType, UserInfo, UserRole } from 'store/user/types';
import { agencyRoles, getCreateUserRoleOption, getRoleOptionsV2 } from 'util/userRoles';
import { Agency } from 'store/agencies/types';
import { registerUserInAgency, updateAgencyDetails, createAgency } from 'store/agencies/requests';
import showToast from 'actions/toastActions';
import { selectRoles } from 'store/roles/actions';
import { registerFormValidate } from 'util/validators';
import { getAgency } from 'store/agencies/thunks';
import { AGENCY, LOCATION, PARENT_AGENCY } from 'constants/agency';

import styles from './CreateAgencyModal.module.css';
import { useNavigate } from 'react-router-dom';

interface UserWithParentAgeny extends Partial<UserInfo> {
  parent_agency_id?: number;
}

type AgencyReassignmentDialogPropTypes = {
  open: boolean;
  onClose: () => void;
  isParentAgency: boolean;
  agency: Agency | null;
  isCreateOnly?: boolean;
};

const CreateAgencyModal = ({
  open,
  onClose,
  isParentAgency,
  agency,
  isCreateOnly = false,
}: AgencyReassignmentDialogPropTypes) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const storeRoles = useSelector(selectRoles);
  const navigate = useNavigate();
  const roles = getCreateUserRoleOption(storeRoles, language);
  const initialValues: {
    first_name: string;
    last_name: string;
    email: string;
    skip_signature: boolean;
    role_id?: number;
    agency_name?: string;
  } = {
    first_name: '',
    last_name: '',
    email: '',
    agency_name: '',
    skip_signature: false,
    role_id: roles.findIndex((role) => role.name.includes(UserRole.AgencyAdmin)),
  };
  const [userInfo, setUserInfo] = useState(initialValues);
  const [isSearching, toggleIsSearching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedAgency, setSelectedAgency] = useState<{ id: number; displayName: string } | null>(
    null,
  );
  const [newAgencyName, setNewAgencyName] = useState('');

  const roleOptions = getRoleOptionsV2(agencyRoles(roles), language);

  const allUserInfoFilled = () => {
    return userInfo.email && userInfo.first_name && userInfo.last_name;
  };

  const noUserInfoFilled = () => {
    return !userInfo.email && !userInfo.first_name && !userInfo.last_name;
  };

  const validForm = () => {
    if (isCreateOnly && newAgencyName && (allUserInfoFilled() || noUserInfoFilled())) {
      return !!newAgencyName;
    }
    return (
      !isCreateOnly &&
      !!(selectedAgency?.id || (!selectedAgency?.id && newAgencyName.length && allUserInfoFilled()))
    );
  };

  const handleAgencySelection = (selected: UserAgencySelectorType | null) => {
    if (selected) {
      const { agency_id, displayName } = selected;
      if (agency_id) {
        setSelectedAgency({ id: agency_id, displayName });
      }
      if (selected?.agency_id) {
        setNewAgencyName('');
        setUserInfo(initialValues);
      }
    }
  };

  const handleDeselect = () => {
    setSelectedAgency(null);
    setNewAgencyName('');
    setUserInfo(initialValues);
  };

  const requestState = () => {
    setSelectedAgency(null);
    setNewAgencyName('');
    setUserInfo(initialValues);
  };

  const handleSubmit = async () => {
    try {
      if (!selectedAgency && !newAgencyName) {
        return dispatch(showToast(getString('missingInfoMsg', language), 'error'));
      }
      setIsSubmitting(true);
      const agencyAdminRole = roleOptions.find((r) => r.value === UserRole.AgencyAdmin)?.role;
      const agencyId = selectedAgency?.id;
      const agencyName = selectedAgency === null ? newAgencyName : undefined;

      if (agencyId && agency) {
        await updateAgencyDetails(agencyId, { parent_agency_id: agency.id });
        dispatch(getAgency(agency.id));
      } else if (agencyName && noUserInfoFilled()) {
        const newAgency = await createAgency({
          name: agencyName?.trim(),
        });
        navigate(`/agencies/${newAgency.id}`);
      } else if (agencyName && allUserInfoFilled()) {
        const data: UserWithParentAgeny = {
          email: userInfo.email.trim().toLowerCase(),
          agency_name: agencyName.trim(),
          first_name: userInfo.first_name.trim(),
          last_name: userInfo.last_name.trim(),
          role_id: agencyAdminRole?.id,
          parent_agency_id: agency?.id,
        };
        const error = registerFormValidate(
          data,
          selectedAgency,
          selectedAgency === null ? 'no' : 'yes',
          roles,
          language,
        );
        if (error) {
          return dispatch(showToast(error, 'error', 5000));
        }
        const { agency: createdAgency } = await registerUserInAgency(data);
        if (agency) {
          dispatch(getAgency(agency.id));
        }
        if (isCreateOnly && createdAgency) {
          navigate(`/agencies/${createdAgency.id}`);
        }
      }
      requestState();
      dispatch(showToast(getString('agencyUpdatedSuccessMsg', language)));
      onClose();
    } catch (e) {
      dispatch(showToast(e.message, 'error'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const getTitle = () => {
    if (isCreateOnly) {
      return getString('addAgency', language);
    }
    return isParentAgency ? getString('addAgency', language) : getString('addLocation', language);
  };

  return (
    <Modal size="xl" opened={open} onClose={onClose} centered title={getTitle()}>
      <Container>
        <SimpleGrid cols={1}>
          {!isCreateOnly && (
            <Title order={5}>
              {getString('createOrAddAgencyUnder', language)} {agency?.name}
            </Title>
          )}
        </SimpleGrid>
        <Space h="lg" />
        <SimpleGrid cols={3}>
          {!isCreateOnly && (
            <Input.Wrapper
              label={
                <div>
                  <span>
                    {getString(agency?.hierarchy === PARENT_AGENCY ? AGENCY : LOCATION, language)}
                  </span>
                  {isSearching && <Spinner className={styles.TinySpinner} />}
                </div>
              }
            >
              <UserAgencySearch
                placeholder={getString('searchAgency', language)}
                onSelect={handleAgencySelection}
                onDeselect={handleDeselect}
                className={styles.SearchBar}
                toggleIsSearching={toggleIsSearching}
                selected={
                  selectedAgency?.displayName
                    ? { id: String(selectedAgency?.id), displayName: selectedAgency?.displayName }
                    : null
                }
                hasCreateNewAgency
                isAgencyOnly
                maxHeight={150}
              />
            </Input.Wrapper>
          )}
          <Input.Wrapper label={getString('agencyName', language)}>
            <Input
              value={newAgencyName}
              disabled={!!selectedAgency?.id}
              onChange={(e) => setNewAgencyName(e.target.value)}
              data-test-id="agency-name-input"
            />
          </Input.Wrapper>
          <Input.Wrapper
            label={`${getString('agency_admin', language)} ${getString('email', language)}`}
          >
            <Input
              onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
              value={userInfo.email}
              disabled={!!selectedAgency?.id}
              data-test-id="agency-admin-email-input"
            />
          </Input.Wrapper>
          <Input.Wrapper label={getString('role', language)}>
            <Select
              value={
                roleOptions[roleOptions.findIndex((role) => role.value === UserRole.AgencyAdmin)]
                  ?.value
              }
              data={roleOptions}
              disabled
            />
          </Input.Wrapper>
          <Input.Wrapper label={getString('firstName', language)}>
            <Input
              data-test-id="agency-admin-first-name-input"
              onChange={(e) => setUserInfo({ ...userInfo, first_name: e.target.value })}
              value={userInfo.first_name}
              disabled={!!selectedAgency?.id}
            />
          </Input.Wrapper>
          <Input.Wrapper label={getString('lastName', language)}>
            <Input
              onChange={(e) => setUserInfo({ ...userInfo, last_name: e.target.value })}
              value={userInfo.last_name}
              disabled={!!selectedAgency?.id}
              data-test-id="agency-admin-last-name-input"
            />
          </Input.Wrapper>
        </SimpleGrid>
        <Space h="xl" />
        <Group justify="flex-end">
          <Button color="darkRed" onClick={onClose} disabled={isSubmitting}>
            {getString('cancel', language)}
          </Button>
          <Button
            data-test-id="submit-button"
            onClick={handleSubmit}
            disabled={!validForm() || isSubmitting}
          >
            {isSubmitting
              ? `${getString('submitting', language)}...`
              : getString('submit', language)}
          </Button>
        </Group>
      </Container>
    </Modal>
  );
};

export default CreateAgencyModal;
