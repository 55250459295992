import {
  handleFileAttachment,
  handleJsonResponse,
  requestDelete,
  requestDownloadFile,
  requestGet,
  requestPost,
  requestPostFile,
  requestPut,
  SERVICE_URL,
} from 'util/request';
import { queryStringify } from 'util/stringUtils';
import { PlateType, StorePlateData, WellType } from './types';

export const requestGetAllPlates = async (filter: {
  page?: number;
  barcode?: string;
  plateType?: string[];
  analysisType?: string[];
  status?: string[];
  labId?: string[];
  sortBy?: string;
  order?: string;
  all?: boolean;
}) =>
  handleJsonResponse(
    await requestGet(
      `${SERVICE_URL}/plates/?${queryStringify({
        page: filter.page || 1,
        barcode: filter.barcode,
        plate_type: filter.plateType?.length ? filter.plateType.toString() : undefined,
        analysis_type: filter.analysisType?.length ? filter.analysisType.toString() : undefined,
        status: filter.status?.length ? filter.status.toString() : undefined,
        homogenization_plate_id: filter.labId?.length ? filter.labId.toString() : undefined,
        sort_by: filter.sortBy || undefined,
        order: filter.order || undefined,
        all: filter.all,
      })}`,
    ),
  );

export const requestCreatePlate = async (data: Partial<PlateType>) =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/plate/`, { body: data }));

export const requestGetRelatedPlates = async (barcode: string) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/related_plates_v2/${barcode}`));

export const requestGetHomogenRelatedPlates = async (barcode: string) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/homogenization_related_plates/${barcode}`));

export const requestGetPlate = async (barcode: string) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/plate/${barcode}`));

export const requestGetPlateWithDetails = async (barcode: string) => {
  const plate = await requestGetPlate(barcode);

  // Related Plates
  const relatedPlates = await requestGetRelatedPlates(barcode);

  // Related Documents
  const relatedDocumentsResponse = await requestGet(
    `${SERVICE_URL}/plate_uploaded_files/${barcode}`,
  );
  const relatedDocuments = await handleJsonResponse(relatedDocumentsResponse);

  return {
    ...plate,
    related_plates: relatedPlates,
    related_documents: relatedDocuments.plate_uploaded_files,
  };
};

export const requestUpdatePlate = async (
  barcode: string,
  body: Partial<PlateType> | PlateType['meta_data'] | Partial<StorePlateData>,
) => handleJsonResponse(await requestPut(`${SERVICE_URL}/plate/${barcode}`, { body }));

export const requestDeletePlate = async (barcode: string) =>
  handleJsonResponse(await requestDelete(`${SERVICE_URL}/plate/${barcode}`));

export const requestUploadDocument = async (
  barcode: string,
  fileName: string,
  notes: string,
  documentType: string,
) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/plate_file_upload/${barcode}`, {
      body: { file_name: fileName, notes, document_type: documentType },
    }),
  );

export const requestUpdateDocument = async (id: number, body: any) =>
  handleJsonResponse(await requestPut(`${SERVICE_URL}/plate_uploaded_file/${id}`, { body }));

export const requestGenerateSampleSheet = async (id: number, fileName: string) =>
  requestDownloadFile(`${SERVICE_URL}/sample_sheet/${id}`, fileName);

export const requestDownloadHomogenizationDetails = (barcode: string) =>
  requestDownloadFile(
    `${SERVICE_URL}/plate_details/${barcode}`,
    `homogenization_details_${barcode}`,
  );

export const requestGenerateQPCRSampleSheet = async (id: number) =>
  handleJsonResponse(await requestPut(`${SERVICE_URL}/generate_qpcr_sample_sheet/${id}`));

export const requestGenerateBarcodeSheet = async (id: number) =>
  handleJsonResponse(await requestPut(`${SERVICE_URL}/generate_qpcr_barcode_sheet/${id}`));

export const requestCreateWell = async (barcode: string, body: Partial<WellType>) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/well/`, {
      body: { ...body, plate_barcode: barcode },
    }),
  );

export const requestDeleteWell = async (id: number) =>
  handleJsonResponse(await requestDelete(`${SERVICE_URL}/well/${id}`));

export const requestPostUploadIngestFile = async (barcode: string, file: File) =>
  handleJsonResponse(
    await requestPostFile(`${SERVICE_URL}/upload_ingest_file/${barcode}`, {
      file,
    }),
  );

export const requestDownloadPlateRunsheet = async (plate: PlateType) => {
  const filename = `plate_${plate.barcode}_run_sheet.csv`;
  return handleFileAttachment(
    await requestGet(`${SERVICE_URL}/plate_run_sheet/${plate.barcode}`),
    filename,
  );
};

export const requestGetShippablePlates = async (page: number, group_id?: string) =>
  handleJsonResponse(
    await requestGet(
      `${SERVICE_URL}/plates_ready_to_ship/?page=${page}${group_id ? `&group_id=${group_id}` : ''}`,
    ),
  );

export const requestPutMarkPlateArrived = async (barcode: string, is_arrived: boolean) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/mark_plate_arrived/${barcode}?is_arrived=${is_arrived}`),
  );
