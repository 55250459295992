import React, { useState } from 'react';
import { Accordion } from 'common';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { SamplingPlanType } from 'store/fields/types';
import { AnalyticType } from 'store/analytics/types';
import {
  getAnalysisViewOptions,
  getAnalyticBuckets,
  getAnalyticsPerCategory,
  getCategoryFromParams,
} from 'util/results';
import { OperationType } from 'store/operation/types';
import { MODERATE_RISK, HIGH_RISK } from 'constants/fieldRisks';
import { useNavigate } from 'react-router-dom';
import { RESULT_DISPLAY_CATEGORIES } from 'constants/results';
import AnalyticBar from '../../../common/AnalyticBar';

type ListPropsType = {
  samplingPlan: SamplingPlanType;
  analytics: AnalyticType[];
  analyticCategories: string[];
  operation: OperationType;
};

const AnalyticCategoryList = ({
  samplingPlan,
  analyticCategories,
  analytics,
  operation,
}: ListPropsType) => {
  const language = useBroswerLanguage();
  const navigate = useNavigate();
  const [accordionsOpen, setAccordionOpen] = useState<string | undefined>(undefined);

  const toggleAccordion = (panelType: string) =>
    accordionsOpen === panelType ? setAccordionOpen(undefined) : setAccordionOpen(panelType);

  const analyticBuckets = getAnalyticBuckets(analyticCategories, analytics);

  const analyticsInField = samplingPlan
    ? Object.keys(samplingPlan.analytics).flatMap((cat) => Object.keys(samplingPlan.analytics[cat]))
    : [];

  const analyticsPerAccordion = accordionsOpen
    ? getAnalyticsPerCategory(
        analyticBuckets,
        getCategoryFromParams(accordionsOpen),
        analyticsInField,
        samplingPlan,
      )
    : [];

  const anaylyticBars = (() =>
    analyticsPerAccordion.map((analytic) => (
      <AnalyticBar
        textSize="sm"
        activeAnalytic={analytic}
        samplingPlan={samplingPlan}
        onClick={() =>
          navigate(
            `/results/analytic/${operation.id}/${samplingPlan.field_id}/${samplingPlan.id}/${analytic.id}`,
          )
        }
        displayRiskLevels={[MODERATE_RISK, HIGH_RISK]}
        key={analytic.id}
      />
    )))();

  const barDisplay = (() => {
    if (!accordionsOpen) {
      return <div />;
    }
    if (anaylyticBars.length) {
      return anaylyticBars;
    }
    return getString('noResultsForCategory', language);
  })();

  return (
    <div>
      {getAnalysisViewOptions(language, RESULT_DISPLAY_CATEGORIES).map((panel) => (
        <Accordion
          key={panel.displayName}
          title={panel.displayName}
          toggleOpen={() => toggleAccordion(panel.value)}
          open={accordionsOpen === panel.value}
        >
          {barDisplay}
        </Accordion>
      ))}
    </div>
  );
};

export default AnalyticCategoryList;
