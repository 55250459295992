import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Typeahead, TypeaheadPropsType } from './Components/Mantine/Typeahead';
import { useDebouncedCallback } from 'use-debounce';
import showToast from 'actions/toastActions';
import { requestGetBatches } from 'store/batches/requests';
import { BatchType } from 'store/batches/types';

type BatchSearchBarPropsType = Omit<TypeaheadPropsType, 'onSelect' | 'data' | 'value'> & {
  onSelect: (id: number) => void;
  onDeselect: () => void;
  autofocus?: boolean;
  batchTypes?: string[];
  value?: number;
};

export const BatchSearchBar = ({
  onSelect,
  onDeselect,
  autofocus,
  batchTypes,
  value,
  ...props
}: BatchSearchBarPropsType) => {
  const dispatch = useDispatch();
  const [filterId, setFilterId] = useState(String(value || ''));
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [batches, setBatches] = useState<BatchType[]>([]);

  const debounceFetchPlates = useDebouncedCallback(
    async (barcode: string) => {
      try {
        if (barcode) {
          setIsFetching(true);
          const response = await requestGetBatches({
            page: 1,
            id: filterId,
            type: batchTypes,
          });
          if (response.items) {
            setBatches(response.items);
          }
        } else {
          setBatches([]);
        }
      } catch (e) {
        dispatch(showToast(e.message));
      }
      setIsFetching(false);
      setTriggerSearch(false);
    },
    300,
    { trailing: true },
  );

  useEffect(() => {
    if (triggerSearch) {
      debounceFetchPlates(filterId);
    }
  }, [debounceFetchPlates, filterId]);

  const batchOptions = useMemo(() => {
    return batches.map((b) => ({
      id: b.id,
      label: String(b.id),
      value: b,
    }));
  }, [batches]);

  const handleSelect = (batch: BatchType) => {
    setFilterId(String(batch.id));
    onSelect(batch.id);
  };

  const handleDeselect = () => {
    setFilterId('');
    onDeselect();
  };

  const handleTextChange = (text: string) => {
    setTriggerSearch(true);
    setFilterId(text);
  };

  return (
    <Typeahead
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      onTextChange={handleTextChange}
      data={batchOptions}
      value={filterId}
      autofocus={autofocus}
      waitForKeyPress
      isLoading={isFetching}
      {...props}
    />
  );
};
