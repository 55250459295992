import { AnalyticType } from 'store/analytics/types';
import { FieldType } from 'store/fields/types';

interface ObjectWithNameInterface {
  name: string;
}

function sortByName<T extends ObjectWithNameInterface>(list: Array<T>): Array<T> {
  return list.concat().sort((a, b) => {
    const name1 = a.name ? a.name.toLowerCase() : 'zzz';
    const name2 = b.name ? b.name.toLowerCase() : 'zzz';
    if (name1 < name2) {
      return -1;
    }
    return name1 > name2 ? 1 : 0;
  });
}

export function sortByValue<T>(list: Array<T>, sortedBy: string): Array<T> {
  return list.concat().sort((a, b) => {
    const name1 = a[sortedBy] ? a[sortedBy].toLowerCase() : 'zzz';
    const name2 = b[sortedBy] ? b[sortedBy].toLowerCase() : 'zzz';
    if (name1 < name2) {
      return -1;
    }
    return name1 > name2 ? 1 : 0;
  });
}

interface ObjectWithFarmNameInterface {
  name: string;
  farm_name: string | null;
}

export function sortFarmThenFieldName<T extends ObjectWithFarmNameInterface>(
  list: Array<T>,
): Array<T> {
  return list.concat().sort((a, b) => {
    const farmName1 = a.farm_name ? a.farm_name.toLowerCase() : 'zzz';
    const name1 = a.name ? a.name.toLowerCase() : 'zzz';
    const farmName2 = b.farm_name ? b.farm_name.toLowerCase() : 'zzz';
    const name2 = b.name ? b.name.toLowerCase() : 'zzz';

    if (farmName1 > farmName2) {
      return 1;
    }
    if (farmName1 < farmName2) {
      return -1;
    }

    // Else go to the 2nd item
    if (name1 < name2) {
      return -1;
    }
    if (name1 > name2) {
      return 1;
    } // nothing to split them
    return 0;
  });
}

export const sortByFieldName = (fields: FieldType[]) =>
  fields.sort((a, b) =>
    a.features[0].properties.name.toLowerCase() > b.features[0].properties.name.toLowerCase()
      ? 1
      : -1,
  );

export const sortAnalyticsById = (analytics: AnalyticType[], sortedArr: number[] | undefined) =>
  sortedArr
    ? analytics.sort((a, b) => sortedArr.indexOf(a.id) - sortedArr.indexOf(b.id))
    : analytics;

export default sortByName;
