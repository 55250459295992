import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Group, Space, Stack, Text, Title } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { RootState } from 'store';
import { getString } from 'strings/translation';
import {
  getAnalysisDisplayName,
  getFilteredReadsString,
  getProgressStageForAnalysis,
  isAnalysisRequired,
} from 'util/analysis';
import { getBatchNamePlusText } from 'util/batches';
import { getPlateNamePlusText } from 'util/plates';
import { sortByCreatedAt } from 'util/date';
import getChemistryResults from 'store/chemistryResults/thunks';
import { LabSampleType } from 'store/labSamples/types';
import {
  AMMONIUM_ACETATE,
  GENOMIC,
  M3,
  NITRATE,
  OM,
  PH,
  QPCR,
  SHOTGUN,
} from 'constants/analysis';
import {
  AA_ANALYSIS,
  HOMOGENIZATION,
  NITRATE_ANALYSIS,
  OM_ANALYSIS,
  M3_ANALYSIS,
  PH_ANALYSIS,
  QPCR as QPCR_PLATE,
  SHOTGUN as SHOTGUN_PLATE,
} from 'constants/plates';
import { THREE_EM_DASH } from 'constants/defaultValues';

import { ChemistryResults } from './ChemistryResults';
import styles from './Container.module.css';

type ProcessingContainerPropsType = {
  sample: LabSampleType;
};

export const ProcessingContainer = ({ sample }: ProcessingContainerPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const { analytics, results, hasFailed, isFetching } = useSelector((state: RootState) => ({
    analytics: state.analytics.allAnalytics,
    results: sample.barcode ? state.chemistryResults.byBarcode[sample.barcode] : null,
    hasFailed: state.chemistryResults.hasFailed,
    isFetching: state.chemistryResults.isFetching,
  }));

  useEffect(() => {
    if (sample.barcode && !results && !hasFailed) {
      dispatch(getChemistryResults(sample.barcode));
    }
  }, [sample, results, hasFailed]);

  const primarySampleResults = useMemo(
    () =>
      sortByCreatedAt(results?.filter((result) => result.sample_uuid === sample.sample_uuid) || []),
    [sample, results],
  );

  const cellTemplate = (label: string, values: (string | number)[], links?: string[]) => (
    <Group gap="0.5rem" flex={1} justify="flex-start" align="flex-start">
      <Text fw={700}>{`${label}: `}</Text>
      {values.length ? (
        values.map((value, index) => {
          const link = links ? links[index] : undefined;
          return (
            <Group key={value}>
              {index > 0 && <Text>{', '}</Text>}
              {link ? (
                <Link className={styles.Link} to={link}>
                  {value}
                </Link>
              ) : (
                <Text>{value || THREE_EM_DASH}</Text>
              )}
            </Group>
          );
        })
      ) : (
        <Text>{THREE_EM_DASH}</Text>
      )}
    </Group>
  );

  const generateAnalysisCell = (analysis: string) => {
    const label = getAnalysisDisplayName(analysis, language);
    const progress = getProgressStageForAnalysis(sample, analysis, language);
    const filtered_reads =
      analysis === SHOTGUN && sample.total_filtered_reads
        ? [getFilteredReadsString(sample, language)]
        : [];
    return cellTemplate(label, [progress, ...filtered_reads]);
  };

  const generatePlateCell = (plateType: string, analysis?: string) => {
    const label = getPlateNamePlusText(plateType, 'plate', language);
    const plates = sample.plates_summary?.[plateType] || [];
    const hideBarcode = analysis && !isAnalysisRequired(sample, analysis);

    const barcodes = !hideBarcode && plates?.length ? plates.map((p) => p.barcode) : [];
    const links =
      !hideBarcode && barcodes.length
        ? barcodes.map((barcode) => `/lab/plates/details/${barcode}`)
        : undefined;

    return cellTemplate(label, barcodes, links);
  };

  const generateBatchCell = (plateType: string, batchType: string) => {
    const label = getBatchNamePlusText(batchType, 'batch', language);
    const plates = sample.plates_summary?.[plateType];

    const bIds = plates?.length
      ? (plates.map((p) => p.batch_id).filter((p) => !!p) as number[])
      : [];
    const links = bIds?.length ? bIds.map((bId) => `/lab/batches/details/${bId}`) : undefined;

    return cellTemplate(label, bIds, links);
  };

  return (
    <Stack>
      <Title order={2} fw={700}>
        {getString('processingSummary', language)}
      </Title>
      <Stack gap="sm" justify="space-between">
        <Title size="1.5rem" fw={500}>
          {getString('qpcrAnalysis', language)}
        </Title>
        <Group gap="xs" align="flex-start">
          {generateAnalysisCell(QPCR)}
          {generatePlateCell(HOMOGENIZATION, QPCR)}
          {generatePlateCell(QPCR_PLATE)}
          <Space flex={1} />
        </Group>
        <Divider />
      </Stack>
      <Stack gap="sm" justify="space-between">
        <Title size="1.5rem" fw={500}>
          {getString('shotgunAnalysis', language)}
        </Title>
        <Group gap="xs" align="flex-start">
          {generateAnalysisCell(SHOTGUN)}
          {generatePlateCell(HOMOGENIZATION, SHOTGUN)}
          {generatePlateCell(SHOTGUN_PLATE)}
          {generateBatchCell(SHOTGUN_PLATE, GENOMIC)}
        </Group>
        <Divider />
      </Stack>
      <Stack gap="sm" justify="space-between">
        <Title size="1.5rem" fw={500}>
          {getString('nutrientDetails', language)}
        </Title>
        <Group gap="xs" align="flex-start">
          {generateAnalysisCell(M3)}
          {generatePlateCell(M3_ANALYSIS)}
          {generateBatchCell(M3_ANALYSIS, M3)}
          <Space flex={1} />
        </Group>
        <Group gap="xs" align="flex-start">
          {generateAnalysisCell(PH)}
          {generatePlateCell(PH_ANALYSIS)}
          <Space flex={2} />
        </Group>
        <Group gap="xs" align="flex-start">
          {generateAnalysisCell(NITRATE)}
          {generatePlateCell(NITRATE_ANALYSIS)}
          {generateBatchCell(NITRATE_ANALYSIS, NITRATE)}
          <Space flex={1} />
        </Group>
        <Group gap="xs" align="flex-start">
          {generateAnalysisCell(AMMONIUM_ACETATE)}
          {generatePlateCell(AA_ANALYSIS)}
          {generateBatchCell(AA_ANALYSIS, AMMONIUM_ACETATE)}
          <Space flex={1} />
        </Group>
        <Group gap="xs" align="flex-start">
          {generateAnalysisCell(OM)}
          {generatePlateCell(OM_ANALYSIS)}
          {generateBatchCell(OM_ANALYSIS, OM)}
          <Space flex={1} />
        </Group>
        <Divider />
      </Stack>
      <ChemistryResults
        analytics={analytics}
        chemistryResults={primarySampleResults}
        isFetching={isFetching}
      />
    </Stack>
  );
};
