import React from 'react';
import { PrescriptionType } from 'store/prescriptions/types';
import { getString } from 'strings/translation';
import { getNutrientName, getPrescriptionSummaryValues } from 'util/prescription';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { FieldType } from 'store/fields/types';
import styles from './Map.module.css';
import { Stack, Text } from '@mantine/core';

interface MapScriptProps {
  prescription: PrescriptionType | undefined;
  field: FieldType;
}

const MapScriptInfo = ({ prescription, field }: MapScriptProps) => {
  const language = useBroswerLanguage();

  const { inputs } = useSelector((state: RootState) => ({
    inputs: state.inputs.inputs,
  }));

  if (!prescription) {
    return null;
  }

  const { basedOn } = getPrescriptionSummaryValues(prescription, inputs, field, language);

  return (
    <Stack gap="xs" className={styles.CreationInfo}>
      <Text fw={700}>{prescription.name}</Text>
      <Text>
        <b>{getString('nutrient', language)}:</b> {getNutrientName(prescription.input)}
      </Text>
      <Text>
        <b>{getString('from', language)}:</b> {basedOn}
      </Text>
    </Stack>
  );
};

export default MapScriptInfo;
