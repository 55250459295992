import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Typeahead } from 'common';
import { useDebouncedCallback } from 'use-debounce';
import sortByName from 'util/sortByName';
import { capitalize } from 'util/stringUtils';
import { requestCountySearch } from 'store/salesFlow/requests';
import { CountyType } from 'store/salesFlow/types';
import showToast from 'actions/toastActions';
import { useDispatch } from 'react-redux';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { DEBOUNCE } from 'util/request';

type OperationSearchBarPropsType = {
  onSelect: (val: CountyType) => void;
  placeholder?: string;
  className?: string;
  onDeselect?: () => void;
};

type SelectorOptionType = CountyType & { id: number; displayName: string };

export const CountySearch = ({
  onSelect,
  placeholder,
  className,
  onDeselect,
}: OperationSearchBarPropsType) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const [filterName, setFilterName] = useState('');
  const [selectorOptions, setSelectorOptions] = useState<SelectorOptionType[]>([]);

  const typeaheadRef = useRef<HTMLInputElement>(null);

  const debounceFetchCounties = useDebouncedCallback(
    async (name: string) => {
      if (name) {
        try {
          const newCounties = await requestCountySearch(name);

          const counties = newCounties.map((county: CountyType) => {
            const countyCap = capitalize(county.county);
            const stateCap = capitalize(county.state);

            return {
              id: county.gid,
              gid: county.gid,
              displayName: `${countyCap}, ${stateCap}`,
              ctfips: county.ctfips,
            };
          });
          setSelectorOptions(sortByName([...counties]));
        } catch (e) {
          dispatch(showToast(getString('searchCountyError', language), 'error', 5000));
        }
      }
    },
    DEBOUNCE,
    { trailing: true },
  );

  useEffect(() => {
    debounceFetchCounties(filterName);
  }, [debounceFetchCounties, filterName]);

  const evListener = useCallback(() => {
    setFilterName(typeaheadRef.current?.value || '');
  }, []);

  const keyUpCreate = useCallback(() => {
    if (typeaheadRef.current) {
      typeaheadRef.current.focus();
      document.addEventListener('keyup', evListener, false);
    }
  }, [evListener]);

  const keyUpRemove = useCallback(() => {
    if (typeaheadRef.current) {
      document.removeEventListener('keyup', evListener, false);
    }
  }, [evListener]);

  const handleCountySelection = (idx: number) => {
    onSelect(selectorOptions[idx]);
  };

  return (
    <div onFocus={keyUpCreate} onBlur={keyUpRemove}>
      <Typeahead
        ref={typeaheadRef}
        onNewOption={() => {}}
        onSelect={handleCountySelection}
        options={selectorOptions}
        className={className}
        placeholder={placeholder}
        icon="SearchIcon"
        onDeselect={onDeselect}
      />
    </div>
  );
};
