import {
  RECEIVE_PRESCRIPTION_ZONES,
  REQUEST_PRESCRIPTION_ZONES,
  PRESCRIPTION_ZONE_REQUEST_ERROR,
  REQUEST_EXPORTED_PRESCRIPTIONS,
  RECEIVE_EXPORTED_PRESCRIPTIONS,
  PRESCRIPTION_EXPORT_REQUEST_ERROR,
  PrescriptionZoneType,
} from './types';

export const requestPrescriptionZones = {
  type: REQUEST_PRESCRIPTION_ZONES,
};

export const receivePrescriptionZones = (payload: {
  id: number;
  zones: PrescriptionZoneType[];
  acreageUnit: string;
}) => ({
  type: RECEIVE_PRESCRIPTION_ZONES,
  payload,
});

export const prescriptionZoneRequestError = {
  type: PRESCRIPTION_ZONE_REQUEST_ERROR,
};

export const requestExportedPrescriptions = {
  type: REQUEST_EXPORTED_PRESCRIPTIONS,
};

export const receiveExportedPrescription = (payload: {
  fieldId: number;
  exportedPrescriptions: any;
}) => ({
  type: RECEIVE_EXPORTED_PRESCRIPTIONS,
  payload,
});

export const prescriptionExportRequestError = {
  type: PRESCRIPTION_EXPORT_REQUEST_ERROR,
};
