import { DEM_ZONES, SSURGO_ZONES } from 'constants/samplePlanning';
import { PER_ACRE, PER_ZONE } from 'constants/variables';
import { AnalyticsFieldType } from 'store/analytics/types';
import { EOInferenceLayerType } from 'store/eoCollections/types';
import { RecommendationType } from 'store/recommendations/types';
import { SampleType } from 'store/samples/types';

export const SAMPLE_STATUSES = {
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  READY: 'ready',
  UNASSIGNED: 'unassigned',
  PARTIALLY_SAMPLED: 'partially sampled',
  PARTIALLY_RECEIVED: 'partially received',
  PARTIAL: 'partial',
};

export interface OperationAgencyListType {
  id: number;
  type: string;
  opAgId: number;
  displayName: string;
  name: string;
}

export interface BenchmarksDecilesInterface {
  percentile_10: number;
  percentile_20: number;
  percentile_30: number;
  percentile_40: number;
  percentile_50: number;
  percentile_60: number;
  percentile_70: number;
  percentile_80: number;
  percentile_90: number;
}

export interface BenchmarksInterface {
  [diseaseId: string]: {
    national: BenchmarksDecilesInterface | null;
    sample: BenchmarksDecilesInterface | null;
  };
}

export type AgronomicZonesType = typeof DEM_ZONES | typeof SSURGO_ZONES;

export type ProgressStatusType = {
  total_active_count: string;
  sampled_count: string;
  arrived_count: string;
  nutrient_status: string | null;
  pressure_status: string | null;
  performance_status: string | null;
  sampling_progress_status: 'completed' | 'in_progress' | 'not_started';
  analysis_progress_status: 'completed' | 'in_progress' | 'not_started';
  nutrient_ordered_count: string;
  pressure_ordered_count: string;
  performance_ordered_count: string;
};

export type PriceSummaryType = {
  [product: string]: {
    price_type?: typeof PER_ACRE | typeof PER_ZONE;
    name: string;
    num_zones: number;
    total: number;
    collection: number;
    commission: number;
  };
};

export type SamplePlanType = {
  is_till_rx: boolean;
  is_pro: boolean;
  temporary?: boolean;
  field: number;
  created_at: string;
  field_id: number;
  field_name: string;
  samples: SampleType[];
  created_by_id: number;
  operation_name?: string;
  created_by: number;
  assigned_to_id: null | number;
  assigned_user_name: string | null;
  assigned_user_role: string | null;
  assigned_to_scan_id: null | number;
  assigned_scan_user_name: string | null;
  assigned_scan_user_role: string | null;
  sample_progress_statuses: ProgressStatusType;
  id: number;
  sampling_status:
    | 'created'
    | 'unassigned'
    | 'ready'
    | 'partially sampled'
    | 'sampled'
    | 'partially received'
    | 'received'
    | 'partial'
    | 'partially shipped'
    | 'shipped'
    | 'completed'
    | 'cancelled';
  notes: null | string;
  updated_at: null | string;
  recommendations: RecommendationType[];
  benchmarks: null | BenchmarksInterface;
  delivered_at: null | string;
  ready_to_sample: boolean;
  sample_timing?: string;
  name: string;
  rnd: boolean;
  product: string;
  products: string[];
  density: number;
  analytics: AnalyticsFieldType;
  qpcr_processed?: boolean;
  shotgun_processed?: boolean;
  chemistry_processed?: boolean;
  nitrate_processed?: boolean;
  nutrient_panel_analyze_om?: boolean;
  price_summary: PriceSummaryType;
  zone_type?: string;
  sample_groupings: {
    products: string[];
    groupings: string[][];
  };
  crop_year?: number;
  sampled_at?: string;
  plan_type: 'split_density' | 'zone_by_zone';
  agency_id?: number | null;
  nutrient_layers: EOInferenceLayerType[];
};

export type ColumnSortType = { col: string | undefined; asc: boolean };

export type AlertType = {
  id: number;
  name: string;
};

export type SamplePlanTrackingType = {
  billing_user_id: number | null;
  field_name: string;
  field_farm_name: string | null;
  field_id: number;
  created_at: string;
  created_by_id: number;
  operation_id: number;
  operation_name: string;
  created_by: number;
  assigned_to_id: null | number;
  assigned_to_scan_id: null | number;
  assigned_user_name: string | null;
  assigned_to_first_name: string | null;
  assigned_to_last_name: string | null;
  assigned_user_role: string | null;
  assigned_scan_user_name: string | null;
  assigned_scan_user_role: string | null;
  field_centroid: { type: string; coordinates: number[] };
  calculated_area_acres: number;
  id: number;
  status:
    | 'created'
    | 'unassigned'
    | 'ready'
    | 'partially sampled'
    | 'sampled'
    | 'shipped'
    | 'partially shipped'
    | 'partially received'
    | 'received'
    | 'partial'
    | 'completed'
    | 'cancelled';
  notes: null | string;
  updated_at: null | string;
  delivered_at: null | string;
  ready_to_sample: boolean;
  sample_timing?: string;
  name: string;
  rnd: boolean;
  products: string[];
  density: number;
  zone_type?: string;
  days_marked_ready: number | null;
  alerts: AlertType[];
  not_ready_reason: string | null;
  sample_sampled_count: number;
  sample_received_count: number;
  sample_processed_count: number;
  sample_total_active_count: number;
  nutrient_completed_count: string;
  nutrient_not_analyzed_count: string;
  nutrient_ordered_count: string;
  pressure_completed_count: string;
  pressure_not_analyzed_count: string;
  pressure_ordered_count: string;
  performance_completed_count: string;
  performance_not_analyzed_count: string;
  performance_ordered_count: string;
  nitrate_ordered_count: string;
  nitrate_completed_count: string;
  agency_name: string;
  agency_id: number;
  is_pro: boolean;
  is_till_rx: boolean;
};

export const RECEIVE_SAMPLE_PLAN = 'RECEIVE_SAMPLE_PLAN';
export const RECEIVE_FIELD_SAMPLE_PLANS = 'RECEIVE_FIELD_SAMPLE_PLANS';
export const REQUEST_SAMPLE_PLAN = 'REQUEST_SAMPLE_PLAN';
export const SAMPLE_PLAN_REQUEST_ERROR = 'SAMPLE_PLAN_REQUEST_ERROR';

export const REQUEST_SAMPLE_PLANS_TRACKING = 'REQUEST_SAMPLE_PLANS_TRACKING';
export const RECEIVE_SAMPLE_PLANS_TRACKING = 'RECEIVE_SAMPLE_PLANS_TRACKING';
export const SAMPLE_PLAN_TRACKING_REQUEST_ERROR = 'SAMPLE_PLAN_TRACKING_REQUEST_ERROR';

type ReceiveSamplePlanType = {
  type: typeof RECEIVE_SAMPLE_PLAN;
  payload: { id: number };
};

type ReceiveFieldPlans = {
  type: typeof RECEIVE_FIELD_SAMPLE_PLANS;
  payload: {
    id: number;
    features: SamplePlanType[];
  };
};

type RequestSamplePlan = {
  type: typeof REQUEST_SAMPLE_PLAN;
  payload: { id: number };
};

type SamplePlanRequestError = {
  type: typeof SAMPLE_PLAN_REQUEST_ERROR;
  payload: { id: number };
};

type RequestSamplePlansTracking = {
  type: typeof REQUEST_SAMPLE_PLANS_TRACKING;
};

type SamplePlanTrackingRequestError = {
  type: typeof SAMPLE_PLAN_TRACKING_REQUEST_ERROR;
};

type ReceiveSamplePlanTrackingActionType = {
  type: typeof RECEIVE_SAMPLE_PLANS_TRACKING;
  payload: {
    plans: SamplePlanTrackingType[];
    page: number;
    per_page: number;
  };
};

export type SamplePlanActionsType =
  | ReceiveSamplePlanType
  | ReceiveFieldPlans
  | RequestSamplePlan
  | SamplePlanRequestError
  | RequestSamplePlansTracking
  | SamplePlanTrackingRequestError
  | ReceiveSamplePlanTrackingActionType;
