import { AppThunk } from 'store';
import { getZones, getExportedPrescriptionsByField } from './requests';
import {
  receivePrescriptionZones,
  requestPrescriptionZones,
  prescriptionZoneRequestError,
  requestExportedPrescriptions,
  receiveExportedPrescription,
  prescriptionExportRequestError,
} from './actions';

export const getExportedPrescriptions =
  (fieldId: number): AppThunk =>
  async (dispatch) => {
    dispatch(requestExportedPrescriptions);
    try {
      const response = await getExportedPrescriptionsByField(fieldId);
      dispatch(
        receiveExportedPrescription({
          fieldId: fieldId,
          exportedPrescriptions: response,
        }),
      );
    } catch (e) {
      dispatch(prescriptionExportRequestError);
    }
  };

const getZonesForPrescription =
  (prescriptionId: number, acreageUnit: string): AppThunk =>
  async (dispatch) => {
    dispatch(requestPrescriptionZones);
    try {
      const response = await getZones(prescriptionId);
      dispatch(
        receivePrescriptionZones({
          id: prescriptionId,
          zones: response.features,
          acreageUnit,
        }),
      );
    } catch (e) {
      dispatch(prescriptionZoneRequestError);
    }
  };

export default getZonesForPrescription;
