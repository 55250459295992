import React, { ChangeEvent, useEffect, useState } from 'react';
import { CloseButton, Combobox, Paper, Text, TextInput, useCombobox } from '@mantine/core';
import { useDebouncedCallback } from 'use-debounce';
import { useDispatch, useSelector } from 'react-redux';
import { FiSearch } from 'react-icons/fi';

import { RecommendationSetType } from 'store/recommendationSets/types';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { SEED, SEED_TREATMENT, TRAITS, TREATMENT } from 'constants/cropPlan';
import { AgronomicProductType, CropPlanType, SeedType } from 'store/cropPlans/types';
import { requestGetProductSearch, requestGetSeedSearch } from 'store/cropPlans/requests';
import { DEBOUNCE } from 'util/request';
import { getPestGroups } from 'store/cropPlans/thunks';
import showToast from 'actions/toastActions';
import { RootState } from 'store';
import { FONT_WEIGHT_BOLD, FONT_WEIGHT_STANDARD } from 'constants/mantine';
import { sortInputsByCoverage } from 'util/cropPlans';

import styles from './CropPlanChart.module.css';
import InputModal from 'apps/Product/EditRecommendationSets/Sections/ProductList/InputModal';

interface AddInputProps {
  cropPlan: CropPlanType;
  recSet: RecommendationSetType;
  selectedChartInfo: { label: string; value: string | number };
}

const AddInput = ({ cropPlan, recSet, selectedChartInfo }: AddInputProps) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const { pestGroups } = useSelector((state: RootState) => ({
    pestGroups: state.cropPlanning.pestGroups,
  }));

  const [products, setProducts] = useState<AgronomicProductType[]>([]);
  const [seeds, setSeeds] = useState<SeedType[]>([]);
  const [selectedSeed, setSelectedSeed] = useState<SeedType | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<AgronomicProductType | null>(null);
  const [productName, setProductName] = useState('');
  const [isSaving, toggleIsSaving] = useState(false);

  const showMessage = (message: string, type = '') => dispatch(showToast(message, type));

  const isSeed = [SEED, TRAITS].includes(selectedChartInfo.label);

  const debounceFetchProducts = useDebouncedCallback(
    async (name: string) => {
      try {
        toggleIsSaving(true);
        const response: AgronomicProductType[] = await requestGetProductSearch(name, cropPlan.crop);
        setProducts(response);
      } catch (error) {
        showMessage('Failed to fetch agronomic products.', 'error');
      } finally {
        toggleIsSaving(false);
      }
    },
    DEBOUNCE,
    { trailing: true },
  );
  const debounceFetchSeeds = useDebouncedCallback(
    async (name: string) => {
      try {
        toggleIsSaving(true);
        const response: SeedType[] = await requestGetSeedSearch(name, cropPlan.crop);
        setSeeds(response);
      } catch (error) {
        showMessage('Failed to fetch seeds.', 'error');
      } finally {
        toggleIsSaving(false);
      }
    },
    DEBOUNCE,
    { trailing: true },
  );

  useEffect(() => {
    const populatePestGroups = async () => {
      try {
        dispatch(getPestGroups());
      } catch (e) {
        showMessage('Failed to fetch pest groupings.', 'error');
      }
    };
    if (!pestGroups) {
      populatePestGroups();
    }
  }, [pestGroups]);

  const handleDropdownSelection = (str: string) => {
    if (isSeed) {
      const seed = seeds.find((s) => s.hybrid === str);
      if (seed) {
        setProductName(str);
        setSelectedSeed(seed);
        setSelectedProduct(null);
      }
    } else {
      const product = products.find((p) => p.name === str);
      if (product) {
        setProductName(str);
        setSelectedSeed(null);
        setSelectedProduct(product);
      }
    }
  };

  const options = (() => {
    const data = isSeed
      ? seeds.map((seed) => ({
          id: seed.id,
          displayName: seed.hybrid,
          fw: seed.coverage_ratings.length ? FONT_WEIGHT_BOLD : FONT_WEIGHT_STANDARD,
          coverage_ratings: seed.coverage_ratings,
        }))
      : products.map((product) => ({
          id: product.id,
          displayName: product.name,
          fw: product.coverage_ratings.length ? FONT_WEIGHT_BOLD : FONT_WEIGHT_STANDARD,
          coverage_ratings: product.coverage_ratings,
        }));
    const sorted = sortInputsByCoverage(data);
    return sorted.map((element) => (
      <Combobox.Option value={element.displayName} key={element.id}>
        {element.displayName}
      </Combobox.Option>
    ));
  })();

  const handleProductNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setProductName(name);
    if (name && !isSaving) {
      if (isSeed) {
        debounceFetchSeeds(name);
      } else {
        debounceFetchProducts(name);
      }
    }
  };

  const reset = () => {
    setSelectedProduct(null);
    setSelectedSeed(null);
    setProductName('');
  };

  const getDefaultCategory = () => {
    // we use 'treatment' in category labels to match naming of column groupings but 'seed_treatment' is
    // required for input categorization
    if (selectedChartInfo.label === TREATMENT) {
      return SEED_TREATMENT;
    }
    return selectedChartInfo.label;
  };

  return (
    <>
      <Paper className={styles.AddInput}>
        <Text fw={600} size="sm" w="100%">
          {getString(isSeed ? 'addSeedChart' : 'addProductChart', language)}
        </Text>
        <Combobox onOptionSubmit={handleDropdownSelection} store={combobox}>
          <Combobox.Target>
            <TextInput
              w="13rem"
              disabled={Boolean(selectedSeed || selectedProduct)}
              leftSection={<FiSearch />}
              rightSection={
                productName ? (
                  <CloseButton
                    onClick={reset}
                    style={{ display: selectedProduct || selectedSeed ? undefined : 'none' }}
                  />
                ) : null
              }
              onChange={handleProductNameChange}
              onClick={() => combobox.openDropdown()}
              onFocus={() => combobox.openDropdown()}
              onBlur={() => combobox.closeDropdown()}
              value={productName}
            />
          </Combobox.Target>

          <Combobox.Dropdown hidden={!options.length} onClick={() => combobox.closeDropdown()}>
            {options}
          </Combobox.Dropdown>
        </Combobox>
      </Paper>
      {(selectedProduct || selectedSeed) && (
        <InputModal
          closeModal={reset}
          defaultCategory={getDefaultCategory()}
          isTemporary
          modalOpened
          recSet={recSet}
          selectedProduct={selectedProduct}
          selectedSeed={selectedSeed}
        />
      )}
    </>
  );
};

export default AddInput;
