import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getUserSet } from 'store/user/thunks';
import { Header, Spinner, Pagination, IconInput } from 'common';
import { RootState } from 'store';
import { getRoles } from 'store/roles/thunks';
import { userIsSuperAdmin } from 'store/user/selectors';
import setToast from 'actions/toastActions';
import { useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { DEBOUNCE } from 'util/request';

import UsersTable from './UsersTable';
import styles from './Container.module.css';

const UserDashboardContainer = () => {
  const language = useBroswerLanguage();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [sortAttribute, setSortAttribute] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filterSearch, setFilterSearch] = useState('');
  const [hasLoaded, setHasLoaded] = useState(false);
  const dispatch = useDispatch();
  const fetchRoles = useCallback(() => dispatch(getRoles()), [dispatch]);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  const { isFetchingRoles, roles, users, isFetchingUsers, totalUsers, pageCount, isSuperAdmin } =
    useSelector((state: RootState) => ({
      isFetchingRoles: state.roles.isFetching,
      isFetchingUsers: state.user.isFetching,
      totalUsers: state.user.summary.total,
      pageCount: state.user.summary.per_page,
      roles: state.roles.roles,
      users: state.user.summary.users,
      isSuperAdmin: userIsSuperAdmin(state),
    }));

  const debounceFetchUsers = useDebouncedCallback(
    (name: string) => {
      dispatch(getUserSet(page, name, sortAttribute, sortOrder === 'desc'));
    },
    DEBOUNCE,
    { trailing: true },
  );

  useEffect(() => {
    debounceFetchUsers(filterSearch);
  }, [debounceFetchUsers, filterSearch, sortOrder, sortAttribute, page]);

  const setToastMessage = useCallback(
    (message: string, type?: string) => {
      dispatch(setToast(message, type));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!isFetchingUsers && !isFetchingRoles && users.length) {
      setHasLoaded(true);
    }
  }, [isFetchingUsers, isFetchingRoles, users]);

  const navigateToUser = (id: string | number) => {
    navigate(`/users/${id}`);
  };

  const setNewSearch = (text: string) => {
    setPage(1);
    setFilterSearch(text);
  };

  if (!hasLoaded) {
    return <Spinner fill />;
  }

  return (
    <div>
      <Header title={getString('manageUsers', language)}>
        {isFetchingUsers && <Spinner className={styles.Spinner} />}
        <IconInput
          className={styles.SearchBar}
          dataTestId="search-user"
          icon="SearchIcon"
          placeholder={getString('searchUsers', language)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewSearch(e.target.value)}
        />
      </Header>
      <UsersTable
        roles={roles}
        users={users}
        setToastMessage={setToastMessage}
        isSuperAdmin={isSuperAdmin}
        navigateToUser={navigateToUser}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        sortAttribute={sortAttribute}
        setSortAttribute={setSortAttribute}
        reloadUsers={() => debounceFetchUsers(filterSearch)}
      />
      <div className={styles.Pagination}>
        <Pagination
          page={page}
          setPage={setPage}
          totalPages={Math.ceil(totalUsers / pageCount) || 1}
        />
      </div>
    </div>
  );
};

export default UserDashboardContainer;
