import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { FieldType } from 'store/fields/types';
import { InputType } from 'store/inputs/types';
import { PrescriptionZoneType } from 'store/prescriptions/types';
import { SampleFeatureType } from 'store/samples/types';
import Settings from './Settings';
import ZoneDetail from './ZoneDetail';

import styles from './Container.module.css';

type SinglePrescriptionContainerProps = {
  field: FieldType;
  inputs: InputType[];
  samples: SampleFeatureType[];
  zones: PrescriptionZoneType[] | null;
  updatePrescriptionZones: (zoneId: number, mapValue: string) => void;
  setSelectedZone: (zoneId: number) => void;
  saveZoneAmount: (zoneId: number, amount: number) => void;
  setIsSubmitting: (value: boolean) => void;
};

type paramsType = {
  fieldId: string;
  operationId: string;
  prescriptionId: string;
};

const SinglePrescriptionContainer = ({
  field,
  inputs,
  samples,
  zones,
  updatePrescriptionZones,
  setSelectedZone,
  saveZoneAmount,
  setIsSubmitting,
}: SinglePrescriptionContainerProps) => {
  const navigate = useNavigate();
  const params = useParams<paramsType>();

  const fieldId = Number(params.fieldId);
  const operationId = Number(params.operationId);
  const prescriptionId = Number(params.prescriptionId);

  const navigateToPrescriptionList = useCallback(
    () => navigate(`/results/rx/${operationId}/${fieldId}/all`),
    [navigate, fieldId, operationId],
  );

  const prescription = useMemo(
    () => field.features[0].properties.prescriptions.find((rx) => rx.id === prescriptionId),
    [field, prescriptionId],
  );

  if (!prescription) {
    navigateToPrescriptionList();
    return null;
  }

  return (
    <div className={styles.Header}>
      <Settings
        existingZones={zones || []}
        field={field}
        inputs={inputs}
        prescription={prescription}
        samples={samples}
        setIsSubmitting={setIsSubmitting}
      />
      {zones && (
        <ZoneDetail
          field={field}
          inputs={inputs}
          prescription={prescription}
          samples={samples}
          zones={zones}
          updatePrescriptionZones={updatePrescriptionZones}
          setSelectedZone={setSelectedZone}
          saveZoneAmount={saveZoneAmount}
        />
      )}
    </div>
  );
};

export default SinglePrescriptionContainer;
