import React from 'react';
import { SamplingPlanType } from 'store/fields/types';
import { UserRole } from 'store/user/types';
import { capitalizeEveryWord, getWhiteLabelTranslation } from 'util/stringUtils';
import {
  CANCELLED,
  COMPLETED,
  CREATED,
  PARTIAL,
  PARTIALLY_RECEIVED,
  PARTIALLY_SAMPLED,
  PARTIALLY_SHIPPED,
  READY,
  RECEIVED,
  SAMPLED,
  SHIPPED,
  UNASSIGNED,
} from 'constants/samplePlanning';
import { NOT_APPLICABLE } from 'constants/defaultValues';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { ProgressStatus } from './ProgressStatus';
import styles from '../TableRow.module.css';
import { Stack, Text } from '@mantine/core';

type PlanStatusCellPropsType = {
  samplingPlan: SamplingPlanType;
};

const PlanStatusCell = ({ samplingPlan }: PlanStatusCellPropsType) => {
  const language = useBroswerLanguage();
  const canSample = Boolean(samplingPlan.products.length);

  const status = samplingPlan.sampling_status;
  const canScan = samplingPlan.is_pro || samplingPlan.is_till_rx;

  const getAssignUser = (altText: string) => {
    if (
      !samplingPlan.assigned_to_id ||
      !samplingPlan.assigned_user_name ||
      !samplingPlan.assigned_user_role
    ) {
      return getWhiteLabelTranslation(altText);
    }

    const prefix =
      samplingPlan.assigned_user_role === UserRole.Sampler ? getString('assignTo', language) : '';

    return `${prefix}${capitalizeEveryWord(samplingPlan.assigned_user_name)}`;
  };

  const getAssignScanUser = (altText: string) => {
    if (
      !samplingPlan.assigned_to_scan_id ||
      !samplingPlan.assigned_scan_user_name ||
      !samplingPlan.assigned_scan_user_role
    ) {
      return getWhiteLabelTranslation(altText);
    }

    const prefix =
      samplingPlan.assigned_scan_user_role === UserRole.Sampler
        ? getString('assignTo', language)
        : '';

    return `${prefix}${capitalizeEveryWord(samplingPlan.assigned_scan_user_name)}`;
  };

  const renderStatus = () => {
    switch (status) {
      case CREATED:
        if (samplingPlan.ready_to_sample) {
          return (
            <Stack gap="0.25rem">
              {canSample && (
                <Text>
                  {`${getString('readyToSample', language)} (${getAssignUser(NOT_APPLICABLE)})`}
                </Text>
              )}
              {canScan && (
                <Text>
                  {`${getString('readyToScan', language)} (${getAssignScanUser(NOT_APPLICABLE)})`}
                </Text>
              )}
            </Stack>
          );
        }
        return (
          <Stack gap="0.25rem">
            {canSample && (
              <Text>
                {`${getString('waitingToSample', language)} (${getAssignUser('Pattern Ag')})`}
              </Text>
            )}
            {canScan && (
              <Text>
                {`${getString('waitingToScan', language)} (${getAssignScanUser('Pattern Ag')})`}
              </Text>
            )}
          </Stack>
        );
      case READY:
        return (
          <Stack gap="0.25rem">
            {canSample && (
              <Text>
                {`${getString('readyToSample', language)} (${getAssignUser(NOT_APPLICABLE)})`}
              </Text>
            )}
            {canScan && (
              <Text>
                {`${getString('readyToScan', language)} (${getAssignScanUser(NOT_APPLICABLE)})`}
              </Text>
            )}
          </Stack>
        );
      case UNASSIGNED:
        return (
          <Stack gap="0.25rem">
            {canSample && (
              <Text>
                {`${getString('readyToSample', language)} (Pattern Ag - ${getString(
                  'unassigned',
                  language,
                )})`}
              </Text>
            )}
            {canScan && (
              <Text>
                {`${getString('readyToScan', language)} (Pattern Ag - ${getString(
                  'unassigned',
                  language,
                )})`}
              </Text>
            )}
          </Stack>
        );
      case PARTIALLY_RECEIVED:
      case PARTIALLY_SAMPLED:
      case SAMPLED:
      case PARTIALLY_SHIPPED:
      case SHIPPED:
      case RECEIVED:
      case PARTIAL:
        return <ProgressStatus samplingPlan={samplingPlan} className={styles.ProgressStatus} />;
      case COMPLETED:
        return <Text>{getString('complete', language)}</Text>;
      case CANCELLED:
        return <Text>{getString('cancelled', language)}</Text>;
      default:
        return <Text>N/A</Text>;
    }
  };

  return <div>{renderStatus()}</div>;
};

export default PlanStatusCell;
