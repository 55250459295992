import React, { useState, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Button, DropdownButton, Container, Label, Input } from 'common';
import showToast from 'actions/toastActions';
import {
  CALCULATOR_VALUES,
  dnaConDisplayNames,
  dnaConOptionsList,
  DNA_CONCENTRATION,
  TOTAL_DNA,
  TOTAL_PCR_DNA,
} from 'constants/DNAConcentration';
import { updatePlate } from 'store/plates/thunks';
import { DNAConcentrationOptionType } from 'store/dnaConcentration/types';

import { DNAWellGrid } from './DNAWellGrid';
import styles from './DNAConcentrationCalculator.module.css';

const { TARGET_DILUTION, TOTAL_DNA_MULTIPLIER, TOTAL_PCR_MULTIPLIER } = CALCULATOR_VALUES;

type DNAConcentrationCalculatorPropsType = {
  plateBarcode: string;
  quadrant?: number;
  targetDilution: string;
  totalDNAMultiplier: string;
  totalPCRMultiplier: string;
};

const DNAConcentrationCalculator = ({
  plateBarcode,
  quadrant = 0,
  targetDilution: plateTargetDilution,
  totalDNAMultiplier: plateTotalDNAMultiplier,
  totalPCRMultiplier: plateTotalPCRMultiplier,
}: DNAConcentrationCalculatorPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const [selectedOption, selectOption] = useState(DNA_CONCENTRATION);
  const [targetDilution, setTargeDilution] = useState(
    plateTargetDilution || TARGET_DILUTION.initialValue,
  );
  const [totalDNAMultiplier, setTotalDNAMultiplier] = useState(
    plateTotalDNAMultiplier || TOTAL_DNA_MULTIPLIER.initialValue,
  );
  const [totalPCRMultiplier, setTotalPCRMultiplier] = useState(
    plateTotalPCRMultiplier || TOTAL_PCR_MULTIPLIER.initialValue,
  );

  const [optionsJson, setOptionsJson] = useState<DNAConcentrationOptionType>({
    dna: true,
  });

  const getRequestOptions = (option: string) => ({
    target_plate_dilution: targetDilution,
    total_dna_multiplier: option === TOTAL_DNA ? totalDNAMultiplier : undefined,
    total_pcr_multiplier: option === TOTAL_PCR_DNA ? totalPCRMultiplier : undefined,
    [option as string]: true,
  });

  const handleOptionSelection = async (option: string) => {
    try {
      selectOption(option);
      await dispatch(
        updatePlate(plateBarcode, {
          target_plate_dilution: targetDilution,
          total_dna_multiplier: totalDNAMultiplier,
          total_pcr_multiplier: totalPCRMultiplier,
        }),
      );
      setOptionsJson(getRequestOptions(option));
    } catch (e) {
      dispatch(showToast(e.message, 'error'));
    }
  };

  return (
    <Container vertical>
      <DNAWellGrid plateBarcode={plateBarcode} quadrant={quadrant} options={optionsJson}>
        <DropdownButton
          title={getString(dnaConDisplayNames[selectedOption], language)}
          render={({ close }: { close: () => void }) =>
            dnaConOptionsList.map((option) => (
              <Button
                key={option}
                light
                square
                onClick={() => {
                  handleOptionSelection(option);
                  close();
                }}
              >
                {getString(dnaConDisplayNames[option], language)}
              </Button>
            ))
          }
        />
      </DNAWellGrid>
      <div className={styles.InputRow}>
        <Label label={getString('targetDilution', language)}>
          <Input
            className={styles.Input}
            value={targetDilution}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setTargeDilution(e.target.value)}
          />
        </Label>
        <Label label={getString('totalDnaMultiplier', language)}>
          <Input
            className={styles.Input}
            value={totalDNAMultiplier}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setTotalDNAMultiplier(e.target.value)}
          />
        </Label>
        <Label label={getString('pcrDnaMultiplier', language)}>
          <Input
            className={styles.Input}
            value={totalPCRMultiplier}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setTotalPCRMultiplier(e.target.value)}
          />
        </Label>
        <Button primary onClick={() => handleOptionSelection(selectedOption)}>
          {getString('recalculate', language)}
        </Button>
      </div>
    </Container>
  );
};

export default DNAConcentrationCalculator;
