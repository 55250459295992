import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Selector, TableRow, TableCol } from 'common';
import { User } from 'store/user/types';
import { RoleType } from 'store/roles/types';
import { submitUserDetails } from 'store/user/thunks';
import { isAgencyRole, userIsAgencyAdmin, userIsAgent } from 'util/userRoles';
import { NOT_APPLICABLE } from 'constants/defaultValues';
import { LANGUAGE_OPTIONS } from 'constants/languages';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';

import { AgencyReassignmentDialog } from './AgencyReassignment';
import styles from './UsersTable.module.css';

type UserRowPropsType = {
  roles: (RoleType & { displayName: string })[];
  user: User;
  setToastMessage: Function;
  isSuperAdmin: boolean;
  navigateToUser: Function;
  reloadUsers: Function;
};

const UserRow = ({
  roles,
  user,
  setToastMessage,
  isSuperAdmin,
  navigateToUser,
  reloadUsers,
}: UserRowPropsType) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const [showDialog, setShowDialog] = useState(false);

  const [activeRoleIndex, setActiveRoleIndex] = useState(
    roles.findIndex((role) => user.roles[0] && role.id === user.roles[0].id),
  );
  const [activeLocaleIndex, setActiveLocaleIndex] = useState(
    LANGUAGE_OPTIONS.findIndex((locale) => user.user_locale === locale.value),
  );

  const isAgencyUser = userIsAgent(user.roles) || userIsAgencyAdmin(user.roles);

  const onChangeRole = async (index: number) => {
    const newRole = roles[index];
    if (isAgencyRole(newRole)) {
      setShowDialog(true);
    } else {
      const requestBody = { email: user.email, role_id: newRole.id };
      setActiveRoleIndex(index);
      dispatch(submitUserDetails(requestBody, user.id, language, false, 'userRoleUpdated'));
      reloadUsers();
    }
  };

  const onChangeLocale = async (index: number) => {
    const user_locale = LANGUAGE_OPTIONS[index].value;
    const requestBody = { email: user.email, user_locale };
    setActiveLocaleIndex(index);
    dispatch(submitUserDetails(requestBody, user.id, language, false, 'userLocaleUpdated'));
  };

  const onAgencySubmit = (role: RoleType) => {
    setActiveRoleIndex(roles.findIndex((r) => r.id === role.id));
    reloadUsers();
    setToastMessage(getString('userRoleUpdated', language));
  };

  const getAgencyName = () => {
    if (user.agencies[0] && isAgencyUser) {
      return user.agencies[0]?.name;
    }
    return NOT_APPLICABLE;
  };

  return (
    <>
      {showDialog && (
        <AgencyReassignmentDialog
          user={user}
          onClose={() => setShowDialog(false)}
          onSubmit={onAgencySubmit}
        />
      )}
      <TableRow className={styles.RowHover}>
        <TableCol className={styles.Clickable}>
          <span onClick={() => navigateToUser(user.id)}>{user.first_name}</span>
        </TableCol>
        <TableCol className={styles.Clickable}>{user.last_name}</TableCol>
        <TableCol>{user.email}</TableCol>
        <TableCol>{getAgencyName()}</TableCol>
        <TableCol shrink className={styles.ShrinkedColumn}>
          <Selector
            className={styles.Selector}
            activeIndex={activeRoleIndex}
            onChange={onChangeRole}
            options={roles}
            disabled={!isSuperAdmin}
          />
        </TableCol>
        <TableCol shrink className={styles.ShrinkedColumn}>
          <Selector
            className={styles.Selector}
            activeIndex={activeLocaleIndex}
            onChange={onChangeLocale}
            options={LANGUAGE_OPTIONS}
            disabled={!isSuperAdmin}
          />
        </TableCol>
      </TableRow>
    </>
  );
};

export default UserRow;
