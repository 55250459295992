import React from 'react';
import { getString } from 'strings/translation';
import { Button } from 'common';
import { Menu } from '@mantine/core';
import { CREATED } from 'constants/samplePlanning';
import { SamplePlanTrackingType } from 'store/samplePlans/types';
import useBroswerLanguage from 'util/hooks/useLanguage';

type ActionButtonPropTypes = {
  samplePlan: SamplePlanTrackingType;
  toggleShowCancelModal: (value: boolean) => void;
  toggleMarkReadyModal: (value: boolean) => void;
  toggleMarkNotReadyModal: (value: boolean) => void;
  openAssignSampler: () => void;
  toggleAlertsModalData: ({ show, snooze }: { show: boolean; snooze: boolean }) => void;
};

const TrackingActionButton = ({
  samplePlan,
  toggleShowCancelModal,
  toggleMarkReadyModal,
  toggleMarkNotReadyModal,
  openAssignSampler,
  toggleAlertsModalData,
}: ActionButtonPropTypes) => {
  const language = useBroswerLanguage();

  const AlertButtons = [
    <Menu.Item
      key="snoozeAlerts"
      onClick={() => toggleAlertsModalData({ show: true, snooze: true })}
    >
      {getString('snoozeAlerts', language)}
    </Menu.Item>,
    <Menu.Item
      key="dismissAlerts"
      onClick={() => toggleAlertsModalData({ show: true, snooze: false })}
    >
      {getString('dismissAlerts', language)}
    </Menu.Item>,
  ];

  return (
    <Menu shadow="md" width={200} trigger="hover">
      <Menu.Target>
        <Button>{getString('actions', language)}</Button>
      </Menu.Target>
      <Menu.Dropdown>
        {samplePlan.alerts.length ? AlertButtons : null}
        <Menu.Item
          key="editPlan"
          disabled={samplePlan.status !== CREATED}
          onClick={() => {
            window.open(
              `/zone-analysis/${samplePlan.operation_id}/${samplePlan.field_id}`,
              '_blank',
            );
          }}
        >
          {getString('editSamplePlan', language)}
        </Menu.Item>
        <Menu.Item
          key="cancelPlan"
          disabled={samplePlan.status !== CREATED}
          onClick={() => {
            toggleShowCancelModal(true);
          }}
        >
          {getString('cancelSample', language)}
        </Menu.Item>
        <Menu.Item
          key="assignSampler"
          disabled={samplePlan.status !== CREATED}
          onClick={() => {
            openAssignSampler();
          }}
        >
          {getString('assignSampler', language)}
        </Menu.Item>
        <Menu.Item
          key="markReady"
          disabled={samplePlan.status !== CREATED || samplePlan.ready_to_sample}
          onClick={() => {
            toggleMarkReadyModal(true);
          }}
        >
          {getString('markReady', language)}
        </Menu.Item>
        <Menu.Item
          key="markNotReady"
          disabled={samplePlan.status !== CREATED || !samplePlan.ready_to_sample}
          onClick={() => {
            toggleMarkNotReadyModal(true);
          }}
        >
          {getString('markNotReady', language)}
        </Menu.Item>
        <Menu.Item
          key="navigate"
          onClick={() => {
            window.open(`/orders/${samplePlan.operation_id}`, '_blank');
          }}
        >
          {getString('goToPlan', language)}
        </Menu.Item>
        <Menu.Item
          key="viewSampleStatus"
          onClick={() => {
            window.open(
              `/view-sample-status/${samplePlan.operation_id}/${samplePlan.field_id}/${samplePlan.id}`,
              '_blank',
            );
          }}
        >
          {getString('viewSampleStatus', language)}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default TrackingActionButton;
