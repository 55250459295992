import { getLocalStorage } from 'util/localStorage';
import { splitOutListProducts } from 'util/pricing';
import {
  AgencyActionType,
  AgencyStateType,
  RECEIVE_ALL_AGENCIES,
  RECEIVE_MY_AGENCIES,
  REQUEST_AGENCIES,
  RECEIVE_AGENCY_ASSOCIATED_USERS,
  AGENCIES_REQUEST_ERROR,
  RECEIVE_SINGLE_AGENCY,
  USER_AGENCY_ID,
  REQUEST_AGENCY_USERS,
  RECEIVE_AGENCY_CONTRACTS,
  REQUEST_AGENCY_CONTRACTS,
  RECEIVE_DEFAULT_PRICES,
  RECEIVE_MIN_MAX_PRICES,
  REQUEST_MY_AGENCIES,
  RECEIVE_AGENCY_RESULT_PREFERENCES,
  RECEIVE_AGENCY_CONTRACT_METRICS,
  RECEIVE_AGENCY_CONTRACT_TIERS,
} from './types';

export const initialState: AgencyStateType = {
  currentAgency: null,
  allAgencies: [],
  myAgencies: [],
  allUsers: [],
  byId: {},
  default_prices: {},
  min_max_prices: {},
  contractTiers: {},
  hasFailed: false,
  hasFetched: false,
  hasFetchedMyAgencies: false,
  isFetching: true,
  isFetchingUsers: true,
  isFetchingMyAgencies: true,
  isFetchingContracts: true,
  hasFailedList: [],
  hasFetchedList: [],
  isFetchingList: [],
};

export const AgencyReducer = (state = initialState, action: AgencyActionType) => {
  switch (action?.type) {
    case REQUEST_AGENCIES:
      return {
        ...state,
        isFetching: true,
        isFetchingList: action.payload?.id
          ? state.isFetchingList.concat(Number(action.payload.id))
          : state.isFetchingList,
      };
    case REQUEST_AGENCY_USERS:
      return {
        ...state,
        isFetchingUsers: true,
      };
    case RECEIVE_SINGLE_AGENCY:
      return {
        ...state,
        currentAgency:
          action.payload?.id === Number(getLocalStorage(USER_AGENCY_ID))
            ? action.payload
            : state.currentAgency,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            ...action.payload,
          },
        },
        hasFetched: true,
        isFetching: false,
      };
    case RECEIVE_ALL_AGENCIES:
      return {
        ...state,
        allAgencies: action.payload.agencies,
        hasFetched: true,
        isFetching: false,
      };
    case REQUEST_MY_AGENCIES:
      return {
        ...state,
        isFetchingMyAgencies: true,
      };
    case RECEIVE_MY_AGENCIES:
      return {
        ...state,
        myAgencies: action.payload.agencies,
        hasFetchedMyAgencies: true,
        isFetchingMyAgencies: false,
      };
    case RECEIVE_AGENCY_ASSOCIATED_USERS:
      return {
        ...state,
        allUsers: action.payload.users,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            allUsers: action.payload.users,
          },
        },
        isFetchingUsers: false,
      };
    case RECEIVE_AGENCY_RESULT_PREFERENCES:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            result_preferences: action.payload.preferences,
          },
        },
      };
    case REQUEST_AGENCY_CONTRACTS:
      return {
        ...state,
        isFetchingContracts: true,
      };
    case RECEIVE_AGENCY_CONTRACTS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.agencyId]: {
            ...state.byId[action.payload.agencyId],
            pricing_contracts: action.payload.contracts.agency.map((contract) =>
              splitOutListProducts(contract),
            ),
            parent_pricing_contracts: action.payload.contracts.parent_agency.map((contract) =>
              splitOutListProducts(contract),
            ),
          },
        },
        isFetchingContracts: false,
      };
    case RECEIVE_AGENCY_CONTRACT_METRICS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.agencyId]: {
            ...state.byId[action.payload.agencyId],
            metricsByContractId: {
              ...(state.byId[action.payload.agencyId].metricsByContractId || {}),
              [action.payload.contractId]: action.payload.metrics,
            },
          },
        },
      };
    case RECEIVE_DEFAULT_PRICES:
      return {
        ...state,
        default_prices: action.payload,
      };
    case RECEIVE_MIN_MAX_PRICES:
      return {
        ...state,
        min_max_prices: action.payload,
      };
    case RECEIVE_AGENCY_CONTRACT_TIERS:
      return {
        ...state,
        contractTiers: {
          ...state.contractTiers,
          [action.payload.agencyId]: action.payload.contractTiers,
        },
      };
    case AGENCIES_REQUEST_ERROR:
      return {
        ...state,
        hasFailed: true,
        isFetching: false,
        hasFailedList: action.payload?.id
          ? state.hasFailedList.concat(Number(action.payload.id))
          : state.hasFailedList,
        isFetchingList: state.isFetchingList.filter((id) => id !== action.payload.id),
      };
    default:
      return state;
  }
};
