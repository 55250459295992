import React, { useEffect } from 'react';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/charts/styles.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PrivateRoleRoute from 'common/routes/PrivateRoleRoute';
import { Center, Loader, MantineProvider, createTheme, Button, Modal, Title } from '@mantine/core';
import { activeOperation as activeOperationSelector } from 'selectors/operationSelectors';
import {
  ACTIVE_OPERATION_ID,
  ACTIVE_USER_ID,
  LOADING_FETCHES,
  REFRESH_INTERVAL,
} from 'constants/auth';
import { getLocalStorage } from 'util/localStorage';
import { setActiveOperation } from 'store/operation/thunks';
import routes from 'routes';
import useFirebase from 'util/hooks/useFirebase';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import AuthProvider, { useAuth } from 'Auth';
import { getUserStartPage } from 'util/auth';
import { getInitialMeta } from 'store/user/thunks';
import cornCollage from 'images/sales/cornCollage.png';
import soybeanCollage from 'images/sales/soybeanCollage.png';
import fieldOverview from 'images/sales/fieldOverview.png';
import farmerBucket from 'images/sales/farmerBucket.png';
import resultsDisplay from 'images/sales/resultsDisplay.png';
import heatMap from 'images/sales/heatMap.png';
import aerialField from 'images/sales/aerialField.png';
import { userIsAgencyAdmin, userIsAgent } from 'util/userRoles';
import { fetchCollections } from 'store/eoCollections/thunks';

const App = () => {
  const auth = useAuth();
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const firebase = useFirebase();

  const { activeOperation, currentUser, defaultOperationId, hasFailed } = useSelector(
    (state: RootState) => ({
      activeOperation: activeOperationSelector(state),
      currentUser: state.user.currentUser,
      defaultOperationId: state.operations.activeOperationId,
      hasFailed: state.user.hasFailed,
    }),
  );

  const preLoadImages = () => {
    const imagesToPreload = [
      cornCollage,
      soybeanCollage,
      fieldOverview,
      farmerBucket,
      resultsDisplay,
      heatMap,
      aerialField,
    ];
    imagesToPreload.forEach((image) => {
      new Image().src = image;
    });
  };

  useEffect(() => {
    preLoadImages();
  }, []);

  // Logged in
  useEffect(() => {
    if (auth.isLoggedIn && auth.fetchMetaStatus === LOADING_FETCHES.NOT_STARTED) {
      auth.setFetchMetaStatus(LOADING_FETCHES.LOADING);
      dispatch(getInitialMeta(getString('failedToFetchMsg', language), auth.setFetchMetaStatus));
    }
  }, [auth.isLoggedIn, auth.fetchMetaStatus]);

  useEffect(() => {
    if (auth.isLoggedIn && !activeOperation) {
      const localOperationId = getLocalStorage(ACTIVE_OPERATION_ID);
      if (localOperationId) {
        setActiveOperation(Number(localOperationId));
      } else if (defaultOperationId) {
        setActiveOperation(defaultOperationId);
      }
    }
  }, [auth.isLoggedIn, defaultOperationId, activeOperation]);

  useEffect(() => {
    if (auth.isLoggedIn && currentUser) {
      const { agencies, can_scan, roles } = currentUser;
      const isAgencyUser = userIsAgencyAdmin(roles) || userIsAgent(roles);
      if (can_scan || (isAgencyUser && agencies[0]?.self_scanning)) {
        dispatch(fetchCollections());
      }
    }
  }, [auth.isLoggedIn, currentUser]);

  // fetch user & refresh token on the initial load or login
  useEffect(() => {
    const activeUserId = getLocalStorage(ACTIVE_USER_ID);
    if (activeUserId) {
      if (!auth.isLoggedIn) {
        getUserStartPage(Number(activeUserId), dispatch, auth);
        auth.setLoggedIn(true);
      }
    } else {
      auth.redirectToLogin();
      auth.setLoadingAuth(false);
    }
  }, [auth.isLoggedIn]);

  useEffect(() => {
    if (auth.isLoggedIn && location.pathname.startsWith('/auth/login')) {
      navigate('/');
    }
  }, [auth.isLoggedIn, location]);

  // set up periodic refresh once the user is logged in
  useEffect(() => {
    if (auth.isLoggedIn) {
      const refreshInterval = setInterval(() => auth.refresh('errorLoggedInMsg'), REFRESH_INTERVAL);
      return () => clearInterval(refreshInterval);
    }
    return () => {};
  }, [auth.isLoggedIn]);

  useEffect(() => {
    if (hasFailed) {
      auth.logout('errorLoggingInMsg');
    }
  }, [hasFailed]);

  useEffect(() => {
    firebase.pageView(window.location.href, location.pathname, document?.title);
  }, [firebase, location]);

  if (auth.loadingAuth || auth.fetchMetaStatus === LOADING_FETCHES.LOADING) {
    return (
      <Center h="100vh">
        <Loader />
      </Center>
    );
  }

  return (
    <Routes>
      <Route path="/*" element={<div>{getString('fourOFourMsg', language)}</div>} />
      {routes.map(({ layout, component, path, ...rest }) => {
        const LayoutComponent = layout;
        const PageComponent = component;
        if (rest.isAgentRoute) {
          // TODO: REMOVE PageComponent Props when Lab app is updated to TSX
          // They're causing lint errors for the rest all components
          return (
            <Route
              key={path}
              path={path}
              {...rest}
              element={
                <PrivateRoleRoute {...rest}>
                  <LayoutComponent>
                    <PageComponent />
                  </LayoutComponent>
                </PrivateRoleRoute>
              }
            />
          );
        }
        return (
          <Route
            key={path}
            path={path}
            {...rest}
            element={
              <LayoutComponent>
                <PageComponent />
              </LayoutComponent>
            }
          />
        );
      })}
    </Routes>
  );
};

const mantineTheme = createTheme({
  // fontFamily: 'Roboto, sans-serif',
  primaryColor: 'blue',
  fontSmoothing: true,
  primaryShade: 0,
  black: '#003166', // Dark Blue
  colors: {
    blue: [
      '#003166',
      '#00448d',
      '#0057b4',
      '#006adc',
      '#047dff',
      '#2b91ff',
      '#52a5ff',
      '#7abaff',
      '#a1ceff',
      '#c8e2ff',
    ],
    grey: [
      '#9d9d9d',
      '#a6a6a6',
      '#b0b0b0',
      '#bababa',
      '#c4c4c4',
      '#cecece',
      '#d7d7d7',
      '#e1e1e1',
      '#ebebeb',
      '#f5f5f5',
    ],
    green: [
      '#578712',
      '#649a15',
      '#70ad18',
      '#7DC11B',
      '#8ac731',
      '#97cd48',
      '#a4d35f',
      '#b1d976',
      '#bee08d',
      '#48FD81',
    ],
  },
  components: {
    Button: Button.extend({
      defaultProps: {
        radius: 'md',
        variant: 'filled',
        styles: {
          inner: { fontSize: 14, fontWeight: 550 },
        },
      },
    }),
    ModalTitle: Modal.Title.extend({
      defaultProps: {
        styles: {
          title: {
            fontSize: 20,
            fontWeight: 550,
          },
        },
      },
    }),
    Title: Title.extend({
      defaultProps: {
        styles: {
          root: {
            fontWeight: 400,
          },
        },
      },
    }),
  },
});

const AppWrapper = () => {
  // Context is null initially, so this allows it to be created.
  // ex) auth.isLoggedIn would throw an error
  // Creating a default context throws off the flow
  return (
    <AuthProvider>
      <MantineProvider theme={mantineTheme}>
        <App />
      </MantineProvider>
    </AuthProvider>
  );
};

export default AppWrapper;
