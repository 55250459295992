export const ACRES_IN_HECTARE = 2.471;

export const TONS_IN_METRIC_TON = 1.10231;

export const LB_ACRES_IN_KG_HA = 1.121;

export const BU_AC_IN_SACA_HA = 2.2;

export const KG_IN_LBS = 2.205;

export const KG_IN_METRIC_TON = 1000;

export const LBS_IN_TON = 2000;

export const REAL_IN_DOLLAR = 5.26;

export const PLAN_TRACKING_PAGE_SIZE = 500;
