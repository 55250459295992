import {
  RECEIVE_FIELD_SAMPLE_PLANS,
  REQUEST_SAMPLE_PLAN,
  SamplePlanActionsType,
  SAMPLE_PLAN_REQUEST_ERROR,
  SamplePlanType,
  RECEIVE_SAMPLE_PLANS_TRACKING,
  SamplePlanTrackingType,
  REQUEST_SAMPLE_PLANS_TRACKING,
  SAMPLE_PLAN_TRACKING_REQUEST_ERROR,
} from './types';
import { PLAN_TRACKING_PAGE_SIZE } from 'constants/translations';

export type SamplePlanStateType = {
  plans: { [id: number]: SamplePlanType[] };
  hasFailedList: number[];
  hasFetchedList: number[];
  isFetchingList: number[];
  isFetchingTracking: boolean;
  hasFailedTracking: boolean;
  summary: {
    items: { [page: number]: SamplePlanTrackingType[] };
    page: number;
    per_page: number;
    isLastPage: boolean;
  };
};

export const initialState: SamplePlanStateType = {
  hasFailedList: [],
  hasFetchedList: [],
  isFetchingList: [],
  plans: {},
  isFetchingTracking: true,
  hasFailedTracking: false,
  summary: {
    items: {},
    page: 1,
    per_page: PLAN_TRACKING_PAGE_SIZE,
    isLastPage: false,
  },
};

export const SamplePlanReducer = (
  state = initialState,
  action: SamplePlanActionsType,
): SamplePlanStateType => {
  switch (action.type) {
    case RECEIVE_FIELD_SAMPLE_PLANS:
      return {
        ...state,
        hasFetchedList: state.hasFetchedList.concat(action.payload.id),
        isFetchingList: state.isFetchingList.filter((id) => id !== action.payload.id),
        plans: {
          ...state.plans,
          [action.payload.id]: action.payload.features,
        },
      };
    case REQUEST_SAMPLE_PLAN:
      return {
        ...state,
        isFetchingList: state.isFetchingList.concat(action.payload.id),
      };
    case SAMPLE_PLAN_REQUEST_ERROR:
      return {
        ...state,
        hasFailedList: state.hasFailedList.concat(action.payload.id),
        isFetchingList: state.isFetchingList.filter((id) => id !== action.payload.id),
      };
    case REQUEST_SAMPLE_PLANS_TRACKING:
      return {
        ...state,
        isFetchingTracking: true,
      };
    case RECEIVE_SAMPLE_PLANS_TRACKING:
      return {
        ...state,
        isFetchingTracking: false,
        summary: {
          ...state.summary,
          items:
            action.payload.page === 1
              ? { [action.payload.page]: action.payload.plans }
              : { ...state.summary.items, [action.payload.page]: action.payload.plans },
          page: action.payload.page,
          isLastPage: action.payload.per_page / PLAN_TRACKING_PAGE_SIZE !== 1,
        },
      };
    case SAMPLE_PLAN_TRACKING_REQUEST_ERROR:
      return {
        ...state,
        isFetchingTracking: false,
        hasFailedTracking: true,
      };
    default:
      return state;
  }
};
