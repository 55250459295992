import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Collapse, Group, Stack, Table, Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import { LabSampleType } from 'store/labSamples/types';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { CANCELLED } from 'constants/samples';
import { requestQRCode } from 'store/samples/requests';
import { printSampleBarcode } from 'util/printFormat';
import showToast from 'actions/toastActions';

type SplitDensitySummaryPropsType = {
  primarySample: LabSampleType;
  samples: LabSampleType[];
};

export const SplitDensitySummary = ({ primarySample, samples }: SplitDensitySummaryPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const [opened, { toggle }] = useDisclosure(false);

  const groupSamples = samples
    .filter((sample) => sample.sample_grouping_id === primarySample.sample_grouping_id)
    .filter((sample) => sample.status !== CANCELLED)
    .sort((s1, s2) => {
      if (s1.biological_subsample && !s2.biological_subsample) {
        return -1;
      }
      return s2.biological_subsample && !s1.biological_subsample ? 1 : 0;
    });
  const standardSamples = groupSamples.filter((sample) => !sample.biological_subsample);
  const subsample = groupSamples.find((sample) => sample.biological_subsample);

  const numArrived = standardSamples.filter((sample) => sample.arrived_at !== null).length;

  const handlePrint = async () => {
    try {
      if (subsample) {
        const response = await requestQRCode(subsample.barcode);
        const qrCodeUrl = response.url;
        const printWindow = window.open('', '_blank');
        const qrCodeImage = new Image();
        qrCodeImage.src = qrCodeUrl;
        qrCodeImage.onload = () => {
          printWindow?.document.open();
          printWindow?.document.write(printSampleBarcode(subsample.barcode, qrCodeUrl));
          printWindow?.document.close();
        };
      }
    } catch (error) {
      dispatch(showToast(error));
    }
  };

  const chevronDisplay = () => {
    return opened ? <FiChevronUp /> : <FiChevronDown />;
  };

  return (
    <>
      <Group align="start" justify="space-between">
        <Stack gap="0">
          <Text fw={700}>{getString('splitDensityGroup', language)}</Text>
          <Text size="sm" fw={700}>{subsample?.id}</Text>
        </Stack>
        <Stack gap="0">
          <Text fw={700}>{getString('arrived', language)}</Text>
          <Text size="sm">
            {numArrived} {getString('of', language)} {standardSamples.length}
          </Text>
        </Stack>
        <Stack gap="0">
          <Text fw={700}>{getString('splitDensityBarcode', language)}</Text>
          <Text size="sm">{subsample?.barcode}</Text>
        </Stack>
        <Stack align="center">
          <Button variant="outline" style={{ width: 'fit-content' }} onClick={toggle}>
            <Text fw={700}>{getString('barcodes', language)}</Text>
            {chevronDisplay()}
          </Button>
        </Stack>
        <Button onClick={handlePrint} variant="outline">
          <Text style={{ width: 'fit-content' }}>
            {getString('printSplitDensityBarcode', language)}
          </Text>
        </Button>
      </Group>
      <Collapse in={opened}>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>{getString('sampleBarcode', language)}</Table.Th>
              <Table.Th>{getString('biologicalSubsample', language)}</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {groupSamples.map((sample) => (
              <Table.Tr key={sample.id}>
                <Table.Td>
                  {sample.barcode ? (
                    <Link to={`/lab/samples/${sample.barcode}`}>{sample.barcode}</Link>
                  ) : (
                    <Text>{sample.barcode || getString('pending', language)}</Text>
                  )}
                </Table.Td>
                <Table.Td>
                  {getString(sample.biological_subsample ? 'yes' : 'no', language)}
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Collapse>
    </>
  );
};
