import { SET_TOAST } from 'constants/actionTypes';

const setToastMessage = (payload) => ({ type: SET_TOAST, payload });

const showToast =
  (message, type = 'standard', timeout = 3000) =>
  (dispatch) => {
    dispatch(setToastMessage({ message, type }));
    setTimeout(() => dispatch(setToastMessage({ message: null, type: null })), timeout);
  };

export default showToast;
