import React, { useState } from 'react';
import { Button, InputWithUnits, Label } from 'common';
import { useNavigate } from 'react-router-dom';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { useDispatch, useSelector } from 'react-redux';
import setToast from 'actions/toastActions';
import { RootState } from 'store';
import { displayPriceOrBar } from 'util/salesFlow';
import { getAcreageUnitFromLang } from 'util/units';
import { CORN, SOYBEANS } from 'constants/variables';
import { requestPutOperation } from 'store/operation/requests';
import SlideStyles from '../Shared.module.css';
import Styles from './Container.module.css';
import OfferTable from './OfferTable';

interface OfferContainerProps {
  operationId: number;
}

const OfferContainer = ({ operationId }: OfferContainerProps) => {
  const navigate = useNavigate();
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const { operationCropInfo } = useSelector((state: RootState) => ({
    operationCropInfo: state.salesFlow.byOperationId[operationId],
  }));
  const { average_total_return, total_acres } = operationCropInfo.summary;
  const { crop_plans } = operationCropInfo;
  const cornPlan = crop_plans.find((plan) => plan.crop === CORN);
  const soybeanPlan = crop_plans.find((plan) => plan.crop === SOYBEANS);

  const [loading, setLoading] = useState<boolean>(false);
  const [lockedInAcres, setLockedInAcres] = useState<number>(
    cornPlan && soybeanPlan ? cornPlan.acreage + soybeanPlan.acreage : 0,
  );
  const lockInOffer = async () => {
    try {
      setLoading(true);
      await requestPutOperation(operationId, {
        locked_in_acres: lockedInAcres,
      });
      navigate(`/orders/${operationId}`);
    } catch (e) {
      dispatch(setToast(getString('lockInOfferErrorMsg', language), 'error', 5000));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className={SlideStyles.Header}>
        <b>
          {operationCropInfo.crop_plans[0].operation.name} {getString('report', language)} -{' '}
          {operationCropInfo.crop_plans[0].county.county},{' '}
          {operationCropInfo.crop_plans[0].county.state}
        </b>
      </div>
      <div className={SlideStyles.BorderBottom} />
      <div className={SlideStyles.Images}>
        <div className={Styles.OfferInfo}>
          <ul>
            <li>
              {getString('estimatedReturnAcross', language)}:{' '}
              {displayPriceOrBar(average_total_return * total_acres, '')}
            </li>
            <li>{getString('lockInAcresNow', language)}</li>
            <li>{getString('noRiskCancel', language)}</li>
          </ul>
        </div>
        <div className={Styles.OfferInfo}>
          <OfferTable operationId={operationId} />
          <div className={Styles.MarginTop}>
            <Label className={Styles.InputLabel} label={getString('lockedInAcres', language)}>
              <InputWithUnits
                type="number"
                units={getAcreageUnitFromLang(language)}
                className={Styles.InputLabel}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setLockedInAcres(Number(e.target.value))
                }
                value={String(lockedInAcres)}
              />
            </Label>
            <Button primary onClick={lockInOffer} disabled={loading}>
              {getString('lockInOfferToday', language)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferContainer;
