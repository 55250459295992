import { AppThunk } from 'store';
import { collectionRequestError, receiveEOCollections, requestEOCollections } from './actions';
import { deleteCollection, getCollections, requestConfirmCollections } from './requests';
import { CollectionConfirmationPayloadType } from './types';

export const fetchCollections = (): AppThunk => async (dispatch) => {
  try {
    dispatch(requestEOCollections);
    const collections = await getCollections();
    dispatch(receiveEOCollections(collections));
  } catch (e) {
    dispatch(collectionRequestError);
  }
};

export const disableCollection =
  (id: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(requestEOCollections);
      await deleteCollection(id);
      const collections = await getCollections();
      dispatch(receiveEOCollections(collections));
    } catch (e) {
      dispatch(collectionRequestError);
    }
  };

export const confirmCollectionAssignment =
  (body: CollectionConfirmationPayloadType): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(requestEOCollections);
      await requestConfirmCollections(body);
      const collections = await getCollections();
      dispatch(receiveEOCollections(collections));
    } catch (e) {
      dispatch(collectionRequestError);
    }
  };
