import { AppThunk } from 'store';
import showToast from 'actions/toastActions';
import { requestRoles, receiveRoles, roleRequestError } from './actions';
import { requestAllRoles } from './requests';

export const getRoles = (): AppThunk => async (dispatch) => {
  dispatch(requestRoles);
  try {
    const response = await requestAllRoles();
    dispatch(receiveRoles(response.role));
  } catch (e) {
    dispatch(showToast(e.message, 'error'));
    dispatch(dispatch(roleRequestError));
  }
};
