import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import mapboxgl from 'mapbox-gl';
import { RootState } from 'store';
import { FiSearch } from 'react-icons/fi';
import { ActionIcon, Divider, Group, Select, Stack, Text, Title } from '@mantine/core';
import { Typeahead } from 'common/Components/Mantine/Typeahead';
import { MultiSelect } from 'common/Components/Mantine/MultiSelect';
import { HeatMapAnalyticType } from 'store/heatMap/types';
import { AnalyticType } from 'store/analytics/types';
import { CORN, SOYBEANS, SUGAR_BEETS } from 'constants/variables';
import { HIGH_RISK, LOW_RISK, MODERATE_RISK } from 'constants/fieldRisks';
import {
  extractOperationsFromHeatMap,
  getAnalyticsForCategory,
  getCategoriesForCrop,
  getHeatMapForAnalytic,
  getHeatMapForOperation,
  getRiskLevelOptions,
} from 'util/heatMap';
import { getRecommendationSet } from 'store/recommendationSets/thunks';
import { PATTERN_RECOMMENDATION_SET } from 'constants/results';
import { Agency } from 'store/agencies/types';
import { ReactComponent as cornIcon } from 'images/icons/crops/corn.svg';
import { ReactComponent as soybeanIcon } from 'images/icons/crops/soybean.svg';
import { ReactComponent as sugarBeetsIcon } from 'images/icons/crops/sugarBeets.svg';
import { SummaryBar } from './Common/SummaryBar';
import { AnalyticsRow } from './Common/AnalyticsRow';
import HeatMap from './Map/HeatMap';
import { HeatList } from './List/HeatList';

type HeatMapFilterPropsType = {
  heatMap: HeatMapAnalyticType[];
  analytics: AnalyticType[];
  agency?: Agency;
};

const HeatMapFilter = ({ heatMap, analytics, agency }: HeatMapFilterPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const [mapView, setMapView] = useState(true);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const mapContainerRef = useRef(null);

  const [operationId, setOperationId] = useState<number | null>();
  const operationOptions = useMemo(() => extractOperationsFromHeatMap(heatMap), [heatMap]);
  const handleSelectOperation = (operation: any) => {
    setOperationId(operation);
  };
  const handleClearOperation = () => {
    setOperationId(null);
  };

  const recSetId = agency?.recommendation_set_id || PATTERN_RECOMMENDATION_SET;
  const { recSet } = useSelector((state: RootState) => ({
    recSet: state.recommendationSets.byId[recSetId],
  }));

  useEffect(() => {
    dispatch(getRecommendationSet(recSetId));
  }, [recSetId]);

  const [crop, setCrop] = useState(CORN);

  const categoryOptions = getCategoriesForCrop(language, crop);
  const [category, setCategory] = useState(categoryOptions[0].value);

  const initialAnalytics = getAnalyticsForCategory(crop, category, analytics);
  const [activeAnalytic, setActiveAnalytic] = useState(initialAnalytics[0]);

  const riskLevelOptions = getRiskLevelOptions(language, [HIGH_RISK, MODERATE_RISK, LOW_RISK]);
  const [riskLevels, setRiskLevels] = useState<string[]>([]);

  useEffect(() => {
    // If category is no longer available, set to first in list
    if (categoryOptions.length && categoryOptions.findIndex((o) => o.value === category) < 0) {
      setCategory(categoryOptions[0].value);
    }
  }, [crop, category]);

  const operationHeatMap = operationId ? getHeatMapForOperation(heatMap, operationId) : heatMap;
  const activeAnalyticHeatMap = activeAnalytic
    ? getHeatMapForAnalytic(operationHeatMap, activeAnalytic.id)
    : [];
  const activeHeatMapWithRiskFilter = activeAnalytic
    ? getHeatMapForAnalytic(operationHeatMap, activeAnalytic.id, ...riskLevels)
    : [];

  const categoryAnalytics = useMemo(
    () => initialAnalytics.filter((a) => getHeatMapForAnalytic(operationHeatMap, a.id).length),
    [crop, category, analytics, heatMap, operationId],
  );
  useEffect(() => {
    // If crop, category, or analytics list change, reset the active analytic
    if (categoryAnalytics?.length) {
      setActiveAnalytic(categoryAnalytics[0]);
    }
  }, [categoryAnalytics]);

  const availableCrops = useMemo(() => {
    const cropList = [
      {
        label: getString(CORN, language),
        value: CORN,
        icon: cornIcon,
      },
      {
        label: getString(SOYBEANS, language),
        value: SOYBEANS,
        icon: soybeanIcon,
      },
      {
        label: getString(SUGAR_BEETS, language),
        value: SUGAR_BEETS,
        icon: sugarBeetsIcon,
      },
    ];
    return cropList;
  }, [heatMap, operationId]);

  return (
    <Stack>
      <Stack>
        <Typeahead
          onSelect={handleSelectOperation}
          onDeselect={handleClearOperation}
          data={operationOptions}
          placeholder={'Search for Account'}
          leftSection={<FiSearch />}
          w="30rem"
        />
        <Group gap="lg">
          <Group gap="0.5rem">
            <Text>{`${getString('show', language)}:`}</Text>
            <Select
              onChange={(value: string | null) => value && setCategory(value)}
              data={categoryOptions}
              value={category}
              w="14rem"
            />
            <Text>+</Text>
            <MultiSelect
              data={riskLevelOptions}
              value={riskLevels}
              onChange={setRiskLevels}
              all={getString('allRiskLevels', language)}
              clearable
            />
          </Group>
          <Divider color="blue" variant="dashed" orientation="vertical" />
          <Group gap="md">
            {availableCrops.map(({ value: c, icon: CropIcon }) => (
              <ActionIcon
                key={c}
                variant="outline"
                onClick={() => setCrop(c)}
                size="lg"
                style={
                  c === crop
                    ? {
                        outline: '2px solid var(--mantine-color-orange-3)',
                        borderColor: 'var(--mantine-color-orange-3)',
                      }
                    : {}
                }
              >
                <CropIcon />
              </ActionIcon>
            ))}
          </Group>
        </Group>
        <Divider size="sm" />
      </Stack>
      <Stack>
        <Title order={4}>{getString('percentOfFieldsAtModerateOrHighRisk', language)}</Title>
        {activeAnalytic && (
          <AnalyticsRow
            activeAnalytic={activeAnalytic}
            setActiveAnalytic={setActiveAnalytic}
            analytics={categoryAnalytics}
            heatMap={operationHeatMap}
          />
        )}
        <SummaryBar
          analyticId={activeAnalytic?.id}
          heatMap={activeAnalyticHeatMap}
          setMapView={setMapView}
          mapView={mapView}
        />
        <Divider size="sm" />
      </Stack>
      {activeAnalytic &&
        (mapView ? (
          <HeatMap
            mapContainerRef={mapContainerRef}
            mapRef={mapRef}
            heatMap={activeHeatMapWithRiskFilter}
            analytic={activeAnalytic}
          />
        ) : (
          <HeatList
            heatMap={activeHeatMapWithRiskFilter}
            analytic={activeAnalytic}
            recSet={recSet}
          />
        ))}
    </Stack>
  );
};

export default HeatMapFilter;
