import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { printSampleBarcode } from 'util/printFormat';
import { LabSampleType } from 'store/labSamples/types';
import { requestQRCode } from 'store/samples/requests';

type SampleQRCodePropsType = {
  sample: LabSampleType;
};

export const SampleQRCode = ({ sample }: SampleQRCodePropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const handlePrint = async () => {
    try {
      const response = await requestQRCode(sample.barcode);
      const qrCodeUrl = response.url;
      const printWindow = window.open('', '_blank');
      const qrCodeImage = new Image();
      qrCodeImage.src = qrCodeUrl;
      qrCodeImage.onload = () => {
        printWindow?.document.open();
        printWindow?.document.write(printSampleBarcode(sample.barcode, qrCodeUrl));
        printWindow?.document.close();
      };
    } catch (error) {
      dispatch(showToast(error));
    }
  };

  return (
    <Button variant="outline" onClick={handlePrint}>
      {getString('printQRCode', language)}
    </Button>
  );
};
