import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useMapboxGl from 'common/MapHooks';
import {
  Feature,
  FeatureCollection,
  Point,
  Polygon,
  feature,
  featureCollection,
} from '@turf/helpers';
import mapboxgl, { GeoJSONSource, GeoJSONSourceRaw, LngLatBoundsLike } from 'mapbox-gl';
import { Button } from 'common';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getMapPinColor, MapMarkers } from 'util/mapImageryColors';
import MAPBOX_CONSTANTS, { MARKERS, MARKERS_SELECTED, MODES } from 'constants/mapbox';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import turfBbox from '@turf/bbox';
import { getFieldGeometry } from 'store/fields/thunks';
import { SamplePlanTrackingType } from 'store/samplePlans/types';
import { createRoot } from 'react-dom/client';
import { getString } from 'strings/translation';
import removeMapLayer from 'util/mapbox';
import AssignSampler from '../Orders/FieldList/FullProductReport/OrderButtons/AssignSampler';
import styles from './TrackingMap.module.css';
import createTrackingMapPopup from './TrackingMapPopup';
import { BulkAssignSampler } from './BulkAssign';
import showToast from 'actions/toastActions';
import booleanIntersects from '@turf/boolean-intersects';

const DEFAULT = 'default';
const BULK_ASSIGN = 'bulk_assign';

interface TrackingMapProps {
  mapContainerRef: { current: HTMLDivElement | null };
  mapRef: { current: mapboxgl.Map | null };
  drawRef: { current: any | null };
  setMapSearchBounds: (bounds: number[][] | null) => void;
  currentMapBounds: number[][] | null;
  toggleInitSearch: (val: boolean) => void;
}

interface ViewPortProps {
  centerLongitude?: number;
  centerLatitude?: number;
  latitude: number;
  longitude: number;
  zoom: number;
  width?: number;
  height?: number;
}

const TrackingMap = ({
  mapContainerRef,
  mapRef,
  drawRef,
  setMapSearchBounds,
  currentMapBounds,
  toggleInitSearch,
}: TrackingMapProps) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [centerLongitude, centerLatitude] = [-97, 37];
  const [tempBounds, setTempBounds] = useState<number[][] | null>(null);
  const [clickMode, setClickMode] = useState<typeof DEFAULT | typeof BULK_ASSIGN>(DEFAULT);
  const [isDrawing, toggleIsDrawing] = useState<boolean>(false);
  const { samplePlans, fieldGeometries } = useSelector((state: RootState) => ({
    samplePlans: state.samplePlans.summary.items,
    fieldGeometries: state.fieldGeometry.geometries,
  }));
  const samplePlanArr = useMemo(
    () => Object.values(samplePlans).flatMap((samplePlan) => samplePlan),
    [samplePlans],
  );

  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [bulkAssignSamplerModal, toggleBulkAssignSamplerModal] = useState(false);
  const [assignSamplerModal, toggleAssignSamplerModal] = useState(false);
  const [assignSamplerPlan, setAssignSamplerPlan] = useState<SamplePlanTrackingType | null>(null);
  const [viewport, setViewport] = useState<ViewPortProps>({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: 4,
    width: 0,
    height: 0,
  });
  const [planIds, setPlanIds] = useState(new Set());

  useMapboxGl(
    mapContainerRef,
    mapRef,
    drawRef,
    viewport,
    setViewport,
    () => {},
    true,
    false,
    MAPBOX_CONSTANTS.styleOutdoors,
  );

  const openAssignSampler = async (plan: SamplePlanTrackingType) => {
    toggleAssignSamplerModal(true);
    setAssignSamplerPlan(plan);
    if (!fieldGeometries[plan.field_id]) {
      dispatch(getFieldGeometry(plan.field_id));
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.on('load', () => {
        Object.values(MapMarkers).forEach((marker, index) => {
          mapRef.current?.loadImage(marker.image, (error, image) => {
            if (error) {
              throw error;
            }
            // @ts-ignore
            mapRef.current?.addImage(marker.name, image);
            if (index === Object.values(MapMarkers).length - 1) {
              setIsMapLoaded(true);
            }
          });
        });
      });
    }
  }, [mapRef]);

  useEffect(() => {
    const planArrSet = new Set(samplePlanArr.map((samplePlan) => samplePlan.id));
    if (planIds !== planArrSet && isMapLoaded && mapRef.current?.getSource(`markers`)) {
      mapRef.current?.removeLayer(`markers`);
      mapRef.current?.removeSource(`markers`);
    }
    setPlanIds(planArrSet);
  }, [samplePlanArr, isMapLoaded, mapRef]);

  useEffect(() => {
    if (isMapLoaded && mapRef.current) {
      mapRef.current.on('moveend', () => {
        const canvas = mapRef.current?.getCanvas();
        const w = canvas?.width;
        const h = canvas?.height;
        if (w && h) {
          const upperLeft = mapRef.current?.unproject([0, 0]).toArray();
          const upperRight = mapRef.current?.unproject([w, 0]).toArray();
          const bottomRight = mapRef.current?.unproject([w, h]).toArray();
          const bottomLeft = mapRef.current?.unproject([0, h]).toArray();
          if (upperLeft && upperRight && bottomRight && bottomLeft) {
            setTempBounds([upperLeft, upperRight, bottomRight, bottomLeft, upperLeft]);
          }
        }
      });
    }
  }, [isMapLoaded, mapRef]);

  const getSelectedPlans = () => {
    try {
      // dangling _ otherwise, just catch it and return []
      const { _data } = mapRef.current?.getSource(MARKERS_SELECTED) as any;
      return (_data as FeatureCollection).features.reduce(
        (list, feat) =>
          feat.properties?.samplePlan ? [...list, feat.properties?.samplePlan] : list,
        [] as SamplePlanTrackingType[],
      );
    } catch (err) {
      return [];
    }
  };

  const setupSelectedMarkersLayer = (markers: FeatureCollection) => {
    const source = {
      type: 'geojson',
      data: markers,
    } as GeoJSONSourceRaw;
    mapRef.current?.addLayer({
      id: MARKERS_SELECTED,
      type: 'symbol',
      source,
      layout: {
        'icon-image': ['get', 'pinColor'],
        'icon-allow-overlap': true,
      },
    });
  };

  const onBulkAssignClick = useCallback(
    (e: any) => {
      const plan = JSON.parse(e.features[0].properties.samplePlan);

      if (plan.id) {
        const newPlanFeat = feature(plan.field_centroid, {
          samplePlan: plan,
          pinColor: MapMarkers.RED_MARKER.name,
        });

        if (mapRef.current?.getLayer(MARKERS_SELECTED)) {
          const { _data } = mapRef.current?.getSource(MARKERS_SELECTED) as any;
          const alreadyHighlighted = (_data as FeatureCollection).features.some(
            (feat) => feat?.properties?.samplePlan?.id === plan.id,
          );
          const zoneAddedOrRemoved = alreadyHighlighted
            ? (_data as FeatureCollection).features.filter(
                (feat) => feat.properties?.samplePlan?.id !== plan.id,
              )
            : [_data.features, newPlanFeat].flat();
          const highlightLayer = featureCollection(zoneAddedOrRemoved as Feature<Point>[]);
          (mapRef.current?.getSource(MARKERS_SELECTED) as GeoJSONSource).setData(highlightLayer);
        } else {
          setupSelectedMarkersLayer(featureCollection([newPlanFeat]));
        }
      }
    },
    [mapRef],
  );

  const onCreatePolygon = useCallback(
    (e: FeatureCollection<Polygon>) => {
      try {
        const feat = e.features[0];
        const { _data } = mapRef.current?.getSource(MARKERS) as any;
        const insideRectangle = (_data as FeatureCollection).features.reduce(
          (featList, planFeat) => {
            if (booleanIntersects(feat, planFeat)) {
              const updatedFeat = {
                ...planFeat,
                properties: {
                  ...planFeat.properties,
                  pinColor: MapMarkers.RED_MARKER.name,
                },
              } as Feature<Point>;
              return featList.concat([updatedFeat]);
            }
            return featList;
          },
          [] as Feature<Point>[],
        );
        const highlightLayer = featureCollection(insideRectangle as Feature<Point>[]);
        if (mapRef.current?.getLayer(MARKERS_SELECTED)) {
          (mapRef.current?.getSource(MARKERS_SELECTED) as GeoJSONSource).setData(highlightLayer);
        } else {
          setupSelectedMarkersLayer(highlightLayer);
        }
        drawRef.current?.deleteAll();
        handleToggleDraw(false);
      } catch (err) {
        dispatch(showToast(err.message));
      }
    },
    [mapRef, drawRef],
  );

  const handleToggleDraw = (enable: boolean) => {
    if (mapRef.current) {
      if (enable) {
        drawRef.current?.changeMode(MODES.DRAW_RECTANGLE);
        mapRef.current?.on('draw.create', onCreatePolygon);
      } else {
        mapRef.current?.off('draw.create', onCreatePolygon);
        drawRef.current?.changeMode(MODES.STATIC);
        drawRef.current?.deleteAll();
      }
    }
    toggleIsDrawing(enable);
  };

  const handleSetClickBehavior = (mode: typeof DEFAULT | typeof BULK_ASSIGN) => {
    if (mapRef.current) {
      if (mode === BULK_ASSIGN) {
        mapRef.current.on('click', MARKERS, onBulkAssignClick);
      } else {
        mapRef.current.off('click', MARKERS, onBulkAssignClick);
        removeMapLayer(mapRef, MARKERS_SELECTED);
        drawRef.current?.deleteAll();
      }
      setClickMode(mode);
    }
  };

  useEffect(() => {
    if (
      isMapLoaded &&
      samplePlanArr.length &&
      mapRef.current &&
      !mapRef.current?.getSource(`markers`)
    ) {
      const popups = document.querySelectorAll('.mapboxgl-popup');
      popups.forEach((popup) => popup.remove());
      const source = {
        type: 'geojson',
        data: featureCollection(
          samplePlanArr.map((samplePlan) =>
            feature(samplePlan.field_centroid, {
              samplePlan,
              pinColor: getMapPinColor(samplePlan),
            }),
          ),
        ),
      } as GeoJSONSourceRaw;
      mapRef.current?.addLayer({
        id: MARKERS,
        type: 'symbol',
        source: source,
        layout: {
          'icon-image': ['get', 'pinColor'],
          'icon-allow-overlap': true,
        },
      });
      const bbox = turfBbox(source.data) as LngLatBoundsLike;
      mapRef.current.fitBounds(bbox, {
        padding: 50,
        maxZoom: 10,
      });
      const zoom = mapRef.current.getZoom();
      setViewport((prevViewport) => ({
        ...prevViewport,
        centerLatitude,
        centerLongitude,
        zoom: zoom > 13 ? zoom : 10,
      }));
      mapRef.current.on('click', MARKERS, (e: any) => {
        const plan = JSON.parse(e.features[0].properties.samplePlan);
        const pop = createTrackingMapPopup({ plan, openAssignSampler, language });

        const placeholder = document.createElement('div');
        const root = createRoot(placeholder);
        root.render(pop);
        if (mapRef.current instanceof mapboxgl.Map) {
          new mapboxgl.Popup({
            closeOnClick: true,
          })
            .setLngLat(e.lngLat)
            .setDOMContent(placeholder)
            .addTo(mapRef.current as mapboxgl.Map);
        }
      });
      handleSetClickBehavior(DEFAULT);

      mapRef.current.on('mouseenter', MARKERS, () => {
        if (mapRef.current) {
          mapRef.current.getCanvas().style.cursor = 'pointer';
        }
      });

      mapRef.current.on('mouseleave', MARKERS, () => {
        if (mapRef.current) {
          mapRef.current.getCanvas().style.cursor = '';
        }
      });
    }
  }, [samplePlanArr, mapRef.current, isMapLoaded]);

  return (
    <div className={styles.Wrapper}>
      {assignSamplerModal && assignSamplerPlan && (
        <AssignSampler
          samplePlan={assignSamplerPlan}
          fieldId={assignSamplerPlan.field_id}
          onClose={() => toggleAssignSamplerModal(false)}
          onSubmit={() => toggleInitSearch(true)}
          getFieldsWithUpdates={() => {}}
          billing_user_id={assignSamplerPlan.billing_user_id}
          opened={assignSamplerModal}
        />
      )}
      {bulkAssignSamplerModal && (
        <BulkAssignSampler
          samplePlans={getSelectedPlans()}
          onClose={() => toggleBulkAssignSamplerModal(false)}
          onSubmit={() => {
            handleSetClickBehavior(DEFAULT);
            toggleInitSearch(true);
          }}
        />
      )}
      <div className={styles.MapWrapper} ref={wrapperRef}>
        <div ref={mapContainerRef} className={styles.MapWrapper} />
        <div className={styles.MapButtons}>
          <Button onClick={() => tempBounds && setMapSearchBounds(tempBounds)}>
            {getString('searchCurrentBounds', language)}
          </Button>
          {!!currentMapBounds && (
            <Button danger onClick={() => tempBounds && setMapSearchBounds(null)}>
              {getString('removeSearchBounds', language)}
            </Button>
          )}
        </div>
        <div className={styles.MapButtonsBottomRow}>
          {clickMode === DEFAULT ? (
            <Button onClick={() => handleSetClickBehavior(BULK_ASSIGN)}>
              {getString('bulkAssign', language)}
            </Button>
          ) : (
            <>
              <Button onClick={() => toggleBulkAssignSamplerModal(true)}>
                {getString('clickToAssignPlans', language)}
              </Button>
              <Button onClick={() => handleToggleDraw(!isDrawing)} primary={isDrawing}>
                {getString('drawRectangle', language)}
              </Button>
              <Button onClick={() => handleSetClickBehavior(DEFAULT)} danger>
                {getString('cancel', language)}
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrackingMap;
