import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Header, Spinner, Table, TableCol, TableRow } from 'common';
import { getAllFeatureFlags } from 'store/featureFlags/thunks';
import { FeatureFlagType } from 'store/featureFlags/types';
import { RootState } from 'store';
import showToast from 'actions/toastActions';
import sortByName from 'util/sortByName';

import styles from './Container.module.css';

const FeatureFlagsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { errorMessage, flags, hasFetched } = useSelector((state: RootState) => state.featureFlags);

  useEffect(() => {
    dispatch(getAllFeatureFlags());
  }, [dispatch]);

  useEffect(() => {
    if (errorMessage) {
      dispatch(showToast(errorMessage, 'error'));
    }
  }, [dispatch, errorMessage]);

  if (!hasFetched && !flags.length) {
    return <Spinner fill />;
  }

  const sortedFlags = sortByName(flags);

  const getRow = (flag: FeatureFlagType) => {
    const { enabled_for_all: enabledForAll, description, id, name } = flag;

    return (
      <TableRow key={id}>
        <TableCol>
          <Link to={`/product/feature_flag/${id}`}>{name}</Link>
        </TableCol>
        <TableCol>{description}</TableCol>
        <TableCol>{enabledForAll ? 'Yes' : 'No'}</TableCol>
      </TableRow>
    );
  };

  return (
    <div className={styles.Wrapper}>
      <Header title="Feature Flags">
        <Button onClick={() => navigate('/product/feature_flag/create')} primary>
          Create
        </Button>
      </Header>
      <Table className={styles.Table}>
        <TableRow className={styles.TableHeader}>
          <TableCol>Name</TableCol>
          <TableCol>Description</TableCol>
          <TableCol>Enabled for all?</TableCol>
        </TableRow>
        {sortedFlags.map(getRow)}
      </Table>
    </div>
  );
};

export default FeatureFlagsContainer;
