export const HIGH_RISK = 'high';
export const MODERATE_RISK = 'moderate';
export const LOW_RISK = 'low';

export const BINARY_RISK = 'binary';

export const AWAITING_RESULTS = 'awaiting_results';
export const NOT_ANALYZED = 'not_analyzed';
export const NO_DATA = 'no_data';
export const ND = 'ND';
export const NOT_DETECTED = 'not_detected';
export const AWAITING_RESULTS_LANG = 'awaitingResults';
export const NOT_ANALYZED_LANG = 'notAnalyzed';
export const NO_DATA_LANG = 'noData';

export const RISK_AREA_TYPES = {
  BINARY: 'binary',
  LOW_MODERATE_HIGH: 'low_moderate_high',
  HIGH_MODERATE_LOW: 'high_moderate_low',
  LOW_MODERATE: 'low_moderate',
  MODERATE_LOW: 'moderate_low',
  MODERATE_LOW_MODERATE: 'moderate_low_moderate',
};

export const riskLevels = [
  HIGH_RISK,
  MODERATE_RISK,
  LOW_RISK,
  AWAITING_RESULTS,
  NOT_ANALYZED,
  NO_DATA,
  NOT_DETECTED,
];

export const riskToDisplay = {
  [HIGH_RISK as string]: 'highRisk',
  [MODERATE_RISK as string]: 'moderateRisk',
  [LOW_RISK as string]: 'lowRisk',
  [AWAITING_RESULTS as string]: 'pending',
  [NOT_ANALYZED as string]: 'notAnalyzed',
  [NO_DATA as string]: 'noData',
  [NOT_DETECTED as string]: 'notDetected',
};

export const lowModHighRiskLevels = [LOW_RISK, MODERATE_RISK, HIGH_RISK];
