import React, { FunctionComponent, ReactNode, useMemo, useEffect } from 'react';

import { getString } from 'strings/translation';
import Plus from 'images/icons/plus.png';
import useBrowserLanguage from 'util/hooks/useLanguage';
import styles from './PrintHeaderFooter.module.css';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { getIsPatternSite, hostNameLogoParse } from 'util/auth';
import classNames from 'classnames';
import { Box, Text } from '@mantine/core';

interface FooterProps {
  children: ReactNode;
}

type paramsType = {
  operationId: string;
};

const PrintHeaderFooter: FunctionComponent<FooterProps> = ({ children }) => {
  const operationId = Number(useParams<paramsType>().operationId);
  const language = useBrowserLanguage();
  const [logo, setLogo] = React.useState<string | null>(null);
  const isPattern = getIsPatternSite();

  const { agencies, operation } = useSelector((state: RootState) => ({
    agencies: state.agencies.allAgencies,
    operation: state.operations.operationsById[operationId],
  }));

  useEffect(() => {
    const logoFromUrl = hostNameLogoParse();
    if (logoFromUrl) {
      setLogo(logoFromUrl);
    }
  }, []);

  const agencyLogo = useMemo(() => {
    const findAgencyByOperationBillingUser = agencies?.find(
      (agency) => operation?.billing_user_id === agency.primary_user.id,
    );
    return findAgencyByOperationBillingUser?.agency_logo_url;
  }, [agencies, operation]);

  return (
    <Box className={styles.Body}>
      <Box className={classNames(styles.PrintOnly, styles.Header)}>
        {logo && (
          <img
            alt="Pattern Logo"
            className={styles.Logo}
            data-test-id="pattern-logo-print"
            src={logo}
          />
        )}
        {isPattern && agencyLogo && (
          <>
            <img alt="Plus" className={styles.Plus} src={Plus} />
            <img alt="Agency Logo" className={styles.Logo} src={agencyLogo} />
          </>
        )}
        <Text size="lg" fw={600} fs="italic" className={styles.HeaderText}>
          {getString('printHeader', language)}
        </Text>
      </Box>
      {children}
      <Box className={classNames(styles.PrintOnly, styles.Footer)}>
        <Text fw={500}>WWW.PATTERN.AG</Text>
      </Box>
    </Box>
  );
};

export default PrintHeaderFooter;
