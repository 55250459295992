import React, { useEffect } from 'react';
import { Center, Loader, Stack, useCombobox } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useDispatch, useSelector } from 'react-redux';

import setToast from 'actions/toastActions';
import { RootState } from 'store';
import { getPestGroups } from 'store/cropPlans/thunks';
import { RecommendationSetType } from 'store/recommendationSets/types';

import ProductSearch from './ProductSearch';
import SeedCatalog from './SeedCatalog';
import CropProtectionCatalog from './CropProtectionCatalog';

type ProductListPropsType = {
  recSet: Partial<RecommendationSetType>;
  onError: (msg: string) => void;
  isSaving: boolean;
  toggleIsSaving: (saving: boolean) => void;
};

const ProductList = ({ onError, isSaving, recSet, toggleIsSaving }: ProductListPropsType) => {
  const [opened, { open, close }] = useDisclosure(false);
  const dispatch = useDispatch();

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const { analytics, pestGroups } = useSelector((state: RootState) => ({
    analytics: state.analytics.analytics,
    pestGroups: state.cropPlanning.pestGroups,
  }));

  useEffect(() => {
    const populatePestGroups = async () => {
      try {
        dispatch(getPestGroups());
      } catch (e) {
        onError('Failed to fetch pest groupings.');
      }
    };
    if (!pestGroups) {
      populatePestGroups();
    }
  }, [pestGroups]);

  const setToastMessage = (message: string, type?: string, time?: number) =>
    dispatch(setToast(message, type, time));

  if (!pestGroups) {
    return (
      <Center h="20vh">
        <Loader />
      </Center>
    );
  }

  return (
    <Stack>
      <ProductSearch
        closeModal={close}
        isSaving={isSaving}
        onError={onError}
        modalOpened={opened}
        openModal={open}
        recSet={recSet}
        toggleIsSaving={toggleIsSaving}
      />
      <Stack gap="xl">
        <SeedCatalog
          analytics={analytics}
          pestGroups={pestGroups}
          recSet={recSet}
          seeds={Object.values(recSet.recommended_seeds || {}).map(
            (recSeed) => recSeed.seed_metadata,
          )}
          setToastMessage={setToastMessage}
        />
        <CropProtectionCatalog
          analytics={analytics}
          recSet={recSet}
          products={Object.values(recSet.recommended_products || {}).map(
            (recProd) => recProd.product_metadata,
          )}
          pestGroups={pestGroups}
          setToastMessage={setToastMessage}
        />
      </Stack>
    </Stack>
  );
};

export default ProductList;
