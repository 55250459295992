import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { Accordion } from 'common';
import { Button, Center, Stack } from '@mantine/core';
import { AnalyticType } from 'store/analytics/types';
import { FieldType } from 'store/fields/types';
import { InputType } from 'store/inputs/types';
import { SampleFeatureType } from 'store/samples/types';

import CreatePrescriptionModal from './CreatePrescriptionModal';
import PrescriptionSummary from './PrescriptionSummary';

type PrescriptionListProps = {
  analytics: AnalyticType[];
  field: FieldType;
  inputs: InputType[];
  samples: SampleFeatureType[];
};

type paramsType = {
  fieldId: string;
  operationId: string;
  prescriptionId: string;
};

const PrescriptionListContainer = ({
  analytics,
  field,
  inputs,
  samples,
}: PrescriptionListProps) => {
  const language = useBroswerLanguage();
  const { id: fieldId, operation_id } = field.features[0].properties;
  const params = useParams<paramsType>();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(params.prescriptionId === 'create');
  const [showListDetails, setShowListDetails] = useState(true);
  const onSubmit = useCallback(
    (id: number) => {
      setShowModal(false);
      navigate(`/results/rx/${operation_id}/${fieldId}/${id}`);
    },
    [fieldId, navigate, operation_id],
  );
  return (
    <>
      <Stack>
        <Center>
          <Button variant="outline" disabled={!samples?.length} onClick={() => setShowModal(true)}>
            + {getString('createNutrientRx', language)}
          </Button>
        </Center>
        <Accordion
          key="prescriptions"
          title={getString('nutrientRx', language)}
          toggleOpen={() => setShowListDetails(!showListDetails)}
          open={showListDetails}
        >
          {showListDetails
            ? field.features[0].properties.prescriptions
                .filter((prescription) => prescription.show_in_ui)
                .map((rx) => (
                  <PrescriptionSummary
                    field={field}
                    inputs={inputs}
                    key={`summary-${rx.id}`}
                    prescription={rx}
                  />
                ))
            : null}
        </Accordion>
      </Stack>
      {!!field && analytics?.length && samples?.length && (
        <CreatePrescriptionModal
          analytics={analytics}
          field={field}
          onClose={() => setShowModal(false)}
          onSubmit={onSubmit}
          samples={samples}
          opened={showModal}
        />
      )}
    </>
  );
};

export default PrescriptionListContainer;
